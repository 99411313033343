export default class ChatRoomPhotoCardResponse {
  constructor({
    tradingPhotoCardId,
    photoCardId,
    memberId,
    producibleType,
    producibleId,
    filename,
    name,
    visibility,
    artistId,
    memberName,
    eventId,
    eventName,
    eventSlug,
    pocaname,
    pictureUrl,
    collectedCount,
    chatRoomsCount,
    isWished,
    price,
    category,
    receivedReviews,
    sentReviews,
  }) {
    this.tradingPhotoCardId = tradingPhotoCardId;
    this.photoCardId = photoCardId;
    this.memberId = memberId;
    this.producibleType = producibleType;
    this.producibleId = producibleId;
    this.filename = filename;
    this.name = name;
    this.visibility = visibility;
    this.artistId = artistId;
    this.memberName = memberName;
    this.eventId = eventId;
    this.eventName = eventName;
    this.eventSlug = eventSlug;
    this.pocaname = pocaname;
    this.pictureUrl = pictureUrl;
    this.pictureUrl = pictureUrl;
    this.collectedCount = collectedCount;
    this.chatRoomsCount = chatRoomsCount;
    this.isWished = isWished;
    this.price = price;
    this.category = category;
    this.receivedReviews = receivedReviews;
    this.sentReviews = sentReviews;
  }
}
