import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

const DEFAULT_PAGE = 10000;

export default function useCommentsQuery(postId, perPage) {
  async function fetchComments({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage || DEFAULT_PAGE,
      page: pageParam,
    });

    const url = `/api/v1/posts/${postId}/comments?${queryParams.toString()}`;
    try {
      const comments = await queryGet(url);
      return comments;
    } catch (error) {
      throw new Error("Error fetching paginated data.");
    }
  };

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["Comments", postId],
    fetchComments,
    {
      getNextPageParam: (lastPage, _) => lastPage?.meta?.pagination?.nextPage,
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  };
}
