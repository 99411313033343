import React from "react";

import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import FilledButton from "../components/FilledButton";
import { t } from "i18next";
import SecondaryLineButton from "../components/buttons/SecondaryLineButton";

export default function SelectPhotoCardBottomNav({
  selectedMember,
  selectedCardCount,
  totalCardCount,
  onConfirmedButton,
  setEditable,
  editable=true,
  showEditButton=false,
}) {
  const percentage = Math.round(selectedCardCount/totalCardCount * 1000) / 10

  return(
      <Box
        maxW={'xl'}
        className="bottomNavbar"
        paddingY="18px"
        borderTopStartRadius={{base: "18px", xl: ""}}
        borderTopRightRadius={{base: "18px", xl: ""}}
        style={{
          filter: "drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))",
          paddingBottom: "calc(env(safe-area-inset-bottom) + 18px)"
        }}
      >
        <Grid
          templateRows='repeat(2, 1fr)'
          templateColumns='repeat(6, 1fr)'
          alignItems={"center"}
          paddingX="24px"
          backgroundColor={"#FFF"}
        >
          <GridItem rowSpan={2} colSpan={1}>
            <CircularProgressbarWithChildren
              value={percentage}
              strokeWidth={7}
              styles={buildStyles({
                pathColor: "#5C3DFA",
                trailColor: "#DCD6FA"
              })}
            >
              <Text fontSize={"20px"} fontWeight={590}>🔮</Text>
             </CircularProgressbarWithChildren>
          </GridItem>
          <GridItem rowSpan={2} colSpan={3}>
            <Stack alignItems={"start"} className={"pl-3"} spacing={0}>
              <Text textColor={"#444"} as="b">
                {t("photoCardBottomNav.collectedPhotoCardCount", {totalCount: totalCardCount, collectedCount: selectedCardCount})}
              </Text>
              <Text fontSize={"13px"} textColor={"#666666"}>
                {t("photoCardBottomNav.collectedPhotoCardPercentage", {member: selectedMember?.name, percentage: percentage || 0})}
              </Text>
            </Stack>
          </GridItem>
          <GridItem rowSpan={2} colSpan={2}>
            {
              editable ?
              <FilledButton
                paddingX={"18px"}
                paddingY={"11px"}
                fontSize={"14px"}
                minWidth={"62px"}
                fontWeight={500}
                style={{float: "right"}}
                textColor={selectedCardCount > 0 ? "#FFF" : "#B9B9B9"}
                text={t("done")}
                background={selectedCardCount > 0 ? "#5C3DFA" : "#E7E7E7"}
                filled={selectedCardCount > 0}
                onClicked={onConfirmedButton}
              /> :
              (
                showEditButton && <SecondaryLineButton
                  text={t("photoCardBottomNav.editModeButton")}
                  onClickButton={() => setEditable(true)}
                  style={{minWidth: "62px", float: "right", padding: "11px 10px"}}
                />
              )
            }
          </GridItem>
        </Grid>
      </Box>
  )
}
