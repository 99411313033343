import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AddTradingPhotoCardPage, ArtistHomePage, ArtistPhotoCardsPage, IntroPage, IntroTradingPhotoCardPage, LoginWithCodePage, ReportPhotoCardPage, RequestVerificationCodePage, SelectArtistsPage, SelectMembersPage } from '../pages';
import ArtistPhotoCardsPageV2 from "../pages/ArtistPhotoCardsPageV2";
import BlacklistTradingPhotoCardsPage from "../pages/BlacklistTradingPhotoCardsPage";
// import EventExchangePhotoCardsPage from "../pages/EventExchangePhotoCardsPage";
// import EventExchangeTargetsPage from "../pages/EventExchangeTargetsPage";
import IdentityVerificationCallbackPage from "../pages/IdentityVerificationCallbackPage";
import ManageArtistPage from "../pages/ManageArtistPage";
import ManageMemberPage from "../pages/ManageMemberPage";
import MergeUserProfilesPage from "../pages/MergeUserProfilesPage";
import MyPhotoCardsMarketPage from "../pages/MyPhotoCardsMarketPage";
import MyPhotoCardsWishPage from "../pages/MyPhotoCardsWishPage";
import PhotoCardDetailPage from "../pages/PhotoCardDetailPage";
import SelectArtistsPageForUnlike from "../pages/SelectArtistsPageForUnlike";
import SharePocapanPage from "../pages/SharePocapanPage";
import UnauthorizedAccessPage from "../pages/UnauthorizedAccessPage";
import WelcomeLottiePage from "../pages/WelcomeLottiePage";

// import WishedPhotoCardPage from "../pages/WishedPhotoCardPage";
// import WishedTradingsPage from "../pages/WishedTradingsPage";
import MyPage from "../pages/MyPage";

import MyTradingPhotoCardsPage from "../pages/MyTradingPhotoCardsPage";
import TradingPhotoCardChatRoomPage from "../pages/TradingPhotoCardChatRoomPage";
import TradingPhotoCardPage from "../pages/TradingPhotoCardPage";
import TradingReportPage from "../pages/TradingReport";
import TradingUserListPage from "../pages/TradingUserListPage";
import MyTradingPhotoCardChatRoomsPage from "../pages/trading/MyTradingPhotoCardChatRoomsPage";

import ManageAccountPage from "../pages/ManageAccountPage";
import OrderDetailPage from "../pages/shop/OrderDetailPage";
import OrderStatusPage from "../pages/shop/OrderStatusPage";
import ShopPage from "../pages/shop/ShopPage";

import AddPhotoCardPage from "../pages/feed/AddPhotoCardPage";
import AddPostPage from "../pages/feed/AddPostPage";
import FeedHomePage from "../pages/feed/FeedHomePage";
import PostDetailPage from "../pages/feed/PostDetailPage";
import UserFeedProfilePage from "../pages/feed/UserFeedProfilePage";

import ForceToUpdatePage from "../pages/ForceToUpdatePage";
import CreateFeedUserProfilePage from "../pages/feed/CreateFeedUserProfilePage";
import FeedIntroPage from "../pages/feed/FeedIntroPage";
import FeedReportPage from "../pages/feed/FeedReportPage";
import RepostsListPage from "../pages/feed/RepostsListPage";
import OnRouterChanged from "../utils/OnRouterChanged";
import ScrollToTop from "../utils/ScrollToTop";

import AddTradingPhotoCardReviewPage from "../pages/trading/AddTradingPhotoCardReviewPage";
import SelectReviewChatRoomPage from "../pages/trading/SelectReviewChatRoomPage";
import SelectTradingPhotoCardReviewPage from "../pages/trading/SelectTradingPhotoCardReviewPage";
import TradingPhotoCardReviewDetailPage from "../pages/trading/TradingPhotoCardReviewDetailPage";
import TradingPhotoCardReviewsPage from "../pages/trading/TradingPhotoCardReviewsPage";

import NotificationPageV2 from "../pages/NotificationPageV2";
import TradingPhotoCardPageV2 from "../pages/TradingPhotoCardPageV2";
import AddTradingPhotoCardPageV2 from "../pages/trading/AddTradingPhotoCardPageV2";
import TradingUserProfilePage from "../pages/trading/TradingUserProfilePage";
import TradingUserProfileSettingsPage from "../pages/trading/TradingUserProfileSettingsPage";

import ReportPhotoCardActivitiesPage from "../pages/photo_cards/ReportPhotoCardActivitiesPage";
import ReportPhotoCardCandidatesPage from "../pages/photo_cards/ReportPhotoCardCandidatesPage";
import ReportPhotoCardCategoriesPage from "../pages/photo_cards/ReportPhotoCardCategoriesPage";
import ReportPhotoCardConfirmPage from "../pages/photo_cards/ReportPhotoCardConfirmPage";
import ReportPhotoCardMiscActivitiesPage from "../pages/photo_cards/ReportPhotoCardMiscActivitiesPage";
import ReportPhotoCardEventsPage from "../pages/photo_cards/ReportPhotoCardEventsPage";
import ReportPhotoCardUploadPhotoPage from "../pages/photo_cards/ReportPhotoCardUploadPhotoPage";
import AddTagInputPage from "../pages/tags/AddTagInputPage";
import CompletedAddTradingPhotoCardsPage from "../pages/trading/CompletedAddTradingPhotoCardsPage";
import ConfirmNewSalesTradingPhotoCardsPage from "../pages/trading/ConfirmNewSalesTradingPhotoCardsPage";
import { ReportPhotoCardSelectArtistPage } from "../pages/photo_cards/ReportPhotoCardSelectArtistPage";
import { ReportPhotoCardSelectMemberPage } from "../pages/photo_cards/ReportPhotoCardSelectMemberPage";

function Router() {
  return (
    <BrowserRouter>
      <OnRouterChanged/>
      <ScrollToTop>
      <Routes>
        <Route path="/" element={<IntroPage />} />
        <Route path="/request_verification_code" element={<RequestVerificationCodePage />} />
        <Route path="/welcome" element={<WelcomeLottiePage />} />
        <Route path="/login_with_code" element={<LoginWithCodePage />} />
        <Route path="/unauthorized_access" element={<UnauthorizedAccessPage />} />
        <Route path="/identity_verification_callback" element={<IdentityVerificationCallbackPage />} />
        <Route path='/notifications' element={<NotificationPageV2 />} />
        <Route path="/artists" element={<SelectArtistsPage />} />
        <Route path="/select_initial_artists" element={<SelectArtistsPage />} />
        <Route path='/artists/:artist_id/members' element={<SelectMembersPage />} />
        <Route path='/artists/:artist_id/photo_cards' element={<ArtistPhotoCardsPage />} />
        <Route path='/artists/photo_cards_v2' element={<ArtistPhotoCardsPageV2 />} />
        <Route path='/artists/home' element={<ArtistHomePage />} />
        <Route path='/artists/:artist_id/home' element={<ArtistHomePage />} />

        <Route path='/artists/:artist_id/intro_trading_photo_cards' element={<IntroTradingPhotoCardPage />} />
        <Route path='/artists/add_trading_photo_cards' element={<AddTradingPhotoCardPage />} />
        <Route path='/artists/trading_photo_cards' element={<TradingPhotoCardPage />} />
        <Route path='/artists/:artist_id/my_trading_photo_cards' element={<MyTradingPhotoCardsPage />} />
        <Route path='/artists/:artist_id/trading_photo_card_users' element={<TradingUserListPage />} />
        <Route path='/artists/:artist_id/blacklist_trading_photo_cards' element={<BlacklistTradingPhotoCardsPage />} />
        <Route path='/artists/:artist_id/chats/:chat_room_id' element={<TradingPhotoCardChatRoomPage />} />
        {/* <Route path='/artists/:artist_id/wished_photo_cards' element={<WishedPhotoCardPage />} /> */}
        {/* <Route path='/artists/:artist_id/wished_tradings' element={<WishedTradingsPage />} /> */}

        <Route path='/tradings/photo_cards' element={<TradingPhotoCardPageV2 />} />
        <Route path='/tradings/add_trading_photo_cards' element={<AddTradingPhotoCardPageV2 />} />
        <Route path='/tradings/confirm_new_trading_photo_cards' element={<ConfirmNewSalesTradingPhotoCardsPage />} />
        <Route path='/tradings/completed_add' element={<CompletedAddTradingPhotoCardsPage />} />

        <Route path='/my' element={<MyPage />} />
        <Route path='/users/merge_profiles' element={<MergeUserProfilesPage />} />
        <Route path='/users/:user_id/my_photo_cards_market' element={<MyPhotoCardsMarketPage />} />
        <Route path='/users/:user_id/my_photo_cards_wish' element={<MyPhotoCardsWishPage />} />


        <Route path='/photo_cards/:photo_card_id' element={<PhotoCardDetailPage />} />
        <Route path='/photo_cards/report' element={<ReportPhotoCardPage />} />
        <Route path='/report-photo-card/select-artist' element={<ReportPhotoCardSelectArtistPage />} />
        <Route path='/report-photo-card/select-member' element={<ReportPhotoCardSelectMemberPage />} />
        <Route path='/report-photo-card/event-activities' element={<ReportPhotoCardActivitiesPage />} />
        <Route path='/report-photo-card/misc-activities' element={<ReportPhotoCardMiscActivitiesPage />} />
        <Route path='/report-photo-card/events' element={<ReportPhotoCardEventsPage />} />
        <Route path='/report-photo-card/card-categories' element={<ReportPhotoCardCategoriesPage />} />
        <Route path='/report-photo-card/card-candidates' element={<ReportPhotoCardCandidatesPage />} />
        <Route path='/report-photo-card/upload-photo' element={<ReportPhotoCardUploadPhotoPage />} />
        <Route path='/report-photo-card/confirm' element={<ReportPhotoCardConfirmPage />} />
        <Route path='/tags/add' element={<AddTagInputPage />} />

        <Route path='/share_pocapan' element={<SharePocapanPage />} />
        {/* <Route path='/artists/:artist_id/events/:event_id/exchange_photo_cards' element={<EventExchangePhotoCardsPage />} />
        <Route path='/artists/:artist_id/events/:event_id/targets' element={<EventExchangeTargetsPage />} /> */}

        <Route path='/trading/user_profile/:user_profile_id' element={<TradingUserProfilePage />} />
        <Route path='/trading/user_profile/:user_profile_id/settings' element={<TradingUserProfileSettingsPage />} />
        <Route path='/trading_photo_cards/:trading_photo_card_id/my_chat_rooms' element={<MyTradingPhotoCardChatRoomsPage />} />
        <Route path='/trading_photo_cards/select_reviews' element={<SelectTradingPhotoCardReviewPage />} />
        <Route path='/trading_photo_cards/:trading_photo_card_id/select_chat_rooms' element={<SelectReviewChatRoomPage />} />
        <Route path='/trading_photo_cards/:trading_photo_card_id/add_review' element={<AddTradingPhotoCardReviewPage />} />
        <Route path='/reviews/:review_id' element={<TradingPhotoCardReviewDetailPage />} />
        <Route path='/trading_photo_cards/reviews' element={<TradingPhotoCardReviewsPage />} />

        <Route path='/artists/manage' element={<ManageArtistPage />} />
        <Route path='/members/manage' element={<ManageMemberPage />} />
        <Route path='/account/manage' element={<ManageAccountPage />} />
        <Route path='/artists/reset_all_cards' element={<SelectArtistsPageForUnlike />} />

        <Route path='/shop/:product_id' element={<ShopPage />} />
        <Route path='/orders/:order_id' element={<OrderDetailPage />} />
        <Route path='/orders/:order_id/status' element={<OrderStatusPage />} />

        <Route path='/trading_reports' element={<TradingReportPage />} />

        <Route path='/feed/intro' element={<FeedIntroPage />} />
        <Route path='/feed/create_feed_user_profile' element={<CreateFeedUserProfilePage />} />
        <Route path='/feed' element={<FeedHomePage />} />
        <Route path='/posts/:post_id' element={<PostDetailPage />} />
        <Route path='/posts/:post_id/reposts' element={<RepostsListPage />} />
        <Route path='/posts/add' element={<AddPostPage />} />
        <Route path='/feed/add_photo_card' element={<AddPhotoCardPage />} />
        <Route path='/feed/report/' element={<FeedReportPage />} />
        <Route path='/feed/user_profile/:user_profile_id' element={<UserFeedProfilePage />} />
        <Route path='/ask_update' element={<ForceToUpdatePage/>}/>

      </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
export default Router;
