import { Center, Text, VStack, useToast } from '@chakra-ui/react';
import React from "react";

import Page from "../components/Page";

import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import FilledButton from "../components/FilledButton";
import ToastMessageBox from "../components/alerts/ToastMessageBox";
import { boldWordsInText } from "../utils/TextUtil";
import { sendToNative } from "../utils/NativeUtil";

const AccountEnableGuideBox = styled.div`
  display: flex;
  width: 327px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: var(--gray-surface, rgba(0, 0, 0, 0.04));
  color: var(--gray-text-main-lv-2, #444);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  word-break: auto-phrase
`;

const BOLD_WORDS = [
  t("childLoginPage.firstBoldArea"),
  t("childLoginPage.secondBoldArea"),
  t("childLoginPage.thirdBoldArea"),
  t("officialEmail")
]

export default function UnauthorizedAccessPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const currentQueryParams = new URLSearchParams(location.search);
  const isBlocked = currentQueryParams.get("blocked") === "true";
  const isChild = currentQueryParams.get("isChild") === "true";

  const pageType = isBlocked
    ? "blockUserPage"
    : isChild
    ? "childLoginPage"
    : "requiredLoginPage";

    const pageTitle = t(`${pageType}.pageName`);
    const titleText = t(`${pageType}.title`);
    const descriptionText = t(`${pageType}.description`);

    const titleEmoji = isBlocked ? "🚫" : isChild ? "👩‍👧‍👦" : "🥺";
    const titleFontSize = isChild ? "26px" : "17px";

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}년 ${currentDate.getMonth() + 1}월 ${currentDate.getDate()}일`;
    const description = t("childLoginPage.description");
    const updatedDescription = description.replace(/이용정지일 : \d+년 \d+월 \d+일/, `이용정지일 : ${formattedDate}`);

    const handleLogin = (isDeleteAccount) => {
      // 이 페이지로 왔다면 로그인이 풀린 상태.. 네이티브는 로그아웃 시킨다.
      initializeLocalStorage();
      if(isDeleteAccount) {
        toast({
          duration: 1500,
          render: () => (
            <ToastMessageBox message={t("childLoginPage.exitPocapantoastMessage")}/>
          ),
        })
        setTimeout(() => { navigate("/") }, 1000);
      } else {
        navigate("/");
      }
    };

    const initializeLocalStorage = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("artist");
      sendToNative('{"type": "logout"}')
    }

    return (
      <Page title={pageTitle} showBackButton={false}>
        <Center h={"calc(var(--vh, 1vh) * 90)"}>
          <VStack marginTop={"40px"} className={isChild ? "text-left" : "text-center"}>
            <Text fontSize={"50px"}>{titleEmoji}</Text>
            <Text fontSize={titleFontSize} fontWeight={700} color={"#444444"} wordBreak={"keep-all"}>
              {titleText}
            </Text>
            <Text className="whitespace-pre-line" fontSize={"14px"} fontWeight={400} color={"#888888"} paddingBottom={"20px"} wordBreak={"keep-all"}>
              {isChild? boldWordsInText(updatedDescription, BOLD_WORDS) : boldWordsInText(descriptionText, BOLD_WORDS)}
            </Text>
            {isChild && (
              <AccountEnableGuideBox className="text-center">
                <Text className="whitespace-pre-line" fontSize={"14px"} wordBreak={"keep-all"}>
                  {boldWordsInText(t("childLoginPage.enableAccountGuide"), BOLD_WORDS)}
                </Text>
              </AccountEnableGuideBox>
            )}
            {!isBlocked && !isChild && (
              <FilledButton
                paddingX={"18px"}
                paddingY={"11px"}
                fontWeight={600}
                fontSize={"14px"}
                text={t("login")}
                onClick={() => handleLogin(false)}
              />
            )}
            {
              isChild && <VStack spacing={"72px"}>
                <Text color={"#FF0000"} fontSize={"14px"} fontWeight={400} as={"u"} onClick={() => handleLogin(true)}>
                  {t("childLoginPage.deleteAccount")}
                </Text>
                <Text color={"primary.500"} fontSize={"14px"} fontWeight={400} as={"u"} onClick={() => handleLogin(false)}>
                  {t("childLoginPage.backToIntro")}
                </Text>
              </VStack>
            }
          </VStack>
        </Center>
      </Page>
    );
}
