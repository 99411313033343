import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const updateQueryParams = (newParams, nextPath) => {
    // 새로운 파라미터 추가
    Object.keys(newParams).forEach(key => {
      queryParams.set(key, newParams[key]);
    });

    // 다음 페이지로 이동
    navigate({
      pathname: nextPath, // 다음 페이지의 경로
      search: queryParams.toString(), // 업데이트된 쿼리 파라미터
    });
  };

  return { queryParams, updateQueryParams };
};
