import React from 'react';

import { HStack, Skeleton } from '@chakra-ui/react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import styled from 'styled-components';

import RegularSolidButton from '../buttons/RegularSolidButton';

const Row = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
`

const HorizontalScrollMembers = ({
  members,
  selectedMember,
  onSelectedMember,
  showEntire=true,
  showLikedEmoji=true,
}) => {
  if(isEmpty(members)) {
    return (
      <HStack paddingY={"8px"} paddingLeft={"24px"}>
        <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='rgba(92,62,250)' endColor='primary.500' />
        <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='secondary.500' endColor='secondary.700' />
        <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='secondary.500' endColor='secondary.700' />
      </HStack>
    )
  }

  return (
    <div className="w-full" style={{}}>
    <ScrollMenu wrapperClassName='padding-left-scroll-container'>
      <Row>
      {
        showEntire &&
        <RegularSolidButton
          key={"all"}
          text={t("entire")}
          onClicked={() => onSelectedMember(null)}
          filled={isEmpty(selectedMember)}
        />
      }
      </Row>

      {
        members.map((member) => (
          <Row key={member.memberId}>
            <RegularSolidButton
              text={(showLikedEmoji) && member.isLiked ? member.name + " ♥" : member.name}
              onClicked={() => onSelectedMember(member)}
              filled={selectedMember?.memberId === member.memberId}
            />
          </Row>
        ))
      }
    </ScrollMenu>
    </div>
  );
}

export default HorizontalScrollMembers;
