import React from 'react';
import Lottie from "lottie-react";
import welcomeAnimation from "../lotties/lottie-welcome.json";
import { Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';


export default function WelcomeLottiePage() {
  const navigate = useNavigate();

  const location = useLocation();
  const stateProps = location.state;

  setTimeout(() => {
    navigate(stateProps?.redirectUrl || "/artists");
  }, 1500);

  return (
    <Wrap
      position={"relative"}
      align='center'
      backgroundColor="white"
    >
      <WrapItem height={"calc(100vh)"}>
        <Lottie
          animationData={welcomeAnimation} loop={true}
          style={{height: "100%"}}
        />
      </WrapItem>
      <WrapItem
        style={{position: "absolute", top: 0, left: 0}}
        alignItems={"center"}
        justifyContent={"center"}
        minWidth={"100%"}
        minHeight={"100%"}
      >
        <VStack>
          <Text textColor={"rgba(34, 34, 34, 1)"} fontSize={"26px"} fontWeight={700}>{stateProps?.title || t("welcomePage.title")}</Text>
          <Text textColor={"#888888"} fontSize={"17px"} fontWeight={700}>{stateProps?.description || t("welcomePage.description")}</Text>
        </VStack>
      </WrapItem>
    </Wrap>
  )

}