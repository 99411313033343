import baseUrl from "../../../../utils/baseUrl";
import getToken from "../../../../utils/getToken";

export default async function createChatRoom(artistId, tradingPhotoCardId) {
  return fetch(baseUrl() + `/artists/${artistId}/trading_photo_cards/${tradingPhotoCardId}/chat_room`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then((data) => data.json())
}
