import baseUrl from "../../../utils/baseUrl"
import getToken from "../../../utils/getToken"

export default async function addAndMergeUserProfileV1(category, nickname, dataUrl) {
  async function addAndMergeUserProfile(category, nickname, dataUrl) {
    return fetch(baseUrl() + `/api/v1/user_profiles/create_and_merge_trading_profiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      body: JSON.stringify({
        user_profile: {
          category: category,
          nickname: nickname,
          data_url: dataUrl,
        }
      }),
    });
  }

  return addAndMergeUserProfile(category, nickname, dataUrl);
}
