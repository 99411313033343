import baseUrl from "../../../utils/baseUrl"
import getToken from "../../../utils/getToken"

export default async function updateUserProfileV1({
  userProfileId,
  nickname,
  description,
  dataUrl,
}) {
  async function updateUserProfile(userProfileId, nickname, description, dataUrl) {
    return fetch(baseUrl() + `/api/v1/user_profiles/${userProfileId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      body: JSON.stringify({
        user_profile: {
          nickname: nickname,
          description: description,
          data_url: dataUrl,
        }
      }),
    });
  }
  return updateUserProfile(userProfileId, nickname, description, dataUrl);
}
