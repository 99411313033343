import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function addComment(postId, content, displayOnFeed=false, anonymous=false) {
  return fetch(baseUrl() + `/api/v1/posts/${postId}/comment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      post: {
        content: content,
        display_on_feed: displayOnFeed,
        anonymous: anonymous,
      },
    }),
  });
};
