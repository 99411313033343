import React from "react";

import { Box, Center, Flex, HStack, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Spacer, Text, VStack } from '@chakra-ui/react';
import { isEmpty, isUndefined } from "lodash";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import LightColors from "../../constants/LightColors";
import { ReactComponent as CardFillIcon } from "../../icons/card.fill.svg";
import { ReactComponent as MoreIcon } from "../../icons/more.svg";
import useCollectedPhotoCardsStat from "../api/queries/useCollectedPhotoCardsStat";
import PrimaryButton from "../buttons/PrimaryButton";
import GridSkeletons from "../skeletons/GridSkeletons";
import { t } from "i18next";

const authorNameStyle = {
  fontWeight: 600,
  color: LightColors.textmainlv2,
  lineHeight: "17px",
  fontSize: "14px"
}

const authoredAtStyle = {
  paddingTop: "1px",
  fontWeight: 500,
  color: LightColors.textmainlv4,
  lineHeight: "14px",
  fontSize: "12px",
  paddingLeft: "6px"
}

const CardFillIconWrapper = styled.div`
  width: 34px;
  height: 34px;
  background: linear-gradient(180deg, #804CF2 0%, #D360FE 100%);
  border-radius: 50%;
  padding: 7px;
`

const PhotoCardsBox = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  border-radius: 12px;
  border: 1px solid ${LightColors.separatorlv2};
`

export default function MyCollectedPhotoCardsStat({user, onHiddenCollectedPhotoCardStat}) {
  const navigate = useNavigate();
  const collectedPhotoCardsStat = useCollectedPhotoCardsStat(user?.userId);


  if (isUndefined(collectedPhotoCardsStat)) {
    return(<GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={'190px'} paddingX={"22px"}/>)
  }

  if (isEmpty(collectedPhotoCardsStat) || collectedPhotoCardsStat.collectedPhotoCardsCount <= 0) {
    return(<></>)
  }

  if (isEmpty(collectedPhotoCardsStat.baseUserProfile)) {
    return(<></>)
  }

  if (isEmpty(collectedPhotoCardsStat)) {
    return(<></>)
  }

  return (
    <Flex paddingTop={"20px"} paddingX={"22px"} paddingBottom={"23px"} borderBottom={`1px solid ${LightColors.separatorlv1}`}>
      {
        collectedPhotoCardsStat &&
        <Box width={"34px"}>
          <Center>
            <CardFillIconWrapper>
              <CardFillIcon width={"20px"} height={"20px"} fill="white"/>
            </CardFillIconWrapper>
          </Center>
        </Box>
      }
      <VStack className="w-full" spacing={0} align={"left"} paddingLeft={"10px"}>
        <Flex paddingBottom={"9px"}>
          <Flex direction={"row"} alignItems={"center"}>
            <Text style={authorNameStyle}>{t("myCollectedPhotoCardsStat.authorName", {nickname: collectedPhotoCardsStat.baseUserProfile.nickname})}</Text>
            <Text style={authoredAtStyle}>{t("myCollectedPhotoCardsStat.authoredAt")}</Text>
          </Flex>
          <Spacer/>
          {
            <Menu>
              <MenuButton
                minWidth={"0px"}
                height={"18px"}
                as={IconButton}
                variant=""
                aria-label='Options'
                paddingX={"0px!important"}
                icon={<MoreIcon fill={LightColors.textsublv2} width={"17px"} height={"17px"} />}
              />
              <MenuList>
                {
                  <MenuItem onClick={() => onHiddenCollectedPhotoCardStat()}>
                    {t("myCollectedPhotoCardsStat.hideMenu")}
                  </MenuItem>
                }
              </MenuList>
            </Menu>
          }
        </Flex>
        <PhotoCardsBox>
          <Flex paddingBottom={"12px"} paddingRight={"16px"}>
            <Text lineHeight={"16px"} fontSize={"13px"} fontWeight={600} color={LightColors.textmainlv2}>{t("myCollectedPhotoCardsStat.myCollectedPhotoCards")}</Text>
            <Text lineHeight={"16px"} fontSize={"12px"} fontWeight={700} paddingLeft={"5px"} color={LightColors.primary}>
              {t("myCollectedPhotoCardsStat.myCollectedPhotoCardsCount", {count: collectedPhotoCardsStat.collectedPhotoCardsCount})}
            </Text>
          </Flex>
          <div className="horizontalScroll hiddenScrollBar">
            {
              collectedPhotoCardsStat?.recentCollectedPhotoCards.map((photoCard) => {
                return (
                  <Image
                    key={photoCard.photoCardId}
                    src={photoCard.pictureUrl}
                    height={"55px"}
                    width={"35px"}
                    borderRadius={"5px"}
                    objectFit={"cover"}
                    marginRight={"5px!important"}
                    onClick={() => navigate(`/photo_cards/${photoCard.photoCardId}`)}
                  />
                )
              })
            }
          </div>
          <Flex paddingTop={"14px"} paddingRight={"16px"} paddingBottom={"2px"}>
            <Flex width={"35px"} height={"35px"} alignItems={"center"}>
              <CircularProgressbarWithChildren
                value={10}
                strokeWidth={7}
                styles={buildStyles({
                  pathColor: LightColors.primary,
                  trailColor: LightColors.theirty,
                })}
              >
                <HStack spacing={0} alignItems={"center"} paddingLeft={"1px"} paddingTop={"1px"}>
                  <Text fontSize={"12px"} lineHeight={"14px"} fontWeight={700} color={LightColors.primary}>{"10"}</Text>
                  <Text fontSize={"7px"} lineHeight={"7px"} paddingTop={"4px"} fontWeight={900} color={LightColors.primary}>{"%"}</Text>
                </HStack>
              </CircularProgressbarWithChildren>
            </Flex>
            <Flex direction={"column"} paddingLeft={"8px"} flexGrow={1} placeContent={"center"}>
              <Text fontWeight={500} fontSize={"11px"} lineHeight={"14px"} paddingBottom={"2px"} color={LightColors.unknwonTextColor1}>
                {t("myCollectedPhotoCardsStat.mostCollectedMember")}
              </Text>
              <Flex direction={"row"}>
                <Text fontSize={"13px"} fontWeight={500} lineHeight={"16px"} color={LightColors.textmainlv2}>
                  {collectedPhotoCardsStat.favoriteMemberStat.memberName}
                </Text>
                <Text paddingLeft={"3px"} fontSize={"10px"} lineHeight={"12px"} paddingTop={"3px"} fontWeight={400} color={LightColors.textsublv1} >
                  {`${collectedPhotoCardsStat.favoriteMemberStat.collectedPhotoCardsCount}/${collectedPhotoCardsStat.favoriteMemberStat.totalPhotoCardsCount}`}
                </Text>
              </Flex>
            </Flex>
            <Flex paddingLeft={"8px"} onClick={() => navigate("/artists/photo_cards_v2")} alignItems={"center"}>
              <PrimaryButton height={"30px"} text={t("myCollectedPhotoCardsStat.showAllButton")} fontSize={"12px"} lineHeight={"14px"} fontWeight={500}/>
            </Flex>
          </Flex>
        </PhotoCardsBox>
      </VStack>
    </Flex>
  )
}
