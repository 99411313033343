import React from "react";
import { Textarea } from '@chakra-ui/react';

export default function InputArea({onChangeText, ...props}) {
  return(
    <Textarea
      fontSize={"17px"}
      fontWeight={600}
      color={"#444"}
      bg={"secondary.600"}
      borderRadius={"12px"}
      border={"#FFF"}
      focusBorderColor={"#FFF"}
      _placeholder={{color: "rgba(0,0,0,0.2)"}}
      onChange={(e) => onChangeText(e.target.value)}
      {...props}
    />
  )
}