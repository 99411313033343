import React from "react";

import { Box, Button, Flex, Menu, MenuButton, Spacer, Switch, Text } from '@chakra-ui/react';

import LightColors from '../../constants/LightColors';
import { ReactComponent as ArrowTriangleIcon } from "../../icons/arrowtriangle.down.svg";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron.down.svg";
import ArtistTextSelector from '../selectors/ArtistTextSelector';
import { useArtistQueryLikedOnly } from "../api/queries/useArtistQuery";

const titleStyle = {
  paddingLeft: "7px",
  fontSize: "14px",
  fontWeight: 500,
}

const boxStyle = {
  borderRadius: "12px",
  height: "44px",
  backgroundColor: "rgba(0, 0, 0, 0.04)",
  padding: "12px 20px"
}

export default function SwitchToggleBar({text, checked, onToggled, onSelectedArtist}) {
  const artists = useArtistQueryLikedOnly();
  return (
    <Flex align={"center"} style={boxStyle}>
      <Switch size='sm' defaultChecked={checked} colorScheme="primary" onChange={(e) => onToggled(e.target.checked)}/>
      <Text style={titleStyle}> {text} </Text>
      <Spacer />
      {
        checked && (
          <ArtistTextSelector
            artists={artists}
            onSelected={(artist) => onSelectedArtist(artist)}
            alignDropdown="left"
            customTextAreaElementMultiple={
              (artist) =>
                <MenuButton
                  as={Button}
                  colorScheme=""
                  color={LightColors.primary}
                  width={"fit-content"}
                  padding={0}
                  rightIcon={
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <ArrowTriangleIcon width={"12px"} height={"12px"} fill={LightColors.primary}/>
                    </Box>
                  }>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Text fontSize={"14px"} fontWeight={500} color={LightColors.primary}>{artist?.name}</Text>
                  </Box>
                </MenuButton>
            }
            customTextAreaElementSingle={
              (artist) => <DropdownTextOnly artist={artist}/>
            }
          />
        )
      }
    </Flex>
  )
}

// 크기, 위치 등을 맞추기 힘들어서 텍스트만 보이지만 메뉴를 활용한다
const DropdownTextOnly = ({artist}) => {
  return (
    <Menu>
      <MenuButton
        height={"40px"}
        as={Button}
        colorScheme=""
        color={LightColors.pureblack}
        width={"fit-content"}
        padding={0}
        rightIcon={
          <Box height="40px" display="flex" alignItems="center" justifyContent="center">
            <ChevronDownIcon width={"22px"} height={"22px"} fill={"#666666"} opacity={0}/>
          </Box>
        }>
        <Box height="40px" display="flex" alignItems="center" justifyContent="center">
          <Text fontSize={"26px"} fontWeight={700} color={"#222"}>{artist?.name}</Text>
        </Box>
      </MenuButton>
    </Menu>
  );
};
