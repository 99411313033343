import React from "react";

import { Avatar, Flex, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Spacer, Text, VStack } from '@chakra-ui/react';

import { ReactComponent as ChatIcon } from "../../icons/chat.svg";
import { ReactComponent as LikeIcon } from "../../icons/like.line.svg";
import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg";
import { ReactComponent as MoreIcon } from "../../icons/more.svg";
import baseUrl from "../../utils/baseUrl";
import getToken from "../../utils/getToken";
import ProfileAvatar from "../thumbnails/ProfileAvatar";
import { t } from "i18next";

const authorNameStyle = {
  fontWeight: 700,
  color: "#222",
  fontSize: "13px"
}

const authoredAtStyle = {
  fontWeight: 500,
  color: "#888",
  fontSize: "13px",
  paddingLeft: "10px"
}

const contentStyle = {
  fontWeight: 500,
  color: "#444",
  fontSize: "15px"
}

const counterStyle = {
  fontWeight: 500,
  color: "#AAA",
  fontSize: "15px"
}

async function toggleLiked(commentId) {
  return fetch(baseUrl() + `/comments/${commentId}/liked`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then((data) => data.json())
}


async function deleteComment(commentId) {
  return fetch(baseUrl() + `/comments/${commentId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then((data) => data.json())
}

export default function Comment({comment, isPosted=false, onUpdatedComment}) {
  const updateComment = (comment) => {
    if(onUpdatedComment){
      onUpdatedComment(comment)
    }
  }

  const onRemoveComment = (comment) => {
    if (window.confirm(t("post.confirmDelete"))) {
      deleteComment(comment.commentId);
      if(onUpdatedComment){
        onUpdatedComment(comment)
      }
    }
  }

  return (
    <Flex key={comment.commentId} paddingBottom={"15px"}>
      {
        !isPosted && <ProfileAvatar widthAndHeight={"34px"} profileImageUrl={comment.userProfile?.pictureUrl}/>
      }
      <VStack className="w-full" spacing={0} align={"left"} paddingLeft={"10px"}>
        <Flex>
          <Flex direction={"row"}>
            <Text style={authorNameStyle}>{comment.authorName}</Text>
            <Text style={authoredAtStyle}>{comment.authoredAt}</Text>
          </Flex>
          <Spacer/>
          {
            comment.isMyComment && <Menu>
              <MenuButton h={"18px"} as={IconButton} variant="" aria-label='Options' icon={<MoreIcon fill="black" width={"18px"} height={"18px"} />} />
              <MenuList>
                <MenuItem onClick={() => onRemoveComment(comment)}>
                  {t("post.deletedCommentMenu")}
                </MenuItem>
              </MenuList>
            </Menu>
          }
        </Flex>
        { comment.content && <Text style={contentStyle}>{comment.content}</Text> }
        {
          !isPosted &&
          <HStack paddingTop={"5px"} spacing={"20px"}>
            <HStack spacing={1}>
              {
                comment.isLiked ? (
                  <>
                    <LikeShapeIcon width={"15px"} height={"15px"} fill={"black"} onClick={() => toggleLiked(comment.commentId).then((r) => updateComment(r.comment))}/>
                    <Text color={"black!important"} style={counterStyle}>{comment.likesCount}</Text>
                  </>
                )
                : (
                  <>
                    <LikeIcon width={"15px"} height={"15px"} fill={"#AAA"} onClick={() => toggleLiked(comment.commentId).then((r) => updateComment(r.comment))}/>
                    <Text style={counterStyle}>{comment.likesCount}</Text>
                  </>
                )
              }
            </HStack>
            <HStack spacing={1}>
              <ChatIcon width={"15px"} height={"15px"} fill={"#AAA"} />
              <Text style={counterStyle}>{t("post.reply")}</Text>
            </HStack>
          </HStack>
        }
      </VStack>
    </Flex>
  )
}
