import React, { useEffect, useRef, useState } from "react";

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { t } from "i18next";
import { isEmpty } from "lodash";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useLocation, useNavigate } from "react-router-dom";
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import styled from "styled-components";

import useUserProfilesListQuery from "../components/api/queries/useUserProfilesListQuery";
import useUserQuery from "../components/api/queries/useUserQuery";
import AddUserProfileV1BottomSheet from "../components/bottomSheet/AddUserProfileV1BottomSheet";
import RegularSolidButton from "../components/buttons/RegularSolidButton";
import StickyHeader from "../components/StickyHeader";
import ExchangesTabPanel from "../components/tradings/ExchangesTabPanel";
import SalesTabPanel from "../components/tradings/SalesTabPanel";
import UserProfileButton from "../components/userProfile/UserProfileButton";
import LightColors from "../constants/LightColors";
import { ReactComponent as PlusIcon } from "../icons/plus.svg";
import { ReactComponent as CloseIcon } from "../icons/xmark.svg";
import { useHandleHistoryBack } from "../utils/useHandleHistoryBack";

const HomeBackground = styled.div`
  height: calc(var(--vh, 1vh)* 100);
`

const FabWrapper = styled.div`

`

const activeTabStyle = {
  fontWeight: 700,
  borderBottom: '3px solid',
  borderColor: '#5C3DFA',
  color: "#5C3DFA",
  borderBox: ""
}

const TRADING_CATEGORY = "trading";

export default function TradingPhotoCardPageV2() {
  const fabWrapperRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenFab, setIsOpenFab] = useState(false);
  const handleHistoryBack = useHandleHistoryBack();
  const currentQueryParams = new URLSearchParams(location.search);

  const [tabIndex, setTabIndex] = useState(currentQueryParams.get("tabIndex") || 0);
  const [isShowProfileBottomSheet, setIsShowProfileBottomSheet] = useState(false);

  const user = useUserQuery();
  const tradingUserProfiles = useUserProfilesListQuery(TRADING_CATEGORY);

  useEffect(() => {
    if (tradingUserProfiles) {
      if (isEmpty(tradingUserProfiles)) {
        setIsShowProfileBottomSheet(true);
      } else if (tradingUserProfiles.length > 1) {
        navigate("/users/merge_profiles");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingUserProfiles])

  const handleTabsChange = (index) => {
    setTabIndex(index)
    navigate(`${location.pathname}?tabIndex=${index}`, { replace: true });
  }

  const onClickFabButton = (isExchange) => {
    if (!user) return;

    if (user.tradingUserProfile) {
      navigate(`/tradings/add_trading_photo_cards?isExchange=${isExchange}`);
    } else {
      setIsShowProfileBottomSheet(true)
    }
  }

  return (
  <HomeBackground className="homeBackground safeAreaPaddingExceptBottomBackground">
    <StickyHeader
      title={t("tradingPhotoCardPage.pageTitle")}
      showBackButton={false}
      alwaysShowTitle={true}
      showTitle={true}
      transparent={true}
      leftContent={
        <CloseIcon fill={LightColors.pureblack} onClick={handleHistoryBack}/>
      }
      rightContent={
        <UserProfileButton
          userProfile={user?.tradingUserProfile}
          onClickedButton={() => isEmpty(tradingUserProfiles) ? setIsShowProfileBottomSheet(true) : navigate(`/trading/user_profile/${user.tradingUserProfile?.userProfileId}`)}
          style={{ width: "max-content" }}
        />
      }
    />
    <div >
    {/* <SimpleGrid alignItems={"center"} columns={3} paddingTop={"13px"} paddingX={"24px"}>
      <div style={{zIndex: 2147483647}} onClick={() => navigate(-1)}>
        <ArrowLeftIcon fill={"#000"}/>
      </div>
      <Text className="text-center" fontSize={"17px"} fontWeight={700} color={"#222"}>{t("tradingPhotoCardPage.pageTitle")}</Text>
      <div className="flex justify-end">
        <UserProfileButton
          userProfile={user?.tradingUserProfile}
          onClickedButton={() => isEmpty(tradingUserProfiles) ? setIsShowProfileBottomSheet(true) : navigate(`/trading/user_profile/${user.tradingUserProfile?.userProfileId}`)}
        />
      </div>
    </SimpleGrid> */}
    </div>

    <Tabs isFitted index={parseInt(tabIndex)} paddingTop={"calc(58px + env(safe-area-inset-top))"} borderColor={"transparent"} colorScheme="primary.500" onChange={handleTabsChange}>
      <TabList paddingX={"16px"} borderBottom={"1px"} borderColor={"rgba(0, 0, 0, 0.04)"}>
        <Tab _active={""} _selected={activeTabStyle} lineHeight={"19px"} paddingY={"13px"}>{t("tradingPhotoCardPage.salesTabName")}</Tab>
        <Tab _active={""} _selected={activeTabStyle} lineHeight={"19px"} paddingY={"13px"}>{t("tradingPhotoCardPage.exchangeTabName")}</Tab>
      </TabList>
      {/* <Marquee style={marqueeStyle}>
        <Text
          align={"center"}
          className="text-center"
          fontSize={"12px"}
          fontWeight={500}
          color={"#FFF"}
          whiteSpace={"pre-wrap"}
        >
          {t("tradingPhotoCardPage.marqueeText")}
        </Text>
      </Marquee> */}
      <TabPanels style={{paddingBottom: "12px", paddingTop: "16px"}}>
        <TabPanel padding={0}>
          {
            parseInt(tabIndex) === 0 &&
            <SalesTabPanel fabWrapperRef={fabWrapperRef}/>
          }
        </TabPanel>
        <TabPanel padding={0}>
          {
            parseInt(tabIndex) === 1 &&
            <ExchangesTabPanel fabWrapperRef={fabWrapperRef}/>
          }
        </TabPanel>
      </TabPanels>

    </Tabs>
    {
      isShowProfileBottomSheet &&
      <AddUserProfileV1BottomSheet
        title={t("tradingPhotoCardPage.addProfileBottomSheetTitle")}
        userProfile={user?.tradingUserProfile}
        isShowProfileBottomSheet={isShowProfileBottomSheet}
        setIsShowProfileBottomSheet={setIsShowProfileBottomSheet}
      />
    }
    <FabWrapper ref={fabWrapperRef}>
      <Fab
        alwaysShowTitle={false}
        style={{ bottom: "calc(env(safe-area-inset-bottom))", right: 0 }}
        icon={
          <PlusIcon fill="white" width={"24px"} height={"24px"}/>
        }
        mainButtonStyles={{
          backgroundColor: isOpenFab ? LightColors.pureblack : LightColors.primary,
          boxShadow: "0px 0px 4px 0px rgba(92, 61, 250, 0.40), 0px 2px 10px 0px rgba(92, 61, 250, 0.30)"
        }}
      >

        <div>
          <RegularSolidButton
            paddingX={"24px"}
            paddingY={"16px"}
            text={t("TradingPhotoCardPageV2.sellPhotoCardButton")}
            fontSize={"17px"}
            lineHeight={"20px"}
            style={{
              boxShadow: "0px 0px 4px 0px rgba(92, 61, 250, 0.40), 0px 2px 10px 0px rgba(92, 61, 250, 0.30)"
            }}
            onClicked={() => onClickFabButton(false)}
          />
        </div>
        <div>
          <RegularSolidButton
            paddingX={"24px"}
            paddingY={"16px"}
            text={t("TradingPhotoCardPageV2.exchangePhotoCardButton")}
            fontSize={"17px"}
            lineHeight={"20px"}
            style={{
              boxShadow: "0px 0px 4px 0px rgba(92, 61, 250, 0.40), 0px 2px 10px 0px rgba(92, 61, 250, 0.30)"
            }}
            onClicked={() => onClickFabButton(true)}
          />
        </div>
      </Fab>
    </FabWrapper>

    {/* <FabButton
      width={"52px"}
      p={"10px!important"}
      onClickFabButton={() => onClickFabButton()}
      style={{
        marginBottom: "calc(3rem + env(safe-area-inset-bottom))"
      }}
    /> */}
  </HomeBackground>
  )
}
