import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function AddPost({title, content, artistId, dataUrls, photoCardId, postType, isAnonymous, repostSourceId}) {
  return fetch(baseUrl() + `/api/v1/posts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      post: {
        title: title,
        content: content,
        post_type: postType,
        data_urls: dataUrls,
        photo_card_id: photoCardId,
        anonymous: isAnonymous,
        artist_id: artistId,
        repost_source_id: repostSourceId,
      },
    }),
  })
}
