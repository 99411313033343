import React, { useEffect, useState } from "react";

import { Spinner, Text } from "@chakra-ui/react";
import { filter, isEmpty } from "lodash";
import Sheet from 'react-modal-sheet';
import { useQueryClient } from "react-query";
import MyTradingPhotoCard from "../../components/tradings/MyTradingPhotoCard";
import { priceToNumberFormat } from "../../utils/NumberUtil";
import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";
import addTradingPhotoCards from "../api/mutations/addTradingPhotoCards";
import PrimaryButton from "../buttons/PrimaryButton";
import { t } from "i18next";

const titleStyle = {
  fontSize: "26px",
  fontWeight: 700,
}

const descriptionStyle = {
  marginTop: "14px",
  fontSize: "15px",
  fontWeight: 500,
  color: "#888"
}

export default function EditNewTradingPhotoCardsBottomSheet({isShowBottomSheet, setIsShowBottomSheet, tradingUserProfile, tradingPhotoCards, onClosed}){
  const queryClient = useQueryClient();

  const [newTradingPhotoCards, setNewTradingPhotoCards] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setNewTradingPhotoCards(tradingPhotoCards);
  }, [tradingPhotoCards])

  const onDone = async () => {
    if(!isUploading && tradingUserProfile){
      setIsUploading(true);
      const response = await addTradingPhotoCards(tradingUserProfile.userProfileId, newTradingPhotoCards)
      if (response.ok) {
        queryClient.invalidateQueries("tradingPhotoCards");
        queryClient.invalidateQueries("tradingPhotoCardsAll");
        setIsUploading(false);
        setIsShowBottomSheet(false);
        onClosed();
      }
    }
  }

  const onChangedItem = (item) => {
    const items = newTradingPhotoCards.map((tradingPhotoCard) => {
      if (tradingPhotoCard.tradingPhotoCardId === item.tradingPhotoCardId) {
        return {...item, price: priceToNumberFormat(item.price)}
      } else {
        return tradingPhotoCard
      }
    });
    setNewTradingPhotoCards(items);
  }

  const onDeletedItem = (item) => {
    const items = filter(newTradingPhotoCards, (tradingPhotoCard) => {
      return tradingPhotoCard.tradingPhotoCardId !== item.tradingPhotoCardId;
    });
    setNewTradingPhotoCards(items);

    if (isEmpty(items)) {
      setIsShowBottomSheet(false);
    }
  }

  return (
    <Sheet
      springConfig={SPRING_CONFIG}
      isOpen={isShowBottomSheet}
      disableDrag={true}
      onClose={() => {}}
    >
      <Sheet.Container style={{
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px"
      }}>
        <Sheet.Header></Sheet.Header>
        <Sheet.Content disableDrag={true} className="bottomSheetContent">
          <Text style={{color: "#5C3DFA", ...titleStyle}}>
            {t("addTradingPhotoCard.editNewTradingTitle")}
          </Text>
          <Text style={{marginTop: 0, ...titleStyle}}>
            {t("addTradingPhotoCard.editNewTradingSubTitle")}
          </Text>
          <Text style={descriptionStyle}>
            {t("addTradingPhotoCard.editNewTradingDescription")}
          </Text>
          <div style={{marginTop: "14px"}}>
            {
              newTradingPhotoCards.map((tradingPhotoCard) => {
                return (
                  <div key={tradingPhotoCard.tradingPhotoCardId} style={{marginBottom: "16px"}}>
                    <MyTradingPhotoCard
                      tradingPhotoCard={tradingPhotoCard}
                      onChangedItem={(item) => onChangedItem(item)}
                      onDeletedItem={(item) => onDeletedItem(item)}
                    />
                  </div>
                )
              })
            }
          </div>
          <div className="bottomNavbar safeAreaPaddingBottom text-center" style={{marginBottom: "32px", width: "calc(100% - 48px)"}}>
            {
              isUploading
              ? <Spinner size='sm' color="primary.500" marginRight={"20px"}/>
              : <PrimaryButton paddingY={"16px"} text={t("done")} onClick={() => onDone()}/>
            }
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onClick={() => onDone()}/>
    </Sheet>
  )
}
