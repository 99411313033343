import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";
import UserProfileResponse from "../model/UserProfileResponse";

export default function useUserProfilesListQuery(category) {
  const queryParams = new URLSearchParams();
  if (category) {
    queryParams.append("category", category)
  }

  const { data: userProfiles } = useQuery(
    ["V1:UserProfilesList", category],
    () => queryGet(`/api/v1/user_profiles?${queryParams.toString()}`),{
      select: (data) => data.userProfiles,
    }
  );
  return userProfiles?.map((userProfile) => new UserProfileResponse(userProfile));
}
