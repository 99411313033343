import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useUserQuery() {
  const { data: user } = useQuery(
    ["users"],
    () => queryGet(`/api/v1/users`),{
      select: (data) => data.user,
      cacheTime: 60 * 10 * 1000,
      staleTime: 60 * 10 * 1000,
    },
  );
  return user;
}
