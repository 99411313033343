import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useDistributorsQuery() {
  const { data: distributors } = useQuery(
    ["distributors"],
    () => queryGet(`/api/v1/tags?category=distributor`),{
      select: (data) => data.tags,
    }
  );
  return distributors;
}
