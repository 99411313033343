import { Badge } from "@chakra-ui/react"
import LightColors from "../../constants/LightColors"
import { isEmpty } from "lodash"

export const TagsList = ({photoCard}) => {
  return(
    <>
      <TagBadges tags={photoCard.distributors}/>
      <TagBadges tags={photoCard.tags}/>
      {
        photoCard.meta?.polaroid &&
        <Badge
          style={{
            backgroundColor: LightColors.surface,
            fontSize: "13px",
            fontWeight: 600,
            lineHeight: "16px",
            color: LightColors.onLabel,
            padding: "5px 8px",
            borderRadius: "4px",
          }}
        >
          {"polaroid"}
        </Badge>
      }
      {
        photoCard.meta?.unit &&
        <Badge
          style={{
            backgroundColor: LightColors.surface,
            fontSize: "13px",
            fontWeight: 600,
            lineHeight: "16px",
            color: LightColors.onLabel,
            padding: "5px 8px",
            borderRadius: "4px",
          }}
        >
          {"unit"}
        </Badge>
      }
    </>
  )
}


const TagBadges = ({tags}) => {
  if (isEmpty(tags)) {
    return(<></>)
  } else {
    return (
      tags.map((tag) => {
        console.log(tag)
        return(
          <Badge
            style={{
              backgroundColor: LightColors.surface,
              fontSize: "13px",
              fontWeight: 600,
              lineHeight: "16px",
              color: LightColors.onLabel,
              padding: "5px 8px",
              borderRadius: "4px",
            }}
            // onClick={() => navigate(`/tags/add?taggableType=PhotoCard&taggableId=${photoCard.photoCardId}`)}
          >
            {tag?.name || tag}
          </Badge>
        )
      })
    )
  }
}
