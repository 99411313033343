import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function addUserIdentityId(impUid, merchantUid) {
  const response = fetch(baseUrl() + `/api/v1/user_identities`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      user_identity: {
        imp_uid: impUid,
        merchant_uid: merchantUid,
      },
    }),
  }).then((data) => data.json());
  //TODO: Handle to invalidate related Query keys
  return response;
}
