import { SimpleGrid, TabPanel, Wrap } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { useQuery } from "react-query";
import EmptyCase from "../../components/errors/EmptyCase";
import LikedMembersList from "../../components/lists/LikedMembersList";
import MatchedTradingPhotoCard from "../../components/tradings/MatchedTradingPhotoCard";
import { queryGet } from "../../utils/queryClient";
import { getMemberIdAnyway } from "../../utils/etcUtils";

export default function WishPhotoCardsExchangeTab({ artist }) {
  const [selectedMember, setSelectedMember] = useState(null);
  const artistId = artist?.artistId;

  const onChangedMember = (member) => {
    if (getMemberIdAnyway(member) !== "all") {
      setSelectedMember(member);
    } else {
      setSelectedMember({ memberId: "all" });
    }
  }

  const exploreQueryParams = new URLSearchParams();
  exploreQueryParams.append('is_wished', true);
  if (getMemberIdAnyway(selectedMember)) {
    exploreQueryParams.append('member_id', getMemberIdAnyway(selectedMember));
  }

  const { data: matchedTradingPhotoCards } = useQuery(
    ["matchedTradingPhotoCards", artistId, exploreQueryParams.toString()],
    () => queryGet(`/artists/${artistId}/matched_trading_photo_cards?` + exploreQueryParams),
    {
      enabled: !!artistId,
      select: (r) => r.matchedTradingPhotoCards,
    },
  );

  return (
    <TabPanel padding={0}>
      {
        ((matchedTradingPhotoCards?.length) ?? 0) > 0 && (
          <Wrap spacing={"8px"} marginBottom={"20px"}>
            <LikedMembersList artistMembers={artist?.members} onChangeLikedMember={onChangedMember} addEntireOption={true} />
          </Wrap>
        )
      }
      {
        matchedTradingPhotoCards && (
          matchedTradingPhotoCards.length > 0 ?
            <>
              <SimpleGrid columns={3} spacing={"7px"} marginBottom={"24px"}>
                {
                  matchedTradingPhotoCards?.map((m) => {
                    return (
                      <MatchedTradingPhotoCard
                        key={m.matchedTradingPhotoCardId}
                        matchedTradingPhotoCard={m}
                      />
                    )
                  })
                }
              </SimpleGrid>
            </> :
            <EmptyCase
              emoji={"🙂"}
              title={t("emptyWishedMatchedTradingPhotoCard.title")}
              description={t("emptyWishedMatchedTradingPhotoCard.description")}
            />
        )
      }
    </TabPanel>
  )
}
