import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

const DEFAULT_PAGE = 10;

export default function useMatchedTradingPhotoCardsQuery(photoCardId, perPage) {
  async function fetchMatchedTradingPhotoCards({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage || DEFAULT_PAGE,
      page: pageParam,
    });

    const url = `/api/v1/photo_cards/${photoCardId}/matched_trading_photo_cards?${queryParams.toString()}`;
    try {
      const data = await queryGet(url);
      return data;
    } catch (error) {
      throw new Error("Error fetching paginated data.");
    }
  }

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["matchedTradingPhotoCards", photoCardId],
    fetchMatchedTradingPhotoCards,
    {
      enabled: !!photoCardId,
      getNextPageParam: (lastPage) => {
        if (!!lastPage?.meta?.pagination?.nextPage) {
          return lastPage?.meta?.pagination?.nextPage;
        } else {
          return undefined
        }
      }
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  };
}
