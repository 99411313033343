import React from "react";

import { Text, VStack } from '@chakra-ui/react';

import { t } from "i18next";
import Lottie from "lottie-react";
import progressAnimation from "../../lotties/progressDragonBall.json";

const lottieStyle = {
  width: "180px",
  height: "180px"
}

const titleStyle = {
  fontWeight: 700,
  color: "rgba(68, 68, 68, 1)",
  fontSize: "17px",
  paddingLeft: "24px",
  paddingRight: "24px",
  wordBreak: "keep-all",
  lineHeight: "22px"
}

const descriptionStyle = {
  fontWeight: 500,
  color: "rgba(136, 136, 136, 1)",
  fontSize: "14px",
  lineHeight: "17px",
  whiteSpace: "pre-wrap"
}

export default function OnGoingMatchingTradingPhotoCard() {
  return (
    <VStack marginTop={"32px"} alignContent={"center"} marginBottom={"96px"}>
      <Lottie
        animationData={progressAnimation} loop={true}
        style={lottieStyle}
      />
      <Text style={titleStyle} className="text-center">
        {t("onGoingMatchingTradingPhotoCard.title")}
      </Text>
      <Text className="text-center" style={descriptionStyle}>
        {t("onGoingMatchingTradingPhotoCard.description")}
      </Text>
    </VStack>
  )
}
