import { HStack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";

import Page from "../components/Page";

import { isEmpty } from 'lodash';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import FilledButton from '../components/FilledButton';
import addUserIdentityId from '../components/api/mutations/addIdentityVerificationId';
import PrimaryButton from '../components/buttons/PrimaryButton';

import Lottie from 'lottie-react';
import SuccessVerified from "../lotties/successVerified.json";

const Title = styled.p`
  color: #222;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const Description = styled.p`
  color: #666;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px!important;
`

const graineyBackgroundStyle = {
  position: "relative",
  filter: "contrast(170%) brightness(200%)", background: "linear-gradient(14deg, rgba(27,85,177,1), rgba(0,0,0,0)), linear-gradient(298deg, rgba(168,0,255,0.33), rgba(0,0,0,0)), url(/assets/backgrounds/grainey.svg)",
  width: "100%",
  height: "100vh",
  marginTop: "40vh",
  zIndex: 0,
}

const pageBackgroundStyle = {
  zIndex: 999,
  top: 0,
  position: "absolute",
  background: "linear-gradient(180deg, #FFF 55%, rgba(255, 255, 255, 0.3) 100%)",
}

const badgeIconStyle = {
  paddingTop: "30px",
  position: "absolute",
  top: "auto",
  left: "50%",
  transform: "translateX(-50%)"
}

export default function IdentityVerificationCallbackPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const redirectPath = currentQueryParams.get("redirect_path");
  const impUid = currentQueryParams.get("imp_uid");
  const merchantUid = currentQueryParams.get("merchant_uid");

  const queryClient = useQueryClient();

  const [isSuccess, setIsSuccess] = useState(currentQueryParams.get("success") === "true");
  const [errorMessage, setErrorMessage] = useState("")

  const navigateRedirectPath = () => {
    navigate(redirectPath, { replace: true });
  }

  useEffect(() => {
    if(isSuccess && impUid && merchantUid) {
      addUserIdentityId(impUid, merchantUid).then((r) => {
        if(r.error) {
          setIsSuccess(false);
          if (r.error.code === "1010"){
            setErrorMessage("해당 번호는 사용할 수 없습니다.");
          }
        }else{
          queryClient.invalidateQueries("userProfiles");
          queryClient.invalidateQueries("userProfile");
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impUid, merchantUid, isSuccess]);

  return (
    <>
    <div style={graineyBackgroundStyle}/>
    <Page title={"본인인증 성공"} showBackButton={false} style={pageBackgroundStyle}>
      {
        isSuccess ?
        <VStack marginTop={"12vh"}>
          <Wrap position={"relative"} align='center'>
            <WrapItem>
              <Lottie animationData={SuccessVerified} loop={true} style={{height: "70%"}}/>
            </WrapItem>
            <WrapItem style={badgeIconStyle}>
              <img src="/assets/icons/verfied.graphic.png" width={140} height={140} alt="verfied.graphic"/>
            </WrapItem>
          </Wrap>
          <HStack marginTop={"-50px!important"}>
            <Title>{"파란체크 획득 완료!"}</Title>
          </HStack>
          <Description>{"본인인증에 성공하였습니다"}</Description>
          <PrimaryButton
            marginTop={"100px!important"}
            fontSize={"17px"}
            lineHeight={"20px"}
            padding={"16px 34px"}
            minWidth={"200px"}
            text={"확인"}
            onClick={() => navigateRedirectPath()}
            />
        </VStack> :
        <VStack marginTop={"35vh"}>
          <Text fontSize={"50px"}>🥺</Text>
          <Text fontSize={"17px"} fontWeight={700} color={"#444444"}>
            {"본인인증을 실패하였습니다."}
          </Text>
          {
            !isEmpty(errorMessage) && <Description>{errorMessage}</Description>
          }
          <FilledButton
            paddingX={"18px"}
            paddingY={"11px"}
            filled={false}
            color={"#444444"}
            fontWeight={600}
            fontSize={"14px"}
            text={"돌아가기"}
            backgroundColor={"#FFF"}
            borderWidth={"1px"}
            borderColor={"#E7E7E7"}
            onClicked={() => navigateRedirectPath()}
          />
        </VStack>
      }
    </Page>
    </>
  );
}
