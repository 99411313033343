const colors = {
  primary: "#5C3DFA",
  background: "#FFFFFF",
  secondary: "rgba(92, 61, 250, 0.05)",
  tint: "#B2A3FF",
  eventText: "#222",
  photoCardText: "#666",
  textMain: "#444444",
  textMainLv4: "#888888",
  textSub: "#888888",
  textPurple: "#382599",
  warning: "#FF142A",
  warningBackground: "rgba(255, 20, 42, 0.1)",
  textSubLv1: "#AAAAAA",
  textSubLv3: "#CBCBCB",
  disabled: "#B9B9B9",
  separator: "#E7E7E7",
}

export default colors;
