import { HStack, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import React from "react";

import Page from "../../components/Page";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from 'styled-components';

import Lottie from 'lottie-react';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import SuccessVerified from "../../lotties/successVerified.json";

const Title = styled.p`
  color: #222;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const Description = styled.p`
  color: #666;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px!important;
`

const graineyBackgroundStyle = {
  position: "relative",
  filter: "contrast(170%) brightness(200%)",
  background: "linear-gradient(14deg, rgba(27,85,177,1), rgba(0,0,0,0)), linear-gradient(298deg, rgba(168,0,255,0.33), rgba(0,0,0,0)), url(/assets/backgrounds/grainey.svg)",
  width: "100%",
  height: "100vh",
  zIndex: 0,
}

const pageBackgroundStyle = {
  zIndex: 999,
  top: 0,
  position: "absolute",
  background: "linear-gradient(180deg, #FFF 55%, rgba(255, 255, 255, 0.3) 100%)",
}

const badgeIconStyle = {
  paddingTop: "30px",
  position: "absolute",
  top: "auto",
  left: "50%",
  transform: "translateX(-50%)"
}

export default function OrderStatusPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const currentQueryParams = new URLSearchParams(location.search);
  const redirectPath = currentQueryParams.get("redirect_path");

  const navigateRedirectPath = () => {
    if(redirectPath) {
      navigate(redirectPath);
    } else {
      window.location.href ="https://www.pocapan.com"
    }
  }

  return (
    <>
    <div style={graineyBackgroundStyle}/>
    <Page
      title={"결제 성공"}
      showBackButton={false}
      style={pageBackgroundStyle}
      ignoreTokenCheck={true}
    >
      {
        <VStack marginTop={"12vh"}>
          <Wrap position={"relative"} align='center'>
            <WrapItem>
              <Lottie animationData={SuccessVerified} loop={true} style={{height: "70%"}}/>
            </WrapItem>
            <WrapItem style={badgeIconStyle}>
              <img src="/assets/icons/verfied.graphic.png" width={140} height={140} alt="verfied.graphic"/>
            </WrapItem>
          </Wrap>
          <HStack marginTop={"-50px!important"}>
            <Title>{"주문 내역이 접수되었습니다!"}</Title>
          </HStack>
          <Description>{"배송이 시작되면 접수된 연락처로 메시지가 발송됩니다."}</Description>
          <Description><b>{`■ 주문번호 (캡쳐해주세요) ■`}</b></Description>
          <Description>{`${params["order_id"]}`}</Description>
          <Description>{`*주문취소시, 메일로 캡쳐본을 보내주세요.`}</Description>
          <PrimaryButton
            marginTop={"100px!important"}
            fontSize={"17px"}
            lineHeight={"20px"}
            padding={"16px 34px"}
            minWidth={"200px"}
            text={"확인"}
            onClick={() => navigateRedirectPath()}
            />
        </VStack>
      }
    </Page>
    </>
  );
}
