import { useParams } from "react-router-dom";
import ErrorBoundaryHome from "../../components/errors/ErrorBoundaryHome";
import ShopProduct1 from "./ShopProduct1";
import ShopProductDermatoryPad from "./ShopProductDermatoryPad";

export default function ShopPage() {
  const params = useParams();
  let productComponent = <ErrorBoundaryHome />;

  if (params?.product_id === '1') {
    productComponent = <ShopProduct1 />;
  } else if (params?.product_id === 'dermatory_pad') {
    productComponent = <ShopProductDermatoryPad />;
  }

  return (
    <>
      {productComponent}
    </>
  );
}
