import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";
import { first } from "lodash";

const DEFAULT_PAGE = 15;

// 공통적인 useChatRoomsQuery 함수
export default function useChatRoomsQuery({ type = "All", tradingPhotoCardId, onlyJoined = false,  onlyMine = false, perPage = DEFAULT_PAGE, }) {
  const baseUrls = {
    "All": "/api/v1/chat_rooms",
    "Purchasing": "/api/v1/chat_rooms/purchasing",
    "Selling": "/api/v1/chat_rooms/selling",
    "Exchanging": "/api/v1/chat_rooms/exchanging",
  };

  const baseKeys = {
    "All": "AllChatRooms",
    "Purchasing": "PurchasingChatRooms",
    "Selling": "SellingChatRooms",
    "Exchanging": "ExchangingChatRooms",
  };

  const filters = {
    only_joined: onlyJoined,
    only_mine: onlyMine,
    trading_photo_card_id: tradingPhotoCardId, // tradingPhotoCardId가 제공된 경우에는 반드시 값이 있어야 하고, 그렇지 않으면 enabled는 true
  };

  // 캐시 키 생성 및 필터 적용
  return useChatRoomsQueryCore({
    baseKey: baseKeys[type],
    baseUrl: baseUrls[type],
    filters: filters,
    perPage: perPage,
    enabled: tradingPhotoCardId !== undefined ? !!tradingPhotoCardId : true
  });
}

// 공통 로직을 처리하는 core 함수
function useChatRoomsQueryCore({ baseKey, baseUrl, filters = {}, perPage, enabled }) {
  const queryParams = createFilteredQueryParams({ perPage, filters });
  const cacheKey = generateCacheKey(baseKey, filters);

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    cacheKey,
    ({ pageParam = 1 }) => fetchChatRoomsQuery({ pageParam, url: baseUrl, queryParams }),
    {
      enabled,
      getNextPageParam: (lastPage) => lastPage?.meta?.pagination?.nextPage || undefined,
    }
  );

  return { data, isFetching, fetchNextPage, hasNextPage };
}

// 필터링된 QueryParam을 생성하는 함수 (기존)
function createFilteredQueryParams({ perPage = DEFAULT_PAGE, page = 1, filters = {} }) {
  const queryParams = new URLSearchParams({ per_page: perPage, page });

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined && filters[key] !== false) {
      queryParams.append(key, filters[key]);
    }
  });

  return queryParams;
}

// QueryParam 기반으로 캐시 키를 생성하는 함수 (기존)
function generateCacheKey(baseKey, filters) {
  const filterKey = Object.entries(filters)
    .filter(([_, value]) => value !== undefined && value !== false)
    .map(([key, value]) => `${key}:${value}`)
    .join("&");

  return [baseKey, filterKey];
}

// ChatRooms 데이터를 가져오는 함수 (기존)
async function fetchChatRoomsQuery({ pageParam = 1, url, queryParams }) {
  queryParams.set("page", pageParam);
  const finalUrl = `${url}?${queryParams.toString()}`;

  try {
    const data = await queryGet(finalUrl);
    return data;
  } catch (error) {
    throw new Error("Error fetching paginated data.");
  }
}

export async function fetchRecentChatRoomQuery({ tradingPhotoCardId }) {
  const filters = { trading_photo_card_id: tradingPhotoCardId };
  const queryParams = createFilteredQueryParams({ perPage: 1, filters });
  const url = `/api/v1/chat_rooms`;

  try {
    const data = await queryGet(`${url}?${queryParams.toString()}`);
    return first(data.chatRooms);
  } catch (error) {
    throw new Error("Error fetching recent chat room.");
  }
}
