import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useArtistQuery(artistId) {
  const { data: artist } = useQuery(
    ["artist", artistId],
    () => queryGet(`/api/v1/artists/${artistId}`),{
      select: (data) => data.artist,
      enabled: !!artistId,
    }
  );
  return artist;
}

export function useArtistQueryLikedOnly() {
  const { data: artists } = useQuery(
    ["likedArtist"],
    () => queryGet(`/api/v1/artists?liked_only=true&page=1&per_page=10000`),{
      select: (data) => data.artists,
    }
  );
  return artists;
}

export function useArtistQueryAll() {
  const { data: artists } = useQuery(
    ["likedAllArtist"],
    () => queryGet(`/api/v1/artists?liked_only=false&page=1&per_page=10000`),{
      select: (data) => data.artists,
    }
  );
  return artists;
}
