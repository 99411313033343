import React from "react";
import { Box, Center } from '@chakra-ui/react';
import colors from "../../constants/colors";

export default function WarningButton(
  {
    text,
    disabled,
    ...props
  }
) {
  return (
    <Box
      className="text-center"
      textColor={disabled ? "#B9B9B9" : colors.warning}
      backgroundColor={disabled ? "#E7E7E7" : colors.warningBackground}
      borderRadius={"52px"}
      alignContent={"center"}
      lineHeight={"17px"}
      paddingX={"12px"}
      paddingY={"8px"}
      fontWeight={600}
      fontSize={"14px"}
      {...props}
    >
      <Center><p>{text}</p></Center>
    </Box>
  )
}
