import getToken from './getToken'
import apiKey from './apiKey';
import baseUrl from './baseUrl';
import { QueryClient } from 'react-query';

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        //query default options here!
        cacheTime: 60 * 10 * 1000,
        staleTime: 60 * 5 * 1000,
      }
    },
    onError: (error) => {
      // global error handler
    }
  })
}

// #region query functions
export async function queryGet(url, token) {
  const res = await fetch(baseUrl() + url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (typeof token === 'undefined' ? getToken() : token),
      "X-API-KEY": apiKey(),
    },
    redirect: "follow",
  })

  const data = await res.json();
  const status = res.status;

  if (status === 401) {
    if (data.error?.code === "4444") {
      window.location.href = "/unauthorized_access?blocked=true";
    } else if (data.error?.code === "5555") {
      window.location.href = "/unauthorized_access?isChild=true";
    } else {
      window.location.href = "/unauthorized_access";
    }
  } else if (status >= 200 && status < 300) {
    return data;
  } else {
    throw new Error(`Network error to get ${url}`);
  }
}

export async function queryPost(url, body, token) {
  return await fetch(baseUrl() + url, {
    method: "POST",
    body: body,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (typeof token === 'undefined' ? getToken() : token),
      "X-API-KEY": apiKey(),
    },
    redirect: "follow",
  })
  .then((data) => {
    if (data.ok) {
      return data.json();
    }
    throw new Error("Network error.");
  })
}
// #endregion
