import { isNull, isUndefined } from "lodash";
import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function updateTradingPhotoCards({tradingPhotoCardId, price, category, status}) {
  return fetch(baseUrl()  + `/api/v1/trading_photo_cards/${tradingPhotoCardId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      trading_photo_card: {
        price : price, // Price could be null
        ...(!isUndefined(category) && !isNull(category) && { category } ),
        ...(!isUndefined(status) && !isNull(status) && { status } ),
      },
    }),
  });
}
