const KNOWN_RESIZABLE_MIMETYPES = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/bmp",
    "image/tiff",
    "image/svg",
    "image/svg+xml",
    "image/x-icon",
]

export default function isResizableMimetype(mimetype) {
    return KNOWN_RESIZABLE_MIMETYPES.includes(mimetype)
}
