import React from "react";

import { Text, VStack } from '@chakra-ui/react';
import { ReactComponent as MatchingFillIcon } from "../../icons/matching.fill.svg";
import TradingPhotoCardInfo from "./TradingPhotoCardInfo";
import { t } from "i18next";

const matchedTitleStyle = {
  fontSize: "13px",
  fontWeight: 600,
  color: "#888"
}

export default function MatchedTradingPhotoCardInfo({source, target}) {
  return (
    <div className="flex">
      <div className="flex-1">
        <VStack align={"flex-start"}>
          <Text style={matchedTitleStyle}>{t("tradingPhotoCardPage.matchedTradingPhotoCardMine")}</Text>
          <TradingPhotoCardInfo tradingPhotoCard={source} halfType={true}/>
        </VStack>
      </div>
      <div className="flex items-center shrink pt-4" style={{paddingLeft: "6px", paddingRight: "6px"}}>
        <MatchingFillIcon width={"16px"} fill={"rgba(170, 170, 170, 1)"} />
      </div>
      <div className="flex-1">
        <VStack align={"flex-start"}>
          <Text style={matchedTitleStyle}>{t("tradingPhotoCardPage.matchedTradingPhotoCardTarget")}</Text>
          <TradingPhotoCardInfo tradingPhotoCard={target} halfType={true}/>
        </VStack>
      </div>
    </div>
  )
}
