import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { t } from 'i18next';

const ArtistRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
`

const SelectorWrapper = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
`

const SelectedText = styled.p`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: ${colors.primary};
  padding-bottom: 2px;
  border-bottom: 2px solid ${colors.primary};
`

const UnSelectedText = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${colors.textMain};
  padding-bottom: 4px;
`

const DEFAULT_LIST_TAB= [
  {
    id: "posts",
    text: t("userFeedProfilePage.postTab"),
  }
]

export default function TabsListSelector({ onSelectedTab, tabsList=DEFAULT_LIST_TAB, hiddenTabId }) {
  const [selectedTab, setSelectedTab] = useState(tabsList[0])

  useEffect(() => {
    if (onSelectedTab) {
      onSelectedTab(selectedTab)
    }
  }, [onSelectedTab, selectedTab]);

  return (
    <SelectorWrapper>
      <ArtistRow>
        {tabsList?.filter((tab) => tab.id !== hiddenTabId)?.map((tab) => (
          <div key={tab.id} onClick={() => setSelectedTab(tab)}>
            {
              tab.id === selectedTab?.id ?
              <SelectedText>{tab.text}</SelectedText>:
              <UnSelectedText>{tab.text}</UnSelectedText>
            }
          </div>
        ))}
      </ArtistRow>
    </SelectorWrapper>
  );
};
