import baseUrl from "../../../../utils/baseUrl";
import getToken from "../../../../utils/getToken";

export default async function leaveChatRoom(chatRoomId) {
  const url = `/chat_rooms/${chatRoomId}/leave`;
  return fetch(baseUrl() + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then((data) => data.json())
}
