import { Text, VStack, Image, Box, Center, Select, SimpleGrid, Wrap, Textarea } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { first, uniq } from "lodash";
import { useQuery } from "react-query";
import { useFilePicker } from 'use-file-picker';

import Page from "../components/Page";
import HomeBottomNav from "../layout/HomeBottomNav";
import FilledButton from "../components/FilledButton";
import { ReactComponent as PlusIcon } from "../icons/plus.svg";

import ImageUpload from "../utils/ImageUploader";
import { queryGet } from "../utils/queryClient";
import { t } from "i18next";
import { getArtistIdAnyway, getMemberIdAnyway } from "../utils/etcUtils";

export default function ReportPhotoCardPage() {
  const { state } = useLocation();
  const [selectedMember, setSelectedMember] = useState()
  const [selectedEventCategory, setSelectedEventCategory] = useState();
  const [customEvent, setCustomEvent] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState();
  const [selectedEventName, setSelectedEventName] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const { data: eventCategories } = useQuery(
    ["eventCategories"],
    () => queryGet(`/events/categories`)
  );

  const { data: events } = useQuery(
    [`events:${getArtistIdAnyway(state.artist)}`],
    () => queryGet(`/artists/${getArtistIdAnyway(state.artist)}/events`),
    {
      enabled: !!state?.artist,
    },
  );

  const { data: members } = useQuery(
    ["userProfile"],
    () => queryGet(`/artists/${getArtistIdAnyway(state?.artist)}/user_profile`),
    {
      enabled: !!state?.artist,
      select: (r) => r.likedMembers,
    },
  );

  useEffect(()=>{
    setSelectedMember(first(members))
  }, [members]);

  const { openFilePicker, filesContent } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  const selectOptionMember = (id) => {
    setSelectedMember(state?.members?.find((m) => getMemberIdAnyway(m) === id))
  }

  const selectOptionEvent = (id) => {
    if(id === t("reportPhotoCardPage.directlyInputOption")){
      setSelectedEventId(0)
      setCustomEvent(true)
    } else if(id) {
      const event = events?.find((e) => e.eventId === id)
      setSelectedEventId(event.id)
      setSelectedEventName(event.name)
      setCustomEvent(false)
    } else {
      setSelectedEventId(null)
      setSelectedEventName(null)
      setCustomEvent(false)
    }
  }

  const uploadPhotoCard = async () => {
    if(requiredAllField()){
      if(isUploading){ return alert(t("reportPhotoCardPage.duplicatedAlert")) }
      setIsUploading(true)
      const response = await ImageUpload(
        filesContent[0].content,
        getArtistIdAnyway(state.artist),
        getMemberIdAnyway(selectedMember),
        selectedEventName,
        selectedEventCategory,
        selectedEventId,
      )
      setIsUploading(false)
      if(response){
        alert(t("reportPhotoCardPage.reportDoneAlert"));
        window.location.reload();
      }
    }else{
      alert(t("reportPhotoCardPage.requiredMissingAlert"));
    }
  }

  const requiredAllField = () => {
    return filesContent.length > 0 && !!getArtistIdAnyway(state.artist) && !!getMemberIdAnyway(selectedMember) && !!selectedEventName && !!selectedEventCategory
  }

  return(
    <div className={!!state.showBackButton ? "safeAreaPaddingExceptBottomBackground" : "homeBackground safeAreaPaddingExceptBottomBackground"}>
    <Page title={t("reportPhotoCardPage.pageName")}
        showBackButton={state.showBackButton}
        themeColor={!!state.showBackButton ? "#FFF" : "#C6DBF8"}
        marginBottom={"128px"}
        style={!!state.showBackButton ? {} : {paddingTop: "13px"}}>
      <SimpleGrid columns={2} gap={1} alignItems="center">
        <Text fontSize={"26px"} fontWeight={700} color={"#222"}>
        {t("reportPhotoCardPage.title")}
        </Text>
        <Select
          width={"80px"}
          color={"primary.500"}
          bg={"secondary.600"}
          borderRadius={"32px"}
          borderWidth={0}
          fontWeight={600}
          lineHeight={"17px"}
          fontSize={"14px"}
          justifySelf={"end"}
          onChange={(e)=>selectOptionMember(e.target.value)}
        >
          {members?.map((m) => {
            return <option key={getMemberIdAnyway(m)} value={getMemberIdAnyway(m)}>{m.name}</option>
          })}
        </Select>
      </SimpleGrid>
      <Center className="pt-10 pb-5">
      {
        filesContent.length === 0
        ?
        <Box
          bg="rgba(0, 0, 0, 0.04)"
          borderRadius="18px"
          width={"120px"}
          height={"170px"}
          onClick={openFilePicker}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <PlusIcon
            width={"46px"}
            height={"46px"}
            fill="rgba(0, 0, 0, 0.4)"
          />
        </Box>
        :
        <Image width="120px" src={filesContent[0].content}/>
      }
      </Center>
      <VStack spacing={3} align="start" paddingTop={"32px"}>
        <Text fontSize={"17px"} fontWeight={700} color={"#444"} lineHeight={"20px"} paddingY={"10px"}>
          {t("reportPhotoCardPage.categoryDescription")}
        </Text>
        <Wrap spacing={"8px"}>
        {
          eventCategories && Object.entries(eventCategories).map(([k,v]) =>
            <FilledButton
              key={k}
              borderRadius={"full"}
              padding={"8px 12px"}
              fontSize={"14px"}
              lineHeight={"17px"}
              fontWeight={600}
              onClick={()=> setSelectedEventCategory(k)}
              text={v}
              filled={k === selectedEventCategory}
            />
          )
        }
        </Wrap>
      </VStack>

      <VStack spacing={3} align="start" className="mt-5" display={selectedEventCategory ? "" : "none"}>
        <Text fontSize={"17px"} fontWeight={700} color={"#444"} lineHeight={"20px"} paddingY={"10px"}>
          {t("reportPhotoCardPage.eventDescription")}
        </Text>
        <Select
          color={"primary.500"}
          bg={"secondary.600"}
          borderRadius={"12px"}
          height={"54px"}
          fontWeight={700}
          fontSize={"17px"}
          justifySelf={"end"}
          onChange={(e)=>selectOptionEvent(e.target.value)}
          placeholder={t("reportPhotoCardPage.selectPlaceHolder")}
        >
          {
            selectedEventCategory && uniq(events?.filter((e) => { return e.category === selectedEventCategory })).map((e) =>
              <option key={e.eventId} value={e.eventId}>{e.name}</option>
            )
          }
          <option key={"defaultOption"} value={t("reportPhotoCardPage.directlyInputOption")}>
            {t("reportPhotoCardPage.directlyInputOption")}
          </option>
        </Select>
        {
          customEvent &&
          <Textarea
            fontSize={"17px"}
            fontWeight={600}
            color={"#444"}
            bg={"secondary.600"}
            borderRadius={"12px"}
            border={"#FFF"}
            focusBorderColor={"#FFF"}
            onChange={(e) => setSelectedEventName(e.target.value)}
          />
        }
      </VStack>

      <FilledButton
        marginTop={"24px"}
        marginBottom={"calc(5px + env(safe-area-inset-bottom))"}
        onClick={uploadPhotoCard}
        text={requiredAllField() ? `🤍${t("reportPhotoCardPage.reportDone")}🤍` : t("reportPhotoCardPage.reportDone")}
        paddingX={"16px"}
        paddingY={"16px"}
        fontSize={"17px"}
        fontWeight={600}
        lineHeight={"20px"}
        borderRadius={"32px"}
        disabled={true}
        bg={requiredAllField() ? "primary.500" : "rgba(0, 0, 0, 0.04)"}
        textColor={requiredAllField() ? "white": "rgba(0, 0, 0, 0.3)"}
      />
    </Page>
    {/* {
      state.navBarVisible === true ?
        <HomeBottomNav
        tabIndex={3}
        artistId={getArtistIdAnyway(state.artist)}
        />
      : <></>
    } */}
    </div>

  )
}
