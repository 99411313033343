import { useEffect, useState } from "react";
import { VStack, Text, Box, Flex, Image } from '@chakra-ui/react';
import PrimaryButton from "../components/buttons/PrimaryButton";
import { isNativeIOS } from "../utils/NativeUtil";
import { isMobile, isIOS, isMacOs } from 'react-device-detect';
import { t } from "i18next";
import { usePreventScroll } from "../utils/UIUtils";

export default function ForceToUpdatePage() {
  const animationSecond = 35.0

  const descriptionStyle = {
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "18px",
    fontColor: "#222222",
    marginBottom: "16px"
  }

  const titleStyle = {
    fontWeight: "700",
    fontSize: "22px",
    lineHeight: "26px",
    fontColor: "#444444",
    marginBottom: "16px"
  }

  const contentStyle = {
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "26px",
    fontColor: "#444444"
  }

  const contentStyle2 = {
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "26px",
    fontColor: "#444444"
  }

  usePreventScroll();

  const redirectToStore = () => {
    // iOS인 경우
    if (isIOS || isMacOs) {
      window.location.href = 'https://apps.apple.com/kr/app/pocapan-%ED%8F%AC%EC%B9%B4%ED%8C%90/id6449088562';
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.peacecover.pocapan';
    }
  };

  return (
    <Box position="relative" height="100vh" overflow="hidden">
      <Box position="absolute" left="50%" transform="translate(-50%, 0%)" overflow="hidden" zIndex={1} backgroundColor={"white"} height={"100vh"} minW={"calc(min(100vw, var(--chakra-sizes-prose), 460px))"}>
      </Box>
      <Box position="absolute" left="50%" transform="translate(-50%, 0%)" overflow="hidden" zIndex={2}>
        <Image src="/assets/force_to_update/bg_letters_top.png" objectFit="cover" maxW="460px" />
      </Box>
      <Box position="absolute" left="50%" transform="translate(-50%, 0%)" bottom="0" overflow="hidden" zIndex={3}>
        <Image src="/assets/force_to_update/bg_letters_btm.png" objectFit="cover" maxW={"460px"}/>
      </Box>
      <Box position="absolute" height="100vh" overflow="hidden" zIndex={4} minW={"calc(min(100vw, var(--chakra-sizes-prose)))"} maxW={"xl"}>
        <Flex height="100vh" flexDirection="column" alignItems="center" justifyContent="center">
          <Image src="/assets/force_to_update/img_invitation.png" mt={"44px"} w={"136px"} h={"152px"}/>
          <Text style={descriptionStyle}>{t("forceToUpdate.pageTitle")}</Text>
          <Text style={titleStyle} whiteSpace="pre-line" textAlign="center">
            {t("forceToUpdate.title")}
          </Text>
          <Box
            width={"327px"}
            height={"272px"}
            borderRadius={"17px"}
            border={"1px solid rgba(255, 255, 255, 0.60)"}
            backgroundColor={"linear-gradient(92deg, rgba(255, 255, 255, 0.50) 31.85%, rgba(255, 255, 255, 0.10) 102.92%)"}
            shadow={"0px 4px 10px 0px rgba(0, 0, 0, 0.04), 0px 0px 24px 0px rgba(255, 255, 255, 0.30) inset"}
            mt={"22px"}
            mb={"33px"}
            overflow="hidden"
            textOverflow="ellipsis"
            position="relative" // 상대 위치 설정
            p={"19px"}
            style={{
              backdropFilter: "blur(20px)"
            }}
          >
            <Text style={contentStyle} whiteSpace="pre-line">
              {t("forceToUpdate.firstRowDescription")}
            </Text>
            <Text style={contentStyle2} whiteSpace="pre-line">
              {t("forceToUpdate.secondRowDescription")}
            </Text>
            <Text style={contentStyle} whiteSpace="pre-line">
              {t("forceToUpdate.thirdRowDescription")}
            </Text>
          </Box>
          <PrimaryButton
            width={"339px"}
            fontWeight={600}
            fontSize={"17px"}
            lineHeight={"20px"}
            padding={"16px 34px"}
            onClick={redirectToStore}
            text={t("forceToUpdate.button")}
            mb={"72px"}
          />
        </Flex>
      </Box>
    </Box>
  );
}
