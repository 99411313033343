import React from "react";
import { Box, Center } from '@chakra-ui/react';
import LightColors from "../../constants/LightColors";

export default function DisabledLineButton(
  {
    text,
    disabled,
    ...props
  }
) {
  return (
    <Box
      className="text-center"
      textColor={LightColors.onDisable}
      borderRadius={"52px"}
      lineHeight={"17px"}
      paddingX={"12px"}
      paddingY={"8px"}
      border={`1px solid ${LightColors.disable}`}
      fontWeight={500}
      fontSize={"14px"}
      {...props}
    >
      <Center><p>{text}</p></Center>
    </Box>
  )
}
