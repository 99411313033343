import { sendToNative } from "./NativeUtil"

export const saveSecureData = (key, value) => {
    localStorage.setItem(key, value);
    sendToNative(`{"type": "setLocalStorageSecure", "key": "${key}", "value": "${value}"}`);
};

export const saveData = (key, value) => {
    localStorage.setItem(key, value);
    sendToNative(`{"type": "setLocalStorage", "key": "${key}", "value": "${value}"}`);
};
  
export default saveData;
  