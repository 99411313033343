import { Box, Center, TabPanel, Wrap, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "react-query";
import { queryGet } from "../../utils/queryClient";
import { ReactComponent as ArrowUpShapeIcon } from "../../icons/arrow.up.shape.svg";
import LikedMembersList from "../../components/lists/LikedMembersList";
import WishedPhotoCard from "../../components/photocard/WishedPhotoCard";
import MyPhotoCardBottomSheet from "../../components/bottomSheet/MyPhotoCardBottomSheet";
import { isEmpty } from "lodash";
import EmptyCase from "../../components/errors/EmptyCase";
import { getMemberIdAnyway } from "../../utils/etcUtils";
import { t } from "i18next";

export default function WishPhotoCardsTab({ artist }) {
  const [selectedMember, setSelectedMember] = useState()
  const [isMyPhotoCardsBottomSheetOpen, setIsMyPhotoCardsBottomSheetOpen] = useState(false)
  const artistId = artist?.artistId;

  const queryParams = new URLSearchParams();
  if (selectedMember && getMemberIdAnyway(selectedMember) !== "all") {
    queryParams.append('member_id', getMemberIdAnyway(selectedMember));
  }

  const { data: wishedPhotoCards } = useQuery(
    ["wishedPhotoCards", artistId, queryParams.toString()],
    () => queryGet(`/artists/${artistId}/wished_photo_cards?` + queryParams),
    {
      enabled: !!artistId,
      select: (r) => r.photoCards
    },
  );

  const onChangeLikedMember = (member) => {
    setSelectedMember(member)
  }

  const onToggled = (photoCard, wished) => {
    // Nothing todo
  }

  return (
    <TabPanel padding={0}>
      <Center>
        <Box
          marginBottom={"16px"}
          paddingX={"24px"}
          paddingY={"14px"}
          width={"100%"}
          borderRadius={"12px"}
          backgroundImage={"/assets/backgrounds/banner_bg_holography.png"}
        >
          <Wrap justify={"center"} onClick={() => setIsMyPhotoCardsBottomSheetOpen(true)}>
            <Text color={"#222222"} fontSize={"17px"} fontWeight={600}>
              {t("myWishedPage.createPhotoCardBoard")}
            </Text>
            <ArrowUpShapeIcon fill="black" />
          </Wrap>
        </Box>
      </Center>
      <LikedMembersList artistMembers={artist?.members} onChangeLikedMember={(member) => onChangeLikedMember(member)} addEntireOption={true} />
      <div style={{ marginTop: "12px" }}>
        {
          wishedPhotoCards && (
            isEmpty(wishedPhotoCards) ?
            <EmptyCase
              title={t("emptyWishedTradingPhotoCard.title")}
              description={t("emptyWishedTradingPhotoCard.description")}
              emoji={"🙂"}
            /> :
            wishedPhotoCards.map((photoCard) => {
              return (
                <div key={photoCard.photoCardId} style={{ marginBottom: "12px" }}>
                  <WishedPhotoCard
                    photoCard={photoCard}
                    onToggled={onToggled}
                  />
                </div>
              )
            })
          )
        }
      </div>

      <MyPhotoCardBottomSheet
        isMyPhotoCardsBottomSheetOpen={isMyPhotoCardsBottomSheetOpen}
        setIsMyPhotoCardsBottomSheetOpen={setIsMyPhotoCardsBottomSheetOpen}
      />
    </TabPanel>
  )
}
