import { isNativeAndroid, sendToNative } from "./NativeUtil";

export default async function copyTextToClipboard(text) {
  try {
      // 클립보드에 텍스트 복사 시도
      await navigator.clipboard.writeText(text);
  } catch (error) {
      // NotAllowedError 처리
      // ios인지 확인
      if (window.webkit?.messageHandlers?.pocapanEvent && error.name === 'NotAllowedError') {
        sendToNative(`{"type": "writeTextToClipboard", "value": "${text}"}`)
      } else if (isNativeAndroid()) {
        window.Android && window.Android.writeTextToClipboard(text);
      } else {
          // 다른 오류에 대해서는 그대로 에러를 다시 발생시킴
          throw error;
      }
  }
}
