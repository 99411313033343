import { Text } from '@chakra-ui/react'
import React from 'react'

function Address() {
  return (
    <Text fontSize={"14px"} fontWeight={400} color={"white"}>
      사업자등록번호 : 525-49-00740 | 대표 : 강종엽 <br/>
      주소: 경기도 안양시 동안구 부림로 113, 2109호(관양동, 평촌아이파크)<br/>
      고객센터 : 070-7954-2342<br/>
      메일주소 : pocapan.offical@gmail.com
    </Text>
  )
}

export default Address
