import baseUrl from "../../../utils/baseUrl"
import getToken from "../../../utils/getToken"

export default async function toggleLikedPost(postId) {
  return fetch(baseUrl() + `/api/v1/posts/${postId}/like`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  });
}
