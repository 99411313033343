import React from "react";

import { Button, HStack, Image, Text, VStack } from '@chakra-ui/react';

import { t } from "i18next";
import { ReactComponent as RefreshFillIcon } from "../../icons/refresh.fill.svg";

const imageStyle = {
  marginTop: "54px",
  height: "106px"
}

const titleStyle = {
  fontWeight: 700,
  color: "rgba(68, 68, 68, 1)",
  fontSize: "17px",
  marginTop: "24px",
  paddingLeft: "24px",
  paddingRight: "24px",
  wordBreak: "keep-all",
  lineHeight: "22px"
}

const descriptionStyle = {
  fontWeight: 500,
  color: "rgba(136, 136, 136, 1)",
  fontSize: "14px",
  lineHeight: "17px",
  whiteSpace: "pre-wrap"
}

const buttonStyle = {
  borderRadius: "42px",
  textColor: "#444",
  fontSize: "14px",
  border: "1px solid var(--gray-separator, #E7E7E7)",
  padding: "0px 14px",
  marginTop: "40px"
}

export default function EmptyMatchedTradingPhotoCard({onClickReMatch}) {
  return (
    <VStack marginTop={"32px"} alignContent={"center"} marginBottom={"96px"}>
      <Image style={imageStyle} src="/assets/backgrounds/emptyMatched.png"/>
      <Text style={titleStyle} className="text-center">
        {t("emptyMatchedTradingPhotoCard.title")}
      </Text>
      <Text className="text-center" style={descriptionStyle}>
        {t("emptyMatchedTradingPhotoCard.description")}
      </Text>
      <Button
        style={buttonStyle}
        variant='unstyled'
        onClick={()=>onClickReMatch()}>
        <HStack>
          <RefreshFillIcon fill="black" width={"15px"} height={"15px"} />
          <Text>{t("tradingPhotoCardPage.reMatching")}</Text>
        </HStack>
      </Button>
    </VStack>
  )
}
