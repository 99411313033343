import { useInfiniteQuery, useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

const DEFAULT_PAGE = 30

export default function useTradingPhotoCards({artistId, memberIds, userProfileId, category, status, perPage}) {
  async function fetchTradingPhotoCardsQuery({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage || DEFAULT_PAGE,
      page: pageParam,
    });

    if (artistId) {
      queryParams.append('artist_id', artistId);
    }

    if (memberIds) {
      queryParams.append('member_ids', memberIds);
    }

    if (userProfileId) {
      queryParams.append('user_profile_id', userProfileId);
    }

    if (category) {
      queryParams.append('category', category);
    }

    if (status) {
      queryParams.append('status', status);
    }

    const url = `/api/v1/trading_photo_cards?${queryParams.toString()}`;
    try {
      const data = await queryGet(url);
      return data;
    } catch (error) {
      throw new Error("Error fetching paginated data.");
    }
  }

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["TradingPhotoCards", artistId, memberIds, userProfileId, category],
    fetchTradingPhotoCardsQuery,
    {
      getNextPageParam: (lastPage, _) => lastPage?.meta?.pagination?.nextPage,
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  };
}

export function useTradingPhotoCardsAll({category}) {
  const tradingPhotoCardsQueryParams = new URLSearchParams({
    page: 1,
    per_page: 10000
  });

  if (category) {
    tradingPhotoCardsQueryParams.append('category', category);
  }

  const { data: tradingPhotoCards } = useQuery(
    ["tradingPhotoCardsAll", category],
    () => queryGet(`/api/v1/trading_photo_cards?${tradingPhotoCardsQueryParams.toString()}`),
    {
      select: (r) => r.tradingPhotoCards,
    },
  );
  return tradingPhotoCards;
}

export function useMatchedPhotoCards({artistId, tradingPhotoCardId, memberId}) {
  const matchingQueryParams = new URLSearchParams();

  if (memberId) { matchingQueryParams.append('member_id', memberId); }
  if (tradingPhotoCardId) { matchingQueryParams.append('trading_photo_card_id', tradingPhotoCardId); }

  const { data: matchedTradingPhotoCards } = useQuery(
    ["matchedTradingPhotoCards", artistId, matchingQueryParams.toString()],
    () => queryGet(`/artists/${artistId}/matched_trading_photo_cards?` + matchingQueryParams),
    {
      enabled: !!artistId && !!tradingPhotoCardId,
      select: (r) => r.matchedTradingPhotoCards,
    },
  );
  return matchedTradingPhotoCards;
}
