class EventBus {
  constructor() {
    this.listeners = {};
  }

  // 이벤트 리스너 등록
  on(event, callback) {
    // console.log(`event: ${event} on`);
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  }

  // 이벤트 리스너 삭제
  off(event, callback) {
    // console.log(`event: ${event} off`);
    if (!this.listeners[event]) {
      return;
    }
    this.listeners[event] = this.listeners[event].filter(listener => listener !== callback);
  }

  // 이벤트 발생시키기
  emit(event, data) {
    // console.log(`event: ${event} emit`);
    if (!this.listeners[event]) {
      return;
    }
    this.listeners[event].forEach(listener => listener(data));
  }
}

// 이벤트 버스 인스턴스 생성
const eventBus = new EventBus();

export default eventBus;
