import { isEmpty } from 'lodash';
import { deviceType, isAndroid, isIOS, isMobile } from 'react-device-detect';

export const sendToNative = (message) => {
    // to ios
    if (window.webkit?.messageHandlers?.pocapanEvent) {
        window.webkit.messageHandlers.pocapanEvent.postMessage(message)
    }
};

export const isNativeIOS = () => {
    return window.webkit?.messageHandlers?.pocapanEvent ?? false;
}

export const isNativeAndroid = () => {
    return ((window.Android && window.Android.isPocapanAndroidApp()) || navigator.userAgent.includes("AndroidPocapan")) ?? false;
}

class NativeMeta {
    constructor() {
        let version = localStorage.getItem('appVersion');
        if(isEmpty(version) && isNativeAndroid()) {
            const versionRegex = /AndroidPocapan (\d+\.\d+\.\d+)/;
            const match = navigator.userAgent.match(versionRegex);
            if (match) {
                version = match[1];
            }
        }
        this.appVersion = version ?? "";
    }

    // #region version
    setAppVersion(version) {
        localStorage.setItem('appVersion', version);
        this.appVersion = version;
    }

    getShortAppVersion() {
        const regex = /^(?<v>\d+\.\d+\.\d+)/;
        const version = this.getAppVersion();
        const match = regex.exec(version);
        if (match) {
            const { v } = match.groups;
            return v;
        }
        return version;
    }

    getAppVersion() {
        return this.appVersion;
    }

    getAppVersionString() {
        if (isMobile) {
            let platform = isIOS ? "iOS" : (isAndroid ? "Android" : "etc")
            let app = isNativeIOS() ? "_app" : "";
            return `${deviceType}_${platform}${app}_${this.getAppVersion()}`;
        }
        return `desktop_${this.getAppVersion()}`;
    }
    // #endregion
}

const meta = new NativeMeta();

window.setAppVersion = meta.setAppVersion;

export function getNativeMeta() {
    return meta;
}

const minimumAppVersionIOS      = '1.0.4'
const minimumAppVersionAndroid  = '1.0.2'

export function requireAppUpdate() {
    const minimumVersion = isNativeIOS() ? minimumAppVersionIOS : minimumAppVersionAndroid;
    const currentVersion = meta.getShortAppVersion();

    if (!currentVersion) {
        return true;
    }

    const segments1 = minimumVersion.split('.').map(Number);
    const segments2 = meta.getShortAppVersion().split('.').map(Number);

    for (let i = 0; i < Math.max(segments1.length, segments2.length); i++) {
        const segment1 = segments1[i] || 0;
        const segment2 = segments2[i] || 0;

        if (segment1 > segment2) {
            return true;
        } else if (segment1 < segment2) {
            return false;
        }
    }
    // 버전이 같음
    return false;
}
