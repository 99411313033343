import * as React from 'react'
import { Center, ChakraProvider, Container, extendTheme } from '@chakra-ui/react'

import './App.css';
import Router from './router/Router';
import { createQueryClient } from './utils/queryClient'
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryHome from './components/errors/ErrorBoundaryHome';
import i18n from './i18n/i18n';
import { getLanguage } from "./utils/etcUtils";

const queryClient = createQueryClient();
const customTheme = extendTheme({
  fonts: {
    heading: `'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif`,
    body: `'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif`,
  },
  colors: {
    primary: {
      500: "#5C3DFA"
    },
    secondary: {
      100: "rgba(92, 61, 250, 0.01)",
      200: "rgba(92, 61, 250, 0.02)",
      300: "rgba(92, 61, 250, 0.03)",
      400: "rgba(92, 61, 250, 0.04)",
      500: "rgba(92, 61, 250, 0.05)",
      600: "rgba(92, 61, 250, 0.06)",
      700: "rgba(92, 61, 250, 0.07)",
      800: "rgba(92, 61, 250, 0.08)",
      900: "rgba(92, 61, 250, 0.09)",
      1000: "rgba(92, 61, 250, 1)",
    },
    gray: {
      500: "#666666"
    },
  }
})

function App() {
  React.useEffect(() => {
    const savedLanguage = getLanguage();
    if (savedLanguage) {
      if(savedLanguage !== localStorage.getItem("i18nextLng")) {
        console.log(`Set locale with savedLanguage : ${savedLanguage}`)
        i18n.changeLanguage(savedLanguage);
      }
    }
  }, []);
  return (
    <QueryClientProvider client={ queryClient }>
      <ErrorBoundary fallback={<ErrorBoundaryHome />}>
        <ChakraProvider theme={customTheme}>
          <Center
            padding={0}
            backgroundImage={{xl: "url(/assets/backgrounds/bg_desktop.png) !important"}}
            backgroundSize={{xl: "cover"}}
            backgroundRepeat={{xl: "no-repeat"}}
          >
            <Container width={"100%"} padding={0}>
              <Router/>
              <ReactQueryDevtools initialOpen={ false } />
            </Container>
          </Center>
        </ChakraProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
