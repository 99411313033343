import React, { useState } from "react";

import { Avatar, HStack, Image } from '@chakra-ui/react';
import { t } from "i18next";
import { isNull } from "lodash";
import styled from "styled-components";

import colors from "../../constants/colors";
import { ellipsis } from "../../utils/TextUtil";
import ExploreTradingPhotoCardBottomDrawer from "../drawer/ExploreTradingPhotoCardBottomDrawer";
import VerifiedIcon from "../icons/VerifiedIcon";

const Wrapper = styled.div`
  padding: 12px 0px;
  border-bottom: solid rgba(231, 231, 231, 0.5)
`

const profileImageStyle = {
  width: "24px",
  height: "24px",
  borderRadius: "34px",
  background: "lightgray 50% / cover no-repeat, rgba(122, 89, 255, 0.26)"
}

const NickNameText = styled.div`
  padding-left: 2px;
  font-weight: 500;
  color: rgba(34, 34, 34, 1);
  line-height: 16px;
  font-size: 13px;
  text-align: left;
  padding-left: 8px;
`

const RequestedAtText = styled.div`
  color: #999;
  font-size: 11px;
  line-height: 13.2px;
  font-weight: 400;
  white-space: pre;
  padding-left: 4px;
`

const DotText = styled.div`
  color: #999;
  font-size: 11px;
  font-weight: 400;
  padding-left: 4px;
`

const PriceText = styled.div`
  color: ${colors.eventText};
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  white-space: pre;
  align-self: center;
`


export default function SalesTradingPhotoCardProfileRow({tradingPhotoCard, showbottomBorder=true}) {
  const [isShowExploreTradingPhotoCardBottomSheet, setIsShowExploreTradingPhotoCardBottomSheet] = useState(false);
  return (
    <>
      {
        tradingPhotoCard &&
        <Wrapper style={{ borderBottomWidth: showbottomBorder ? "1px" : "0px"}} onClick={() => setIsShowExploreTradingPhotoCardBottomSheet(!isShowExploreTradingPhotoCardBottomSheet)}>
          <div className="flex">
            <div className="flex-grow">
              <HStack spacing={0}>
                <div className="flex-none">
                  {
                    tradingPhotoCard.userProfile?.pictureUrl ?
                    <Image
                      style={profileImageStyle}
                      src={tradingPhotoCard.userProfile.pictureUrl}
                      objectFit={"cover"}
                      loading={"lazy"}
                    /> :
                    <Avatar style={profileImageStyle}/>
                  }
                </div>
                <NickNameText>{ellipsis(tradingPhotoCard.userProfile.nickname, 14)}</NickNameText>
                <div style={{paddingLeft: "2px"}}>
                {
                  tradingPhotoCard.userProfile?.identified && <VerifiedIcon width={"14px"} height={"14px"} />
                }
                </div>
                <DotText>{`•`}</DotText>
                <RequestedAtText>{tradingPhotoCard.requestedAt}</RequestedAtText>
              </HStack>
            </div>
            <PriceText>{isNull(tradingPhotoCard.price) ? t("tradingPhotoCardPage.offerPriceWithEmoji") : tradingPhotoCard.price}</PriceText>
          </div>
        </Wrapper>
      }
      {/* <ExploreTradingPhotoCardBottomSheet
        artistId={tradingPhotoCard.artistId}
        tradingPhotoCard={tradingPhotoCard}
        isShowBottomSheet={isShowExploreTradingPhotoCardBottomSheet}
        setIsShowBottomSheet={setIsShowExploreTradingPhotoCardBottomSheet}
      />
       */}
       <ExploreTradingPhotoCardBottomDrawer
          isOpen={isShowExploreTradingPhotoCardBottomSheet}
          onClose={() => setIsShowExploreTradingPhotoCardBottomSheet(false)}
          artistId={tradingPhotoCard.artistId}
          tradingPhotoCard={tradingPhotoCard}
       />
    </>
  )
}
