import { Box, Flex, HStack, Text } from "@chakra-ui/react"
import { t } from "i18next"
import { useState } from "react"
import { useQuery } from "react-query"
import styled from "styled-components"

import { isEmpty } from "lodash"
import StickyHeader from "../../components/StickyHeader"
import LightColors from "../../constants/LightColors"
import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg"
import { queryGet } from "../../utils/queryClient"
import { useQueryParams } from "../../utils/useQueryParams"
import { useNavigate } from "react-router-dom"

const MembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 8px;
`

const Title = styled.div`
  padding: 10px 0px;
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  color: ${LightColors.textmainlv1};
  white-space: pre-wrap;
`

export const ReportPhotoCardSelectMemberPage = () => {
  const navigate = useNavigate();
  const [selectedMember, setSelectedMember] = useState();
  const { queryParams, updateQueryParams } = useQueryParams();
  const artistId = queryParams.get("artistId");

  const { data: members } = useQuery(
    ["Members", artistId],
    () => queryGet(`/artists/${artistId}/members`),{
      select: (data) => data.members,
    }
  );

  const onSelectedMember = (member) => {
    const memberId = member.memberId;
    setSelectedMember(member);
    updateQueryParams({ memberId }, "/report-photo-card/event-activities");
  }

  const calculatePaddinTop = (members) => {
    if(isEmpty(members)) {
      return "calc(0px + env(safe-area-inset-top))";
    } else if(members.length <= 5) {
      return "calc(40% + env(safe-area-inset-top))"
    } else if(members.length <= 8){
      return "calc(25% + env(safe-area-inset-top))"
    } else {
      return "calc(25% + env(safe-area-inset-top))"
    }
  }

  return(
    <>
      <StickyHeader
        showBackButton={true}
        showTitle={true}
        title={t("reportPhotoCardPage.pageName")}
        onClickBackButton={() => navigate(-1)}
      />
      <Flex
        flexDirection={"column"}
        paddingX={"24px"}
        paddingTop={calculatePaddinTop(members)}
        paddingBottom={"calc(48px + env(safe-area-inset-bottom))"}
      >
        <Title>
          {t("ReportPhotoCardSelectMemberPage.title")}
        </Title>
        <MembersWrapper>
        {
          members?.map((member) => {
            const isSelectedMember = selectedMember && selectedMember.memberId === member.memberId;
            return(
              <Box key={member.memberId}>
                <HStack onClick={()=> onSelectedMember(member)}>
                  <Text fontSize={"40px"} lineHeight={"48px"} fontWeight={800} color={isSelectedMember ? LightColors.primary : "#E7E7E7"}>
                    {member.name}
                  </Text>
                  <LikeShapeIcon
                    fill={isSelectedMember ? LightColors.primary : LightColors.purewhite}
                    width={42}
                    height={42}
                    style={{marginLeft: "3px"}}
                  />
                </HStack>
              </Box>
            )
          })
        }
        </MembersWrapper>
      </Flex>
    </>
  )
}
