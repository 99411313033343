import { Box } from "@chakra-ui/react";

export default function DisabledBadge({text, ...props}) {
  return (
    <Box
      bgColor={"rgba(0, 0, 0, 0.04)"}
      textColor={"#888"}
      paddingX={"10px"}
      paddingY={"6px"}
      className="text-center"
      borderRadius={"4px"}
      fontWeight={500}
      fontSize={"12px"}
      lineHeight={"14px"}
      {...props}
    >
      {text}
    </Box>
  )
}
