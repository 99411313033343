import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useRefreshJwtQuery() {
  const { data: authToken } = useQuery(
    ["refreshJwt"],
    () => queryGet(`/api/v1/auth_token/refresh_jwt`),{
      select: (data) => data.user,
      cacheTime: 60 * 1000 * 60 * 24,
      staleTime: 60 * 1000 * 60 * 24,
    }
  );
  return authToken;
}
