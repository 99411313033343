import { Text } from '@chakra-ui/react'
import React from 'react'

function Copyright() {
  return (
    <Text fontSize={"14px"} fontWeight={400} color={"#666666"}>
      Copyright©(주)피스커버. All rights reserved.
    </Text>
  )
}

export default Copyright
