import styled from "styled-components"
import useArtistQuery from "../api/queries/useArtistQuery"
import LightColors from "../../constants/LightColors"
import { useQueryParams } from "../../utils/useQueryParams"

const Text = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${LightColors.textprimary};

`


export const ReportPhotoCardBreadcrumb = () => {
  const { queryParams } = useQueryParams();

  const artist = useArtistQuery(queryParams.get("artistId"));
  const member = artist?.members?.find((member) => member.memberId === queryParams.get("memberId"))

  if (artist && member) {
    return(
      <Text>
        {`${artist.name} ${member.name}`}
      </Text>
    )
  }
}
