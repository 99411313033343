import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";
import { isEmpty } from "lodash";

export default function useArtistEventsQuery({artistId, memberId, category, photoCardCategories}) {
  const queryParams = new URLSearchParams();

  if(memberId) {
    queryParams.append("member_id", memberId)
  }

  if(category) {
    queryParams.append("category", category)
  }

  if(!isEmpty(photoCardCategories)) {
    queryParams.append("photo_card_categories", photoCardCategories)
  }

  const { data: events } = useQuery(
    ["events", artistId, memberId, category, photoCardCategories],
    () => queryGet(`/artists/${artistId}/events?${queryParams.toString()}`),
    {
      enabled: !!artistId,
      select: (data) => data.events,
    },
  );
  return events
}
