import { t } from "i18next"
import { reviewText } from "../../utils/TradingHelper"
import SecondaryButton from "../buttons/SecondaryButton"
import WarningButton from "../buttons/WarningButton"

export const PhotoCardBoxButton = ({
  tradingPhotoCard,
  tradingStatus,
  onClickCompleted,
  onClickToggleCategory,
  onClickNavigateReview,
}) => {
  return (
    (tradingPhotoCard.chatRoomsCount <= 0 && tradingStatus !== "ongoing") ? <></> :
    <div className="flex" style={{paddingTop: "14px", paddingRight: "6px"}}>
      {
        tradingStatus === "ongoing" ?
        <>
          <div
            className="flex-1"
            style={{paddingRight: "8px"}}
          >
            <WarningButton
              text={t("myPhotoCards.chatRoomPageCompletedButton")}
              paddingY={"11px"}
              onClick={() => onClickCompleted(tradingPhotoCard)}
            />
          </div>
          <div
            className="flex-1"
            onClick={() => onClickToggleCategory(tradingPhotoCard)}
          >
            <SecondaryButton
              text={tradingPhotoCard.category === "exchange" ? t("myPhotoCards.chatRoomPageConvertToSaleButton") : t("myPhotoCards.chatRoomPageConvertToExchangeButton")}
              paddingY={"11px"}
            />
          </div>
        </> :
        <div
          className="flex-1"
          onClick={() => onClickNavigateReview(tradingPhotoCard)}
        >
          <SecondaryButton
            text={reviewText(tradingPhotoCard)}
            paddingY={"11px"}
          />
        </div>
      }
    </div>
  )
}
