import React from "react";

import { Text, VStack } from '@chakra-ui/react';

import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import FilledButton from "../FilledButton";

const emojiStyle = {
  fontSize: "50px",
}

const titleStyle = {
  fontWeight: 700,
  color: "rgba(68, 68, 68, 1)",
  fontSize: "17px",
  lineHeight: "20px"
}

const descriptionStyle = {
  fontWeight: 500,
  color: "rgba(136, 136, 136, 1)",
  fontSize: "14px",
  lineHeight: "17px"
}

export default function EmptyTradingPhotoCard({nickName}) {
  const navigate = useNavigate();

  const navigateToAddTradingPhotoCard = () => {
    navigate(`/artists/add_trading_photo_cards`);
  }
  return (
    <VStack marginTop={"128px"} alignContent={"center"}>
      <Text style={emojiStyle}>🙄</Text>
      {
        nickName && <Text style={titleStyle}>
          {t("emptyTradingPhotoCard.title", {nickname: nickName})}
        </Text>
      }
      <Text style={descriptionStyle}>
        {t("emptyTradingPhotoCard.description")}
      </Text>
      <div style={{paddingTop: "40px"}}>
        <FilledButton
          paddingX={"18px"}
          paddingY={"11px"}
          filled={false}
          color={"#444444"}
          fontWeight={600}
          fontSize={"14px"}
          text={t("emptyTradingPhotoCard.addTradingPhotoCard")}
          backgroundColor={"#FFF"}
          borderWidth={"1px"}
          borderColor={"#E7E7E7"}
          onClicked={() => navigateToAddTradingPhotoCard()}
        />
      </div>
    </VStack>
  )
}
