import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import React from "react";
import { useNavigate } from "react-router-dom";
import { getArtistId } from '../../utils/etcUtils';
import { t } from 'i18next';

const titleStyle = {
  fontSize: "14px",
  fontWeight: 500,
}

const buttonStyle = {
  fontSize: "13px",
  fontWeight: 500,
  padding: "6px 12px",
  lineHeight: "16px",
  color: "#FFF",
  borderRadius: "32px",
  backgroundColor: "#444"
}

const boxStyle = {
  borderRadius: "12px",
  height: "44px",
  backgroundColor: "rgba(0, 0, 0, 0.04)",
  padding: "12px 20px",
  marginBottom: "18px"
}

export default function BlacklistManagerBar() {
  const artistId = getArtistId();
  const navigate = useNavigate();

  return (
    <Flex align={"center"} style={boxStyle}>
      <Text style={titleStyle}>
        {t("blacklistCards.exclude")}
      </Text>
      <Spacer />
      <Box style={buttonStyle} onClick={() => navigate(`/artists/${artistId}/blacklist_trading_photo_cards`)}>
        {t("blacklistCards.manage")}
      </Box>
    </Flex>
  )
}

