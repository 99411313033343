import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useReviewStatsQuery({
  userProfileId,
  reviewableType="TradingPhotoCard",
}) {
  const queryParams = new URLSearchParams({
    reviewable_type: reviewableType,
  });
  if (userProfileId) {
    queryParams.append("user_profile_id", userProfileId)
  }

  const url = `/api/v1/reviews/stats?${queryParams.toString()}`;
  const { data: reviewStats } = useQuery(
    ["reviewStats", queryParams.toString()],
    () => queryGet(url),{
      select: (data) => data.reviewStats,
      enabled: !!queryParams.toString(),
    }
  );
  return reviewStats;
}
