import React, { useState } from "react";

import styled from "styled-components";
import AddUserProfileV1BottomSheet from "../bottomSheet/AddUserProfileV1BottomSheet";
import UserProfileBox from "./UserProfileBox";

const TradingPhotoCardsWrapper = styled.div`
  padding-right: 24px;
  padding-left: 16px;
  margin-left: -24px;
  margin-right: -24px;
  padding-bottom: 24px;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export default function MergeUserProfilesList({userProfiles}) {
  const [isShowProfileBottomSheet, setIsShowProfileBottomSheet] = useState(false);
  return (userProfiles &&
    <>
      <TradingPhotoCardsWrapper>
        {
          userProfiles.map((userProfile) => {
            return (
              <UserProfileBox
                key={userProfile.userProfileId}
                userProfile={userProfile}
              />
            )
          })
        }
        <UserProfileBox key={"newProfileId"} onClick={() => setIsShowProfileBottomSheet(true)}/>
      </TradingPhotoCardsWrapper>
      <AddUserProfileV1BottomSheet
        isShowProfileBottomSheet={isShowProfileBottomSheet}
        setIsShowProfileBottomSheet={setIsShowProfileBottomSheet}
        isMergeProfile={true}
        afterGoBack={true}
      />
    </>
  )
}
