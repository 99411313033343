import { isEmpty } from "lodash";

export function setLocalStorageWithExpiry(key, value, ttl) {
  const obj = {
    value : value,
    expire : Date.now() + ttl
  }
  const objString = JSON.stringify(obj);
  window.localStorage.setItem(key, objString);
}

export function getLocalStorageWithExpiry(key) {
	const objString = window.localStorage.getItem(key)

	if (isEmpty(objString)) {
		return null
	}

	const obj = JSON.parse(objString)
	if(Date.now() > obj.expire) {
		window.localStorage.removeItem(key);
		return null
	}

	return obj.value
}
