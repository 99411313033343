import { Box } from "@chakra-ui/react";
import LightColors from "../../constants/LightColors";

export default function SecondaryBadge({text, ...props}) {
  return (
    <Box
      bgColor={LightColors.secondary}
      textColor={LightColors.primary}
      paddingX={"10px"}
      paddingY={"6px"}
      className="text-center"
      borderRadius={"30px"}
      fontWeight={500}
      fontSize={"12px"}
      lineHeight={"14px"}
      {...props}
    >
      {text}
    </Box>
  )
}
