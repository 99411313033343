import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function addTradingPhotoCardReview({
  tradingPhotoCardId,
  reviewerId,
  traderUserProfileId,
  rating,
  blacklisted,
  feedbackOptions,
  content,
  dataUrl
}) {
  return fetch(baseUrl() + `/api/v1/reviews/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      review: {
        trader_user_profile_id: traderUserProfileId,
        reviewable_type: "TradingPhotoCard",
        reviewable_id: tradingPhotoCardId,
        reviewer_type: "UserProfile",
        reviewer_id: reviewerId,
        rating: rating,
        blacklisted: blacklisted,
        feedback_options: feedbackOptions,
        content: content,
        data_url: dataUrl,
      }
    }),
  });
}
