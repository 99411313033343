import React from "react";
import { Image, Text, WrapItem } from '@chakra-ui/react';
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { useState } from "react";
import { t } from "i18next";
import LightColors from "../../constants/LightColors";

const collectedBoxStyle = { backgroundColor: "rgba(0,0,0,0.3)", padding: "2.5px 4px", borderRadius: "3px", color: LightColors.purewhite, marginLeft: "4px", marginBottom: "4px", height: "17px" }
const addButtonStyle = { backgroundColor: LightColors.primary, borderRadius: "6px", color: LightColors.purewhite, padding: "3px", marginBottom: "4px", marginRight: "4px" }

export default function AddTradingPhotoCard({photoCard, onClickPhotoCard, ...props}) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div key={photoCard.photoCardId} onClick={() => onClickPhotoCard(photoCard, photoCard.event)} {...props}>
    {
      (
        <WrapItem className="relative">
          <Image
            src={photoCard.pictureUrl}
            width={"100%"}
            aspectRatio={"55/87"}
            borderRadius="8px"
            loading={"lazy"}
            objectFit={"cover"}
            onLoad={() => setIsLoaded(true)}
          />
          {
            (isLoaded && photoCard.collectedCount > 0) && <div className="absolute bottom-0 left-0" style={collectedBoxStyle}>
              <Text color={LightColors.purewhite} lineHeight={"12px"} fontSize={"10px"} fontWeight={600}>{t("addTradingPhotoCard.collectedBadge")}</Text>
            </div>
          }
          {
            isLoaded && <div className="absolute bottom-0 right-0" style={addButtonStyle}>
              <PlusIcon width={"16px"} height={"16px"} fill={LightColors.purewhite}/>
            </div>
          }
        </WrapItem>
      )
    }
    </div>
  )
}
