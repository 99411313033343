import React, { useEffect, useState } from "react";

import { Divider, HStack, Text, VStack } from '@chakra-ui/react';
import Sheet from 'react-modal-sheet';

import { t } from "i18next";
import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";
import PrimaryButton from "../buttons/PrimaryButton";
import PriamryCheckBox from "../checkbox/PriamryCheckBox";

export default function BaseAgreementBottomSheet({isOpen, onAgreementClick, title, description, buttonText=t("introPage.startButton"), transparentBackground=false}) {
  const [isAgreeAll, setIsAgreeAll] = useState(false);
  const [isNotChild, setIsNotChild] = useState(false);
  const [termAndPrivacy, setTermAndPrivacy] = useState(false);
  const [marketingAgreement, setMarketingAgreement] = useState(false);

  useEffect(() => {
    // Check if all child checkboxes are checked
    const allChecked = isNotChild && termAndPrivacy && marketingAgreement;

    // Set the state of the parent checkbox accordingly
    setIsAgreeAll(allChecked);
  }, [isNotChild, termAndPrivacy, marketingAgreement]);

  const isRequiredAllChecked = () => {
    return termAndPrivacy && isNotChild
  }

  const onClickButton = () => {
    if(!isRequiredAllChecked()) {
      return;
    }
    onAgreementClick(!isNotChild, marketingAgreement);
  }

  const handleAgreeAllToggle = (checked) => {
    setIsAgreeAll(checked);
    setIsNotChild(checked);
    setTermAndPrivacy(checked);
    setMarketingAgreement(checked);
  };

  return (
    <div style={{height: "120px"}}>
    <Sheet
      isOpen={isOpen}
      disableDrag={true}
      springConfig={SPRING_CONFIG}
      onClose={() => {}}
      detent={"content-height"}
    >
      <Sheet.Container style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px"
      }}>
        <Sheet.Header className="py-4">
          {title && <Text fontWeight={600} fontSize={"20px"} marginTop={"10px"} paddingRight={"36px"} wordBreak={"auto-phrase"}>{t("agreements.baseAgreementBottomSheetTitle")}</Text>}
          {description && <Text fontWeight={400} fontSize={"14px"} marginTop={"7px"} marginBottom={"20px"} paddingRight={"36px"} wordBreak={"auto-phrase"}>{description}</Text>}
          <HStack>
            <PriamryCheckBox
              size={"lg"}
              isChecked={isAgreeAll}
              onChanged={(checked) => handleAgreeAllToggle(checked)}
            />
            <Text fontSize={"17px"} fontWeight={600} color={"rgba(34, 34, 34, 1)"}>
              {t("agreements.baseAgreementBottomAgreeAllButton")}
            </Text>
          </HStack>
          <Divider className="py-1"/>
        </Sheet.Header>
        <Sheet.Content className="py-1" disableDrag={true}>
          <VStack align={"flex-start"}>
            <HStack>
              <PriamryCheckBox isChecked={isNotChild} onChanged={(checked) => setIsNotChild(checked)}/>
              <Text fontSize={"15px"} fontWeight={400} color={"rgba(34, 34, 34, 1)"}>
                {t("agreements.baseAgreementAbove14")}
              </Text>
            </HStack>
            <HStack>
              <PriamryCheckBox isChecked={termAndPrivacy} onChanged={(checked) => setTermAndPrivacy(checked)}/>
              <HStack spacing={0.5}>
                <Text fontSize={"15px"} fontWeight={400} color={"rgba(34, 34, 34, 1)"}>
                  {t("agreements.baseAgreementRequiredCheckBox")}
                </Text>
                <a href="/pocapan_terms.html">
                  <Text fontSize={"15px"} fontWeight={400} color={"black"} as="u">
                    {t("agreements.baseAgreementRequiredAgreement")}
                  </Text>
                </a>
              </HStack>
            </HStack>
            <HStack>
              <PriamryCheckBox isChecked={marketingAgreement} onChanged={(checked) => setMarketingAgreement(checked)}/>
              <HStack spacing={0.5}>
                <Text fontSize={"15px"} fontWeight={400} color={"rgba(34, 34, 34, 1)"}>
                  {t("agreements.baseAgreementOptionalCheckBox")}
                </Text>
                <a href="/pocapan_marketing.html">
                  <Text fontSize={"15px"} fontWeight={400} color={"black"} as="u">
                    {t("agreements.baseAgreementPromotionalAgreement")}
                  </Text>
                </a>
              </HStack>
            </HStack>
            <div style={{marginTop: "25px", width: "100%"}}>
              <PrimaryButton
                text={buttonText}
                onClick={() => onClickButton()}
                width={"100%"}
                minHeight={"52px"}
                paddingX={"24px"}
                paddingY={"16px"}
                fontSize={"17px"}
                disabled={!isRequiredAllChecked()}
              />
            </div>
          </VStack>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop style={transparentBackground ? {backgroundColor: "transparent"}: {}} onClick={() => {}}/>
    </Sheet>
    </div>
  )
}
