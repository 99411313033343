import React, { useEffect, useMemo, useState } from "react";

import { HStack, Skeleton, Wrap } from '@chakra-ui/react';
import { isEmpty } from "lodash";

import FilledButton from "../FilledButton";

export default function MultiSelectLikedMembersList({members, onChangeLikedMembers, showAllMember}) {
  const [selectedMembers, setSelectedMembers] = useState([]);

  const isSelectedMembers = (member) => {
    return selectedMembers.includes(member)
  }

  const pushMembers = (member) => {
    if (isSelectedMembers(member)) {
        return selectedMembers.filter((m) => m !== member);
    } else {
        return [...selectedMembers, member];
    }
  }

  const onSelectedMember = (member) => {
    const updatedMembers = pushMembers(member)
    setSelectedMembers(updatedMembers);
    onChangeLikedMembers(updatedMembers);
  }

  useEffect(() => {
    setSelectedMembers([]);
    onChangeLikedMembers([]);
  }, [members])

  const membersList = useMemo(() => {
    return showAllMember ? members : members.filter((m) => m.isLiked);
  }, [showAllMember, members])

  return (
    <>
      {
        !isEmpty(members) ?
        <Wrap spacing={"8px"} paddingY={"8px"}>
          {membersList?.map((member) =>{
            return (
              <FilledButton
                key={member.memberId}
                minWidth={"37px"}
                minHeight={"33px"}
                paddingX={"12px"}
                paddingY={"8px"}
                borderRadius={"32px"}
                fontSize={"14px"}
                text={showAllMember && member.isLiked ? member.name + " ♥" : member.name}
                fontWeight={500}
                onClicked={() => onSelectedMember(member)}
                filled={isSelectedMembers(member)}
              />
            )
          })}
        </Wrap> :
        <HStack paddingY={"8px"}>
          <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='rgba(92,62,250)' endColor='primary.500' />
          <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='secondary.500' endColor='secondary.700' />
          <Skeleton height='33px' width="47px" borderRadius={"32px"} startColor='secondary.500' endColor='secondary.700' />
        </HStack>
      }
    </>
  )
}
