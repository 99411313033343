import baseUrl from "../../../../utils/baseUrl";
import getToken from "../../../../utils/getToken";

export default async function deleteTradingPhotoCards(tradingPhotoCardId) {
  return fetch(baseUrl() + `/trading_photo_cards/${tradingPhotoCardId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then((data) => data.json())
}
