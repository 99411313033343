import { Container, Flex } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { forwardRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQueryClient } from "react-query";
import { ReactComponent as ArrowLeftIcon } from "../icons/arrow.left.svg";
import RouteChangeTracker from "../utils/RouteChangeTracker";
import baseUrl from "../utils/baseUrl";
import getToken from "../utils/getToken";
import BaseAgreementBottomSheet from "./bottomSheet/BaseAgreementBottomSheet";
import { refreshScreenSize } from "../utils/UIUtils";
import { useNavigate, useLocation } from "react-router-dom";
import { getArtistId } from "../utils/etcUtils";
import { t } from "i18next";

const EXCLUDED_PAGE_NAMES =["/", "/request_verification_code", "/login_with_code", "/unauthorized_access"]

const headerBarStyle = {
  background: 'white',
  padding: "10px 16px",
  paddingTop: "calc(10px + env(safe-area-inset-top))",
  paddingLeft: "calc(16px + env(safe-area-inset-left))",
  paddingRight: "calc(16px + env(safe-area-inset-right))",
  width: "100%",
  maxWidth: "calc(var(--chakra-sizes-xl) - 36px)",
  position: "fixed",
  top: 0,
  zIndex: 999
};

const titleStyle = {
  color: 'black',
  fontWeight: 700,
  fontSize: "17px",
  lineHeight: "20px",
  flexGrow: 1,
  textAlign: "center",
};


const navigationStyle = {
  color: '#666',
  fontWeight: 400,
  fontSize: "15px",
};

async function updateUser(userId, isChild, isNewTermsAgreed, isPromotionAgreed) {
  return fetch(baseUrl() + `/users/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      user: {
        is_child: isChild,
        is_new_terms_agreed: isNewTermsAgreed,
        is_promotion_agreed: isPromotionAgreed,
      },
    }),
  }).then((data) => data.json())
}

const Page = forwardRef(({
  children,
  title,
  titleElement,
  leftElement,
  navigationElement,
  themeColor="#FFF",
  onHistoryBackClick,
  onNavigationClick,
  showBackButton=true,
  showTitle=false,
  titleTransparent=false,
  stopFetching=false,
  ignoreTokenCheck=false,
  noPadding=false,
  noGutter=false,
  backgroundColor={xl: "rgba(255, 255, 255, 1)"},
  userProfile,
  ...other
}, ref) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();
  const transparentStyle = titleTransparent ? { background: "transparent", position: "initial", zIndex: 0, marginBottom: "-56px"} : {}

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [forceCloseAgreementBottomSheet, setForceCloseAgreementBottomSheet] = useState(false);

  RouteChangeTracker()

  if(!ignoreTokenCheck && !getToken() && (EXCLUDED_PAGE_NAMES.findIndex((pageName) => pageName === window.location.pathname) === -1)){
    window.location.href ="/unauthorized_access"
  }

  refreshScreenSize();

  const handleAgreement = (isChild, promotionAgreed) => {
    if(isChild) {
      // setIsOpenAlert(true);
    } else {
      requestUpdateUser(isChild, promotionAgreed);
    }
  }

  const requestUpdateUser = (isChild, promotionAgreed) => {
    updateUser(userProfile.uuid, isChild, true, promotionAgreed).then((res) => {
      queryClient.invalidateQueries("userProfiles");
      queryClient.invalidateQueries("userProfile");
      setIsOpenAlert(false);
      setTimeout(() => { setForceCloseAgreementBottomSheet(true); }, 300);
    })
  }

  const onBackClick = () => {
    if (location.key !== 'default') {
      navigate(-1)
    } else {
      const aritst = getArtistId();
      if(aritst) {
        navigate(`/feed`);
      } else {
        navigate(`/artists?artistId=register_artist`);
      }
    }
  }

  if(!!userProfile?.isChild || !!userProfile?.blocked) {
    return (<></>)
  }

  return(
    <>
      <Container ref={ref} {...other}
        padding={0}
        h={"calc(var(--vh, 1vh) * 100)"}
        minW={{xl: "xl"}}
        borderColor={{xl: "#FFF"}}
        boxShadow={{xl: "0px 0px 15px 2px rgba(255,255,255,0.8)"}}
        backgroundColor={backgroundColor}
        overflowY={{xl: "scroll"}}
        className="hiddenScrollBar"
      >
        <Helmet>
          <title>{title}</title>
          <meta name="theme-color" content={themeColor} />
        </Helmet>
        {
          showBackButton &&
          <Flex width={"100%"} style={{...headerBarStyle, ...transparentStyle}} alignItems="center" >
            <div style={{zIndex: 2147483647}} onClick={() => onHistoryBackClick ? onHistoryBackClick() : onBackClick()}>
              {
                leftElement ?
                leftElement :
                <ArrowLeftIcon fill={"#000"}/>
              }
            </div>
            {
              // TODO: Align to center in full-width
              titleElement ?
              titleElement :
              <div style={navigationElement ? titleStyle : { marginLeft: "-24px",...titleStyle }}>
                {showTitle ? title : ""}
              </div>
            }
            {
              navigationElement ?
              <div className="flex justify-end" style={navigationStyle} onClick={onNavigationClick}>
                {navigationElement}
              </div> : <div></div>
            }
          </Flex>
        }
        {
          showBackButton ?
          <div style={{
            padding: `${noPadding ? "0px" : (noGutter ? "0px 24px" : "24px")}`,
            marginTop: "calc(44px + env(safe-area-inset-top))"
          }}>
            {children}
          </div> :
          <div style={{
            padding: `${noPadding ? "0px" : "0px 24px"}`,
            marginTop: "env(safe-area-inset-top)"
          }}>
            {children}
          </div>
        }
      </Container>
      {
        userProfile && <BaseAgreementBottomSheet
          title={t("agreements.baseAgreementBottomSheetTitle")}
          description={t("agreements.baseAgreementBottomSheetDescription")}
          isOpen={!userProfile?.isNewTermsAgreed && !forceCloseAgreementBottomSheet}
          onAgreementClick={(isChild, promotionAgreement) => handleAgreement(isChild, promotionAgreement)}
          buttonText={t("agreements.baseAgreementBottomSheetButton")}
        />
      }
    </>
)});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  navigationElement: PropTypes.element,
  themeColor: PropTypes.string,
  onHistoryBackClick: PropTypes.func,
  onNavigationClick: PropTypes.func,
  showBackButton: PropTypes.bool,
  showTitle: PropTypes.bool,
  titleTransparent: PropTypes.bool,
  noGutter: PropTypes.bool,
  userProfile: PropTypes.object
};

export default Page;
