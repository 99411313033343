import React, { useState } from "react";

import { Badge, Box, Image, Text, VStack } from '@chakra-ui/react';
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as LikeLineIcon } from "../../icons/like.line.svg";
import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg";
import baseUrl from "../../utils/baseUrl";
import getToken from "../../utils/getToken";
import { getArtistId } from "../../utils/etcUtils";

const itemBoxStyle = {
  padding: "10px",
  width: "100%",
  borderRadius: "12px"
}

const imageBoxStyle = {
  paddingLeft: "6px",
  marginTop: "6px",
  marginBottom: "6px",
  marginRight: "14px"
}

const imageStyle = {
  width: "48px",
  height: "75px",
  margin: "0px!important",
  borderRadius: "8px"
}

const eventNameStyle = {
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: 600,
  color: "#222",
  noOfLines: 1,
}

const pocaNameStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: 500,
  color: "#666",
  noOfLines: 1,
}

const badgeStyle = {
  marginTop: "12px!important",
  padding: "4px 8px",
  borderRadius: "4px",
  fontSize: "13px",
  fontWeight: 600,
  lineHeight: "16px"
}

const wishedIconStyle = {
  width: "28px",
  height: "28px",
  backgroundColor: "rgba(92, 61, 250, 0.06)",
  borderRadius: "32px",
  padding: "6px"
}

async function toggleWishedPhotoCard(artistId, memberId, photoCardId, wished) {
  const url = wished ? "/photo_cards/unwished" : "/photo_cards/wished";
  return fetch(baseUrl() + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      artistId: artistId,
      memberId: memberId,
      photoCardIds: [photoCardId],
    }),
  }).then((data) => data.json())
}

export default function WishedPhotoCard({photoCard, onToggled}) {
  const artistId = getArtistId();
  const navigate = useNavigate();
  const [wished, setWished] = useState(true);

  const onClickedWishedPhotoCard = (photoCard) => {
    toggleWishedPhotoCard(artistId, photoCard.member.memberId, photoCard.photoCardId, wished);
    onToggled(photoCard, wished);
    setWished(!wished);
  }

  const navigatePhotoCardDetailPage = (photoCardId) => {
    navigate(`/photo_cards/${photoCardId}`);
  }

  return (photoCard &&
    <Box key={photoCard.photoCardId} style={itemBoxStyle} borderWidth={1}>
      <div className="flex">
        <div className="flex-none" style={imageBoxStyle}>
          <Image
            style={imageStyle}
            src={photoCard.pictureUrl}
            loading={"lazy"}
            onClick={() => navigatePhotoCardDetailPage(photoCard.photoCardId)}
          />
        </div>
        <div className="flex-grow">
          <VStack alignItems={"start"} spacing={2} paddingTop={"6px"}>
            <Text style={eventNameStyle}>{photoCard.event?.name}</Text>
            <Text style={pocaNameStyle} className="overflow-hidden">{photoCard.event?.description}</Text>
            <Badge style={badgeStyle}>
              {photoCard.member.name}
            </Badge>
          </VStack>
        </div>
        <div className="flex-none">
          <div className="flex justify-end" onClick={() => onClickedWishedPhotoCard(photoCard)}>
            {
              wished ? <LikeShapeIcon fill="#5C3DFA" style={wishedIconStyle}/>
              : <LikeLineIcon fill="#5C3DFA" style={wishedIconStyle}/>
            }
          </div>
        </div>
      </div>
    </Box>
  )
}

