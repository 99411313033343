import { SimpleGrid, Skeleton, Stack } from '@chakra-ui/react';
import React from "react";
import { getGridSize, getPhotoCardHeight } from "../../utils/photoCardSize";

export default function EmptyPhotoCardEventSkeletonV2({gridType="lg"}) {
  return (
    <Stack width={"100%"}>
      <Skeleton width={"60%"} height="20px" startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="9px"/>
      <SimpleGrid columns={getGridSize(gridType)} spacing={"4px"} marginTop={"14px!important"}>
        <Skeleton height={getPhotoCardHeight(gridType)} width={"100%"} startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />
        <Skeleton height={getPhotoCardHeight(gridType)} width={"100%"} startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />
        <Skeleton height={getPhotoCardHeight(gridType)} width={"100%"} startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />
        <Skeleton height={getPhotoCardHeight(gridType)} width={"100%"} startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />
      </SimpleGrid>
      <Skeleton width={"45%"} height="20px" startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="9px" marginTop={"24px!important"}/>
      <SimpleGrid columns={getGridSize(gridType)} spacing={"4px"} marginTop={"14px!important"}>
        <Skeleton height={getPhotoCardHeight(gridType)} width={"100%"} startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />
        <Skeleton height={getPhotoCardHeight(gridType)} width={"100%"} startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />
        <Skeleton height={getPhotoCardHeight(gridType)} width={"100%"} startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />
      </SimpleGrid>
    </Stack>
  )
}
