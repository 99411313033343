import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Page from "../../components/Page";
import useUserProfileQuery from "../../components/api/queries/useUserProfileQuery";
import usePostDetailQuery from "../../components/api/queries/usePostDetailQuery";
import styled from "styled-components";
import LightColors from "../../constants/LightColors";
import { Radio, RadioGroup } from "@chakra-ui/react";
import InputArea from "../../components/input/InputArea";
import AddBlockFeedReport from "../../components/api/mutations/AddBlockFeedReport";
import { t } from "i18next";

const ConfirmText = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
  color: ${LightColors.primary};
`

const ContentRow = styled.div`
  display: flex;
  padding: 8px 16px;
`

const ContentRowTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  min-width: 72px;
  color: ${LightColors.textsublv1};
`

const ContentRowDescription = styled.div`
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: ${LightColors.textmainlv3};
`

const Section = styled.div`
  padding-left: 18px;
  padding-right: 18px;
`

const ReportBox = styled.div`
  background: ${LightColors.surface};
  padding-top: 24px;
  padding-bottom: 12px;
  padding-left: 18px;
  margin-top: 8px;
`

const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
  color: ${LightColors.textmainlv2};
`

const RadioRow = styled.div`
  padding: 18px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const RadioWrapper = styled.div`
  flex-shrink: 1;
`

const RadioTextWrapper = styled.div`
  padding-left: 10px;
  flex-grow: 1;
`
const RadioText = styled.div`
  color: ${LightColors.textmainlv2};
  font-size: 17px;
  font-weight: 500;
  line-height: 20.4px;
  text-align: left;
`

const MENUS = [
  {
    type: "INSULT",
    name: t("feedReportPage.menuInsult"),
  },{
    type: "FALSE_INFORMATION",
    name: t("feedReportPage.menuFalseInformation"),
  },{
    type: "OBSCENITY",
    name: t("feedReportPage.menuObscenity"),
  },{
    type: "OVER_POSTING",
    name: t("feedReportPage.menuOverPosting"),
  },{
    type: "PROMOTION",
    name: t("feedReportPage.menuPromotion"),
  },{
    type: "OTHER",
    name: t("feedReportPage.menuOther"),
  }
]

export default function FeedReportPage() {
  const location = useLocation();
  const [radioValue, setRadioValue] = useState();
  const [otherText, setOtherText] = useState();

  const currentQueryParams = new URLSearchParams(location.search);
  const postId = currentQueryParams.get("post_id");
  const post = usePostDetailQuery(postId, false);

  const userProfileId = currentQueryParams.get("user_profile_id");
  const userProfile = useUserProfileQuery(userProfileId);

  const navigate = useNavigate();

  const onReport = () => {
    if(!radioValue) return;

    if (postId) {
      AddBlockFeedReport("Post", postId, radioValue, otherText)
    }

    if (userProfileId) {
      AddBlockFeedReport("UserProfile", userProfileId, radioValue, otherText)
    }
    back();
  }

  const back = () => {
    const isNewTab = currentQueryParams.get("newTabOpen");
    if (isNewTab) {
      window.close();
    } else {
      navigate(-1);
    }
  }

  return (
    <Page
      showBackButton={true}
      title={t("feedReportPage.pageTitle")}
      showTitle={true}
      onHistoryBackClick={() => back()}
      noPadding={true}
      style={{paddingTop: "24px + calc(env(safe-area-inset-top))"}}
      navigationElement={
        <div className="flex justify-end" onClick={() => onReport()}>
          <ConfirmText style={{color: radioValue ? `${LightColors.primary}` : `${LightColors.onDisable}`}}>
            {t("report")}
          </ConfirmText>
        </div>
      }
    >

      {
        post && (
          <>
          <ContentRow>
            <ContentRowTitle>
              {t("feedReportPage.author")}
            </ContentRowTitle>
            <ContentRowDescription>
              {post.authorName}
            </ContentRowDescription>
          </ContentRow>
          <ContentRow>
            <ContentRowTitle>
              {t("feedReportPage.content")}
            </ContentRowTitle>
            <ContentRowDescription>
              {post.content}
            </ContentRowDescription>
          </ContentRow>
          </>
        )
      }
      {
        userProfile &&
        (
          <ContentRow>
            <ContentRowTitle>
              {t("feedReportPage.author")}
            </ContentRowTitle>
            <ContentRowDescription>
              {userProfile.nickname}
            </ContentRowDescription>
          </ContentRow>
        )
      }
      <ReportBox>
        <Title>{t("feedReportPage.selectReportReason")}</Title>
      </ReportBox>
      <Section>
        <RadioGroup onChange={setRadioValue} value={radioValue}>
        {
          MENUS.map((menu) => {
            return(
              <RadioRow key={menu.type}>
                <RadioWrapper>
                  <Radio colorScheme="primary" verticalAlign={"middle"} value={menu.type}/>
                </RadioWrapper>
                <RadioTextWrapper>
                  {
                    <RadioText>
                      {menu.name}
                    </RadioText>
                  }
                </RadioTextWrapper>
              </RadioRow>
            )
          })
        }
        </RadioGroup>
      </Section>
      <Section>
        {
          radioValue === "OTHER" &&
          <InputArea
            placeholder={t("feedReportPage.selectReportReason")} onChangeText={(text) => setOtherText(text)}
          />
        }
      </Section>
    </Page>
  )
}
