import { Box, Flex, HStack, Image, Spacer, Text, VStack } from '@chakra-ui/react';
import { first, isEmpty, isUndefined, sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styled from '@emotion/styled';
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import GridSkeletons from '../skeletons/GridSkeletons';
import { getArtistId } from '../../utils/etcUtils';
import { t } from 'i18next';

const titleStyle = {
  fontSize: "17px",
  fontWeight: 700,
  color: "#444",
}

const cardCountStyle = {
  fontSize: "17px",
  fontWeight: 700,
  color: "#5C3DFA",
  paddingLeft: "3px"
}

const buttonStyle = {
  fontSize: "14px",
  fontWeight: 600,
  padding: "6px 12px",
  color: "rgba(92, 61, 250, 1)",
  borderRadius: "32px",
  backgroundColor: "rgba(92, 61, 250, 0.06)"
}

const SkeletonWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
`

export default function TradingManagerBar({tradingPhotoCards, onClickTradingPhotoCard}) {
  const navigate = useNavigate();
  const [selectedTradigPhotoCard, setSelectedTradigPhotoCard] = useState();
  const artistId = getArtistId();

  useEffect(() => {
    if(!selectedTradigPhotoCard){
      setSelectedTradigPhotoCard(first(tradingPhotoCards))
    }
  }, [selectedTradigPhotoCard, tradingPhotoCards])


  const onSelectPhotoCard = (tradingPhotoCard) => {
    setSelectedTradigPhotoCard(tradingPhotoCard)
    onClickTradingPhotoCard(tradingPhotoCard)
  }

  const sumQuantityTradingPhotoCardsCount = () => {
    return sumBy(tradingPhotoCards, ((c) => c.quantity ?? 1))
  }

  if (isUndefined(tradingPhotoCards)) {
    return(
      <SkeletonWrapper>
        <GridSkeletons gridSize={5} skeletonSize={3} skeletonHeight={'90px'}/>
      </SkeletonWrapper>
    )
  }

  if (isEmpty(tradingPhotoCards)) {
    return (<></>)
  }

  return (
    <VStack align={"initial"}>
      <Flex align={"center"} marginBottom={"8px"}>
        <HStack>
        <Text style={titleStyle}> {t("tradingPhotoCardPage.tradingManagerBarTitle")}</Text>
        {
          tradingPhotoCards && <Text style={cardCountStyle}>{sumQuantityTradingPhotoCardsCount()}</Text>
        }
        </HStack>
        <Spacer/>
        <Box style={buttonStyle} onClick={() => navigate(`/artists/${artistId}/my_trading_photo_cards`)}>
          {t("tradingPhotoCardPage.tradingManagerBarManage")}
        </Box>
      </Flex>
      <HStack marginTop={"4px!important"}>
      <div className="horizontalScroll hiddenScrollBar" style={{marginLeft:"-5px", marginRight: "-24px"}}>
        {
          tradingPhotoCards?.map((t) => {
            return (
              <div
                key={t.tradingPhotoCardId}
                className="relative"
                onClick={()=>{onSelectPhotoCard(t)}}
                style={{height: "116px", marginLeft: "4px", marginTop: "3px"}}>
                <Image
                  src={t.photoCard.pictureUrl}
                  width={57}
                  height={90}
                  objectFit={"cover"}
                  border={(selectedTradigPhotoCard?.tradingPhotoCardId === t.tradingPhotoCardId) ? "2px" :"2px solid"}
                  borderColor={(selectedTradigPhotoCard?.tradingPhotoCardId === t.tradingPhotoCardId) ? "white" : "rgba(0, 0, 0, 0.15)"}
                  borderRadius={"12px"}
                  loading={"lazy"}
                  boxShadow={(selectedTradigPhotoCard?.tradingPhotoCardId === t.tradingPhotoCardId) ? "0 0 0 3px #5C3DFA, 0 4px 8px 0px rgba(92, 62, 250, 0.3);" : "0 0 0 3px rgba(0, 0, 0, 0.15)"}
                  className={selectedTradigPhotoCard?.tradingPhotoCardId !== t.tradingPhotoCardId && "opacity-40"}
                />
              </div>
            )
          })
        }
        <Box
          bg="rgba(0, 0, 0, 0.04)"
          borderRadius="12px"
          paddingX={"23px"}
          paddingY={"36px"}
          alignItems='center'
          justifyContent={'center'}
          marginLeft={"2px"}
          onClick={()=>navigate(`/artists/${artistId}/my_trading_photo_cards`)}
        >
          <PlusIcon
            width={"16px"}
            height={"16px"}
            fill="rgba(0, 0, 0, 0.4)"
            style={{display: "inline-block"}}
          />
        </Box>
      </div>
      </HStack>
    </VStack>
  )
}
