import React, { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { format } from "date-fns";
import { t } from "i18next";
import uuid from "react-uuid";
import styled from "styled-components";

import Page from "../components/Page";
import CommonVerticalButtonDialog from "../components/alerts/CommonVerticalButtonDialog";
import useUserQuery from "../components/api/queries/useUserQuery";
import UserVerificationBottomSheet from "../components/bottomSheet/UserVerificationBottomSheet";
import ArrowRightIcon from "../components/icons/ArrowRightIcon";
import colors from "../constants/colors";
import { sendToNative } from "../utils/NativeUtil";
import initializePortOne from "../utils/PortOne";
import baseUrl from "../utils/baseUrl";
import { setEvent } from "../utils/ga";
import getToken from "../utils/getToken";
import SelectLocaleBottomDrawer from "../components/drawer/SelectLocaleBottomDrawer";
import updateUserLocaleOrCountry from "../components/api/mutations/updateUserLocaleOrCountry";
import SupportedLocales from "../constants/SupportedLocales";
import { first, isEmpty } from "lodash";
import i18n from "../i18n/i18n";
import SelectCountryBottomDrawer from "../components/drawer/SelectCountryBottomDrawer";
import Countries from "../constants/Countries";
import saveData from "../utils/saveData";

async function initializedPhotoCards(artistId) {
  return fetch(baseUrl() + `/photo_cards/initialized`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      artistId: artistId,
    }),
  }).then((data) => data.json())
}

async function deleteUser() {
  return fetch(baseUrl() + `/users/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
  }).then((data) => data.json())
}

const Row = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  min-height: 66px;
  border-bottom: 1px solid #F6F6F6;
`

const TextColumn = styled.div`
  flex-grow: 1;
  align-content: center;
`

const Title = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${colors.textMain};
`

const Description = styled.p`
  padding-top: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: ${colors.textSub};
`

const ButtonColumn = styled.div`
  display: flex;
  flex-shrink: 1;
  align-items: center;
`

const ErrorTextButton = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${colors.warning};
`

const PrimaryTextButton = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${colors.primary};
`

export default function ManageAccountPage() {
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const artistId = currentQueryParams.get("artistId");
  const user = useUserQuery();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isShowVerificationBottomSheet, setIsShowVerificationBottomSheet] = useState(false);
  const [isShowLocaleBottomSheet, setIsShowLocaleBottomSheet] = useState(false);
  const [isShowCountryBottomSheet, setIsShowCountryBottomSheet] = useState(false);

  const {origin, pathname, search} = window.location

  function requestIdentityVerification() {
    const IMP = initializePortOne();

    IMP.certification({
      merchant_uid: `imp-${uuid()}`,
      min_age: 14,
      company: "포카판",
      popup: false,
      m_redirect_url: `${origin}/identity_verification_callback?redirect_path=${encodeURIComponent(pathname + search)}`,
    });
  }

  const invalidateQueries = () => {
    queryClient.invalidateQueries("users");
  }

  const onFinishedInitialized = (isSuccess) => {
    if(isSuccess) {
      invalidateQueries();
    } else {
      alert(t("userProfilePage.errorIntializePhotoCardsAlert"));
    }
  }

  const navigateUserIdentity = (showDialog) => {
    if (showDialog) {
      setIsOpenAlert(true);
    } else {

      if(user?.identified) {
        requestIdentityVerification();
      }else {
        setIsShowVerificationBottomSheet(true);
      }
      setIsOpenAlert(false);
    }
  }

  const confirmInitializePhotoCards = () => {
    if (!user?.likedArtists) return;
    if (user?.likedArtists.length === 0) return;

    if (user?.likedArtists.length > 1) {
      navigate('/artists/reset_all_cards');
    } else {
      // 아티스트 가 한명만 있을 경우
      setEvent({
        category: 'PhotoCard',
        action: 'Select Initialize PhotoCards',
        label: 'User Profile Page',
      });
      if(window.confirm(t("userProfilePage.initializePhotoCardsAlert"))){
        setEvent({
          category: 'PhotoCard',
          action: 'Initialize PhotoCards',
          label: 'User Profile Page',
        });
        initializedPhotoCards(artistId).then((r) => onFinishedInitialized(r.success))
      }
    }
  }

  const initializeLocalStorage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("artist");
    localStorage.clear();
    sendToNative('{"type": "logout"}')
  }

  const logout = () => {
    if (window.confirm(t("userProfilePage.logoutConfirmAlert"))) {
      initializeLocalStorage();
      queryClient.clear();
      return navigate("/")
    }
  }

  const deleteAccount = () => {
    if (window.confirm(t("userProfilePage.deleteAccountConfirmAlert"))) {
      deleteUser().then((r) => {
        // TODO handle error
        initializeLocalStorage();
        queryClient.clear();
        return navigate("/")
      });
    }
  }

  const onConfirmedLocale = async (locale) => {
    const response = await updateUserLocaleOrCountry({userId: user.userId, locale: locale});
    if (response) {
      saveData('i18nextLng', locale);
      i18n.changeLanguage(locale);
      invalidateQueries();
      queryClient.clear();
    }
  }

  const localeObject = useMemo(() => {
    if(isEmpty(user)) return;

    if (user.locale === "zh-HK") {
      return {
        code: "zh-TW",
        name: "繁體中文"
      }
    }

    const filteredLocale = SupportedLocales.filter((locale) => locale.code === user.locale)
    if (!isEmpty(filteredLocale)) {
      return first(filteredLocale)
    }

    return {
      code: "en",
      name: "English",
    }
  }, [user])

  const onConfirmedCountyCode = async (countryCode) => {
    const response = await updateUserLocaleOrCountry({userId: user.userId, country: countryCode});
    if (response) {
      invalidateQueries();
      queryClient.clear();
    }
  }

  const countryObject = useMemo(() => {
    if(isEmpty(user)) return;
    const filteredCountry = Countries.filter((country) => country.code === user.country)
    return first(filteredCountry)
  }, [user])

  return (
    <Page
      title={t("manageAccountPage.pageTitle")}
      showBackButton={true}
      showTitle={true}
      userProfile={user}
      style={{paddingTop: "13px", marginBottom: "96px"}}
    >
      {
        user && user.country === "KR" &&
        <Row>
          <TextColumn>
            <Title>{t("manageAccountPage.identityVerificationTitle")}</Title>
            <Description>{t("manageAccountPage.identityVerificationDescription")}</Description>
          </TextColumn>
          <ButtonColumn onClick={() => navigateUserIdentity(!!user?.identified)}>
            {user?.identified ? <PrimaryTextButton>{t("manageAccountPage.identityVerificationDone")}</PrimaryTextButton> : <ErrorTextButton>{t("manageAccountPage.identityVerificationIncomplete")}</ErrorTextButton>}
            <ArrowRightIcon width={"20px"} style={{marginLeft: "4px", padding: "2px 4px"}} fill="#AAAAAA"/>
          </ButtonColumn>
        </Row>
      }

      <Row onClick={()=>setIsShowCountryBottomSheet(true)}>
        <TextColumn>
          <Title>{t("manageAccountPage.countryTitle")}</Title>
          <Description>{t("manageAccountPage.countryDescription")}</Description>
        </TextColumn>
        <ButtonColumn>
          <PrimaryTextButton>{countryObject?.name}</PrimaryTextButton>
          <ArrowRightIcon width={"20px"} style={{marginLeft: "4px", padding: "2px 4px"}} fill="#AAAAAA"/>
        </ButtonColumn>
      </Row>

      <Row onClick={()=>setIsShowLocaleBottomSheet(true)}>
        <TextColumn>
          <Title>{t("manageAccountPage.localeTitle")}</Title>
          <Description>{t("manageAccountPage.localeDescription")}</Description>
        </TextColumn>
        <ButtonColumn>
          <PrimaryTextButton>{localeObject?.name}</PrimaryTextButton>
          <ArrowRightIcon width={"20px"} style={{marginLeft: "4px", padding: "2px 4px"}} fill="#AAAAAA"/>
        </ButtonColumn>
      </Row>

      <Row>
        <TextColumn>
          <Title>{t("manageAccountPage.promotionTitle")}</Title>
        </TextColumn>
        <ButtonColumn>
          {
            user?.isPromotionAgreed ? <PrimaryTextButton>{t("manageAccountPage.promotionAgreed")}</PrimaryTextButton> : <ErrorTextButton>{t("manageAccountPage.promotionDisagreed")}</ErrorTextButton>
          }
          <ArrowRightIcon width={"20px"} style={{marginLeft: "4px", padding: "2px 4px"}} fill="#AAAAAA"/>
        </ButtonColumn>
      </Row>

      <Row>
        <TextColumn>
          <Title>{t("manageAccountPage.initializePhotoCards")}</Title>
        </TextColumn>
        <ButtonColumn onClick={()=>confirmInitializePhotoCards()}>
          <ArrowRightIcon width={"20px"} style={{marginLeft: "4px", padding: "2px 4px"}} fill="#AAAAAA"/>
        </ButtonColumn>
      </Row>

      <Row>
        <TextColumn>
          <Title>{t("manageAccountPage.deleteAccount")}</Title>
        </TextColumn>
        <ButtonColumn onClick={()=> deleteAccount()}>
          <ArrowRightIcon width={"20px"} style={{marginLeft: "4px", padding: "2px 4px"}} fill="#AAAAAA"/>
        </ButtonColumn>
      </Row>

      <Row>
        <TextColumn>
          <Title>{t("manageAccountPage.logout")}</Title>
        </TextColumn>
        <ButtonColumn onClick={()=> logout()}>
          <ArrowRightIcon width={"20px"} style={{marginLeft: "4px", padding: "2px 4px"}} fill="#AAAAAA"/>
        </ButtonColumn>
      </Row>

      <Row style={{borderBottom: "0px"}}>
        <TextColumn>
          <Title>{t("manageAccountPage.appVersion")}</Title>
        </TextColumn>
        <ButtonColumn>
          <PrimaryTextButton>{"1.0.3"}</PrimaryTextButton>
          <ArrowRightIcon width={"20px"} style={{marginLeft: "4px", padding: "2px 4px"}} fill="#AAAAAA"/>
        </ButtonColumn>
      </Row>

      {
        user && <CommonVerticalButtonDialog
          isOpen={isOpenAlert}
          onClose={() => setIsOpenAlert(false)}
          title={t("manageAccountPage.identityVerificationDialogTitle")}
          description={t("manageAccountPage.identityVerificationDialogDescription", {phoneNumber: user.phoneNumber, certifiedAt: format(new Date(user.certifiedAt), 'yyyy.MM.dd')})}
          upButtonText={t("manageAccountPage.identityVerificationDialogNo")}
          downButtonText={t("manageAccountPage.identityVerificationDialogYes")}
          onClickDialogUpButton={() => setIsOpenAlert(false)}
          onClickDialogDownButton={() => navigateUserIdentity(false)}
        />
      }

      {
        user && !user.identified &&
        <UserVerificationBottomSheet
          isShowBottomSheet={isShowVerificationBottomSheet}
          setIsShowBottomSheet={setIsShowVerificationBottomSheet}
        />
      }

{
        user && isShowCountryBottomSheet &&
        <SelectCountryBottomDrawer
          isOpen={isShowCountryBottomSheet}
          onClose={setIsShowCountryBottomSheet}
          onConfirmedCountryCode={onConfirmedCountyCode}
          defaultCountryCode={user.country}
        />
      }

      {
        user && isShowLocaleBottomSheet &&
        <SelectLocaleBottomDrawer
          isOpen={isShowLocaleBottomSheet}
          onClose={setIsShowLocaleBottomSheet}
          onConfirmedLocale={onConfirmedLocale}
          defaultLocale={localeObject?.code}
        />
      }
    </Page>
  )
}
