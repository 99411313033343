import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function AddEvent({artistId, name, category}) {
  return fetch(baseUrl() + `/api/v1/events`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      event: {
        artist_id: artistId,
        category: category,
        name: name,
      },
    }),
  });
}
