import { GridItem, SimpleGrid, Stack, useBreakpointValue } from '@chakra-ui/react';
import React from "react";
import { Virtuoso } from "react-virtuoso";
import styled from 'styled-components';
import { EventNameBox } from '../../event/EventNameBox';
import PhotoCard from "../../photocard/PhotoCard";

const EventNameBoxWrapper = styled.div`
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 9px;
`


export default function PhotoCardsByEventViewer({
  events,
  onClickedPhotoCard,
  endReached,
}) {


  const columns = useBreakpointValue({
    base: 4,   // 모바일
    md: 6,     // iPad
    lg: 6,     // 큰 화면
  });

  // #region draw cards
  const drawCardsWithEventName = (e) => {
    if (e.photoCards <= 0) {
      return (<div key={e.eventId}></div>)
    } else {
      return(
        <Stack key={e.eventId} paddingBottom={"4px"}>
          <EventNameBoxWrapper>
            <EventNameBox event={e}/>
          </EventNameBoxWrapper>
          <SimpleGrid columns={columns} spacing={"4px"} marginInlineStart={0} marginTop={"0px!important"}>
            {(e.photoCards)?.map((photoCard) => {
              return (
                <GridItem key={photoCard.photoCardId} aspectRatio={"55/87"}>
                  {
                    photoCard &&
                    <PhotoCard photoCard={photoCard} onClick={() => onClickedPhotoCard(photoCard, e)}/>
                  }
                </GridItem>
              )
            })}
          </SimpleGrid>
        </Stack>
      )
    }
  };
  // #endregion

  return (
    <Virtuoso
      useWindowScroll
      style={{ height: '100%' }}
      endReached={endReached}
      overscan={480}
      data={events}
      itemContent={(_, e) => { return drawCardsWithEventName(e)}}
    />
  )
}
