// 더마토리 백개

// import FilledButton from "../../components/FilledButton"

import { Text } from "@chakra-ui/react";
import { forwardRef } from "react";
import Page from "../../components/Page";

const ShopProductFrame = forwardRef(({
  children,
  title, buttonName, onClick,
  ...other
}, ref) => {
  // #region handle navigation events
  const onHistoryBackClick = () => {
    window.history.back();
  };
  // #endregion

  return <>
  <Page
      title={title ?? ''}
      showTitle={true}
      showBackButton={true}
      navigationElement={
        buttonName ?
        <div className="flex justify-end">
          <Text>{buttonName}</Text>
        </div> : <></>
      }
      onNavigationClick={onClick}
      onHistoryBackClick={onHistoryBackClick}
      noPadding={true}
      ignoreTokenCheck={true}
    >
    {children}
  </Page>
  </>
});

export default ShopProductFrame;
