import TradingPhotoCardResponse from "./TradingPhotoCardResponse";

export default class MatchedPhotoCardResponse {
  constructor({
    matchedTradingPhotoCardId,
    artistId,
    sourceId,
    targetId,
    status,
    matchedAt,
    sourceCard,
    targetCard,
  }) {
    this.matchedTradingPhotoCardId = matchedTradingPhotoCardId;
    this.artistId = artistId;
    this.sourceId = sourceId;
    this.targetId = targetId;
    this.status = status;
    this.matchedAt = matchedAt;

    this.sourceCard = new TradingPhotoCardResponse(sourceCard);
    this.targetCard = new TradingPhotoCardResponse(targetCard);
  }
}
