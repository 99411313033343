import React from "react";

import { SimpleGrid, Skeleton, SkeletonText } from "@chakra-ui/react";

export default function GridSkeletons({
  gridSize,
  skeletonSize,
  skeletonHeight,
  showText=false,
  ...props
}){
  return(
    <SimpleGrid columns={gridSize} gap={"15px"} {...props}>
      {
        [...Array(skeletonSize).keys()].map((key) => {
          return(
            <div key={key}>
              <Skeleton
                height={skeletonHeight}
                startColor={"rgba(0, 0, 0, 0.04)"}
                endColor={"rgba(0, 0, 0, 0.02)"}
                borderRadius={"12px"} />
              {
                showText &&
                <SkeletonText
                  mt={"12px"}
                  noOfLines={2}
                  width={"75px"}
                  startColor={"rgba(0, 0, 0, 0.04)"}
                  endColor={"rgba(0, 0, 0, 0.02)"}
                  borderRadius={"12px"}
                />
              }
            </div>
          )
        })
      }
    </SimpleGrid>
  )
}
