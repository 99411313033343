import React, { useEffect, useRef, useState } from "react";

import { isEmpty, trim } from "lodash";
import FileResizer from "react-image-file-resizer";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useFilePicker } from 'use-file-picker';

import { Badge, Box, Divider, Flex, HStack, Image, Switch, Text, Textarea, VStack, WrapItem } from '@chakra-ui/react';

import Page from "../../components/Page";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ProfileLineButton from "../../components/buttons/ProfileLineButton";

import { FileAmountLimitValidator, FileSizeValidator } from "use-file-picker/validators";
import ProgressSpinnerDialog from "../../components/alerts/ProgressSpinnerDialog";
import AddPost from "../../components/api/mutations/AddPost";
import usePostDetailQuery from "../../components/api/queries/usePostDetailQuery";
import SelectArtistFandomBottomSheet from "../../components/bottomSheet/SelectArtistFandomBottomSheet";
import AddPhotoCardBottomSheet from "../../components/bottomSheet/community/AddPhotoCardBottomSheet";
import PostImagesBox from "../../components/community/PostImagesBox";
import QuotedPostPreview from "../../components/community/QuotedPostPreview";
import LightColors from "../../constants/LightColors";
import colors from "../../constants/colors";
import { ReactComponent as AlbumIcon } from "../../icons/album.fill.svg";
import { ReactComponent as SearchPhotoCardIcon } from "../../icons/card.fill.svg";
import { ReactComponent as CloseFillIcon } from "../../icons/xmark.fill.svg";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import isResizableMimetype from "../../utils/ImageUtils";
import { isNativeAndroid } from "../../utils/NativeUtil";
import { t } from "i18next";

const closeWrapItemStyle = {
  borderRadius: "32px",
  backgroundColor: LightColors.black800,
  alignItems: "center",
  justifyContent: "center",
  padding: "4px",
  position: "absolute",
  top: "10px",
  right: "10px",
}

const PhotoCardWrapper = styled.div`
  display: flex;
  padding-top: 16px;
`

const BottomNavWrapper = styled.div`
  padding: 0px 20px;
`

const WarningText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: ${LightColors.warning};
`

const DisclaimerText = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${colors.textSubLv3};
  padding-bottom: 16px;
`


const SelectorArtistWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SelectorWrapper = styled.div`
  flex-grow: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
`

const SelectorTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${colors.textMain};
  padding-left: 10px;
`

const SelectedFandom = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${colors.primary};
  padding-left: 3px;
`

const SelectorDescription = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  color: ${colors.textSubLv1};
`

const RepostBox = styled.div`
  border-width: 1px;
  border-color: ${LightColors.separatorlv2};
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
  border-radius: 12px;
`
const eventNameStyle = {
  fontWeight: 500,
  color: LightColors.textmainlv1,
  fontSize: "14px",
  lineHeight: "17px",
  marginBottom: "5px",
  height: "17px"
}

const pocaNameStyle = {
  fontWeight: 400,
  color: LightColors.textmainlv3,
  fontSize: "12px",
  lineHeight: "14px",
  paddingBottom: "5px",
}

const memberNameBadgeStyle = {
  fontWeight: 600,
  fontSize: "11px",
  lineHeight: "13px",
  borderRadius: "4px",
  width: "fit-content",
  color: LightColors.onSecondary,
  backgroundColor: LightColors.secondary
}

const TitleEditable = styled.div`
  outline: 0px solid transparent;
  outline: none;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: ${LightColors.textmainlv2};
  &:empty:before,
  &:focus:empty:before {
    content: attr(placeholder);
    color: ${LightColors.onDisable};
  }
`

const ContentEditable = styled.div`
  outline: 0px solid transparent;
  outline: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin: 10px 0px;
  color: ${LightColors.onLabel};
  &:empty:before,
  &:focus:empty:before {
    content: attr(placeholder);
    color: ${LightColors.onDisable};
  }
`;

const BoxWrapper = styled.div`
  padding-top: 16px;
`

const WarningTextWrapper = styled.div`
  padding-top: 4px;
`

export default function AddPostPage() {
  const navigate = useNavigate();

  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const quotedPostId = currentQueryParams.get("quotedPostId");
  const artistId = currentQueryParams.get("artistId");
  const fandomName = currentQueryParams.get("fandomName");
  const quotedPost = usePostDetailQuery(quotedPostId, true);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [dataUrls, setDataUrls] = useState([]);
  const [photoCard, setPhotoCard] = useState(null);
  const [selectedArtist, setSelectArtist] = useState();
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isShowSelectArtistBottomSheet, setIsShowSelectArtistBottomSheet] = useState(false);
  const [isShowAddPhotoCardBottomSheet, setIsShowAddPhotoCardBottomSheet] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (artistId) {
      setSelectArtist({artistId: artistId, fandomName: fandomName});
    }
  }, [artistId, fandomName])

  useEffect(() => {
    if (quotedPost) {
      setSelectArtist({artistId: quotedPost.artistId, fandomName: quotedPost.fandomName});
    }
  }, [quotedPost])

  const back = () => {
    const isNewTab = currentQueryParams.get("newTabOpen");
    if (isNewTab) {
      window.close();
    } else {
      navigate(-1);
    }
  }

  const { openFilePicker, plainFiles, filesContent, errors } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    // TODO : Bug fix to load images from Android Device.
    validators: isNativeAndroid() ? [] : [
      new FileAmountLimitValidator({ max: 4 }),
      new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
    ],
  });

  const resizeFile = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        1500,
        2655,
        "JPEG",
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    }
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.click();
        inputRef.current.focus();
        inputRef.current.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'center' });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
    const textarea = inputRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [content])


  useEffect(() => {
    const resizeAndSetDataUrls = async () => {
      if(isEmpty(plainFiles)) return;
      if(isEmpty(filesContent)) return;
      setIsRequesting(true);
      // TODO : Improve to pop and push new photos
      try {
        const resizedFiles = await Promise.all(
          plainFiles.slice(0, 4).filter((file) => !isEmpty(file.type)).map((file, index) => {
            if(isResizableMimetype(file.type)){
              return resizeFile(file);
            }else {
              return filesContent[index].content;
            }
        }));
        setDataUrls(resizedFiles);
      } catch (error) {
        console.error("Error resizing files:", error);
        setIsRequesting(false);
      }
      setIsRequesting(false);
    };
    resizeAndSetDataUrls();
  }, [plainFiles, filesContent]);

  const isAllRequired = () => {
    return (!isEmpty(trim(title)) || !isEmpty(trim(content)) || !isEmpty(dataUrls))
  }

  const requestPost = async () => {
    if(isRequesting || !isAllRequired()) return;
    setIsRequesting(true);

    const trimedTitle = trim(title)
    const trimedContent = trim(content)

    const response = await AddPost({
      title: isEmpty(trimedTitle) ? null : trimedTitle,
      content: isEmpty(trimedContent) ? null : trimedContent,
      artistId: selectedArtist?.artistId,
      dataUrls: dataUrls,
      photoCardId: photoCard?.photoCardId,
      postType: quotedPostId ? "Quotation" : "Post",
      isAnonymous: isAnonymous,
      repostSourceId: quotedPostId,
    });

    setIsRequesting(false);

    if (response.ok) {
      const responseBody = await response.json();
      if(responseBody && responseBody.post) {
        window.opener.postMessage({
          type: "show-toast-uploaded-post-on-feed",
          params: {post: responseBody.post },
        }, window.location.origin);
      }
      back();
    }
  }

  const onSelectedPhotoCard = (photoCard) => {
    setPhotoCard(photoCard);
    setIsShowAddPhotoCardBottomSheet(false);
  }

  const onToggledSwitch = (isSelected) => {
    setIsShowSelectArtistBottomSheet(isSelected);
    if (!isSelected) {
      setSelectArtist(null);
    }
  }

  const onSelectArtistFandom = (artist) => {
    setSelectArtist(artist);
    if (artist) {
      setIsShowSelectArtistBottomSheet(false);
    }
  }

  const onDeletedImage = (index) => {
    const newDataUrls = [...dataUrls];
    newDataUrls.splice(index, 1);
    setDataUrls(newDataUrls);
  }

  return (
    <>
      <Page
        showBackButton={true}
        title={t("addPostPage.pageTitle")}
        leftElement={<CloseIcon fill={"#000"}/>}
        // onHistoryBackClick={() => navigate(-1)}
        onHistoryBackClick={() => back()}
        titleElement={
          <Flex justifyContent={"center"} flexGrow={1}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              borderRadius={"32px"}
              border={"1px solid #E7E7E7"}
              paddingX={"12px"}
              paddingY={"8px"}
              marginRight={"-30px"}
            >
              <Text fontSize={"14px"} lineHeight={"17px"} fontWeight={600} color={LightColors.textmainlv2}>{t("anonymous")}</Text>
              <Switch size='sm' paddingLeft={"4px"} colorScheme="primary" onChange={(e)=>setIsAnonymous(e.target.checked)}/>
            </Box>
          </Flex>
        }
        navigationElement={
          <PrimaryButton
            paddingX={"18px"}
            paddingY={"11px"}
            borderRadius={"32px"}
            fontSize={"14px"}
            text={t("confirm")}
            disabled={!isAllRequired()}
            fontWeight={500}
            onClick={() => requestPost()}
          />
        }
        style={{height: "calc(100vh - 144px)"}}
      >
        <TitleEditable
          contentEditable
          placeholder={t("addPostPage.titlePlaceHolder")}
          onInput={(e)=> setTitle(e.target.textContent)}
        />
        <Textarea
          ref={inputRef}
          rows={1}
          type="text"
          variant='unstyled'
          padding={0}
          marginTop={"10px"}
          fontWeight={500}
          fontSize={"15px"}
          alignContent={"center"}
          placeContent={"center"}
          lineHeight={"20px"}
          autoFocus={true}
          value={content}
          color={LightColors.textmainlv2}
          overflowY={"hidden"} // hidden scroll
          placeholder={t("addPostPage.contentPlaceHolder")}
          _placeholder={{
            color: LightColors.onDisable,
          }}
          onChange={(e)=> setContent(e.target.value)}
        />
        <div >
        {
          isEmpty(dataUrls) ?
          <></> :
          <BoxWrapper>
            <PostImagesBox images={dataUrls} enableDelete={true} enableFullSizeViewer={false} onDeletedImage={onDeletedImage}/>
          </BoxWrapper>
        }
        {
          !isEmpty(errors) &&
          <WarningTextWrapper>
            <WarningText>{t("addPostPage.contentPicturesError")}</WarningText>
          </WarningTextWrapper>
        }
        </div>
        {
          photoCard &&
          <PhotoCardWrapper>
            <WrapItem position={"relative"} alignItems={"center"} width={"100%"}>
              <HStack padding={"16px"} borderRadius={"12px"} borderColor={LightColors.separatorlv2} borderWidth={"1px"} width={"100%"}>
                <Image width={"48px"} height={"75px"} borderRadius={"8px"} src={photoCard.pictureUrl}/>
                <VStack align={"left"} spacing={0} marginStart={"14px!important"}>
                  <Text style={eventNameStyle} noOfLines={1}>{photoCard.eventName || photoCard.event.name}</Text>
                  <Text style={pocaNameStyle}>{photoCard.pocaname || photoCard.name}</Text>
                  <Badge marginTop={"8px!important"} padding={"4px 8px"} style={memberNameBadgeStyle}>{photoCard.memberName || photoCard.member.name}</Badge>
                </VStack>
              </HStack>
              <WrapItem style={closeWrapItemStyle}>
                <CloseFillIcon width={"10px"} height={"10px"} fill={LightColors.purewhite} onClick={() => setPhotoCard(null)}/>
              </WrapItem>
            </WrapItem>
          </PhotoCardWrapper>
        }
        <div>
          {
            quotedPost &&
            <BoxWrapper>
              <Box borderColor={LightColors.separatorlv2}>
                <RepostBox>
                  <QuotedPostPreview post={quotedPost} disableActions={true}/>
                </RepostBox>
              </Box>
            </BoxWrapper>
          }
        </div>
        <HStack spacing={"8px"} paddingTop={"16px"}>
          <ProfileLineButton
            paddingX={"12px"}
            paddingY={"7px"}
            fontSize={"13px"}
            lineHeight={"16px"}
            text={t("picture")}
            leftIcon={<AlbumIcon width={"14px"} height={"14px"} fill={LightColors.textmainlv2}/>}
            onClick={openFilePicker}
          />
          <ProfileLineButton
            paddingX={"12px"}
            paddingY={"7px"}
            fontSize={"13px"}
            lineHeight={"16px"}
            text={t("poca")}
            leftIcon={<SearchPhotoCardIcon width={"14px"} height={"14px"} fill={LightColors.textmainlv2} onClick={()=>setIsShowAddPhotoCardBottomSheet(true)} />}
            onClick={() => setIsShowAddPhotoCardBottomSheet(true)}
          />
          {/* <ProfileLineButton
            paddingX={"12px"}
            text={t("addPostPage.addPhotoCardBoard")}
            rightIcon={<AlbumIcon fill="black"/>}
          /> */}
        </HStack>
      </Page>
      <BottomNavWrapper className="bottomNavbar safeAreaMarginBottom" style={{maxWidth: "calc(var(--chakra-sizes-xl)"}}>
        {
          isEmpty(title) && isEmpty(content) && (
            <DisclaimerText>
              {t("addPostPage.disclaimer")}
            </DisclaimerText>
          )
        }
        <Divider style={{marginLeft: "-24px", marginRight: "-24px", width: "calc(100% + 48px)"}}/>
        <SelectorArtistWrapper>
          <SelectorWrapper>
            <Switch
              size='sm'
              colorScheme="primary"
              isChecked={!!selectedArtist}
              onChange={(e) => onToggledSwitch(e.target.checked)}
            />
            <SelectorTitle>{"Only"}</SelectorTitle>
            {
              selectedArtist && <SelectedFandom>{selectedArtist.fandomName}</SelectedFandom>
            }
          </SelectorWrapper>
          <SelectorDescription>
            {selectedArtist?.fandomName ?  t("addPostPage.onlyFandom", { fandom: selectedArtist?.fandomName }) : t("addPostPage.onlyFandomDescription") }
          </SelectorDescription>
        </SelectorArtistWrapper>
      </BottomNavWrapper>
      {
        isShowSelectArtistBottomSheet &&
        <SelectArtistFandomBottomSheet
          isOpen={isShowSelectArtistBottomSheet}
          setIsOpen={setIsShowSelectArtistBottomSheet}
          onSelectArtistFandom={onSelectArtistFandom}
        />
      }
      {
        isShowAddPhotoCardBottomSheet &&
        <AddPhotoCardBottomSheet
          title={t("addPostPage.addPhotoCard")}
          isCheckPhotoCardBottomSheetOpen={isShowAddPhotoCardBottomSheet}
          setIsCheckPhotoCardBottomSheetOpen={setIsShowAddPhotoCardBottomSheet}
          onConfirmedPhotoCards={onSelectedPhotoCard}
        />
      }
      {
        isRequesting && <ProgressSpinnerDialog
          isOpen={isRequesting}
          onClose={() => isRequesting(false)}
        />
      }
    </>
  )
}
