const LightColors = {
  background: "rgba(255, 255, 255, 1)",
  backgroundelevatedlv1: "rgba(255, 255, 255, 1)",
  backgroundelevatedlv2: "rgba(255, 255, 255, 1)",
  surface: "rgba(0, 0, 0, 0.03999999910593033)",
  shadow: "rgba(0, 0, 0, 0.03999999910593033)",
  textmainlv1: "rgba(34, 34, 34, 1)",
  textmainlv2: "rgba(68, 68, 68, 1)",
  textmainlv3: "rgba(102, 102, 102, 1)",
  textmainlv4: "rgba(136, 136, 136, 1)",
  textsublv1: "rgba(170, 170, 170, 1)",
  textsublv2: "rgba(185, 185, 185, 1)",
  textsublv3: "rgba(203, 203, 203, 1)",
  disable: "rgba(231, 231, 231, 1)",
  onDisable: "rgba(185, 185, 185, 1)",
  label: "rgba(0, 0, 0, 0.10000000149011612)",
  onLabel: "rgba(68, 68, 68, 1)",
  placeholder: "rgba(0, 0, 0, 0.20000000298023224)",
  separatorlv1: "rgba(246, 246, 246, 1)",
  separatorlv2: "rgba(231, 231, 231, 1)",
  pureblack: "rgba(34, 34, 34, 1)",
  purewhite: "rgba(255, 255, 255, 1)",
  systemblack: "rgba(0, 0, 0, 1)",
  primary: "rgba(92, 61, 250, 1)",
  onPrimary: "rgba(255, 255, 255, 1)",
  textprimary: "rgba(56, 37, 153, 1)",
  verfied: "rgba(0, 178, 255, 1)",
  secondary: "rgba(92, 61, 250, 0.06)",
  onSecondary: "rgba(92, 61, 250, 1)",
  warning: "rgba(255, 20, 42, 1)",
  onWarning: "rgba(255, 255, 255, 1)",
  warningcontainer: "rgba(255, 20, 42, 0.10000000149011612)",
  onWarningcontainer: "rgba(255, 20, 42, 1)",
  theirty: "rgba(220, 214, 250, 1)",
  onTheirty: "rgba(178, 163, 255, 1)",
  unknwonTextColor1: "rgba(155, 155, 155, 1)",
  unknwonTextColor2: "rgba(163, 163, 163, 1)",
  black050: "rgba(0, 0, 0, 0.04)",
  black800: "rgba(0, 0, 0, 0.8)",
  black900: "rgba(0, 0, 0, 0.9)"
}

export default LightColors;
