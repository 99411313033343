import React from "react";
import { Box, Center, Text } from '@chakra-ui/react';
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as ShowAllCardIcon } from "../../icons/show.all.cards.svg";

export default function FabButton({
  icon,
  text,
  onClickFabButton, 
  ...props
}) {
  return (
    <div className="flex flex-row-reverse">
      <Box
        className="bottomButtonOnBottomNav"
        borderRadius="full"
        backgroundColor={"rgba(92, 61, 250, 1)"}
        marginRight={"16px"}
        onClick={() => onClickFabButton()}
        padding={"16px 18px 16px 18px"}
        height={"52px"}
        boxShadow={"0px 6px 10px 0px #2B1B8D57"}
        {...props}
      >
        <Center width={"full"} height={"full"}>
          {
            icon === 'showAllCards' 
            ? <ShowAllCardIcon fill="white" width={"24px"} height={"24px"}/> 
            : <PlusIcon fill="white" width={"24px"} height={"24px"}/>
          }
          {
            text &&
            <Text
              lineHeight={"20.4px"}
              fontWeight={600}
              fontSize={17}
              textColor={"white"}
              paddingLeft={"5px"}
              >{text}
            </Text>
          }
        </Center>
      </Box>
    </div>
  )
}