import { Input } from "@chakra-ui/react";
import React from "react";
import LightColors from "../../constants/LightColors";

export default function PrimaryInput({defaultValue, placeholder, onChangeText, ...props}) {
  return(
    <Input
      borderRadius={"12px"}
      bgColor={LightColors.secondary}
      focusBorderColor={LightColors.secondary}
      defaultValue={defaultValue}
      onChange={onChangeText}
      variant="filled"
      paddingY={6}
      _hover={{bgColor: LightColors.secondary}}
      _placeholder={{color: LightColors.placeholder}}
      placeholder={placeholder}
      {...props}
    />
  )
}
