import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";
import { HStack, Text, VStack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { t } from "i18next";
import { isEmpty, isNull } from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import colors from "../../constants/colors";
import { ellipsis } from "../../utils/TextUtil";
import { getArtistId } from "../../utils/etcUtils";
import ToastMessageBox from "../alerts/ToastMessageBox";
import createChatRoom from "../api/mutations/legacy/createChatRoom";
import AddUserProfileV1BottomSheet from "../bottomSheet/AddUserProfileV1BottomSheet";
import SecondaryButton from "../buttons/SecondaryButton";
import VerifiedIcon from "../icons/VerifiedIcon";
import { openNewTab } from "../../utils/UIUtils";

const profileImageStyle = {
  width: "38px",
  height: "38px",
  borderRadius: "34px",
  background: "lightgray 50% / cover no-repeat, rgba(122, 89, 255, 0.26)"
}

const nicknameStyle ={
  fontWeight: 600,
  color: "#444",
  fontSize: "14px"
}

const requestedAtStyle = {
  color: "#999",
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "13.2px",
  whiteSpace: "pre"
}

const responseRateText = {
  color: `${colors.primary}`,
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "13.2px",
}


export function UserProfileRow({userProfile, tradingPhotoCard, maxTextLength=15}){
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const artistId = getArtistId();

  const [isShowProfileBottomSheet, setIsShowProfileBottomSheet] = useState(false);

  const navigateChatRoom = async () => {
    const response = await createChatRoom(artistId, tradingPhotoCard.tradingPhotoCardId);
    if (response.success) {
      navigate(`/artists/${params["artist_id"]}/chats/${response.chatRoom.chatRoomId}`)
    } else if (response.error && response.error.code === "1001" && response.error.message.startsWith("Not found record")) {
      return setIsShowProfileBottomSheet(true);
    } else {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("tradingPhotoCardPage.noProfileToast")}/>
        ),
      })
    }
  }

  const navigateUserTradingProfile = (userProfile) => {
    const currentPath = window.location.pathname;
    const userProfilePath = `/trading/user_profile/${userProfile?.userProfileId}`
    if (currentPath === userProfilePath) return;

    if(!isEmpty(userProfile?.userProfileId)) {
      openNewTab(userProfilePath);
    }
  }

  const buttonText = () => {
    if(tradingPhotoCard.category === "sale"){
      if(!isNull(tradingPhotoCard.price)){
        return t("tradingPhotoCardPage.purchaseWithPrice", {price: tradingPhotoCard.price})
      } else {
        return t("tradingPhotoCardPage.askPrice")
      }
    } else {
      return t("sendMessage");
    }
  }

  return(
    <div className="flex items-center" key={userProfile.userProfileId}>
      <div className="flex-none" onClick={() => navigateUserTradingProfile(userProfile)}>
        {
          userProfile?.pictureUrl ?
          <Image
            style={profileImageStyle}
            src={userProfile?.pictureUrl}
            objectFit={"cover"}
            loading={"lazy"}
          /> :
          <Avatar style={profileImageStyle}/>
        }
      </div>
      <div className="grow" style={{paddingLeft: "9px"}} onClick={() => navigateUserTradingProfile(userProfile)}>
        <VStack alignItems={"start"} spacing={0}>
          <HStack spacing={1}>
            <Text
              className="overflow-hidden"
              style={nicknameStyle}
              noOfLines={1}
            >
              {ellipsis(userProfile?.nickname, maxTextLength)}
            </Text>
            {
              userProfile.identified && <VerifiedIcon width={"14px"} height={"14px"} />
            }
          </HStack>
          <HStack spacing={0}>
            <Text style={requestedAtStyle}>{`${tradingPhotoCard.requestedAt}`}</Text>
            {
              !isEmpty(tradingPhotoCard.userProfile.chatStats?.responseText) && (
                <>
                <Text style={requestedAtStyle} px={"3px"}>{`•`}</Text>
                <Text style={responseRateText}>{`${tradingPhotoCard.userProfile.chatStats?.responseText}`}</Text>
                </>
              )
            }
          </HStack>
        </VStack>
      </div>
      <div className="flex-non">
        <SecondaryButton
          text={buttonText()}
          fontWeight={600}
          paddingY={"11px"}
          paddingX={"18px"}
          onClick={() => navigateChatRoom(tradingPhotoCard.tradingPhotoCardId)}
        />
      </div>
      {
        isShowProfileBottomSheet &&
        <AddUserProfileV1BottomSheet
          title={t("tradingPhotoCardPage.addProfileBottomSheetTitle")}
          isShowProfileBottomSheet={isShowProfileBottomSheet}
          setIsShowProfileBottomSheet={setIsShowProfileBottomSheet}
        />
      }
    </div>
  )
}
