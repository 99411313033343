export const en = {
  translation: {
    pocapan: 'Pocapan',
    requestFailed: 'The request has failed.',
    next: 'Next',
    done: 'Done',
    save: 'Save',
    confirm: 'Done',
    cancel: 'Cancel',
    login: 'Sign In',
    logout: 'Sign out',
    photoCardCount: '{{count}}Cards',
    cardsCount: '{{count}}Cards',
    entire: 'All',
    report: 'Report',
    send: 'Send',
    megaphone: 'Megaphone',
    picture: 'Photo',
    photoCard: 'Photocard',
    poca: 'Poca',
    anonymous: 'Anon',
    comment: 'Comment',
    like: 'Like',
    leave: 'Leave',
    entireMembers: 'All Members',
    sendMessage: 'Send Message',
    accept: 'Accept',
    decline: 'Reject',
    yes: 'Yes',
    no: 'No',
    select: "Select",
    tabs: {
      home: 'Home',
      feed: 'Community',
      pocapan: 'Pocas template',
      tradingPhotoCard: 'TradePan',
      reportPhotoCard: 'Suggest Poca',
      profile: 'My',
      notifications: 'Notification'
    },
    feedPage: {
      title: 'Community',
      defaultProfile: 'Profile 🔒',
      alertMarquee: 'For safe and happy trading, please use the TradePan for photocard trades. Simple trade posts will be removed without notice!',
      onlyFandom: 'View only fandom posts',
      newPostNotification: 'View new post',
      uploadToastMessage: 'Upload complete!',
      uploadToastShowPost: 'view',
      fandomFeed: '{{fandom}} Pan'
    },
    feedIntroPage: {
      title: 'Community',
      introTitle: 'Now you can have fun\nin the community on Pocapan!',
      firstRowTitle: 'Use the anonymous feature to share secretly!',
      firstRowDescription: 'Only people in the same fandom can see my posts!',
      secondRowTitle: 'Share the good issue with everyone using the megaphone!',
      secondRowDescription: 'You can even add your own thoughts with a quote!',
      thirdRowTitle: 'Share with our fandom using Only Fandom!',
      thirdRowDescription: "We're also preparing a feature for close friends!",
      confirmFeedAgreement: 'You are considered to have agreed to the updated terms.'
    },
    feedReportPage: {
      pageTitle: 'Report',
      menuInsult: 'Profanity / Insults',
      menuFalseInformation: 'Spreading False Information',
      menuObscenity: 'Obscenity',
      menuOverPosting: 'Board / Comment Spam',
      menuPromotion: 'Promotion',
      menuOther: 'Other',
      author: 'Author',
      content: 'Post Content',
      selectReportReason: 'Please select a reason for reporting'
    },
    addPostPage: {
      pageTitle: 'Create New Post',
      title: 'Create Social Profile',
      titlePlaceHolder: 'Click here to add a title',
      contentPlaceHolder: 'How was your day?',
      contentPicturesError: 'Upload up to 4 images, total size limit 50MB',
      disclaimer: 'Please refrain from leaving opinions that may discomfort others, such as profanity or insults. Your use of the community may be restricted if reported.',
      onlyFandom: 'Visible only to {{fandom}}',
      onlyFandomDescription: 'Set to show only selected fandom!',
      addPhotoCard: 'Add Poca',
      addPhotoCardBoard: 'PocaBoard',
      friendsOnly: 'Close Friends(Coming soon)'
    },
    postDetailPage: {
      pageTitle: 'Post {{title}}',
      deleteMenu: 'Delete',
      deletedDescription: 'The original post where the comment was made has been deleted.',
      postOnFeed: 'Also post this comment to Community',
      alertDeletedPost: 'The original post has been deleted.',
      commentPlaceHolder: 'Leave a Comment',
      sourcePostDescription: 'Comment on <bold>{{post}}</bold>'
    },
    userFeedProfilePage: {
      pageTitle: 'Social Profile',
      postTab: 'Posts',
      navigateTradingProfile: "Check out {{nickname}}'s Trade Profile",
      menuChangeNickname: "Edit Nickname",
      menuHiddenTradingProfile: "Set Trade Profile to Private",
      menuOpenTradingProfile: "Set Trade Profile to Public"
    },
    addPhotoCardPage: { pageTitle: 'Add Poca' },
    post: {
      emptyPost: 'No Posts Available',
      megaphonedPost: '{{author}} is Using the Megaphone',
      commentedPost: 'Comment on {{content}}',
      deletedPost: 'The post you commented on has been deleted.',
      deletedCommentMenu: 'Delete',
      confirmDelete: 'Are you sure you want to delete this?',
      reportPostMenu: 'Report Post',
      reportUserMenu: 'Report User',
      deletePostMenu: 'Delete',
      onlyFandom: 'Only\n{{fandom}}',
      reply: 'Reply'
    },
    createFeedUserProfilePage: {
      pageTitle: 'Create Social Profile',
      title: 'Create Social Profile',
      description: 'For safety and privacy in the Community, only social profiles can be used for communication!',
      nickNamePlaceHolder: 'Please enter your name'
    },
    reportBottomSheet: {
      megaphone: 'Megaphone Now',
      unmegaphone: 'Cancel Megaphone',
      quotes: 'Add Quote and Megaphone'
    },
    exploreTradingPhotoCardBottomSheet: {
      errorNoProfileToast: 'Request failed. Please ensure your profile is created.',
      tradingPhotoCardInfo: 'Trade Card Detail',
      sendMessage: 'Send Message'
    },
    photoCardBottomNav: {
      collectedPhotoCardCount: '{{collectedCount}} / {{totalCount}}',
      collectedPhotoCardPercentage: "{{member}}'s Pocas {{percentage}}%",
      editModeButton: 'Edit Owned PC'
    },
    introPage: { startButton: 'Start Pocapan!' },
    requestVerificationCodePage: {
      emailErrorMessage: 'Please ensure the email address is in the correct format',
      title: 'Please provide the email you will use to sign in',
      description: "Don't worry, it won't be shared with anyone!",
      placeHolder: 'Click here to enter your email'
    },
    loginWithCodePage: {
      title: 'Please enter the verification code',
      description: 'Sent to {{email}}!',
      notReceivedMessage: "Didn't receive the verification email?",
      sendAgainMessage: 'Resend',
      successSendAlert: 'Email has been resent.',
      failedSendAlert: 'Resend failed. Please try again later.',
      verificationFailedAlert: 'Verification failed. Please check again.'
    },
    welcomePage: {
      title: 'Nice to meet you!',
      description: "We'll make your fan activities easier"
    },
    selectArtistPage: {
      pageName: 'Select Artist',
      title: 'Please choose an artist',
      description: "We'll add groups that don't exist if we get enough requests!",
      errorAlert: 'Please select one artist.',
      whoIsNext: 'Who is next?'
    },
    selectMemberPage: {
      pageName: 'Select a member',
      title: 'Pick your favorite meme',
      errorAlert: 'Please select at least one member.',
      next: 'Done',
      confirmed: 'Member Change Done'
    },
    photoCardPage: {
      pageName: 'Pocas template',
      title: 'Select the photocards you have',
      description: 'Unselected photocards will be marked as unowned poca!',
      errorAlert: 'Please select at least one member.',
      navigationSkip: 'Do it later',
      navigationReport: 'Report',
      navigationEdit: 'Edit',
      confirmErrorAlert: 'Please select at least one photocard.',
      listTitle: 'Photocards template',
      bannerTitle: 'Create your Pocaboard',
      showPhotoCardsOnFeed: 'View My Owend Pocas on Community',
      manageArtist: '💖Manage Artists',
      emptyPhotoCards: 'No Photocards temeplate Available',
      showColorMode: 'View in Color Mode',
      discardAlertDialog: 'You have unsaved changes.\nCancel editing?',
      likedArtistPhotoCardList: "{{artist}}'s Pocas",
      filter: "Filter",
    },
    checkPhotoCardBottomSheet: { title: 'Photocard List', report: 'Report' },
    emptyPhotoCard: {
      title: 'Checked photocards will be displayed here',
      description: 'Check the photocards you have!',
      startCollectPhotoCard: 'Go to Check'
    },
    myPhotoCardBottomSheet: {
      smallSize: 'Small View',
      middleSize: 'Medium View',
      largeSize: 'Large View',
      shareImageTwitterButton: 'Share this image on Twitter',
      collectedPhotoCards: 'Collected Poca',
      unCollectedPhotoCards: 'Unowned Poca',
      wishedPhotoCards: 'Wished Poca',
      showPhotoCardName: 'View Photocard Names',
      createPhotoCardBoard: 'Create Pocaboard',
      shareTwiiterText: "https://twitter.com/intent/tweet?text=1. Use the download icon to save the image.%0A2. If you have an iPhone, find the image in the 'Files' app.%0A3. Share it on Twitter and show off to your friends.%0AFans, 포카판&url=app.pocapan.com"
    },
    reportPhotoCardPage: {
      pageName: 'Report a photocard',
      title: 'Report a photocard',
      categoryDescription: 'What type of Poca is it?',
      eventDescription: 'What type of Poca is it?',
      requiredMissingAlert: 'Please select all.',
      reportDoneAlert: "Thank you! We'll check and update as soon as possible!",
      selectPlaceHolder: 'Click here to find us!',
      directlyInputOption: 'Enter directly',
      reportDone: 'Report',
      duplicatedAlert: 'Uploading photo...'
    },
    userProfilePage: {
      pageName: 'My Pocapan',
      title: 'My',
      myPhotoCard: 'My photocards',
      changeMember: 'Change your collecting member',
      initializePhotoCards: 'Reset My Photocards',
      notice: 'Notices',
      terms: 'Terms of Service',
      privacy: 'Privacy Policy',
      deleteAccount: 'Delete Account',
      logoutConfirmAlert: 'Do you want to sign out?',
      deleteAccountConfirmAlert: 'Delete account?',
      initializePhotoCardsAlert: 'All your photocards will be reset. Are you sure you want to continue?',
      errorIntializePhotoCardsAlert: 'Please try again later.'
    },
    addTradingPhotoCardPage: {
      exchangePageName: "Sell My Pocas",
      salesPageName: "Exchange My Pocas",
      pageName: 'Add a poca to trade',
      title: 'Please register the poca\nyou wish to trade.',
      exchangeTitle: 'Select a Poca to exchange',
      salesTitle: 'Select a Poca to sell',
      searchInputPlaceholder: 'Search by name, album name',
      countButton: '{{count}} Add',
      myPhotoCardTitle: 'My photocards',
      searchPhotoCardTitle: 'All photocards'
    },
    childLoginPage: {
      pageName: 'Guardian Unauthorized Use Restrictions',
      title: 'Access is restricted due to lack of Guardian consent',
      description: 'For users under 14 without guardian consent, access is restricted until consent is obtained. Guardian consent is required within 5 days. If consent is not provided, the account will be deleted, and all information will be destroyed. (Restriction Date: MM/DD/YYYY)',
      enableAccountGuide: 'Guardian consent is handled via email. Please have your parent contact us directly at: pocapan.official@gmail.com',
      deleteAccount: 'Delete Account',
      backToIntro: 'Go to Home'
    },
    blockUserPage: {
      pageName: 'Access Restricted',
      title: 'Access Restricted',
      description: 'Due to multiple reports from users, access has been restricted. For inquiries, please email pocapan.official@gmail.com.'
    },
    requiredLoginPage: {
      pageName: 'Sign-In Required',
      title: 'Sign-In Required',
      description: 'You have been signed out due to an unknown error. Please sign in again for a secure experience.'
    },
    emptyTradingPhotoCard: {
      title: '{{nickname}}, please add the Poca you want to trade!',
      description: 'PocaPan will help you find a match for your trade.',
      addTradingPhotoCard: 'Add a Poca for Trade'
    },
    emptyExploreTradingPhotoCard: {
      title: 'No Poca have been posted yet.',
      description: 'Only showing Pocas others are trading.',
      addTradingPhotoCard: 'Add a Poca for Trade'
    },
    emptyWishedTradingPhotoCard: {
      title: 'No wished Poca yet!',
      description: 'Collect and view your wished Poca at a glance.',
      addTradingPhotoCard: 'Add a Poca for Trade'
    },
    emptyWishedMatchedTradingPhotoCard: {
      title: 'No wished trades yet!',
      description: 'Use this feature when you’re undecided about a trade.',
      addTradingPhotoCard: 'Add a Poca for Trade'
    },
    emptyMessages: { title: 'No messages yet!', button: 'Browse Poca for Trade' },
    forceToUpdate: {
      pageTitle: '💌 Letter from the Pocapan 💌',
      title: 'New Community are coming 💖with the app update💖',
      button: 'Go to update',
      firstRowDescription: 'Hi, this is Team Pocapan!\n' +
        "It's been a year since we launched Pocapan.\n" +
        "We'd like to thank all of you for your interest\n" +
        'and your continued support has helped us get this far.\n' +
        'Pocapan has evolved from a photo card exchange platform\n' +
        'a fan-centered home for virtue culture.',
      secondRowDescription: "We're opening a new Community to make it easier and freer to talk about.",
      thirdRowDescription: 'With trepidation and excitement, we invite you to join us.'
    },
    tradingPhotoCardPage: {
      pageTitle: 'TradePan',
      exchangeTabName: 'Exchange Match',
      salesTabName: 'Trade/Buy List',
      manageMyTradingPhotoCard: 'My Trade poca',
      marqueeText: '🔍Stop Searching! Find your trade partner easily with TradePan! POCAPAN is not responsible for exchange issues, so please be cautious!',
      thisCardOnlyForSale: 'The selected POCA is set as a sale-only card.',
      tradingPhotoCardChatRooms: 'Chat rooms with this Poca',
      noChatRoom: 'No active chat rooms yet.',
      exchangeEventTradingPhotoCards: 'Same event tradeable Pocas',
      emptyEventTradingPhotoCards: 'No same event Pocas available yet.',
      exchangeTradingPhotoCards: 'Tradeable Pocas',
      addProfileBottomSheetTitle: 'Set up your trading profile',
      tradingManagerBarTitle: 'My cards to trade',
      tradingManagerBarManage: 'Edit',
      aggregatedPhotoCardCardTitle: 'Available for me to trade',
      showUserProfilesBottomSheetTitle: 'Which {{fandom}} to trade with?',
      notAvailableExchangeTradingPhotoCards: 'No tradeable Pocas available 🥲',
      offetSalesTradingPhotoCardsRow1: 'Direct purchase from the {{fandom}}',
      offetSalesTradingPhotoCardsRow2: 'below is also available.',
      offetSalesTradingPhotoCardsRow3: ' ',
      matchedTradingPhotoCardBottomDrawerTitle: 'Trade Card Detail',
      matchedTradingPhotoCardMine: 'I have',
      matchedTradingPhotoCardTarget: 'I want',
      addBlackListMenu: 'Not accepting matches',
      reportMenu: 'Report',
      reMatching: 'Find again',
      exchange: 'Trade',
      sale: 'Sell',
      salesSwitchTitle: 'Show sale-only Pocas',
      exchangeTabTitle: 'Pocas for {{saleType}} by {{fandom}}',
      salesSwitchDescription: 'Send a direct message to receive a trade!',
      saleBadge: 'Sale',
      excludeMatching: 'Exclude',
      allowMatching: 'Allow',
      offerPrice: 'Offer',
      offerPriceWithEmoji: 'Offer🧚‍♂️',
      tradingPhotoCardInfoBar: 'My Trade Card',
      noProfileToast: 'Request failed. Please check if your profile is created.',
      askPrice: 'Ask Price',
      purchaseWithPrice: '{{price}} Buy'
    },
    myTradingPhotoCardsPage: { pageName: 'Set a Trade Poca' },
    tradingPhotoCardChatRoom: {
      pageTitle: 'Message',
      noticeDeletedUser: 'The member has been deactivated. For further inquiries, please contact Pocapan.official@gmail.com',
      noticeLeftUser: 'The other ended the chat.',
      leaveChatRoomDialogTitle: 'Leave the chat room?',
      leaveChatRoomDialogDescription: 'Leaving will end the trade, and you will no longer receive messages from the other.',
      leaveChatRoomDialogConfirm: 'Leave Chat Room',
      saleTradingMenu: "On Sale",
      purchasingTradingMenu: "Buying",
      completedTradingMenu: "Trade Completed"
    },
    photoCardDetailPage: {
      firstCaptorTitle: "This card's Poca Fairies💖",
      collected: 'Owned',
      collectedCount: '{{count}} Pocas owned',
      notCollected: 'Unowned',
      changeToNotCollected: 'Change to Unowned',
      changeCollectedCount: 'Set quantity',
      exchangeTradingPhotoCardTitle: 'Trade with the cards below right now!',
      saleTradingPhotoCardTitle: 'Pocas being sold by {{fandom}}',
      emptysaleTradingPhotoCards: 'No one is currently selling this card.',
      feedTitle: '{{fandom}} Pan',
      feedDescription: 'Want to know more? Check the Community!',
      showAllFeed: 'View All',
      firstCaptorBadge: '🧚Firstcaptor',
      changeCollectedBottomSheetTitle: 'You have card!',
      changeCollectedBottomSheetDescription: 'Please enter the quantity of cards you have.',
      tradeButton: "Trade",
      title: '{{memberName}}\'s {{categoryName}} {{categoryEmoji}}'
    },
    myPhotoCards: {
      pageName: 'Trading History',
      totalCount: 'Total {{count}}',
      tradingStatusSelectorOngoing: 'Ongoing Trades',
      tradingStatusSelectorCompleted: 'Completed Trades',
      tradingPhotoCardBoxByPhotoCardBadge: 'Chat Rooms {{count}}',
      exchangingPhotoCardsTabName: 'Trade',
      exchangingPhotoCardsTabEmptyCaseTitle: 'No Pocas traded yet',
      exchangingPhotoCardsTabEmptyCaseButton: 'Go to Trade',
      exchangingPhotoCardsTabReviewNotYet: "The ability to send reviews for 'Exchanges' is still in the works",
      sellingPhotoCardsTabName: 'Sell',
      sellingPhotoCardsTabEmptyCaseTitle: 'No Pocas sold yet',
      sellingPhotoCardsTabEmptyCaseButton: 'Go to Sell',
      purchasingPhotoCardsTabName: 'Buy',
      purchasingPhotoCardsTabEmptyCaseTitle: 'No Pocas bought yet',
      purchasingPhotoCardsTabEmptyCaseButton: 'Go to Buy',
      purchasingPhotoCardsTabSaleBadge: 'For Sale',
      purchasingPhotoCardsTabEmptyExchangeBadge: 'For Trade',
      chatRoomPageName: 'Active Chat Rooms',
      chatRoomPageSoldOutButton: 'Review feature coming soon🖤',
      chatRoomPageCompletedButton: 'Completed Trade',
      chatRoomPageConvertToSaleButton: 'Change to Sale',
      chatRoomPageConvertToExchangeButton: 'Change to Trade',
      chatRoomPageEmptyCase: 'No active chat rooms yet',
      chatRoomExchangeDialogTitle: 'Change to Trade-Only?',
      chatRoomExchangeDialogDescription: 'Existing active chat rooms will be closed and the other party will be notified. The conversation can still continue!',
      chatRoomCompleteDialogTitle: 'Is this a completed trade?',
      chatRoomCompleteDialogDescription: 'The review feature for the other party is coming soon!',
      chatRoomSaleDialogTitle: 'Change to Sale-Only?',
      chatRoomSaleDialogDescription: 'Existing active chat rooms will be closed. If not entered, it will be marked as an offer.',
      chatRoomSaleDialogInputPlaceholder: 'Please enter the amount.',
      changePriceDialogTitle: 'Edit price?',
      changePriceDialogDescription: "If no price is entered, it will be displayed as 'Offer'.",
      onlySale: 'For sale',
      editPrice: 'Edit',
      doneAndSendReview: "Close and Write a review",
      sentReviewButton: "View the review by me",
      sendReviewButton: '💘Write a reivew💘',
      receivedReviewButton: "View the review of me",
    },
    tradingReportPage: {
      pageName: 'Report to Pocapan',
      title: 'PocaPan Report Center',
      descriptionFirstRow: '• Reports are initially processed by an automated system. For duplicate reports, an admin will review and take further action.',
      descriptionSecondRow: '• If a report leads to legal issues, POCAPAN takes no responsibility. Personal information and chat content may be provided if necessary.',
      reportCode: 'Report Code',
      reportDescription: 'Report Details',
      reportDescriptionPlaceholder: 'Please enter detailed information.',
      reportDescriptionEmptyErrorMessage: 'Please enter content.',
      reportAttchment: 'Attachment',
      reportAttchmentPlaceHolder: 'Please attach a file.',
      reportUserEmail: "Reporter's Email Address",
      reportUserEmailEmptyErrorMessage: 'Please enter an email.',
      reportUserEmailFormatErrorMessage: 'Please check the email format.',
      reportAgreement: 'I agree to the provision of personal information and the review of chat content.',
      reportSubmit: 'Submit',
      successToastMessage: 'Report submitted successfully.',
      failedToastMessage: 'The request failed. Please try again later.'
    },
    blacklistCards: {
      manage: 'Edit',
      exclude: 'Set Excluded Pocas',
      pageName: 'Set Excluded Pocas',
      title: 'Set Excluded Pocas',
      description: 'Select cards to exclude from matching. Owned cards are automatically excluded.'
    },
    agreements: {
      baseAgreementBottomSheetTitle: 'Please review and accept the updated Terms of Service.',
      baseAgreementBottomSheetDescription: 'You have the right to refuse, but refusal may limit your access to the service.',
      baseAgreementBottomSheetButton: 'Get Started',
      baseAgreementAbove14: 'Please review and accept the updated Terms of Service.',
      baseAgreementBottomAgreeAllButton: 'Agree to All',
      baseAgreementRequiredCheckBox: '(Required)',
      baseAgreementRequiredAgreement: 'Pocapan Terms and Conditions',
      baseAgreementOptionalCheckBox: '(Optional)',
      baseAgreementPromotionalAgreement: 'Agree to receive marketing information'
    },
    errorBoundary: {
      title: 'An error occurred.',
      description: 'Please go to the home page and try again.',
      button: 'Go to Home'
    },
    newTradingPhotoCardsHorizontalCard: {
      authorName: 'TradePan',
      authoredAt: 'Just Now',
      newSales: 'Newly Listed Pocas for Sale 🛍️' ,
      newExchanges: 'Newly Listed Pocas for Trade ↔️'
    },
    myUserProfileList: {
      privateProfile: 'Social Profile',
      createPrivateProfile: 'Social Profile',
      tradingProfile: 'Trade Profile',
      addProfile: 'Add Profile',
      changeProfile: 'Edit Social Profile'
    },
    profiles: {
      defaultButton: 'Get Started',
      nickNameButton: 'Start as {{nickname}}',
      hasBatchimNickNameButton: 'Start with {{nickname}}',
      emptyNicknameErrorMessage: 'Please enter a nickname.',
      duplicatedNickName: 'This nickname is already taken.',
      failedToCreateAlert: 'Registration failed.',
      mergedNicknameToastMessage: 'Profile merged.',
      title: 'Edit Social Profile',
      inputNickNamePlaceholder: 'Please enter a nickname.',
      mergeProfilePageTitle: 'Merge Profile',
      mergeProfileTitle: 'Multi-fans! Please select one profile.',
      mergeProfileDescription: 'No more switching profiles by artist when using TradePan!',
      mergeProfileFirstDescriptionRow: "• You can manage artists in 'My > Artist Management'.",
      mergeProfileSecondDescriptionRow: '• Multi-profile support will be reintroduced after revision.',
      mergeProfileThirdDescriptionRow: '• Existing conversations will reflect the updated nickname.',
      chooseMergedProfile: 'Select',
      createNewNickname: 'Create New Nickname'
    },
    notifications: {
      pageTitle: 'Notifications',
      messagesTab: 'Messages',
      notificationsTab: 'Notifications',
      notificationsTitle: 'Notification list is under preparation.',
      notificationsDescription: 'Message/comment/like notifications are being sent!',
      messageCategoryAll: "All",
      messageCategorySelling: "Sale",
      messageCategoryPurchasing: "Buy",
      messageCategoryExchanging: "Exchange",
    },
    selectArtists: {
      initArtistTitle: 'Select the artist to reset',
      initArtistDescription: 'Only owned Pocas will be reset! Trade history remains.',
      photoCardsCount: '{{count}} Pocas owned'
    },
    tradingUserListPage: {
      pageName: 'Same Event Pocas',
      title: 'Which {{fandom}} would you like to trade with?',
      batchimTitle: 'Which {{fandom}} would you like to trade with?',
      orRow: 'or',
      saleSubTitleRow: 'Want to buy it quickly? Pay extra...',
      saleTitleFirstRow: 'On Sale',
      saleTitleSecondRow: 'Available for {{fandom}}!'
    },
    addTradingPhotoCard: {
      editNewTradingTitle: 'Registration Complete😍',
      editNewTradingSubTitle: 'Is this for sale only?',
      editNewTradingDescription: 'If for sale only, please enter a price!',
      addButton: 'Add',
      collectedBadge: 'Owned',
      emptyCollectedCaseTitle: 'No Pocas owned.',
      emptyCollectedCaseDescription: 'You can search and trade unregistered Pocas!',
      emptyCollectedCaseButton: 'Register Poca'
    },
    addPhotoCard: {
      searchPlaceholder: '🔍 Search by name or album title',
      description: 'Only one Poca can be added.'
    },
    chatRoom: {
      read: 'Read',
      copyMessageToClipboard: 'Message copied.',
      imageMenu: 'Photo',
      showTraderPhotoCards: "View the other party's trade Pocas",
      placeHolder: 'Enter a message',
      disabledPlaceHolder: 'Unable to chat',
      traderPhotoCardsBottomSheetTitle: "{{nickname}}'s trade Pocas",
      traderPhotoCardsBottomSheetEmptyCase: 'No Pocas for trade.'
    },
    myCollectedPhotoCardsStat: {
      authorName: "{{nickname}}'s PC template",
      authoredAt: 'Just Now',
      myCollectedPhotoCards: 'My Owned Pocas',
      myCollectedPhotoCardsCount: '{{count}}',
      mostCollectedMember: 'Most collected member',
      hideMenu: 'Hide from Community',
      showAllButton: 'View All'
    },
    myPage: {
      pageName: 'My',
      title: 'My',
      tradingsRow: 'Trading History',
      wishedRow: 'My Wishlist',
      manageArtistsRow: 'My Artist',
      manageMembersRow: 'My Bias',
      settings: 'Settings',
      manageAccount: 'Account Settings',
      notice: 'Notice',
      report: 'Send Feedback'
    },
    manageArtistPage: {
      pageName: 'My Artist',
      likedDays: '{{likedDays}}days as a {{fandom}}💖',
      dislikeArtist: 'Unstan',
      likeArtist: 'Add a New Stan',
      atLeastOneMoreArtistAlert: 'You must have at least one artist.',
      pleaseCheckInvalidDateAlert: 'Please check the date again.',
      disLikeArtistDialogTitle: 'Thank you for loving {{artist}}!',
      disLikeArtistDialogDescription: 'Your info is saved. Come back anytime!',
      disLikeArtistDialogConfirm: 'Thank you for loving {{artist}}!',
      disLikeArtistDialogCancel: 'Thank you for loving {{artist}}!',
      changeStartLikedDateDialogTitle: 'Would you like to change {{fandom}} stanning date?',
      changeStartLikedDateDialogConfirm: 'Change',
      addLikedArtist: 'Add'
    },
    manageMemberPage: { pageName: 'My Bias' },
    myWishedPage: {
      pageName: 'My Wishlist',
      wishedPhotoCardsTab: 'Pocas',
      wishedExchangesTab: 'Trades',
      wishedSalesTab: 'Sales',
      createPhotoCardBoard: 'Create Pocaboard with Wishlist',
      emptyWishedSalesTradingPhotoCardTitle: 'No wish sales Pocas yet!',
      emptyWishedSalesTradingPhotoCardDescription: 'Add the Pocas you want to buy here'
    },
    manageAccountPage: {
      pageTitle: 'Account Settings',
      identityVerificationTitle: 'Get Blue Badge',
      identityVerificationDescription: 'Verify your identity to get a Blue Badge!',
      identityVerificationDone: 'Acquired',
      identityVerificationIncomplete: 'Not Acquired',
      countryTitle: 'Country of Access',
      countryDescription: 'Help find stans from the same country',
      localeTitle: 'Service Language',
      localeDescription: 'Set the service language',
      promotionTitle: 'Marketing Notifications',
      promotionAgreed: 'Enabled',
      promotionDisagreed: 'Disabled',
      initializePhotoCards: 'Reset Photocards',
      deleteAccount: 'Delete Account',
      logout: 'Log Out',
      appVersion: 'App Version',
      identityVerificationDialogTitle: 'Re-verify with a new number?',
      identityVerificationDialogDescription: '{{phoneNumber}}\n{{certifiedAt}} Verification completed',
      identityVerificationDialogYes: 'Yes, my number has changed',
      identityVerificationDialogNo: 'No, keep the current number'
    },
    emptyMatchedTradingPhotoCard: {
      title: 'No matched Pocas found',
      description: 'Try adding other Pocas for trade!',
      reMatching: 'Search again'
    },
    onGoingMatchingTradingPhotoCard: {
      title: 'Looking for fans who need your selected Poca!',
      description: 'You’ll be notified once a match is found.'
    },
    changeCountry: {
      globalFeedTitle: "Global Community 🌎",
      globalCountyDescription: "{{country}} and International fan posts in Community.",

      supportedCountyTitle: "{{country}} Community",
      supportedCountyDescription: "Exclusive space for {{country}} fans to interact!",

      unSupportedCountryTitle: "{{country}} Community (Coming Soon)",
      unSupportedCountryDescription: "Open when more {{country}} fans gather!",

      localeDrawerFirstRow: "Please select the language of Pocapan",
      localeDrawerLastRow: "다른 언어는 계속 추가 됩니다!",

      countryDrawerFirstRow: "Set your access country.\nYou can change the access\ncountry once a week.",

      changingAlertTitle: "Change access country to {{country}}?",
      changingAlertDescription: "You can change it again in a week.",

      notAllowedChangingAlertTitle: "Unable to change the access country.",
      notAllowedChangingAlertDescription: "You can change the access country once a week. If you need to change it urgently, please contact us via email.",
    },
    TradingUserProfilePage: {
      pageTitle: "Trade Profile",
      reviewsCount: "{{count}} Reviews",
      reviewColorText: 'Near <color>{{color}}</color>',
      mannerSignal: "Manner Light",
      saleTab: "Items for Sale",
      exchangeTab: "Items for Exchange",
      saleEmptyCards: "No Pocas for Sale",
      exchangeEmptyCards: "No Pocas Cards for Exchange",
      statTradingReviews: "Reviews",
      statLastLogin: "ago, Active",
      statResponseRate: "Response",
    },
    TradingUserProfileSettingsPage: {
      changeProfileImage: "Change Trade Profile Image",
      pageTitle: "Set up Trade Profile",
      add: "Add",
      nickname: "Nickname",
      changeNicknameDialogTitle: "Edit nickname",
      placeHolderNickname: "Please enter a nickname.",
      description: "Bio",
      changeDescriptionDialogTitle: "Edit Bio",
      placeHolderDescription: "Please enter your bio.",
      manageTradingCards: "Manage trade Pocas",
      manageTradingHistories: "View trading history",
      manageTradingReviews: "My reviews",
    },
    SelectTradingPhotoCardReviewPage: {
      pageTitle: "Select a poca to complete",
      title: "Please select a Poca\nthat has completed a Trade",
      skip: "No Poca has completed a trade yet",
    },
    SelectReviewChatRoomPage: {
      title: "Please select the person\nyou made the trade with",
      dialogText: "Poca trade completed",
      skip: "I don't want to choose",
    },
    ReviewIcon: {
      red: "Not great",
      yellow: "Good",
      green: "Excellent",
    },
    ReviewColorText: {
      red: "Red",
      yellow: "Yellow",
      green: "Green",
    },
    ReviewFeedbackOptions: {
      DetailedDescription: "🧸 Item is in the same condition as described",
      IncludesBonus: "🎁 It came with extras",
      Friendly: "🫶 Friendly and well mannered",
      QuickResponse: "⚡️ is quick to reply",
      WannaTradeAgain: "🙌 Would definitely trade with him again",

      DifferentFromDescription: "😵‍💫 The item is not as described",
      BadPackaging: "🕸️ The packaging is in bad condition",
      Unfriendly: "🧟‍♀️ is unfriendly and has bad manners",
      SlowResponse: "🤐 They don't answer my chats after reading them",
      UnfairCancellation: "🫥 Unilaterally cancel the trade",
    },
    AddTradingPhotoCardReviewPage: {
      title: "How was your\ntrade with {{nickname}}?",
      description: "Please pick a manner sign! Not visible to the other person.",
      redReviewTitle: "What didn't you like?",
      yellowReviewTitle: "What was good?",
      greenReviewTitle: "What was the best part?",
      blacklistReviewRating: "I don't want to deal with this person again.",
      reviewContentTitle: "Is there anything else you'd like to say?",
      reviewContentDescription: "Leave a review to improve your own Trade light!",
      reviewContentPlaceHolder: "Enter anything you'd like to say to the other person.",
      done: "Review sent",
      doneDialogTitle: "Thank you for writing a review.",
      doneDialogDescription: "Pocapan will be more peaceful thanks to you!",
      notConfirmedTradingButton: "I've never done a trade with this person before.",
    },
    TradingPhotoCardReviewDetailPage: {
      sentTitle: "I sent a review to the other party",
      receivedTitle: "Your review has arrived\nfrom the other party!",
      confirm: "Leave a review for the other person",
      notConfirmedTradingButton: "I've never done trade with this person",
      rejctDialogTitle: "Never traded with {{nickname}} before?"
    },
    TradingPhotoCardReviewsPage: {
      pageTitle: "List of reviews",
      feedbackOptionsTitle: "People say this is good!",
      reviewsTitle: "Reviews from others",
      emptyReviews: "No reviews from others yet",
    },
    TradingPhotoCardFilterBottomDrawer: {
      title: "Filter",
      identityVerifiedTitle: "본인 인증",
      identityVerifiedRadio: "본인 인증",
      categoryTitle: "Photocard Type",
      eventTitle: "Activity with Photocard",
    },
    PhotoCardFilterBottomDrawer: {
      title: "Filter",
      categoryTitle: "Photocard Type",
      eventTitle: "Activity with Photocard",
      initButton: "Reset",
      defaultCategory: "Normal PC ",
      specialCategory: "POB PC",
      mdCategory: "Merch PC",
      tradingCategory: "Trading PC",
      emptyCategory: "No Photocards for the selected type.",
    },
    ExchangesTabPanel: {
      matchingSubTab: "Match List",
      chattingSubTab: "Chat List",
      availablePoca: "Exchange avaliable!",
      chatRoomsBoxText:	"There are {{count}} chats exchanging this Poca.",
      filter: "Filter",
    },
    SalesTabPanel: {
      purchaseSubTab: "Buy Pocas",
      saleSubTab: "My Sale Pocas",
      filter: "Filter",
    },
    ConfirmNewSalesTradingPhotoCardsPage: {
      title: "Please enter the sale price.",
      description: `If no price is entered, it will be displayed as 'Offer'.`,
      removeAllDialog: "All Pocas have been cleared. Please select again."
    },
    DoneTradingPhotoCardsPage: {
      title: "Registered for Exchange!",
      description: "Don’t miss messages. Turn on notifications!"
    },
    TradingPhotoCardPageV2: {
      chatRoomCountCard:	"Chat {{count}}",
      exploreCard:	"Browse",
      addCard:	"Add",
      sellPhotoCardButton:	"Sell My Pocas",
      exchangePhotoCardButton:	"Exchange My Pocas",
    },
    EventCategory: {
      album: { title: "💿 Recording Activities", description: "Poca received for releasing an album" },
      offline: { title: "🎤 Official offline activities", description: "Concert/Fan Meeting/Popup Poca" },
      ADs: { title: "💄 Commercial activities", description: "Poca related to the artist's commercial activities " },
      misc: { title: "🪪 Fan-club & Light Stick & SSG", description: "A Poca received from Fan-club, Light Stick, Season's Greeting" },
      content: { title: "📺 Content", description: "Poca related to media such as content, photo books, magazines.." },
    },
    ReportPhotoCardActivitiesPage: {
      pageName: "Add a missing Poca",
      title: "Which activity is this Poca from?",
      unknownActivity: "I'm not sure"
    },
    ReportPhotoCardCandidatesPage: {
      pageName: "Add a missing Poca",
      title: "Do any of the pocas\nbelow look the same?",
      emptyTag: "No tags",
      addManullay: "Add your Poca"
    },
    PhotoCardCategory: {
      "album": {
          "default": {
              "name": "💿 Album PC",
              "description": "The default Poca included in the album."
          },
          "special": {
              "name": "🍀 POB PC",
              "description": "A Poca received from the purchase store or special spot."
          },
          "md": {
              "name": "🧸 MD PC",
              "description": "A Poca received when purchasing merchandise."
          }
      },
      "offline": {
          "default": {
              "name": "💌 Standard PC",
              "description": "A Poca received an official offline event."
          },
          "special": {
              "name": "🍀 POB PC",
              "description": "Poca from a retailer or specific location"
          },
          "md": {
              "name": "🧸 MD PC",
              "description": "Poca given when buying merchandise"
          },
          "trading": {
              "name": "🤝 Trade PC",
              "description": "Poca exclusively for trading"
          }
      },
      "ADs": {
          "default": {
              "name": "💌 Standard PC",
              "description": "The default Poca included."
          },
          "special": {
              "name": "🍀 POB PC",
              "description": "Poca from a retailer or specific location"
          },
          "md": {
              "name": "🧸 MD PC",
              "description": "Poca given when buying merchandise"
          }
      },
      "content": {
          "default": {
              "name": "💌 Standard PC",
              "description": "The default Poca included."
          },
          "special": {
              "name": "🍀 POB PC",
              "description": "Poca from a retailer or specific location"
          },
          "md": {
              "name": "🧸 MD PC",
              "description": "Poca given when buying merchandise"
          }
      },
      "misc": {
          "default": {
              "name": "💌 Standard PC",
              "description": "The default Poca included."
          },
          "special": {
              "name": "🍀 POB PC",
              "description": "Poca from a retailer or specific location"
          },
          "md": {
              "name": "🧸 MD PC",
              "description": "Poca given when buying merchandise"
          }
      },
      "etc": {
          "default": {
              "name": "💌 Standard PC",
              "description": "The default Poca included."
          },
          "special": {
              "name": "🍀 POB PC",
              "description": "Poca from a retailer or specific location"
          },
          "md": {
              "name": "🧸 MD PC",
              "description": "Poca given when buying merchandise"
          },
          "trading": {
              "name": "🤝 Trade PC",
              "description": "Poca exclusively for trading"
          }
      }
    },
    ReportPhotoCardCategoriesPage: {
      pageName: "Add a missing Poca",
      title: "What is the type of poca?"
    },
    ReportPhotoCardConfirmPage: {
      pageName: "Add a missing Poca",
      title: "What is the type of poca?",
      confirmedTitle: "New POCA added!",
      confirmedDescription: "POCA added with the help of {{nickname}}!",
      completedTitle: "Report successful",
      completedDescription: "You will be registered as the Firstcaptor🧚 of this POCA",
      tagTitle: "Add Version, Shop, nickname, Etc to make it easier for other fans to recognize you!",
      tagDescription: "If you have more than 5 tags, only the most used tags will be displayed.",
      tagInputPlaceholder: "ex) jewel version or hot-dog poca",
      addTag: "Add +"
    },
    ReportPhotoCardMiscActivitiesPage: {
      membershipName: "Fan-club/membership",
      seasonsName: "Season's Greetings",
      lightstickName: "Lightstick",
      title: "Which activity is this Poca from?",
      unknownEvent: "I'm not sure",
    },
    ReportPhotoCardEventsPage: {
      albumTitle: "What was the album?",
      contentTitle: "What was the content?",
      offlineTitle: "What official offline was it?",
      adsTitle: "What was the commercial?",
      defaultTitle: "When was the POCA released?",
      debutedDescription: "{{artistName}}'s debut year was <bold>{{debutedYear}}<bold>.",
      addManually: "Add your own +",
      eventDialogTitle: "Please enter an album name.",
      eventDialogInputPlaceholder: "Album, Event name..",
      albumDescription: "{{description}} #{{volume}}",
    },
    ReportPhotoCardSelectArtistPage: {
      title: "Please select the artist\nto report the photo card.",
    },
    ReportPhotoCardSelectMemberPage: {
      title: "Which member's photo card is it?",
    },
    ReportPhotoCardUploadPhotoPage: {
      title: "Upload your own POCA!",
      description: "As more POCAs are upload by fans, You'll be able to search for them by photo!",
      disclaimerRowLine1: "Remove toploaders or stickers.",
      disclaimerRowLine2: "Take a photo without light bleed.",
      disclaimerRowLine3: "Fans will review and add it automatically!",
      addPhotoCardButton: "Open Camera / Album",
    },
    ShowTradingProfileBottomDrawer: {
      title: "Want to sell photocards faster?",
      description: "Make your Trade Profile public!",
      openButton: "Go public now",
      skipButton: "Maybe later",
    },
    ReportPhotoCardBox: {
      title: "If you can't find the Poca of {{memberName}} you own",
      description: "You can earn a title 🏆️ by reporting it",
      reportButton: "Add your Poca"
    }
  }
}
