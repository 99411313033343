import React from "react";
import { HStack, Stack, Skeleton } from '@chakra-ui/react';

export default function EmptyPhotoCardEventSkeleton() {
  return (
    <Stack>
      <Skeleton width={"150px"} height="20px" startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="9px"/>
      <HStack marginTop={"14px!important"}>
        <Skeleton height='90px' width="60px" startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />
        <Skeleton height='90px' width="60px" startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />
        <Skeleton height='90px' width="60px" startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />
        <Skeleton height='90px' width="60px" startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />
      </HStack>
      <Skeleton width={"120px"} height="20px" startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="9px" marginTop={"24px!important"}/>
      <HStack marginTop={"14px!important"}>
        <Skeleton height='90px' width="60px" startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)'borderRadius="8px" />
        <Skeleton height='90px' width="60px" startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)'borderRadius="8px" />
        <Skeleton height='90px' width="60px" startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)'borderRadius="8px" />
      </HStack>
    </Stack>
  )
}