import { t } from 'i18next';
import React from 'react';
import styled from 'styled-components';
import LightColors from '../../constants/LightColors';
import { capitalizeFirstLetter } from '../../utils/TextUtil';

const FeedbackProgress = ({ reviewsCount, positiveFeedbackOptions }) => {
  return (
    <div>
      {Object.entries(positiveFeedbackOptions).map(([feedback, count]) => {
        const progress = (count / reviewsCount) * 100;

        return (
          <FeedbackItem key={feedback}>
            <ProgressContainer>
              <ProgressTextContainer>
                <OptionText>{t(`ReviewFeedbackOptions.${capitalizeFirstLetter(feedback)}`)}</OptionText>
                <CountText>{count}</CountText>
              </ProgressTextContainer>
              <LoadingBar width={progress} />
            </ProgressContainer>
          </FeedbackItem>
        );
      })}
    </div>
  );
};

export default FeedbackProgress;

const FeedbackItem = styled.div`
  margin-bottom: 6px;
`;

const ProgressContainer = styled.div`
  width: 100%;
  background-color: ${LightColors.surface};
  height: 44px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
`;

const ProgressTextContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: space-between; /* Align OptionText to left and CountText to right */
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const OptionText = styled.div`
  z-index: 2; // Ensures text appears on top of the progress bar
  font-size: 13px;
  font-weight: 500;
  line-height: 15.51px;
  text-align: right;
  color: ${LightColors.textmainlv2};
`;

const CountText = styled.div`
  z-index: 2; // Ensures text appears on top of the progress bar
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: ${LightColors.onSecondary};
`;

const LoadingBar = styled.div`
  height: 100%;
  background-color: ${LightColors.theirty};
  position: absolute;
  border-radius: 16px;
  top: 0;
  left: 0;
  z-index: 1;
  width: ${(props) => props.width}%;
  transition: width 0.3s ease;
`;
