import React, { useState } from "react";

import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Flex, Image, Text, VStack, useDisclosure } from '@chakra-ui/react';

import { t } from "i18next";

import Page from "../components/Page";
import HomeBottomNav from "../layout/HomeBottomNav";
import PrimaryButton from "../components/buttons/PrimaryButton";
import SecondaryBadge from "../components/badges/SecondayBadge";

import { queryGet } from "../utils/queryClient";
import { refreshScreenSize } from "../utils/UIUtils";
import { getArtistId } from "../utils/etcUtils";

export default function IntroTradingPhotoCardPage() {
  const artistId = getArtistId();
  const navigate = useNavigate();

  const [isOpened, setIsOpened] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: tradingPhotoCards } = useQuery(
    ["tradingPhotoCards", artistId],
    () => queryGet(`/artists/${artistId}/trading_photo_cards`),
    {
      enabled: !!artistId,
      select: (data) => data.tradingPhotoCards,
    },
  );

  refreshScreenSize();

  if(!isOpened){
    setIsOpened(true)
    onOpen()
  }

  return (
    <>
      <Page title={t("introTradingPhotoCardPage.pageName")} showBackButton={false}>
        {
          tradingPhotoCards && tradingPhotoCards.length > 0 ?
          <Flex height={"calc(var(--vh, 1vh) * 100 - 90px)"} direction={"column"} justifyContent={"space-between"} paddingTop={"36px"}>
            <VStack paddingX={"10px"} gap={0}>
              <SecondaryBadge text={t("introTradingPhotoCardPage.waitingCountBadge", {count: 963})}/>
              <Text color={"black"} fontWeight={700} fontSize={"26px"} lineHeight={"31px"} marginTop={"15px!important"}>
                {t("introTradingPhotoCardPage.registeredTitle")}
              </Text>
              <Text marginTop={"28px!important"} paddingX={"18px"} color={"gray"} fontWeight={400} fontSize={"13px"} lineHeight={"16px"} className="text-center whitespace-pre-line">
                {t("introTradingPhotoCardPage.registeredDescription")}
              </Text>
              <Image marginTop={"16px!important"} src="/assets/backgrounds/bg_heart.png" height={"210px"}/>
              <PrimaryButton
                marginTop={"12px!important"}
                onClick={() => navigate("/share_pocapan")}
                text={t("introTradingPhotoCardPage.shareButton")}
                paddingX={"24px"}
                paddingY={"16px"}
                fontWeight={600}
                fontSize={"17px"}
                lineHeight={"20px"}
              />
            </VStack>
            {/* <Text
              className="text-center"
              color={"#382599"}
              marginBottom={"15px"}
              fontWeight={400}
              fontSize={"14px"}
              lineHeight={"17px"}
              textDecorationLine={"underline"}
              marginTop="44px"
              onClick={()=>navigate(`/artists/add_trading_photo_cards`,{state: {showBottomSheet: false}})}>
                {t("introTradingPhotoCardPage.editRegisteredButton")}
            </Text> */}
          </Flex>
           :
           tradingPhotoCards && <Flex height={"calc(var(--vh, 1vh) * 100 - 90px)"} direction={"column"} justifyContent={"space-between"} paddingTop={"36px"}>
            <VStack paddingX={"10px"} gap={0}>
              <SecondaryBadge text={"Comming soon"}/>
              <Text color={"black"} fontWeight={700} fontSize={"26px"} lineHeight={"31px"} marginTop={"15px!important"}>
                {t("introTradingPhotoCardPage.introTitle")}
              </Text>
              <Text color={"gray"} fontWeight={400} fontSize={"13px"} paddingX={"18px"} wordBreak={"keep-all"} lineHeight={"16px"} className="text-center" marginTop={"7px!important"}>
                {t("introTradingPhotoCardPage.introDescription")}
              </Text>
              <Image marginTop={"16px!important"} src="/assets/backgrounds/intro_matching_photoc_cards.png" height={"210px"}/>
              {/* <PrimaryButton
                text={t("introTradingPhotoCardPage.introRegisterButton")}
                paddingX={"24px"}
                paddingY={"16px"}
                marginTop={"12px!important"}
                fontWeight={600}
                fontSize={"17px"}
                lineHeight={"20px"}
                onClick={()=>navigate(`/artists/add_trading_photo_cards`,{state: {showBottomSheet: true}})}
              /> */}
            </VStack>
            <Box borderWidth={"1px"} borderColor={"#E7E7E7"} borderRadius={"16px"} padding={"20px"}>
              <VStack className="text-center">
                <Text paddingX={"18px"} color={"gray"} fontWeight={400} fontSize={"13px"} lineHeight={"16px"} className="text-center whitespace-pre-line">
                  {t("introTradingPhotoCardPage.introMessage")}
                </Text>
                <SecondaryBadge text={t("introTradingPhotoCardPage.waitingCountBadge", {count: 963})}></SecondaryBadge>
              </VStack>
            </Box>
          </Flex>
          }
      </Page>
      {/* <HomeBottomNav
        tabIndex={1}
        artistId={artistId}
      /> */}
    </>
  )
}
