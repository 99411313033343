import { TabPanel } from "@chakra-ui/react";
import { t } from "i18next";
import { first, isEmpty, isNull, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";
import CommonDialog from "../../components/alerts/CommonDialog";
import CommonVerticalButtonDialog from "../../components/alerts/CommonVerticalButtonDialog";
import ChatRoomPhotoCardResponse from "../../components/api/model/ChatRoomPhotoCardResponse";
import updateTradingPhotoCards from "../../components/api/mutations/updateTradingPhotoCards";
import useExchangingPhotoCardsQuery from "../../components/api/queries/useExchagingPhotoCardsQuery";
import EmptyCase from "../../components/errors/EmptyCase";
import PrimaryInput from "../../components/input/PrimaryInput";
import TradingPhotoCardBoxByPhotoCard from "../../components/photocard/TradingPhotoCardBoxByPhotoCard";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import LightColors from "../../constants/LightColors";
import { extractPrice } from "../../utils/TextUtil";
import { navigateReviewPath } from "../../utils/TradingHelper";
import BoxTabs from "./BoxTabs";

const CounterInfoWrapper = styled.div`
  padding-top: 18px;
  padding-bottom: 12px;
`

const Counter = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`

const PhotoCardBoxWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`

const PhotoCardBox = styled.div`
  border: 1px solid ${LightColors.separatorlv1};
  border-radius: 12px;
  padding: 10px 10px 16px 16px;
  align-items: center;
`

const TabDesciprtion = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding-bottom: 11px;
  color: ${LightColors.textsublv1};
`

export default function ExchangingPhotoCardsTab() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();

  const currentQueryParams = new URLSearchParams(location.search);
  const [price, setPrice] = useState();
  const [tradingStatus, setTradingStatus] = useState(currentQueryParams.get("tradingStatus") || "ongoing");
  const [selectedTradingPhotoCard, setSelectedTradingPhotoCard] = useState();

  const [paginatedPhotoCards, setPaginatedPhotoCards] = useState();
  const [meta, setMeta] = useState();

  const [isOpenSaleDialog, setIsOpenSaleDialog] = useState(false);
  const [isOpenCompletedDialog, setIsOpenCompletedDialog] = useState(false);

  const { data, isFetching, fetchNextPage, hasNextPage } = useExchangingPhotoCardsQuery(tradingStatus)

  const handlePaginatedPhotoCards = (paginatedData) => {
    const photoCards = paginatedData.pages?.flatMap((page) =>
      page.photoCards.map((photoCard) => new ChatRoomPhotoCardResponse(photoCard))
    )
    setPaginatedPhotoCards(photoCards);
  }

  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedPhotoCards(data);
      setMeta(first(data.pages)?.meta);
    }
  }, [data]);

  const onSubTabSelected = (index) => {
    const tradingStatus = index === 0 ? "ongoing" : "completed"
    setTradingStatus(tradingStatus);
  }

  const onClickCompleted = (tradingPhotoCard) => {
    setSelectedTradingPhotoCard(tradingPhotoCard);
    setIsOpenCompletedDialog(true);
  }

  const onClickToggleCategory = (tradingPhotoCard) => {
    setSelectedTradingPhotoCard(tradingPhotoCard);
    setIsOpenSaleDialog(true);
  }

  const closeSaleDialog = () => {
    setPrice(null);
    setIsOpenSaleDialog(false);
  }

  const invalidateItems = () => {
    queryClient.invalidateQueries(["ExchangingPhotoCards"]);
    queryClient.invalidateQueries(["SellingPhotoCards"]);
  }

  const handleUpdateAPIResponse = async (response) => {
    if(response.ok) {
      setIsOpenCompletedDialog(false);
      invalidateItems();
      const responseBody = await response.json();
      return responseBody.tradingPhotoCard;
    } else {
      return null;
    }
  }

  const onChangedToSale = async () => {
    if(isEmpty(selectedTradingPhotoCard)) return;

    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: selectedTradingPhotoCard.tradingPhotoCardId,
      price: (isUndefined(price) || isNull(price)) ? price : extractPrice(price),
      category: "sale",
    });
    const responseBody = await handleUpdateAPIResponse(response)
    if (responseBody) {
      closeSaleDialog();
    }
  }

  const onChangedToCompleted = async () => {
    if(isEmpty(selectedTradingPhotoCard)) return;

    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: selectedTradingPhotoCard.tradingPhotoCardId,
      price: selectedTradingPhotoCard.price,
      status: "sold_out",
    });
    const responseBody = await handleUpdateAPIResponse(response)
    if (responseBody && responseBody.chatRoomsCount > 0) {
      navigate(`/trading_photo_cards/${responseBody.tradingPhotoCardId}/select_chat_rooms`);
    }
  }

  const navigateReview = (tradingPhotoCard) => {
    navigate(navigateReviewPath(tradingPhotoCard));
  }

  return(
    <TabPanel padding={0}>
      <TabDesciprtion>{t("myPhotoCards.exchangingPhotoCardsTabReviewNotYet")}</TabDesciprtion>
      <BoxTabs
        tabLabels={
          [
            t("myPhotoCards.tradingStatusSelectorOngoing"),
            t("myPhotoCards.tradingStatusSelectorCompleted")
          ]
        }
        onTabSelected={(index) => onSubTabSelected(index)}
      />
      {
        isUndefined(paginatedPhotoCards) ?
        <>
          <CounterInfoWrapper>
            <Counter>
              {t("myPhotoCards.totalCount", {count: "-"})}
            </Counter>
          </CounterInfoWrapper>
          <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={"109px"}/>
        </> :
          isEmpty(paginatedPhotoCards) ?
          <EmptyCase
            emoji={"😳"}
            title={t("myPhotoCards.exchangingPhotoCardsTabEmptyCaseTitle")}
            buttonText={t("myPhotoCards.exchangingPhotoCardsTabEmptyCaseButton")}
            onClickButton={() => navigate("/artists/add_trading_photo_cards")}
          /> :
          <>
            <CounterInfoWrapper>
              <Counter>
                {t("myPhotoCards.totalCount", {count: meta?.pagination?.totalCount})}
              </Counter>
            </CounterInfoWrapper>
            <Virtuoso
              useWindowScroll
              style={{ height: '100%' }}
              endReached={loadMore}
              overscan={480}
              data={paginatedPhotoCards}
              itemContent={(_, e) => {
                return (
                  <PhotoCardBoxWrapper key={e.photoCardId}>
                    <PhotoCardBox>
                      <TradingPhotoCardBoxByPhotoCard
                        photoCard={e}
                        showChatRoomsCount={true}
                        onClickBox={() => navigate(`/trading_photo_cards/${e.tradingPhotoCardId}/my_chat_rooms`)}
                      />
                      {/* <PhotoCardBoxButton
                        tradingPhotoCard={e}
                        tradingStatus={tradingStatus}
                        onClickCompleted={onClickCompleted}
                        onClickToggleCategory={onClickToggleCategory}
                        onClickNavigateReview={navigateReview}
                      /> */}
                    </PhotoCardBox>
                  </PhotoCardBoxWrapper>
                )
              }}
            />
          </>
      }

      {/* Toggle To SaleCategory Dialog */}
      <CommonDialog
        isOpen={isOpenSaleDialog}
        onClose={() => closeSaleDialog()}
        title={t("myPhotoCards.chatRoomSaleDialogTitle")}
        description={t("myPhotoCards.chatRoomSaleDialogDescription")}
        children={
          <PrimaryInput
            defaultValue={null}
            placeholder={t("myPhotoCards.chatRoomSaleDialogInputPlaceholder")}
            onChangeText={(e) => setPrice(e.target.value)}
            style={{marginTop: "16px"}}
          />
        }
        onClickDialogButton={onChangedToSale}
      />

      {/* Delete TradingPhotoCard Dialog */}
      <CommonVerticalButtonDialog
        isOpen={isOpenCompletedDialog}
        onClose={() => setIsOpenCompletedDialog(false)}
        upButtonText={t("myPhotoCards.doneAndSendReview")}
        downButtonText={t("no")}
        title={t("myPhotoCards.chatRoomCompleteDialogTitle")}
        onClickDialogUpButton={onChangedToCompleted}
        onClickDialogDownButton={() => setIsOpenCompletedDialog(false)}
      />
    </TabPanel>
  )
}
