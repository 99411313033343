import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function usePostDetailQuery(postId, isQuotedPost=false) {
  const queryParams = new URLSearchParams();

  if(isQuotedPost) {
    queryParams.append("is_quoted_post", isQuotedPost)
  }

  const { data: post } = useQuery(
    ["PostDetail", postId, queryParams.toString()],
    () => queryGet(`/api/v1/posts/${postId}?${queryParams.toString()}`),{
      select: (data) => data.post,
      enabled: !!postId,
    }
  );
  return post;
}
