import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function AddPhotoCard({artistId, memberId, eventId, category, filename, dataUrl}) {
  return fetch(baseUrl() + `/api/v1/photo_cards`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      photo_card: {
        artist_id: artistId,
        member_id: memberId,
        event_id: eventId,
        category: category,
        filename: filename,
        data_url: dataUrl,
      },
    }),
  });
}
