import baseUrl from "../../../../utils/baseUrl"
import getToken from "../../../../utils/getToken"

export default async function updateCollectedPhotoCard(photoCardId, collectedCount) {
  function updateCollectedCount(photoCardId, collectedCount) {
    return fetch(baseUrl() + `/photo_cards/${photoCardId}/update_collected_count`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      body: JSON.stringify({
        collectedCount: collectedCount,
      }),
    }).then((data) => data.json())
  }

  return updateCollectedCount(photoCardId, collectedCount);
}
