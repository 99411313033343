import { Box } from "@chakra-ui/react";

export default function RoundedBadge({bgColor, textColor, text, ...props}) {
  return (
    <Box
      bgColor={bgColor}
      textColor={textColor}
      paddingX={"7px"}
      paddingY={"4px"}
      className="text-center"
      borderRadius={"30px"}
      fontWeight={600}
      fontSize={"11px"}
      lineHeight={"13px"}
      {...props}
    >
      {text}
    </Box>
  )
}