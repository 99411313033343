const SupportedLocales = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "ko",
    name: "한국어",
  },
  {
    code: "zh-TW",
    name: "繁體中文"
  },
  {
    code: "zh-CN",
    name: "简体中文"
  },
  // {
  //   code: "jp",
  //   name: "日本語",
  // },
]

export default SupportedLocales;
