import React from "react";

import { isEmpty, isUndefined } from "lodash";
import styled from "styled-components";
import GridSkeletons from "../skeletons/GridSkeletons";
import MatchedTradingPhotoCard from "./MatchedTradingPhotoCard";
import { Flex } from "@chakra-ui/react";

const TradingPhotoCardsWrapper = styled.div`
  padding-top: 12px;
  padding-right: 24px;
  padding-left: 16px;
  margin-left: -24px;
  margin-right: -24px;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const SkeletonWrapper = styled.div`
  padding-left: 8px;
`

const MatchedTradingPhotoCardWrapper = styled.div`
  display: inline-flex;
  width: 156px;
  padding-left: 8px;
`

export default function MatchedTradingPhotoCardsHorizontalList({matchedTradingPhotoCards}) {
  return (
    <TradingPhotoCardsWrapper>
      {
        isEmpty(matchedTradingPhotoCards) ?
          <SkeletonWrapper>
            <GridSkeletons gridSize={2} skeletonSize={2} skeletonHeight={'96px'} showText={true}/>
          </SkeletonWrapper>
        :
          matchedTradingPhotoCards.map((m) => {
            return (
              <MatchedTradingPhotoCardWrapper key={m.matchedTradingPhotoCardId}>
                <MatchedTradingPhotoCard matchedTradingPhotoCard={m}/>
              </MatchedTradingPhotoCardWrapper>
            )
          })
      }
      </TradingPhotoCardsWrapper>
  )
}
