import React from "react";

import { AbsoluteCenter, AlertDialog, AlertDialogContent, AlertDialogOverlay, Spinner } from '@chakra-ui/react';

import LightColors from "../../constants/LightColors";

export default function ProgressSpinnerDialog({isOpen, onClose}) {
  return (
  <AlertDialog
    isOpen={isOpen}
    onClose={onClose}
    isCentered
    >
      <AlertDialogOverlay style={{ zIndex: 2147483647 }} >
        <AlertDialogContent>
          <AbsoluteCenter>
            <Spinner size='md' color={LightColors.primary}/>
          </AbsoluteCenter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
