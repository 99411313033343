import { Text } from '@chakra-ui/react';
import React from "react";
import Sheet from 'react-modal-sheet';

import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";

import { t } from 'i18next';
import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { queryGet } from "../../utils/queryClient";
import { UserProfileRow } from "../tradings/UserProfileRow";

const containerStyle = {
  paddingLeft: "calc(24px + env(safe-area-inset-left))",
  paddingRight: "calc(24px + env(safe-area-inset-right))",
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px"
}

const headerStyle = {
  paddingTop: "24px",
  paddingBottom: "12px",
}

const subTitleStyle = {
  fontSize: "15px",
  fontWeight: 400,
  marginInlineStart: "0px!important",
}

const titleStyle = {
  fontSize: "18px",
  fontWeight: 700,
  marginBottom: "8px"
}

const dividerWrapper = {
  paddingTop: "16px",
  paddingBottom: "16px"
}

export default function ShowUserProfilesBottomSheet(
  {
    artistId,
    isShowBottomSheet,
    setIsShowBottomSheet,
    saleTradingPhotoCards,
    exchangeTradingPhotoCards
  }
) {
  const { data: artist } = useQuery(
    ["artist", artistId],
    () => queryGet(`/artists/${artistId}`),{
      select: (data) => data?.artist,
    }
  );

  return(
    <>
    <Sheet
      isOpen={isShowBottomSheet}
      disableDrag={true}
      onClose={() => setIsShowBottomSheet(false)}
      detent="content-height"
      style={{ zIndex: 214748364 }}
      springConfig={SPRING_CONFIG}
    >
      <Sheet.Container style={containerStyle}>
        <Sheet.Content>
          {
            !isEmpty(exchangeTradingPhotoCards) && <>
              <div style={headerStyle}>
                <Text style={titleStyle}>
                  {t("tradingPhotoCardPage.showUserProfilesBottomSheetTitle", {fandom: artist?.fandomName || "-"})}
                </Text>
              </div>
              {
                exchangeTradingPhotoCards.map((tradingPhotoCard, index) => {
                  return (
                    <div key={tradingPhotoCard.tradingPhotoCardId}>
                      {index > 0 && <div style={dividerWrapper}><hr/></div>}
                      <UserProfileRow
                        key={tradingPhotoCard.tradingPhotoCardId}
                        tradingPhotoCard={tradingPhotoCard}
                        userProfile={tradingPhotoCard.userProfile}
                      />
                    </div>
                  )
                })
              }
            </>
          }
          {
            !isEmpty(saleTradingPhotoCards) && <>
              <div style={headerStyle} >
                <div style={subTitleStyle}>
                  {t("tradingPhotoCardPage.notAvailableExchangeTradingPhotoCards")}
                </div>
                <Text style={titleStyle}>
                  {t("tradingPhotoCardPage.offetSalesTradingPhotoCardsRow1", {fandom: artist?.fandomName})}
                  <span style={{color: "#5C3DFA"}}>{t("tradingPhotoCardPage.offetSalesTradingPhotoCardsRow2")}</span>
                  {t("tradingPhotoCardPage.offetSalesTradingPhotoCardsRow3")}
                </Text>
              </div>
              {
                saleTradingPhotoCards.map((tradingPhotoCard, index) => {
                  return (
                    <div key={tradingPhotoCard.tradingPhotoCardId}>
                      {index > 0 && <div style={dividerWrapper}><hr/></div>}
                      <UserProfileRow
                        key={tradingPhotoCard.tradingPhotoCardId}
                        tradingPhotoCard={tradingPhotoCard}
                        userProfile={tradingPhotoCard.userProfile}
                      />
                    </div>
                  )
                })
              }
            </>
          }
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onClick={() => setIsShowBottomSheet(false)} />
    </Sheet>
    </>
  )
}
