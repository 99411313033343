import React from "react";

import { Avatar, HStack, Image, Skeleton, SkeletonText, Text, VStack } from '@chakra-ui/react';
import { t } from "i18next";
import { isEmpty, isNull } from "lodash";
import removedEmojiEventName, { ellipsis } from "../../utils/TextUtil";
import { openNewTab } from "../../utils/UIUtils";
import WhiteBadge from "../badges/WhiteBadge";
import ProfileLineButton from "../buttons/ProfileLineButton";
import VerifiedIcon from "../icons/VerifiedIcon";
import { isCompletedTrading } from "../../utils/TradingHelper";

const imageStyle = {
  width: "40px",
  height: "62px",
  margin: "0px!important",
  borderRadius: "6px"
}

const eventNameStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: 500,
  color: "#222"
}

const pocaNameStyle = {
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: 500,
  color: "#666",
  noOfLines: 1,
  marginBottom: "4px",
}

const profileImageSize={
  width: "16px",
  height: "16px",
}

const priceTextStyle = {
  fontSize: "17px",
  fontWeight: 600,
  color: "#382599"
}

const badgeStyle = {
  padding: "3px 6px",
  fontSize: "11px",
  fontWeight: 600,
}

export default function TradingPhotoCardInfo({tradingPhotoCard, showProfile=false, showPrice=false, halfType=false, onSelectPrice}) {
  const navigatePhotoCardDetailPage = (photoCardId) => {
    const currentPath = window.location.pathname;
    const targetPath = `/photo_cards/${photoCardId}`;

    if(currentPath !== targetPath) {
      // TODO : Please be careful to open newtab infinitely
      openNewTab(targetPath);
    }
  }

  const navigateUserTradingProfile = (userProfile) => {
    const currentPath = window.location.pathname;
    const userProfilePath = `/trading/user_profile/${userProfile?.userProfileId}`
    if (currentPath === userProfilePath) return;

    if(!isEmpty(userProfile?.userProfileId)) {
      openNewTab(userProfilePath);
    }
  }

  const onSelectedPrice = (price) => {
    if (onSelectPrice) {
      onSelectPrice(price)
    }
  }

  return (tradingPhotoCard ?
    <div className="flex">
      <div className="flex-none">
        <Image
          style={imageStyle}
          src={tradingPhotoCard.photoCard.pictureUrl}
          loading={"lazy"}
          onClick={() => navigatePhotoCardDetailPage(tradingPhotoCard.photoCard.photoCardId)}
        />
      </div>
      <div className="grow" style={{paddingLeft: "10px"}}>
        <VStack alignItems={"start"} spacing={"5px"}>
          <Text style={eventNameStyle} noOfLines={1} className="overflow-hidden">
            {removedEmojiEventName(tradingPhotoCard.eventName || tradingPhotoCard.photoCard.event?.name)}
          </Text>
          <Text style={pocaNameStyle} noOfLines={1} className="overflow-hidden">
            {ellipsis(tradingPhotoCard.photoCardId || tradingPhotoCard.photoCard.event?.description, halfType ? 10 : 20)}
          </Text>
          <div style={{marginTop: "4px"}}>
            <WhiteBadge text={tradingPhotoCard.memberName || tradingPhotoCard.photoCard.member.name} style={badgeStyle}/>
          </div>
        </VStack>
      </div>
      {
        (showProfile || showPrice) &&
        <VStack align={"end"} alignSelf={(showProfile && showPrice) ? "" : "center"}>
          {
            showProfile &&
            <div className="flex-none" style={{paddingLeft: "6px", maxWidth: "135px"}} onClick={() => navigateUserTradingProfile(tradingPhotoCard.userProfile)}>
              <ProfileLineButton
                leftIcon={
                  tradingPhotoCard.userProfile.pictureUrl ?
                  <Image src={tradingPhotoCard.userProfile.pictureUrl} style={profileImageSize} objectFit={"cover"} borderRadius={"full"}/> :
                  <Avatar style={profileImageSize}/>
                }
                rightIcon={tradingPhotoCard.userProfile.identified ? <div style={{minWidth: "14px"}}><VerifiedIcon width={"14px"} height={"14px"} /></div> : <></>}
                text={tradingPhotoCard.userProfile.nickname}
              />
            </div>
          }
          {
            showPrice &&
            <Text style={priceTextStyle} onClick={() => onSelectedPrice(tradingPhotoCard.price)} decoration={onSelectPrice && !isCompletedTrading(tradingPhotoCard) ? "underline" : null}>
              {isNull(tradingPhotoCard.price) ? t("tradingPhotoCardPage.offerPriceWithEmoji") : tradingPhotoCard.price}
            </Text>
          }
        </VStack>
      }
    </div> :
    <HStack align={"flex-start"}>
      <Skeleton width={"40px"} height={"62px"} startColor='rgba(0, 0, 0, 0.04)' endColor='rgba(0, 0, 0, 0.02)'/>
      <VStack paddingTop={"2px"}>
        <SkeletonText noOfLines={2} width={"75px"} startColor='rgba(0, 0, 0, 0.04)' endColor='rgba(0, 0, 0, 0.02)'/>
      </VStack>
    </HStack>
  )
}
