import ReactGA from "react-ga4";

export function init() {
  if(enabledReactGA()) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      debug: process.env.REACT_APP_DEPLOY_ENV === "production",
    });
  } else {
    console.log("Initialized ReactGA")
  }
}

export function setPageview(page) {
  if(enabledReactGA()) {
    ReactGA.send({ hitType: "pageview", page: page });
  } else {
    console.log(page)
  }
}

export function setEvent(event) {
  if(enabledReactGA()) {
    ReactGA.event(event)
  } else {
    console.log(event)
  }
}

export function enabledReactGA() {
  return process.env.REACT_APP_GA_TRACKING_ID && process.env.REACT_APP_DEPLOY_ENV === "production"
}