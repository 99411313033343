import baseUrl from "../../../../utils/baseUrl"
import getToken from "../../../../utils/getToken"

export default async function updateWishedPhotoCard(isWished, artistId, memberId, photoCardList) {
  async function wishPhotoCards(artistId, memberId, photoCardList) {
    return fetch(baseUrl() + `/photo_cards/wished`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      body: JSON.stringify({
        artistId: artistId,
        memberId: memberId,
        photoCardIds: photoCardList,
      }),
    }).then((data) => data.json());
  }

  async function unwishPhotoCards(artistId, memberId, photoCardList) {
    return fetch(baseUrl() + `/photo_cards/unwished`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      body: JSON.stringify({
        artistId: artistId,
        memberId: memberId,
        photoCardIds: photoCardList,
      }),
    }).then((data) => data.json());
  }

  if(isWished) {
    return unwishPhotoCards(artistId, memberId, photoCardList);
  } else {
    return wishPhotoCards(artistId, memberId, photoCardList);
  }
}
