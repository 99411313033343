import { Image, Spinner } from "@chakra-ui/react";
import { isNativeIOS } from "../../utils/NativeUtil";
import ShopProductFrame from "./ShopProductFrame";
import styled from 'styled-components';
import { useState } from "react";

const shadowStyle = {
  borderRadius: "24px",
  boxShadow: "0px 0px 22px 10px rgba(0,0,0,0.02),0px 5px 11px 0px rgba(0,0,0,0.04)"
};

export default function ShopProduct1() {
  const [isLoading, setIsLoading] = useState(false);

  const openUrl = (url) => {
    window.location.href = url;
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 5000); // 5초를 밀리초로 변환하여 지정
  }
  return <>
  <ShopProductFrame
    buttonName="판매신청"
    onClick={() => openUrl("https://forms.gle/XMQKtUUU1UMzw9sm6")}
  >
    <div style={{ 
      paddingBottom: "calc(30px + env(safe-area-inset-bottom))",
      paddingLeft: "24px",
      paddingRight: "24px",
    }}>
      <Image src="/assets/shop/p1/header.png" marginBottom={"22px"}/>
      <Image src="/assets/shop/p1/product_btn.png" borderRadius="38px!important" marginBottom={"22px"} style={shadowStyle} onClick={(_) => openUrl("https://pumasipan.imweb.me/shop_view/?idx=1")}/>
      <Image src="/assets/shop/p1/apply_btn.png" marginBottom={"22px"} style={shadowStyle} onClick={(_) => openUrl("https://forms.gle/XMQKtUUU1UMzw9sm6")}/>
      <Image src="/assets/shop/p1/footer.png" />
    </div>
  </ShopProductFrame>
  {
    isLoading &&
    <Wrapper>
      <Overlay>
        <Spinner size='sm' color="primary.500"/>
      </Overlay>
    </Wrapper>
  }
  </>
}

const Wrapper = styled.div`
  position: relative;
  z-index: 2147483647;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483647;
`;