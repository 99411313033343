import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

const DEFAULT_PAGE = 10;

export default function useExchangingPhotoCardsQuery(status, perPage) {
  async function fetchExchangingPhotoCardsQuery({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage || DEFAULT_PAGE,
      page: pageParam,
      status: status,
    });

    const url = `/api/v1/photo_cards/exchanging?${queryParams.toString()}`;
    try {
      const data = await queryGet(url);
      return data;
    } catch (error) {
      throw new Error("Error fetching paginated data.");
    }
  }

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["ExchangingPhotoCards", status],
    fetchExchangingPhotoCardsQuery,
    {
      getNextPageParam: (lastPage) => {
        if (!!lastPage?.meta?.pagination?.nextPage) {
          return lastPage?.meta?.pagination?.nextPage;
        } else {
          return undefined
        }
      }
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  };
}
