import React, { useEffect, useRef, useState } from "react";

import { Badge, Box, Divider, Flex, HStack, Image, ScaleFade, SlideFade, Text, VStack } from '@chakra-ui/react';
import { format } from "date-fns";
import ko from "date-fns/locale/ko";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import LightColors from "../../constants/LightColors";
import { ReactComponent as LikeIcon } from "../../icons/like.line.svg";
import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg";
import { ReactComponent as MegaphoneFillIcon } from "../../icons/megaphone.fill.svg";
import { ReactComponent as MegaphoneIcon } from "../../icons/megaphone.svg";
import RepostBottomDrawer from "../drawer/RepostBottomDrawer";
import toggleLikedPost from "../api/mutations/LikePost";
import ProfileAvatar from "../thumbnails/ProfileAvatar";
import PostImagesBox from "./PostImagesBox";
import QuotedPostPreview from "./QuotedPostPreview";
import { t } from "i18next";

const authorNameStyle = {
  fontWeight: 600,
  color: LightColors.textmainlv2,
  lineHeight: "17px",
  fontSize: "14px"
}

const authoredAtStyle = {
  paddingTop: "3px",
  fontWeight: 400,
  color: LightColors.textmainlv4,
  lineHeight: "14px",
  fontSize: "12px"
}

const titleStyle = {
  fontWeight: 600,
  color: LightColors.textmainlv2,
  fontSize: "18px",
  lineHeight: "24px",
  paddingTop: "3px",
  paddingBottom: "3px",
  userSelect: "auto",
}

const contentStyle = {
  fontWeight: 500,
  color: LightColors.textmainlv2,
  fontSize: "16px",
  lineHeight: "22px",
  paddingTop: "3px",
  paddingBottom: "3px",
  userSelect: "auto",
}

const eventNameStyle = {
  fontWeight: 600,
  color: "#222",
  fontSize: "15px",
  lineHeight: "18px",
  noOfLines: 1,
}

const pocaNameStyle = {
  fontWeight: 500,
  color: "#666",
  fontSize: "14px",
  lineHeight: "17px",
  noOfLines: 1,
}

const memberNameBadgeStyle = {
  fontWeight: 600,
  fontSize: "13px",
  lineHeight: "16px",
  borderRadius: "4px",
  width: "fit-content",
  backgroundColor: "rgba(0, 0, 0, 0.1)"
}
const ImageBoxWrpper = styled.div`
  padding-top: 3px;
  padding-bottom: 3px;
`

const PhotoCardWrpper = styled.div`
  padding-top: 3px;
  padding-bottom: 3px;
`

const HolographyBox = styled.div`
  margin-left: 12px;
  border: 1px solid transparent;
  border-radius: 32px;
  background-image: linear-gradient(#fff, #fff), linear-gradient(102deg, #860EFF 0%, #2DE7D1 44%, #C855FF 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: 7px 12px 5px 12px;
`

const FandomText = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  background: linear-gradient(102deg, #860EFF 0%, #2DE7D1 44%, #C855FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`

const RepostBox = styled.div`
  border-width: 1px;
  border-color: ${LightColors.separatorlv2};
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
  border-radius: 12px;
`

const DividerWrapper = styled.div`
  padding-bottom: 16px;
  margin-left: -24px!important;
  margin-right: -24px!important;
`

const PostStatText = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${LightColors.textmainlv4}
`

const PostStatCount = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${LightColors.textmainlv3}
`

const DotWrapper = styled.p`
  color: ${LightColors.textsublv3}
`

export default function Post({post, reposted=false}) {
  const focusRef = useRef();
  const navigate = useNavigate()

  const [postState, setPostState] = useState();
  const [isOpenRepostBottomSheet, setIsOpenRepostBottomSheet] = useState();

  useEffect(()=>{ setPostState(post) }, [post]);

  const navigatePostDetails = () => {
    const pathName = `/posts/${postState.postId}`
    if(pathName !== window.location.pathname){
      navigate(pathName)
    }
  }

  const onClickLiked = async (postId) => {
    const response = await toggleLikedPost(postId);

    if (response && response.ok) {
      setPostState(prevPost => ({
        ...prevPost,
        isLiked: !prevPost.isLiked,
        likesCount: prevPost.isLiked ? (prevPost.likesCount || 0) - 1 : (prevPost.likesCount || 0) + 1,
      }))
    }
  }

  const onClickReposted = async (isMegaphoned) => {
    setPostState(prevPost => ({
      ...prevPost,
      isMegaphoned: isMegaphoned,
      repostsCount: isMegaphoned ? (prevPost.repostsCount || 0) - 1 : (prevPost.repostsCount || 0) + 1,
    }))
  }

  return (postState &&
    <Flex key={postState.postId} ref={focusRef}>
      <VStack flexGrow={1} spacing={0} align={"left"} paddingLeft={"0px"}>
        <Flex paddingBottom={"9px"} direction={"row"} alignItems={"center"}>
          <Flex flexGrow={1}>
            <ProfileAvatar
              profileImageUrl={postState.anonymous ? "/assets/icons/ghost_profile_icon.png": postState.authorThumbnailUrl}
              widthAndHeight={"34px"}
              onClick={() => postState?.userProfileId && navigate(`/feed/user_profile/${postState?.userProfileId}`)}
            />
            <VStack align={"start"} paddingLeft={"10px"} spacing={0} onClick={() => navigatePostDetails()}>
              <Text style={authorNameStyle}>{postState.authorName}</Text>
              <Text style={authoredAtStyle}>{format(new Date(postState.authoredAt),'aaa HH:mm · yyyy.MM.dd', {locale: ko})}</Text>
            </VStack>
          </Flex>
          {
            postState.fandomName &&
            <Flex flexShrink={1}>
              <HolographyBox>
                <FandomText>{`Only ${postState.fandomName}`}</FandomText>
              </HolographyBox>
            </Flex>
          }
        </Flex>

        <div onClick={() => navigatePostDetails()}>
          { postState.title && <Text key={"title"} style={titleStyle}>{postState.title}</Text> }
          { postState.content && <Text key={"content"} whiteSpace={"pre-wrap"} style={contentStyle}>{postState.content}</Text> }
        </div>

        <div>
          {
            !isEmpty(postState.pictureUrls) &&
            <ImageBoxWrpper>
              <PostImagesBox images={postState.pictureUrls} focusRef={focusRef}/>
            </ImageBoxWrpper>
          }

          {
            postState.photoCard &&
              <PhotoCardWrpper>
                <HStack
                  padding={"16px"}
                  borderRadius={"12px"}
                  borderWidth={"1px"}
                  borderColor={LightColors.separatorlv2}
                  onClick={() => navigate(`/photo_cards/${postState.photoCard.photoCardId}`)}
                >
                  <Image width={"40px"} height={"68px"} src={postState.photoCard.pictureUrl}/>
                  <VStack align={"left"} spacing={0}>
                    <Text style={eventNameStyle} noOfLines={1}>{postState.photoCard.eventName}</Text>
                    <Text style={pocaNameStyle}>{postState.photoCard.pocaname || postState.photoCard.name}</Text>
                    <Badge marginTop={"8px!important"} padding={"4px 8px"} style={memberNameBadgeStyle}>{postState.photoCard.memberName}</Badge>
                  </VStack>
                </HStack>
              </PhotoCardWrpper>
          }
        </div>

        <div>
          {
            (postState.repostSource && postState.postType === "quotation") &&
            <Box borderColor={LightColors.separatorlv2} marginY={"3px"}>
              <RepostBox>
                <QuotedPostPreview post={postState.repostSource} reposted={true} disableActions={true}/>
              </RepostBox>
            </Box>
          }
        </div>

        {/* <div>
          {
            postState.commentSource &&
            <Box borderWidth={"1px"} borderColor={LightColors.separatorlv2} padding={"12px"} borderRadius={"12px"}>
              <Comment comment={postState.commentSource} isPosted={true}/>
            </Box>
          }
        </div> */}

        <HStack paddingTop={"10px"} paddingBottom={"16px"} spacing={"4px"} marginLeft={"-3px!important"}>
          <div onClick={() => onClickLiked(postState.postId)}>
          {
            <>
            {
              postState.isLiked &&
              <ScaleFade initialScale={0.5} in={postState.isLiked}>
                <LikeShapeIcon width={"30px"} height={"30px"} fill={LightColors.warning}/>
              </ScaleFade>
            }
            {
              !postState.isLiked &&
              <ScaleFade initialScale={0.9} in={!postState.isLiked}>
                <LikeIcon width={"30px"} height={"30px"} fill={LightColors.textmainlv3}/>
              </ScaleFade>
            }
            </>
          }
          </div>
          <div onClick={() => setIsOpenRepostBottomSheet(true)}>
          {
            (postState.isMegaphoned || postState.isQuoted) ?
            <MegaphoneFillIcon width={"30px"} height={"30px"} fill={LightColors.primary}/> :
            <MegaphoneIcon width={"30px"} height={"30px"} fill={LightColors.textmainlv3}/>
          }
          </div>
        </HStack>

        <DividerWrapper>
          <Divider color={LightColors.separatorlv1}/>
        </DividerWrapper>
        {
          <HStack spacing={"6px"}>
            <HStack spacing={"3px"}>
              <PostStatText>{t("comment")}</PostStatText>
              <PostStatCount>{postState.commentsCount}</PostStatCount>
            </HStack>
            <DotWrapper>{"·"}</DotWrapper>
            <HStack spacing={"3px"} onClick={() => navigate(`/posts/${postState.postId}/reposts`)}>
              <PostStatText>{t("megaphone")}</PostStatText>
              <PostStatCount>{postState.repostsCount}</PostStatCount>
            </HStack>
            <DotWrapper>{"·"}</DotWrapper>
            <HStack spacing={"3px"}>
              <PostStatText>{t("like")}</PostStatText>
              <PostStatCount>{postState.likesCount}</PostStatCount>
            </HStack>
          </HStack>
        }
      </VStack>
      {
        isOpenRepostBottomSheet &&
        <RepostBottomDrawer
          focusRef={focusRef}
          post={postState}
          isOpen={isOpenRepostBottomSheet}
          onClose={() => setIsOpenRepostBottomSheet(false)}
          onConfirmedRepost={() => onClickReposted(true)}
          onCanceledRepost={() => onClickReposted(false)}
        />
      }
    </Flex>
  )
}
