import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function ConfirmPhotoCard(photoCardId) {
  return fetch(baseUrl() + `/api/v1/photo_cards/${photoCardId}/confirmed`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
  })
}
