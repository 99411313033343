
import React, { useState } from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';

import { Image, Text, VStack, useToast } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useNavigate, useParams } from "react-router-dom";
import styled from 'styled-components';
import Page from '../../components/Page';
import ToastMessageBox from '../../components/alerts/ToastMessageBox';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import PrimaryInput from '../../components/input/PrimaryInput';
import initializePortOne from "../../utils/PortOne";
import Footer from './Footer';

const BoxWrapper = styled.div`
  border: 1px solid;
  border-radius: 12px;
  border-image-source: linear-gradient(134.41deg, rgba(255, 255, 255, 0.6) 5.69%, rgba(255, 255, 255, 0) 95.22%);
  padding: 16px 20px;
  margin-bottom: 8px;
  box-shadow: 0px 3px 20px 0px #0000000A, 0px 0px 8px 0px #00000005;
`

const ImageWrapper = styled.div`
  padding: 16px 0px;
`

export default function OrderDetailPage() {
  const params = useParams();
  const orderId = params["order_id"];
  const navigate = useNavigate();
  const toast = useToast();

  const [fullAddress, setFullAddress] = useState();
  const [postCode, setPostCode] = useState();
  const [detailAddress, setDetailAddress] = useState();
  const [name, setName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  const open = useDaumPostcodePopup("https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");

  const handleComplete = (data) => {
    setFullAddress(data.address);
    setPostCode(data.zonecode)
  };

  const findPostCode = () => {
    open({ onComplete: handleComplete });
  };

  const isVaildToPay = () => {
    if(isEmpty(fullAddress)) return false;
    if(isEmpty(detailAddress)) return false;
    if(isEmpty(phoneNumber)) return false;
    return true
  }

  const requestPay = () => {
    if (!isVaildToPay()) {
      return;
    }
    const IMP = initializePortOne();

    const productName = "더마토리 패드"
    const amount = 19900
    const payMethod ="card"
    const buyerEtcObject = {
      buyer_addr: `${fullAddress} ${detailAddress}`,
      buyer_postcode: postCode,
      buyer_name: name,
    }

    let params = { // param
      pg: "danal_tpay.9810030929",
      pay_method: payMethod,
      merchant_uid: `imp-${orderId}`,
      name: productName,
      amount: amount,
      buyer_tel: phoneNumber,
      ...buyerEtcObject
    }

    IMP.request_pay(params, rsp => {
      if (rsp.success) {
        navigate(`/orders/${orderId}/status?redirect_path=/shop/dermatory_pad`)
      } else {
        toast({
          duration: 1500,
          render: () => (
            <ToastMessageBox message={"결제가 취소되었습니다."}/>
          ),
        });
      }
    });
  }


  return (
    <>
      <Page
        title={"상품 결제"}
        showTitle={true}
        showBackButton={true}
        noGutter={true}
        noPadding={true}
        ignoreTokenCheck={true}
      >
        <BoxWrapper>
          <VStack spacing={0} align={"left"}>
            <Text fontWeight={800} fontSize={"18px"}>{"주문상품 정보"}</Text>
            <Text fontWeight={800} paddingTop={"12px"}>{"상품명"}</Text>
            <Text fontSize={"1rem"}>{"더마토리 패드 리필 기획세트 160매 (본품+리필)"}</Text>
            <Text fontWeight={800}>{"주문 가격"}</Text>
            <Text>{"19,900"}</Text>
          </VStack>
        </BoxWrapper>

        <BoxWrapper>
          <VStack spacing={0} align={"left"}>
            <Text fontWeight={800} fontSize={"18px"}>{"취소 및 반품안내"}</Text>
            <Text paddingTop={"12px"} fontSize={"14px"} whiteSpace={"pre-wrap"}>
              {`- 주문 후 즉시 취소를 진행하셨더라도 취소 완료 후 입금 또는 카드 취소까지 결제수단에 따라 1~5일 소요될 수 있습니다.\n- 이후에도 환불이 진행되지 않을 경우 결제수단 측으로 문의 부탁드립니다.\n- 주문건이 배송준비중 으로 변경된 경우에는 취소 처리가 불가능합니다.\n- 구매를 희망하지 않는 경우 수령 후 7일 이내에 반품 접수를 진행해주십시오. 구매의사 변동에 의한 반품일 경우 왕복 택배비 6천원이 부과됩니다`}
            </Text>
          </VStack>
        </BoxWrapper>

        <BoxWrapper>
          <VStack align={"left"}>
            <Text fontWeight={800}>{"배송지 정보"}</Text>
            {
              fullAddress ?
              <>
                <Text fontSize={"14px"} whiteSpace={"pre-wrap"} textAlign={"left"}>
                  {`주소 : ${fullAddress}\n우편번호 : ${postCode}`}
                </Text>
                <Text fontSize={"14px"} paddingTop={"8px"}>{"상세주소 입력"}</Text>
                <PrimaryInput onChangeText={(e) => setDetailAddress(e.target.value)}/>
              </> :
              <>
                <SecondaryButton
                  onClick={findPostCode}
                  width={"100%"}
                  paddingY={"12px"}
                  text={"우편번호 찾기"}
                />
              </>
            }
          </VStack>
        </BoxWrapper>

        <BoxWrapper>
          <VStack align={"left"}>
            <Text fontWeight={800}>{"받는분 정보"}</Text>
            <Text fontSize={"14px"} paddingTop={"2px"}>{"성함"}</Text>
            <PrimaryInput onChangeText={(e) => setName(e.target.value)}/>
            <Text fontSize={"14px"} paddingTop={"2px"}>{"연락처"}</Text>
            <PrimaryInput onChangeText={(e) => setPhoneNumber(e.target.value)}/>
          </VStack>
          <PrimaryButton
            disabled={!(isVaildToPay())}
            onClick={requestPay}
            marginTop={"32px"}
            paddingY={"16px"}
            text={"결제하기"}
          />
        </BoxWrapper>
        <ImageWrapper>
          <Image src='https://pocapan.com/shop/1/dermatory_purple.jpg'/>
        </ImageWrapper>
        <Footer/>
      </Page>
    </>
  );
}
