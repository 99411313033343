import React, { useState } from 'react';

import { Grid, GridItem, Image, SimpleGrid, WrapItem } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import FullImageViewerDialog from './FullImageViewerDialog';
import LightColors from '../../constants/LightColors';
import { ReactComponent as CloseIcon } from "../../icons/xmark.fill.svg";

const closeWrapItemStyle = {
  borderRadius: "32px",
  backgroundColor: LightColors.black800,
  alignItems: "center",
  justifyContent: "center",
  padding: "5px",
  position: "absolute",
  top: "10px",
  right: "10px",
}

export default function PostImagesBox({ images, focusRef, enableDelete, enableFullSizeViewer=true,  onDeletedImage }) {
  const [image, setImage] = useState();
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const openImage = (index, image) => {
    setCurrentImageIndex(index);
    setImage(image);
  }

  const closeImage = () => {
    setCurrentImageIndex(null)
    setImage(null)
  }


  const handleNextImage = () => {
    const nextIndex = (currentImageIndex + 1) % images.length
    setCurrentImageIndex(nextIndex);
    setImage(images[nextIndex]);
  };

  const handlePrevImage = () => {
    const nextIndex = (currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1) % images.length
    setCurrentImageIndex(nextIndex);
    setImage(images[nextIndex]);
  };

  if (isEmpty(images)) {
    return <></>;
  }

  const DeleteIcon = (index) => {
    if(enableDelete) {
      return (
        <WrapItem style={closeWrapItemStyle}>
          <CloseIcon width={"14px"} height={"14px"} fill={LightColors.purewhite} onClick={() => onDeletedImage && onDeletedImage(index)}/>
        </WrapItem>
      )
    } else {
      <></>
    }
  }

  if (images.length === 1) {
    return(
      <>
        <WrapItem position={"relative"}>
          <Image
            src={images[0]}
            objectFit={"cover"}
            width={"100%"}
            maxHeight={"360px"}
            minHeight={"50px"}
            borderRadius={"16px"}
            onClick={() => openImage(0, images[0])}
          />
          {DeleteIcon(0)}
        </WrapItem>
        {
          enableFullSizeViewer && image &&
          <FullImageViewerDialog
            focusRef={focusRef}
            isOpen={!!image}
            imageUrl={image}
            onClosed={() => closeImage()}
          />
        }
      </>
    );
  }

  if (images.length === 2) {
    return(
      <>
        <SimpleGrid columns={2} height={"200px"} gap={"2px"}>
          <WrapItem position={"relative"}>
            <Image
              src={images[0]}
              objectFit={"cover"}
              width={"100%"}
              height={"200px"}
              borderLeftRadius={"16px"}
              onClick={() => openImage(0, images[0])}
            />
            {DeleteIcon(0)}
          </WrapItem>
          <WrapItem position={"relative"}>
            <Image
              src={images[1]}
              objectFit={"cover"}
              width={"100%"}
              height={"200px"}
              borderRightRadius={"16px"}
              onClick={() => openImage(1, images[1])}
            />
            {DeleteIcon(1)}
          </WrapItem>
        </SimpleGrid>
        {
          enableFullSizeViewer && image &&
            <FullImageViewerDialog
              focusRef={focusRef}
              isOpen={!!image}
              imageUrl={image}
              onClosed={() => closeImage()}
              onPrevImage={handlePrevImage}
              onNextImage={handleNextImage}
            />
        }
      </>
    );
  }

  if (images.length === 3) {
    return (
      <>
        <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(2, 1fr)' height={"200px"} gap={"2px"}>
          <GridItem rowSpan={2} colSpan={1} position={"relative"} alignContent={"center"}>
            <Image
              src={images[0]}
              objectFit={"cover"}
              width={"100%"}
              height={"100%"}
              borderLeftRadius={"16px"}
              onClick={() => openImage(0, images[0])}
            />
            {DeleteIcon(0)}
          </GridItem>
          <GridItem rowSpan={1} colSpan={1}  position={"relative"}>
            <Image
              src={images[1]}
              objectFit={"cover"}
              width={"100%"}
              height={"99px"}
              borderTopRightRadius={"16px"}
              onClick={() => openImage(1, images[1])}
            />
            {DeleteIcon(1)}
          </GridItem>
          <GridItem rowSpan={2} colSpan={1}  position={"relative"}>
            <Image src={images[2]} objectFit={"cover"} width={"100%"} height={"99px"} borderBottomEndRadius={"16px"} onClick={() => openImage(2, images[2])}/>
            {DeleteIcon(2)}
          </GridItem>
        </Grid>
        {
          enableFullSizeViewer && image &&
            <FullImageViewerDialog
              focusRef={focusRef}
              isOpen={!!image}
              imageUrl={image}
              onClosed={() => closeImage()}
              onPrevImage={handlePrevImage}
              onNextImage={handleNextImage}
            />
        }
      </>
    );
  }

  if (images.length >= 4) {
    return (
      <>
        <SimpleGrid columns={2} height={"200px"} gap={"2px"}>
          <WrapItem position={"relative"}>
            <Image
              src={images[0]}
              objectFit={"cover"}
              width={"100%"}
              height={"99px"}
              borderTopLeftRadius={"16px"}
              onClick={() => openImage(0, images[0])}
            />
            {DeleteIcon(0)}
          </WrapItem>
          <WrapItem position={"relative"}>
            <Image
              src={images[2]}
              objectFit={"cover"}
              width={"100%"}
              height={"99px"}
              borderTopRightRadius={"16px"}
              onClick={() => openImage(2, images[2])}
            />
            {DeleteIcon(2)}
          </WrapItem>
          <WrapItem position={"relative"}>
            <Image
              src={images[1]}
              objectFit={"cover"}
              width={"100%"}
              height={"99px"}
              borderTopRightRadius={"16px"}
              onClick={() => openImage(1, images[1])}
            />
            {DeleteIcon(1)}
          </WrapItem>
          <WrapItem position={"relative"}>
            <Image
              src={images[3]}
              objectFit={"cover"}
              width={"100%"}
              height={"99px"}
              borderTopRightRadius={"16px"}
              onClick={() => openImage(3, images[3])}
            />
            {DeleteIcon(3)}
          </WrapItem>
        </SimpleGrid>
        {
          enableFullSizeViewer && image &&
            <FullImageViewerDialog
              focusRef={focusRef}
              isOpen={!!image}
              imageUrl={image}
              onClosed={() => closeImage()}
              onPrevImage={handlePrevImage}
              onNextImage={handleNextImage}
            />
        }
      </>
    );
  }
}
