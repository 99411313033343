import { Box, Flex, HStack, Image, Spacer, Stack, Text, WrapItem } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import styled from "styled-components";
import LightColors from "../../constants/LightColors";
import { ReactComponent as DashPlusIcon } from "../../icons/DashPlus.svg";
import { ReactComponent as ProfileFillIcon } from "../../icons/profile.fill.svg";
import { openNewTab } from "../../utils/UIUtils";
import { t } from "i18next";
import { EmojiBySupportedCountry } from "../../constants/Countries";

const ImageBoxWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 19px;
  height: 19px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
  border-radius: 50%;
`

export default function HomeHeader({
  user,
  title=t("feedPage.title"),
  onClickTitle,
  onClickProfileButton
}) {
  const countryCode = localStorage.getItem("feedCountryCode");
  const onClickProfile = () => {
    if(isEmpty(user?.baseUserProfile)) {
      if(onClickProfileButton) {
        onClickProfileButton();
      }
    } else {
      openNewTab(`/feed/user_profile/${user?.baseUserProfile?.userProfileId}`);
    }
  }

  return(
    <Stack spacing={3}>
      {
        <Flex>
          <Text fontSize={"26px"} fontWeight={700} color={"#222"} onClick={onClickTitle}>
            {`${title} ${EmojiBySupportedCountry(countryCode || user?.country)}`}
          </Text>
          <Spacer/>
          <Flex justifyItems={"end"}>
            <Box
              className="text-center"
              display="flex"
              alignItems="center"
              justifyContent="center"
              alignSelf={"center"}
              textColor="primary.500"
              backgroundColor="secondary.600"
              borderRadius="52px"
              lineHeight="17px"
              minWidth="56px"
              height={"33px"}
              fontSize="14px"
              fontWeight={500}
            >
              <Box
                display="flex"
                justifyContent="flex-end"
                paddingLeft={"8px"}
                paddingRight={"10px"}

                onClick={() => onClickProfile()}
              >
                {
                  user?.baseUserProfile ?
                  <>
                    <Box pr={"6px"}>
                      {
                        user.baseUserProfile?.pictureUrl
                        ? <WrapItem className="relative">
                            <Image
                              width={"19px"}
                              height={"19px"}
                              objectFit={"cover"}
                              borderRadius={"full"}
                              src={user.baseUserProfile?.pictureUrl}
                            />
                            <ImageBoxWrapper/>
                          </WrapItem>
                        : <ProfileFillIcon fill="white" width={"19px"} height={"19px"} />
                      }
                    </Box>
                    <Box marginTop={"1px"}>
                      {user.baseUserProfile?.nickname || "-" }
                    </Box>
                  </>
                  : !isEmpty(user) &&
                  <HStack spacing={0} alignItems={"center"} onClick={() => onClickProfile()}>
                    <DashPlusIcon width={"19px"} height={"19px"} fill={LightColors.onSecondary}/>
                    <Text paddingStart={"6px"} fontWeight={600}>{t("feedPage.defaultProfile")}</Text>
                  </HStack>
                }
              </Box>
            </Box>
          </Flex>
        </Flex>
      }
    </Stack>
  )
}
