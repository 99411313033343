import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

const DEFAULT_PAGE = 30;

export default function useReviewsQuery({
  userProfileId,
  reviewableType="TradingPhotoCard",
  perPage=DEFAULT_PAGE
}) {
  async function fetchReviews({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage,
      page: pageParam,
      reviewable_type: reviewableType,
    });
    if (userProfileId) {

      queryParams.append("user_profile_id", userProfileId)
    }


    const url = `/api/v1/reviews?${queryParams.toString()}`;
    try {
      const reviews = await queryGet(url);
      return reviews;
    } catch (error) {
      throw new Error("Error fetching paginated data to get useReviewsQuery");
    }
  };

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    ["Reviews", reviewableType, userProfileId],
    fetchReviews,
    {
      getNextPageParam: (lastPage, _) => lastPage?.meta?.pagination?.nextPage,
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch
  };
}
