export const getPhotoCardHeight = (gridType) => {
  switch(gridType){
    case "sm":
      return 62
    case "md":
      return 75
    case "lg":
      return 136
    default:
      return 136
  }
}

export const getGridSize = (gridType) => {
  switch(gridType){
    case "sm":
      return 9
    case "md":
      return 6
    case "lg":
      return 4
    default:
      return 4
  }
}
