import { isEmpty, isUndefined } from "lodash";

export const ListRenderer = ({ data, renderList, skeletonComponent, emptyComponent }) => {
  if (isUndefined(data)) {
    return skeletonComponent;
  }
  if (isEmpty(data)) {
    return emptyComponent;
  }
  return renderList(data);
};
