import { isEmpty } from "lodash";

export default function filterPhotoCards(events, searchText) {
  if(isEmpty(events)) return;

  const upperSearchText = searchText.toUpperCase();
  const splitedText = upperSearchText.split(" ");

  // Function to calculate a basic score for a photoCard based on keyword matches
  function calculateScore(photoCard) {
    const eventText = photoCard.eventName?.toUpperCase();
    const memberText = photoCard.memberName?.toUpperCase();

    // Calculate score based on keyword matches in splitedText
    const score = splitedText.reduce((totalScore, keyword) => {
      return (
        totalScore +
        (eventText.includes(keyword) ? 1 : 0) +
        (memberText.includes(keyword) ? 1 : 0)
      );
    }, 0);

    return score;
  }

  // Filter and rank photoCards based on keyword matches
  const filteredAndRankedPhotoCards = events
    .flatMap(event =>
      event.photoCards.map(photoCard => ({
        ...photoCard,
        event: event,
        score: calculateScore(photoCard),
      }))
    )
    .filter(photoCard => photoCard.score > 0) // 점수가 0 이상인 항목만 남김
    .sort((a, b) => b.score - a.score); // 점수를 기준으로 내림차순 정렬

  return filteredAndRankedPhotoCards;
}


export function filterSearchablePhotoCards(photoCards, searchText) {
  if(isEmpty(photoCards)) return;

  const upperSearchText = searchText.toUpperCase();
  const splitedText = upperSearchText.split(" ");

  // Function to calculate a basic score for a photoCard based on keyword matches
  function calculateScore(photoCard) {
    const textFields = [
      photoCard.eventName?.toUpperCase() || "",
      photoCard.memberName?.toUpperCase() || "",
      photoCard.categoryName?.toUpperCase() || ""
    ];
    const allTags = [...photoCard.tags, ...photoCard.distributors].map(tag => tag.toUpperCase());

    // 키워드와의 매칭을 기반으로 점수를 계산
    const score = splitedText.reduce((totalScore, keyword) => {
      const upperKeyword = keyword.toUpperCase();
      return (
        totalScore +
        textFields.filter(field => field.includes(upperKeyword)).length +
        (allTags.some(tag => tag.includes(upperKeyword)) ? 1 : 0)
      );
    }, 0);
    return score;
  }

  // Filter and rank photoCards based on keyword matches
  const filteredAndRankedPhotoCards = photoCards.map(photoCard => ({
        ...photoCard,
        score: calculateScore(photoCard),
    }))
    .filter(photoCard => photoCard.score > 0) // 점수가 0 이상인 항목만 남김
    .sort((a, b) => b.score - a.score); // 점수를 기준으로 내림차순 정렬

  return filteredAndRankedPhotoCards;
}
