import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useTradingPhotoCardQuery(tradingPhotoCardId) {
  const { data: tradingPhotoCard } = useQuery(
    ["tradingPhotoCard", tradingPhotoCardId],
    () => queryGet(`/api/v1/trading_photo_cards/${tradingPhotoCardId}`),{
      select: (data) => data.tradingPhotoCard,
    }
  );
  return tradingPhotoCard;
}
