import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
// import { ReactComponent as SaveIcon } from "../../icons/save.svg";

const FullSizeImageViewer = ({ imageUrl, onClosed }) => {
  const canvasRef = useRef(null);
  useEffect(() => {
    // Save the original viewport content
    const originalViewportContent = document.querySelector('meta[name="viewport"]').getAttribute('content');

    // Change meta tag for viewport scale
    const viewport = document.querySelector('meta[name="viewport"]');
    document.body.style.overflow = "hidden";
    if (viewport) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=3.0');
    }

    // Cleanup function when the component unmounts
    return () => {
      // Revert the changes to the original viewport content
      const viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        viewport.setAttribute('content', originalViewportContent);
      }
      document.body.style.overflow = "auto";
    };
  }, []);


  // const saveImage = () => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext('2d');
  //   const img = new Image();

  //   img.src = imageUrl;
  //   img.crossOrigin = "Anonymous";
  //   img.onload = () => {
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     ctx.drawImage(img, 0, 0, img.width, img.height);
  //     const dataURL = canvas.toDataURL('image/png');
  //     const link = document.createElement('a');
  //     link.href = dataURL;
  //     link.download = `${`pocapan_${Date.now()}`}.png`;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };
  // };


  return (
    <Wrapper>
      <Overlay>
        <CloseWrapper>
          <CloseIcon fill={"white"} onClick={onClosed}/>
        </CloseWrapper>
        <FullImage src={imageUrl} alt="Full Size" />
        {/* <SaveWrapper>
          <SaveIcon fill={"white"} onClick={saveImage}/>
        </SaveWrapper> */}
        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </Overlay>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 2147483646;
  touch-action: auto;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483646;
  touch-action: auto;
`;

const FullImage = styled.img`
  max-width: 95%;
  max-height: 95%;
  z-index: 2147483646;
  touch-action: auto;
`;

const CloseWrapper = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  padding-top: 34px;
  z-index: 2147483647;
`;

// const SaveWrapper = styled.button`
//   position: absolute;
//   bottom: 20px;
//   right: 20px;
//   cursor: pointer;
//   z-index: 2147483647;
// `;

export default FullSizeImageViewer;
