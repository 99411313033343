import { useEffect } from "react";

export default function ScrollRestoration(pathName, onScrolled) {
  useEffect(() => {
    // Save scroll position to sessionStorage when leaving the page
    const handleScroll = () => {
      sessionStorage.setItem(`scrollPosition:${pathName}`, window.scrollY.toString());
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [pathName]);

  useEffect(() => {
    // Restore scroll position from sessionStorage when coming back to the page
    const scrollPosition = sessionStorage.getItem(`scrollPosition:${pathName}`);

    if (scrollPosition) {
      let positonY = parseInt(scrollPosition);
      positonY = positonY + parseInt(positonY/20);
      setTimeout(() => { window.scrollTo(0, positonY); }, 300);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathName]);
}
