import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function appendTags({taggableType, taggableId, tagNames}) {
  return fetch(baseUrl() + `/api/v1/tags/append`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      tag: {
        taggable_id: taggableId,
        taggable_type: taggableType,
        names: tagNames,
      }
    }),
  });
};
