import React, { useEffect, useState } from "react";

import { Box, Flex, HStack, Image, Spacer, Text, VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { t } from 'i18next';
import { first, isEmpty, isUndefined, sumBy } from "lodash";
import { useNavigate } from "react-router-dom";

import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import LightColors from '../../constants/LightColors';
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { getArtistId } from '../../utils/etcUtils';
import GridSkeletons from '../skeletons/GridSkeletons';
import RowSkeletons from '../skeletons/RowSkeletons';

const titleStyle = {
  fontSize: "17px",
  fontWeight: 700,
  color: "#444",
}

const cardCountStyle = {
  fontSize: "17px",
  fontWeight: 700,
  color: "#5C3DFA",
  paddingLeft: "3px"
}

const buttonStyle = {
  fontSize: "14px",
  fontWeight: 600,
  padding: "6px 12px",
  color: "rgba(92, 61, 250, 1)",
  borderRadius: "32px",
  backgroundColor: "rgba(92, 61, 250, 0.06)"
}

const SkeletonWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
`

const CardColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
`

const ChatRoomText = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
  color: ${(props) => props.color};
  padding-top: 6px;
`


export default function TradingManagerBarV2({tradingPhotoCards, category, isShowExploreCard=false, isShowChatRoomsCount=false, onClickTradingPhotoCard}) {
  const navigate = useNavigate();
  const [selectedTradigPhotoCard, setSelectedTradigPhotoCard] = useState();
  const artistId = getArtistId();

  useEffect(() => {
    if(!isShowExploreCard && !selectedTradigPhotoCard) {
      setSelectedTradigPhotoCard(first(tradingPhotoCards))
    }

  }, [selectedTradigPhotoCard, tradingPhotoCards])


  const onSelectPhotoCard = (tradingPhotoCard) => {
    setSelectedTradigPhotoCard(tradingPhotoCard);
    onClickTradingPhotoCard(tradingPhotoCard);
  }

  const sumQuantityTradingPhotoCardsCount = () => {
    return sumBy(tradingPhotoCards, ((c) => c.quantity ?? 1))
  }

  if (isUndefined(tradingPhotoCards)) {
    return(
      <SkeletonWrapper style={{padding: "0px 24px"}}>
        <RowSkeletons skeletonSize={1} skeletonsCount={1} skeletonHeight={"24px"}/>
        <GridSkeletons gridSize={5} skeletonSize={3} skeletonHeight={'90px'} marginY={"16px"}/>
      </SkeletonWrapper>
    )
  }

  return (
    <VStack align={"initial"}>
      <Flex align={"center"} marginY={"4px"} paddingX={"24px"}>
        <HStack>
          <Text style={titleStyle}>
            {
              category === "sale" ?
              t("tradingPhotoCardPage.tradingManagerBarSaleTitle") :
              t("tradingPhotoCardPage.tradingManagerBarExchangeTitle")
            }
          </Text>
          {
            tradingPhotoCards && <Text style={cardCountStyle}>{sumQuantityTradingPhotoCardsCount()}</Text>
          }
        </HStack>
        <Spacer/>
        <Box
          style={buttonStyle}
          onClick={() => navigate(`/artists/${artistId}/my_trading_photo_cards?tabIndex=${category === "sale" ? 1 : 0}`)}>
          {t("tradingPhotoCardPage.tradingManagerBarManage")}
        </Box>
      </Flex>

      <ScrollMenu wrapperClassName='padding-left-scroll-container'>
        <ExploreCard
          selectedTradigPhotoCard={selectedTradigPhotoCard}
          onClickedCard={() => onSelectPhotoCard(null)}
        />
        {
          tradingPhotoCards.map((tradingPhotoCard) => {
            const isSelected = selectedTradigPhotoCard?.tradingPhotoCardId === tradingPhotoCard.tradingPhotoCardId;
            return (
              <CardColumn key={tradingPhotoCard.tradingPhotoCardId}>
                <div
                  className="relative"
                  onClick={()=>{onSelectPhotoCard(tradingPhotoCard)}}
                  style={{padding: "4px"}}>
                  <Image
                    src={tradingPhotoCard.photoCard.pictureUrl}
                    width={"44px"}
                    height={"68px"}
                    objectFit={"cover"}
                    border={(isSelected) ? "2px" :"2px solid"}
                    borderColor={(isSelected) ? "white" : "rgba(0, 0, 0, 0.15)"}
                    borderRadius={"12px"}
                    loading={"lazy"}
                    boxShadow={(isSelected) ? "0 0 0 3px #5C3DFA, 0 4px 8px 0px rgba(92, 62, 250, 0.3);" : "0 0 0 3px rgba(0, 0, 0, 0.15)"}
                    className={!isSelected && "opacity-40"}
                  />
                </div>
                <ChatRoomText color={isSelected ? LightColors.primary : LightColors.textsublv1}>
                  {t("TradingPhotoCardPageV2.chatRoomCountCard", { count: tradingPhotoCard.chatRoomsCount })}
                </ChatRoomText>
              </CardColumn>
            )
          })
        }
        <AddCard
          onClickedCard={() => navigate(`/tradings/add_trading_photo_cards?isExchange=${category === "exchange"}`)}
        />
      </ScrollMenu>
    </VStack>
  )
}

const ExploreCard = ({selectedTradigPhotoCard, onClickedCard}) => {
  return (
    <CardColumn>
      <div
        className="relative"
        onClick={() => onClickedCard()}
        style={{padding: "4px"}}
      >
        <Image
          src={isEmpty(selectedTradigPhotoCard) ? "/assets/icons/SelectedExplore.png" : "/assets/icons/UnselectedExplore.png"}
          width={"44px"}
          height={"68px"}
          objectFit={"contain"}
          paddingY={"18px"}
          paddingX={"12px"}
          border={isEmpty(selectedTradigPhotoCard) ? "2px" :"2px solid"}
          borderColor={isEmpty(selectedTradigPhotoCard) ? "white" : "rgba(0, 0, 0, 0.15)"}
          borderRadius={"12px"}
          loading={"lazy"}
          boxShadow={isEmpty(selectedTradigPhotoCard) ? "0 0 0 3px #5C3DFA, 0 4px 8px 0px rgba(92, 62, 250, 0.3);" : "0 0 0 3px rgba(0, 0, 0, 0.15)"}
          className={!isEmpty(selectedTradigPhotoCard) && "opacity-40"}
        />
      </div>
      <ChatRoomText color={isEmpty(selectedTradigPhotoCard) ? LightColors.primary : LightColors.textsublv1}>
        {t("TradingPhotoCardPageV2.exploreCard")}
      </ChatRoomText>
    </CardColumn>
  )
}


const AddCard = ({onClickedCard}) => {
  return (
    <VStack>
      <Box
        bg="rgba(0, 0, 0, 0.04)"
        borderRadius="12px"
        paddingX={"16px"}
        paddingY={"24px"}
        alignItems='center'
        justifyContent={'center'}
        marginLeft={"2px"}
        onClick={()=>onClickedCard()}
      >
        <PlusIcon
          width={"16px"}
          height={"16px"}
          fill="rgba(0, 0, 0, 0.4)"
          style={{display: "inline-block"}}
        />
      </Box>
      <ChatRoomText color={LightColors.textsublv1} style={{paddingTop: "0px"}}>
        {t("TradingPhotoCardPageV2.addCard")}
      </ChatRoomText>
    </VStack>
  )
}
