import { Badge, Box, Divider, Image, Input, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import React, { useEffect, useState } from "react";
import LightColors from '../../constants/LightColors';
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { inputPriceFormat } from "../../utils/NumberUtil";
import styled from 'styled-components';
import { isEmpty } from 'lodash';

const EventName = styled.p`
  color: ${LightColors.textmainlv2};
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const PhotoCardName = styled.div`
  color: ${LightColors.textmainlv3};
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: left;
  margin-top: 8px!important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`
const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 4px;
  flex-wrap: wrap;
  margin-top: 8px !important;
`
const itemBoxStyle = {
  padding: "10px",
  width: "100%",
  borderRadius: "12px"
}

const imageBoxStyle = {
  minWidth: "48px",
  minHeight: "75px",
  width: "48px",
  height: "75px"
}

const imageStyle = {
  width: "48px",
  height: "75px",
  margin: "0px!important",
  borderRadius: "8px"
}

const closeIconStyle = {
  width: "28px",
  height: "28px",
  backgroundColor: "rgba(92, 61, 250, 0.06)",
  borderRadius: "32px",
  padding: "6px",
  marginBottom: "16px",
}

const inputStyle = {
  fontSize: "17px",
  fontWeight: 500,
  lineHeight: "32px",
  color: "#444",
  textAlign: "right",
  borderWidth: 0,
}

const placeHolderStyle = {
  textAlign: "left",
  fontSize: "17px",
  lineHeight: "32px",
  fontWeight: 500,
  color: "rgba(0, 0, 0, 0.20)",
}

export default function NewSaleTradingPhotoCard({tradingPhotoCard, onChangedItem, onDeletedItem, confirmed=false, hideToggle=false}) {
  const [price, setPrice] = useState(tradingPhotoCard.price);
  const [checked, setChecked] = useState(tradingPhotoCard?.category === "sale");

  useEffect(() => {
    setChecked(tradingPhotoCard?.category === "sale")
  }, [tradingPhotoCard])

  const updatedPrice = (e) => {
    setPrice(inputPriceFormat(e.target.value));
    updateTradingPhotoCard(checked, e.target.value);
  }

  const updateTradingPhotoCard = (checked, price) => {
    onChangedItem({
      ...tradingPhotoCard,
      price: price,
      category: checked ? 'sale' : 'exchange',
    })
  };

  return (tradingPhotoCard &&
    <>
      <Box key={tradingPhotoCard.tradingPhotoCardId} style={itemBoxStyle} borderWidth={1}>
        <div className="flex">
          <div style={imageBoxStyle}>
            <Image
              style={imageStyle}
              src={tradingPhotoCard.photoCard.pictureUrl}
              objectFit={"cover"}
              loading={"lazy"}
            />
          </div>
          <div style={{flexGrow: 1, paddingLeft: "6px"}}>
            <VStack alignItems={"start"} spacing={0} paddingTop={"6px"}>
              <EventName>{t("photoCardDetailPage.title", {memberName: tradingPhotoCard.photoCard.memberName, categoryName: tradingPhotoCard.photoCard.categoryName, categoryEmoji: tradingPhotoCard.photoCard.categoryEmoji})}</EventName>
                  <PhotoCardName>{tradingPhotoCard.photoCard.eventName}</PhotoCardName>
                  <TagsWrapper>
                    {
                      tradingPhotoCard.photoCard.distributorName &&
                      <Badge
                        style={{
                          backgroundColor: LightColors.surface,
                          fontSize: "13px",
                          fontWeight: 600,
                          lineHeight: "16px",
                          color: LightColors.onLabel,
                          padding: "5px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        {tradingPhotoCard.photoCard.distributorName}
                      </Badge>
                    }
                    {
                      !isEmpty(tradingPhotoCard.photoCard.tags) && tradingPhotoCard.photoCard.tags.map((tag) => {
                        return(
                          <Badge
                            style={{
                              backgroundColor: LightColors.surface,
                              fontSize: "13px",
                              fontWeight: 600,
                              lineHeight: "16px",
                              color: LightColors.onLabel,
                              padding: "5px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            {tag.name}
                          </Badge>
                        )
                      })
                    }
                    {
                      tradingPhotoCard.photoCard.meta?.polaroid &&
                      <Badge
                        style={{
                          backgroundColor: LightColors.surface,
                          fontSize: "13px",
                          fontWeight: 600,
                          lineHeight: "16px",
                          color: LightColors.onLabel,
                          padding: "5px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        {"polaroid"}
                      </Badge>
                    }
                    {
                      tradingPhotoCard.photoCard.meta?.unit &&
                      <Badge
                        style={{
                          backgroundColor: LightColors.surface,
                          fontSize: "13px",
                          fontWeight: 600,
                          lineHeight: "16px",
                          color: LightColors.onLabel,
                          padding: "5px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        {"unit"}
                      </Badge>
                    }
                  </TagsWrapper>
            </VStack>
          </div>
          <div style={{flexGrow: 1, justifyContent: "flex-end"}}>
            <VStack align={"end"}>
              <CloseIcon fill={LightColors.primary} style={closeIconStyle} onClick={() => onDeletedItem(tradingPhotoCard)}/>
            </VStack>
          </div>
        </div>
        {
          <VStack>
            <Divider paddingTop={"10px"} paddingBottom={"6px"} width={"100%"} zIndex={999}/>
            <Input
              style={inputStyle}
              isDisabled={confirmed}
              focusBorderColor={"#FFF"}
              errorBorderColor={"#FFF"}
              placeholder={t("myPhotoCards.chatRoomSaleDialogInputPlaceholder")}
              _placeholder={placeHolderStyle}
              value={price || ""}

              onChange={(e) => updatedPrice(e)}
            />
          </VStack>
        }
      </Box>
    </>
  )
}

