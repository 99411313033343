import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function AddBlockFeedReport(blockableType, blockableId, reasonType, otherReason) {
  return fetch(baseUrl() + `/api/v1/block_feed_reports`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      block_feed_report: {
        blockable_type: blockableType,
        blockable_id: blockableId,
        reason_type: reasonType,
        other_reason: otherReason,
      },
    }),
  });
}
