import styled from '@emotion/styled'
import React from 'react'
import Address from './Address'
import Copyright from './Copyright'

const Wrapper = styled.footer`
  position: relative;
  background: #19133A;
  padding: 38px 20px 30px 20px;
  height: 330px;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Title = styled.h4`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #FFF;
  margin-bottom: 16px;
`


function Footer() {
  return (
    <Wrapper>
      <Container>
        <Column>
          <Title>
            {"피스커버"}
          </Title>
          <Address />
          <Copyright />
        </Column>
      </Container>
    </Wrapper>
  )
}

export default Footer
