import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Avatar, Flex, HStack, Text, VStack } from "@chakra-ui/react";

import { t } from "i18next";
import styled from "styled-components";
import { setEvent } from "../utils/ga";

import Page from "../components/Page";
import useUserQuery from "../components/api/queries/useUserQuery";
import UserProfileList from "../components/lists/UserProfileList";
import colors from "../constants/colors";
import HomeBottomNav from "../layout/HomeBottomNav";

import { ReactComponent as CardFillIcon } from "../icons/card.fill.svg";
import { ReactComponent as FirstShapeIcon } from "../icons/first.shape.svg";
import { ReactComponent as LikeShapeIcon } from "../icons/like.shape.svg";
import { ReactComponent as ProfileFillIcon } from "../icons/profile.fill.svg";
import { ReactComponent as StarShapeIcon } from "../icons/star.shape.svg";

const avatarStyle = {
  width: "36px",
  height: "36px",
  backgroundColor: "rgba(93, 62, 255, 0.06)"
}

const avatarIconStyle = {
  width: "20px",
  height: "20px",
  fill: "#5C3DFA"
}

const MenuRowText = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.textMain};
`

export default function MyPage() {
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const artistId = currentQueryParams.get("artistId");

  const navigate = useNavigate();
  const user = useUserQuery();

  const navigateIfUserExists = (path) => {
    if (!user) return;
    navigate(path);
  }

  return (
    <div className="homeBackground safeAreaPaddingExceptBottomBackground">
      <Page
        showBackButton={false}
        title={t("myPage.pageName")}
        userProfile={user}
        style={{paddingTop: "13px", marginBottom: "96px"}}
        themeColor={"#C6DBF8"}
      >
        <Flex mb="17px">
          <Text fontSize={"26px"} fontWeight={700} color={"#222"}>{t("myPage.title")}</Text>
        </Flex>

        <UserProfileList user={user}/>

        <VStack alignItems={"left"} spacing={"20px"} marginTop={"25px"}>
          <HStack spacing={"13px"} onClick={()=> navigateIfUserExists(`/users/${user?.userId}/my_photo_cards_market`)}>
            <Avatar style={avatarStyle} icon={<CardFillIcon style={avatarIconStyle}/>} />
            <MenuRowText>{t("myPage.tradingsRow")}</MenuRowText>
          </HStack>
          <HStack spacing={"13px"} onClick={()=> navigateIfUserExists(`/users/${user?.userId}/my_photo_cards_wish`)}>
            <Avatar style={avatarStyle} icon={<LikeShapeIcon style={avatarIconStyle}/>} />
            <MenuRowText>{t("myPage.wishedRow")}<span style={{color: colors.primary}}>{` ${user?.wishedPhotoCardsCount || ""}`}</span></MenuRowText>
          </HStack>
          <HStack spacing={"13px"} onClick={()=> navigate('/artists/manage')}>
            <Avatar style={avatarStyle} icon={<StarShapeIcon style={avatarIconStyle}/>} />
            <MenuRowText>{t("myPage.manageArtistsRow")}<span style={{color: colors.primary}}>{` ${user?.likedArtistsCount || ""}`}</span></MenuRowText>
          </HStack>
          <HStack spacing={"13px"} onClick={() => navigate('/members/manage')}>
            <Avatar style={avatarStyle} icon={<FirstShapeIcon style={avatarIconStyle}/>} />
            <MenuRowText>{t("myPage.manageMembersRow")}<span style={{color: colors.primary}}>{` ${user?.likedMembersCount || ""}`}</span></MenuRowText>
          </HStack>
        </VStack>

        {
          // #region 설정
        }
        <div style={{marginTop: "26px"}}>
          <Text fontSize={"14px"} fontWeight={600} color={"#222"}>{t("myPage.settings")}</Text>
        </div>
        <VStack alignItems={"left"} spacing={"20px"} marginTop={"21px"}>
          <HStack spacing={"13px"} onClick={()=> navigate("/account/manage")}>
            <Avatar style={avatarStyle} icon={<ProfileFillIcon style={avatarIconStyle}/>} />
            <Text>{t("myPage.manageAccount")}</Text>
          </HStack>
        </VStack>
        {
          // #endregion
        }

        {
          // #region 약관 & 로그 아웃 등 그 밖 기능등
        }
        <VStack alignItems={"baseline"} fontSize="xs" color={"rgba(170, 170, 170, 1)"} mt="24px">
          <Text onClick={()=>window.location.href="/pocapan_terms.html"}>{t("userProfilePage.terms")}</Text>
          <Text onClick={()=>window.location.href="/pocapan_privacy.html"}>{t("userProfilePage.privacy")}</Text>
          <Text className="pt-5" wordBreak={"keep-all"}>
            <a href="https://www.pocapan.com" style={{textDecoration: "underline"}}>@Peacecover</a> inc. All rights reserved.
          </Text>
        </VStack>
        {
          // #endregion
        }
        <div className="safeAreaMarginBottom" style={{
          paddingBottom: "130px"
        }}/>
      </Page>

      <HomeBottomNav
        tabIndex={2}
        artistId={artistId}
      />
    </div>
  )
}
