import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, Center, Divider, HStack, Menu, MenuButton, MenuItem, MenuList, Skeleton, SkeletonText, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { format } from "date-fns";
import ko from "date-fns/locale/ko";
import { t } from 'i18next';
import { findIndex, first, isEmpty, last, sortBy } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Virtuoso } from 'react-virtuoso';

import Page from "../components/Page";
import LeaveChatRoomDialog from "../components/alerts/LeaveChatRoomDialog";
import leaveChatRoom from '../components/api/mutations/legacy/leaveChatRoom';
import useChatRoomMessages from '../components/api/queries/useCharRoomMessages';
import PrimaryButton from "../components/buttons/PrimaryButton";
import ChatMessage from "../components/chats/ChatMessage";
import MessageInputBox from "../components/chats/MessageInputBox";
import VerifiedIcon from '../components/icons/VerifiedIcon';
import ChatRoomTradingPhotoCardInfo from "../components/tradings/ChatRoomTradingPhotoCardInfo";
import MatchedTradingPhotoCardInfo from "../components/tradings/MatchedTradingPhotoCardInfo";
import LightColors from "../constants/LightColors";
import { ellipsis } from '../utils/TextUtil';
import { isCompletedTrading } from "../utils/TradingHelper";
import { openNewTab, refreshScreenSize } from '../utils/UIUtils';
import { queryGet } from "../utils/queryClient";

const titleStyle = {
  color: 'black',
  fontWeight: 700,
  fontSize: "17px",
  lineHeight: "20px",
  textAlign: "center",
};

const photoCardBarStyle = {
  marginTop: "-24px",
  position: "fixed",
  background: "white",
  width: "calc(100% - 24px)",
  paddingRight: "24px",
  zIndex: 990,
  maxWidth: "calc(var(--chakra-sizes-xl) - 48px)",
}

const dateBoxStyle = {
  borderRadius: "30px",
  background: "rgba(0, 0, 0, 0.06)",
  fontSize: "12px",
  color: "#888",
  fontWeight: 500,
  padding: "6px 12px"
}

const chatRoomNoticeTextStyle = {
  fontSize: "12px",
  fontWeight: 400,
  color: "#666"
}

const tradingCardSectionStyle = {
  background: "white",
  paddingTop: "16px",
  paddingBottom: "16px",
  width: "100%",
}


const matchedCardSectionStyle = {
  background: "white",
  paddingTop: "16px",
  paddingBottom: "16px",
}

const DEFAULT_TIMEOUT = 10000;

export default function TradingPhotoCardChatRoomPage() {
  const photoCardBarRef = useRef(null);
  const messageAreaRef = useRef(null);
  const params = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [hasQueryExecuted, setHasQueryExecuted] = useState(false);
  const [paginatedChatRoomMessages, setPaginatedChatRoomMessages] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    refreshScreenSize();
  }, []);

  useEffect(() => {
    if(messageAreaRef?.current){
      messageAreaRef.current.scrollIntoView({ block: "end" });
    }
  }, [messageAreaRef])


  const { data: chatRoom } = useQuery(
    ["chatRoom", params.chat_room_id],
    () => queryGet(`/chat_rooms/${params.chat_room_id}`),
    {
      enabled: !!params.chat_room_id,
      select: (r) => r.chatRoom,
      cacheTime: 0,
    },
  );

  const { data, isFetching, fetchNextPage, hasNextPage } = useChatRoomMessages(chatRoom?.chatRoomId)

  const handlePaginatedChatRoomMessages = (paginatedData) => {
    const chatRoomMessages = paginatedData.pages?.flatMap((page) =>
      page.chatRoomMessages.map((message) => message)
    ).reverse()
    setPaginatedChatRoomMessages(chatRoomMessages);
  }

  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedChatRoomMessages(data);
    }
  }, [data]);

  const tradingUserProfile = useMemo(() => {
    const index = findIndex(chatRoom?.userProfiles, (p) => p.userId !== chatRoom?.currentUserId);
    return chatRoom?.userProfiles[index];
  }, [chatRoom]);

  const myLastUnSeenMessage = useMemo(() => {
    return first(sortBy(paginatedChatRoomMessages?.filter((message) => message.isMine && !!message.seenAt), (m) => { return -m.seenAt }))
  }, [paginatedChatRoomMessages])

  useEffect(() => {
    const refetchMessges = setInterval(() => { refetchMessages() }, DEFAULT_TIMEOUT);
    setTimeout(() => { scrollToBottom(true) }, 300);
    return () => { clearInterval(refetchMessges) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!paginatedChatRoomMessages || isEmpty(paginatedChatRoomMessages)) return;
    if (!hasQueryExecuted) return;

    let lastMessage = last(paginatedChatRoomMessages);
    if (lastMessage.isMine) {
      // setTimeout(() => { scrollToBottom(true) }, 100);
    }
  }, [paginatedChatRoomMessages]);

  useEffect(() => {
    if (!hasQueryExecuted && !!paginatedChatRoomMessages) {
      setHasQueryExecuted(true)
      if(paginatedChatRoomMessages.length > 7) {
        // setTimeout(() => { scrollToBottom(false) }, 100);
      }
    }
  }, [hasQueryExecuted, paginatedChatRoomMessages])

  const refetchMessages = () => {
    queryClient.invalidateQueries(["ChatRoomMessages", params.chat_room_id]);
  }

  const onSentMessage = () => {
    refetchMessages();
    // fetch 되어 message에 반영되기 전에 bottom으로 내려봤자 동작을 하지 않음
    scrollToBottom(true);
  }

  const scrollToBottom = (isSmooth = true) => {
    if(messageAreaRef?.current) {
      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
      // messageAreaRef.current.scrollIntoView({ behavior: isSmooth ? 'smooth' : "instant", block: "end" });
    }
  }

  const onClickDialogButton = async () => {
    if(chatRoom?.chatRoomId) {
      const response = await leaveChatRoom(chatRoom?.chatRoomId)
      if (response.success) {
        queryClient.invalidateQueries(["chatRooms"])
      }
    }
    window.history.back();
  }

  const noticeMessage = () => {
    // TODO : Handle if I left this chatroom AND Blocked User
    if (chatRoom?.chatRoomUsers?.some((u) => !!u.deletedAt)) {
      return t("tradingPhotoCardChatRoom.noticeDeletedUser");
    } else if (chatRoom?.chatRoomUsers?.some((u) => !!u.leftAt)) {
      return t("tradingPhotoCardChatRoom.noticeLeftUser");
    }
  }

  const navigateUserTradingProfile = (userProfile) => {
    const currentPath = window.location.pathname;
    const userProfilePath = `/trading/user_profile/${userProfile?.userProfileId}`
    if (currentPath === userProfilePath) return;

    if(!isEmpty(userProfile?.userProfileId)) {
      openNewTab(userProfilePath);
    }
  }

  return (
    <>
      <Page
        title={t("tradingPhotoCardChatRoom.pageTitle")}
        titleElement={
          <HStack spacing={1} justifyContent={"center"} justifySelf={"center"} flexGrow={1} px={3}
          onClick={() => navigateUserTradingProfile(tradingUserProfile)}>
            <div style={titleStyle}>{ellipsis(tradingUserProfile?.nickname, 20)}</div>
            {
              tradingUserProfile && tradingUserProfile?.identified && <VerifiedIcon width={14} height={14}/>
            }
          </HStack>
        }
        showTitle={false}
        showBackButton={true}
        navigationElement={
          <MeatBallMenus
            zIndex={9999}
            chatRoomId={params.chat_room_id}
            onOpen={onOpen}
          />
        }
        style={{ height: "auto" }}
      >
        <div ref={photoCardBarRef} style={photoCardBarStyle}>
          <Divider style={{marginLeft: "-24px", marginRight: "-24px"}}/>
          <TradingPhotoCardSection
            chatRoom={chatRoom}
            tradingUserProfile={tradingUserProfile}
          />
          <Divider style={{marginLeft: "-24px", marginRight: "-24px", width: "calc(100% + 48px)"}}/>
        </div>
        <div
          ref={messageAreaRef}
          className="flex flex-col"
          style={{
            paddingBottom: "calc(env(safe-area-inset-bottom) + 70px)",
            paddingTop: ((photoCardBarRef?.current?.clientHeight || 0) - 24 + 12)
          }}
        >
          <NoticeText
            key="notice"
            tradingStatus={chatRoom?.tradingStatus}
            noticeMessage={noticeMessage()}
          />
          {/* {
            !isEmpty(paginatedChatRoomMessages) &&
            <EasyVirtualized
              useParentScrollElement={true}
              onLoadMore={loadMore}
              hasMore={hasNextPage}
              scrollReverse={true}
              overscanRowCount={20}
              loader={<Center marginTop={"8px"}><Spinner size='sm' color="primary.500" marginRight={"20px"}/></Center>}
            >
              {
                paginatedChatRoomMessages.map((message, index) => {
                  return(
                    <MessageBubble
                      key={message.chatRoomMessageId}
                      chatRoom={chatRoom}
                      message={message}
                      nextMessage={(index + 1 < paginatedChatRoomMessages.length ? paginatedChatRoomMessages[index + 1] : message)}
                      myLastUnSeenMessage={myLastUnSeenMessage}
                    />
                  )
                })
              }
            </EasyVirtualized>
          } */}
          <Virtuoso
            useWindowScroll
            style={{ height: '100%' }}
            startReached={loadMore}
            overscan={480}
            data={paginatedChatRoomMessages}
            initialTopMostItemIndex={(paginatedChatRoomMessages?.length || 0) - 1}
            itemContent={(index, message) => {
              return(
                <MessageBubble
                  chatRoom={chatRoom}
                  message={message}
                  nextMessage={(index + 1 < paginatedChatRoomMessages.length ? paginatedChatRoomMessages[index + 1] : message)}
                  myLastUnSeenMessage={myLastUnSeenMessage}
                />
              )
            }}
          />
          {
            (
              !!chatRoom?.tradingPhotoCard &&
              isCompletedTrading(chatRoom?.tradingPhotoCard) &&
              paginatedChatRoomMessages?.length > 10
            ) &&
            <Box style={{
              background: LightColors.surface,
              padding: "18px",
              borderRadius: "30px",
            }}>
              <VStack spacing={"16px"}>
                <Text
                  fontSize={"15px"}
                  fontWeight={500}
                  lineHeight={"21px"}
                >
                  {t("myPhotoCards.chatRoomPageCompletedButton")}
                </Text>
                <PrimaryButton
                  width={"100%"}
                  paddingY={"16px"}
                  fontSize={"14px"}
                  fontWeight={600}
                  lineHeight={"17px"}
                  text={t("myPhotoCards.sendReviewButton")}
                  onClick={() => navigate(`/trading_photo_cards/${chatRoom.tradingPhotoCard.tradingPhotoCardId}/add_review?chatRoomId=${chatRoom.chatRoomId}&traderUserProfileId=${tradingUserProfile?.userProfileId}`)}
                />
              </VStack>
            </Box>
          }
        </div>
      </Page>
      {
        <>
          <MessageInputBox
            aritstId={params.artist_id}
            tradingUserProfile={tradingUserProfile}
            messageAreaRef={messageAreaRef}
            onSentMessage={()=>onSentMessage()}
            disabled={!isEmpty(noticeMessage)}
          />
        </>
      }
      <LeaveChatRoomDialog isOpen={isOpen} onClose={onClose} onClickDialogButton={onClickDialogButton} />
    </>
  )
}

function NoticeText({tradingStatus, noticeMessage}) {
  if(!tradingStatus) return (<></>);


  if(isEmpty(noticeMessage)) return (<></>);

  return(
    <div style={{width: "100%", paddingTop: "20px"}} className="text-center">
      <Text style={chatRoomNoticeTextStyle}>{noticeMessage}</Text>
    </div>
  )
}

function MeatBallMenus({chatRoomId, onOpen}) {
  const navigate = useNavigate();
  return(
    <Menu autoSelect={false} zIndex={9999}>
      <MenuButton>
        <Text fontSize={"18px"} fontWeight={600}>{"⋯"}</Text>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => navigate(`/trading_reports?chatRoomId=${chatRoomId}`)}>{t("report")}</MenuItem>
        <MenuItem onClick={() => onOpen()}>{t("leave")}</MenuItem>
      </MenuList>
    </Menu>
  )
}

function TradingPhotoCardSection({chatRoom, tradingUserProfile}) {
  if(!chatRoom) {
    return(
      <div className="flex" style={tradingCardSectionStyle}>
        <div className="flex-1 w-full">
          <VStack align={"flex-start"}>
            <HStack align={"flex-start"}>
              <Skeleton width={"40px"} height={"62px"} startColor='rgba(0, 0, 0, 0.04)' endColor='rgba(0, 0, 0, 0.02)'/>
              <VStack paddingTop={"2px"}>
                <SkeletonText noOfLines={2} width={"75px"} startColor='rgba(0, 0, 0, 0.04)' endColor='rgba(0, 0, 0, 0.02)'/>
              </VStack>
            </HStack>
          </VStack>
        </div>
      </div>
    )
  }
  if(!!chatRoom?.matchedTradingPhotoCard) {
    return(
      <div style={matchedCardSectionStyle}>
        <MatchedTradingPhotoCardInfo
          source={chatRoom?.matchedTradingPhotoCard?.sourceCard}
          target={chatRoom?.matchedTradingPhotoCard?.targetCard}
        />
      </div>
    )
  }

  if(!!chatRoom?.tradingPhotoCard) {
    return(
      <div className="flex w-full" style={tradingCardSectionStyle}>
        <div className="w-full">
          <VStack align={"flex-start"}>
            <ChatRoomTradingPhotoCardInfo
              chatRoomId={chatRoom.chatRoomId}
              tradingPhotoCard={chatRoom?.tradingPhotoCard}
              isMine={chatRoom.currentUserId === chatRoom?.tradingPhotoCard?.userId}
              tradingUserProfile={tradingUserProfile}
              showPrice={true}
            />
          </VStack>
        </div>
      </div>
    )
  }
}

function MessageBubble({chatRoom, message, nextMessage, myLastUnSeenMessage}) {
  const currentDate = new Date(message.sentAt);
  const nextDate = new Date(nextMessage.sentAt)

  return(
    <div key={message.chatRoomMessageId}>
      {
        format(nextDate, 'P', {locale: ko}) !== format(currentDate, 'P', {locale: ko}) &&
        <Center marginBottom={"12px"}>
          <Box style={dateBoxStyle}>{format(currentDate, 'PPP', {locale: ko})}</Box>
        </Center>
      }
      <div style={{paddingBottom: "12px"}}>
        <ChatMessage
          key={message.chatRoomMessageId}
          chatRoom={chatRoom}
          message={message}
          isLastUnSeenMessage={myLastUnSeenMessage === message}
        />
      </div>
    </div>
  )
}
