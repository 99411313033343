import React from "react";
import { Box, HStack, Text } from '@chakra-ui/react';
import { ellipsis } from "../../utils/TextUtil";

export default function ProfileLineButton(
  {
    text,
    leftIcon,
    rightIcon,
    disabled,
    ...props
  }
) {
  return (
    <Box
      textColor={disabled ? "rgba(185, 185, 185, 1)" : "rgba(68, 68, 68, 1)"}
      backgroundColor={"white"}
      borderRadius={"32px"}
      padding={"7px 10px 7px 8px"}
      fontWeight={600}
      fontSize={"14px"}
      maxW={"135px"}
      noOfLines={1}
      boxSizing={"border-box"}
      border={"1px solid #E7E7E7"}
      {...props}
    >
      <HStack spacing={0}>
        { leftIcon && <>{leftIcon}</> }
        { text && <Text noOfLines={1} maxW={"85px"} paddingLeft={"6px"}>{ellipsis(text, 10)}</Text> }
        { rightIcon && <div style={{paddingLeft: "2px"}}>{rightIcon}</div> }
      </HStack>
    </Box>
  )
}
