import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useCountryHomeMenusQuery(countryCode) {
  const { data: homeMenus } = useQuery(
    ["homeMenus", countryCode],
    () => queryGet(`/api/v1/countries/${countryCode}/menus`),{
      select: (data) => data.menus,
    }
  );
  return homeMenus;
}
