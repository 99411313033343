import { isEmpty } from "lodash"
import baseUrl from "../../../utils/baseUrl"
import getToken from "../../../utils/getToken"

export default async function upsertCollectedPhotoCards(artistId, updatedCollectedData) {
  if(isEmpty(updatedCollectedData)) return;
  const response = fetch(baseUrl() + `/api/v1/artists/${artistId}/photo_cards/upsert_collected`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      photo_cards: Object.keys(updatedCollectedData).map((photoCardId) => {
        return {
          photo_card_id: photoCardId,
          collected_count: updatedCollectedData[photoCardId].collectedCount,
        }
      }),
    }),
  }).then((data) => data.json())
  //TODO: Handle to invalidate related Query keys
  return response
}
