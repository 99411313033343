import React, { useMemo } from "react";

import { Box, Center, Flex, Text, VStack } from '@chakra-ui/react';
import { first, isEmpty } from "lodash";
import styled from "styled-components";

import { ScrollMenu } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';

import LightColors from "../../constants/LightColors";
import { ReactComponent as MatchingFillIcon } from "../../icons/matching.fill.svg";
import useExploreTradingPhotoCardsQuery from "../api/queries/useExploreTradingPhotoCardsQuery";
import ExploreTradingPhotoCard from "../tradings/ExploreTradingPhotoCard";
import { t } from "i18next";

const authorNameStyle = {
  fontWeight: 600,
  color: LightColors.textmainlv2,
  lineHeight: "17px",
  fontSize: "14px"
}

const authoredAtStyle = {
  paddingTop: "1px",
  fontWeight: 500,
  color: LightColors.textmainlv4,
  lineHeight: "14px",
  fontSize: "12px",
  paddingLeft: "6px"
}

const CardFillIconWrapper = styled.div`
  width: 34px;
  height: 34px;
  background: linear-gradient(180deg, #804CF2 0%, #D360FE 100%);
  border-radius: 50%;
  padding: 7px;
`

const ExploreTradingPhotoCardListWrapper = styled.div`
  padding: 6px 0px;
`;

const ExploreTradingPhotoCardWrapper = styled.div`
  min-width: 120px;
  padding-left: 6px;
  padding-right: 6px;
`;

export default function NewTradingPhotoCardsHorizontalCard({artistId, category}) {
  const { data } = useExploreTradingPhotoCardsQuery(artistId, null, category, null, "recent", 10);
  const tradingPhotoCards = useMemo(() => {
    return first(data?.pages)?.tradingPhotoCards
  }, [data]);

  if (isEmpty(tradingPhotoCards)) {
    return (<></>)
  }

  return(
    <Flex paddingTop={"20px"} paddingX={"22px"} paddingBottom={"20px"} borderBottom={`1px solid ${LightColors.separatorlv1}`} overflowX={"hidden"}>
      {
        tradingPhotoCards &&
        <Box width={"34px"}>
          <Center>
            <CardFillIconWrapper>
              <MatchingFillIcon width={"20px"} height={"20px"} fill="white"/>
            </CardFillIconWrapper>
          </Center>
        </Box>
      }
      <VStack className="w-full" spacing={0} align={"left"} paddingLeft={"10px"}>
        <Flex paddingBottom={"6px"}>
          <Flex direction={"row"} alignItems={"center"}>
            <Text style={authorNameStyle}>{t("newTradingPhotoCardsHorizontalCard.authorName")}</Text>
            <Text style={authoredAtStyle}>{t("newTradingPhotoCardsHorizontalCard.authoredAt")}</Text>
          </Flex>
        </Flex>
        <Flex direction={"row"} alignItems={"center"}>
          <Text color={LightColors.textmainlv2} fontSize={"14px"} fontWeight={600} lineHeight={"14px"}>
            {category === "sale" ? t("newTradingPhotoCardsHorizontalCard.newSales") : t("newTradingPhotoCardsHorizontalCard.newExchanges")}
          </Text>
          </Flex>
        <ExploreTradingPhotoCardListWrapper key={artistId}>
          <ScrollMenu>
            {
              tradingPhotoCards?.map((tradingPhotoCard) => {
                return(
                  <ExploreTradingPhotoCardWrapper key={tradingPhotoCard.tradingPhotoCardId}>
                    <ExploreTradingPhotoCard
                      key={tradingPhotoCard.tradingPhotoCardId}
                      tradingPhotoCard={tradingPhotoCard}
                    />
                  </ExploreTradingPhotoCardWrapper>
                )
              })
            }
            </ScrollMenu>
        </ExploreTradingPhotoCardListWrapper>
      </VStack>
    </Flex>
  )
}
