import { t } from "i18next";
import styled from "styled-components"

const ReviewContainer = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-content: center;
  border-radius: 50%;
`;

const ReviewText = styled.div`
  text-align: center;
  color: #707070;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  padding-top: 8px;
`


const ReviewIcon = ({ ratingText, isSelected, size, onClick }) => (
  <ReviewContainer size={size} isSelected={isSelected} onClick={onClick}>
    <img
      src={ isSelected ? `/assets/icons/reviews/${ratingText}ReviewOn.png` : `/assets/icons/reviews/${ratingText}ReviewOff.png` }
      alt={ `${ratingText} Review Icon` }
      style={{width: `${size}px`, height: `${size}px`}}
    />
    <ReviewText>{t(`ReviewIcon.${ratingText}`)}</ReviewText>
  </ReviewContainer>
);

export default ReviewIcon;


