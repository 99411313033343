import React from "react";

import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogOverlay, Text, VStack } from '@chakra-ui/react';

import { useRef } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import { t } from "i18next";

const titleStyle ={
  fontWeight: 700,
  fontSize: "17px",
  color: "#222",
  whiteSpace: "pre-line"
}

const descriptionStyle ={
  fontWeight: 400,
  fontSize: "15px",
  color: "#888",
  whiteSpace: "pre-line"
}

export default function CommonVerticalButtonDialog({
  isOpen,
  onClose,
  onClickDialogUpButton,
  onClickDialogDownButton,
  title,
  description,
  children,
  upButtonText=t("confirm"),
  downButtonText=t("cancel"),
  isOneButton=false,
}) {
  const cancelRef = useRef();
  return (
  <AlertDialog
    isOpen={isOpen}
    onClose={onClose}
    autoFocus={false}
    closeOnEsc={true}
    leastDestructiveRef={cancelRef.current}
    isCentered
    >
      <AlertDialogOverlay style={{ zIndex: 2147483647 }}>
        <AlertDialogContent borderRadius={"40px"} paddingTop={"32px"} paddingBottom={"16px"} marginX={"6%"} style={{boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)"}}>
          <AlertDialogBody paddingX={"32px"}>
            <VStack className="text-center">
              {title && <Text style={titleStyle}>{title}</Text>}
              {description && <Text style={descriptionStyle}>{description}</Text>}
            </VStack>
            {
              children && <div>
                {children}
              </div>
            }
            <VStack className="w-full" marginTop={"40px"}>
              <PrimaryButton
                ref={cancelRef.current}
                fontWeight={600}
                fontSize={"17px"}
                lineHeight={"20px"}
                padding={"16px 34px"}
                width={"100%"}
                onClick={() => onClickDialogUpButton()}
                color={"white"}
                text={upButtonText}
              />
              {
                !isOneButton && <SecondaryButton
                  fontWeight={600}
                  fontSize={"17px"}
                  lineHeight={"20px"}
                  padding={"16px 34px"}
                  width={"100%"}
                  onClick={() => onClickDialogDownButton()}
                  text={downButtonText}
                />
              }
            </VStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
