import React from "react";

import { Skeleton, SkeletonText, VStack } from "@chakra-ui/react";
import styled from "styled-components";

const SkeletonWrapper = styled.div`
  width: 100%;
`

export default function RowSkeletons({
  gapHeight="8px",
  skeletonsCount,
  skeletonHeight,
  showText=false,
}){
  return(
    <VStack gap={gapHeight}>
      {
        [...Array(skeletonsCount).keys()].map((key) => {
          return(
            <SkeletonWrapper key={key}>
              <Skeleton
                width={"100%"}
                height={skeletonHeight}
                startColor={"rgba(0, 0, 0, 0.04)"}
                endColor={"rgba(0, 0, 0, 0.02)"}
                borderRadius={"12px"} />
              {
                showText &&
                <SkeletonText
                  width={"100%"}
                  mt={"12px"}
                  noOfLines={2}
                  startColor={"rgba(0, 0, 0, 0.04)"}
                  endColor={"rgba(0, 0, 0, 0.02)"}
                  borderRadius={"12px"}
                />
              }
            </SkeletonWrapper>
          )
        })
      }
    </VStack>
  )
}
