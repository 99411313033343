import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isNativeAndroid, isNativeIOS, requireAppUpdate } from "./NativeUtil";

export default function OnRouterChanged() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      // 페이지 이동시 처리할 로직
      // console.log(`Location: ${location.pathname}`);
      if (location.pathname === '/ask_update')
        return;

      if (isNativeIOS()) {
        if (requireAppUpdate()) {
          navigate('/ask_update');
        }
      } else if (isNativeAndroid()){
        if (requireAppUpdate()) {
          navigate('/ask_update');
        }
      }
      // 이동한 페이지에 따라 원하는 동작 수행
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return <></>;
}
