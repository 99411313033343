import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { AlertDialog, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { ReactComponent as RightArrowIcon } from "../../icons/arrow.right.svg";
import { ReactComponent as LeftArrowIcon } from "../../icons/arrow.left.svg";
import LightColors from '../../constants/LightColors';

const FullImageViewerDialog = ({ isOpen, imageUrl, onClosed, focusRef, onPrevImage, onNextImage }) => {
  const canvasRef = useRef(null);

  // 터치 시작 지점 저장
  const [touchStartY, setTouchStartY] = useState(0);

  useEffect(() => {
    // Save the original viewport content
    const originalViewportContent = document.querySelector('meta[name="viewport"]').getAttribute('content');

    // Change meta tag for viewport scale
    const viewport = document.querySelector('meta[name="viewport"]');
    document.body.style.overflow = "hidden";
    if (viewport) {
      setTimeout(() => {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=3.0');
      }, 0); // Apply the change after the next frame
    }

    // Cleanup function when the component unmounts
    return () => {
      // Revert the changes to the original viewport content
      const viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        viewport.setAttribute('content', originalViewportContent);
      }
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleTouchStart = (e) => {
    setTouchStartY(e.touches[0].clientY);
  };

  // 스와이프 동작을 확인
  const handleTouchMove = (e) => {
    const touchEndY = e.touches[0].clientY;
    const swipeDistance = touchEndY - touchStartY;

    // 스와이프가 아래로 150px 이상 이동하면 닫기
    if (swipeDistance > 150) {
      onClosed();
    }
  };

  return (
      <AlertDialog
        isOpen={isOpen}
        autoFocus={false}
        closeOnOverlayClick={false}
        onClose={onClosed}
        finalFocusRef={focusRef}
        isCentered
        size={"full"}
      >
      <AlertDialogOverlay
        zIndex={2147483647}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <AlertDialogContent height={"100vh"}>
          <Wrapper>
            <Overlay>
              <CloseWrapper>
                <CloseIcon width={"20px"} height={"20px"} fill={LightColors.purewhite} onClick={onClosed}/>
              </CloseWrapper>
              {
                onPrevImage &&
                <LeftArrayWrapper>
                  <LeftArrowIcon width={"20px"} height={"20px"} fill={LightColors.purewhite} onClick={onPrevImage}/>
                </LeftArrayWrapper>
              }
              {
                onNextImage &&
                <RightArrayWrapper >
                  <RightArrowIcon width={"20px"} height={"20px"} fill={LightColors.purewhite} onClick={onNextImage}/>
                </RightArrayWrapper>
              }
              <FullImage src={imageUrl} alt="Full Size Image" />
              <canvas ref={canvasRef} style={{ display: 'none' }} />
            </Overlay>
          </Wrapper>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 2147483646;
  touch-action: auto;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483646;
  touch-action: auto;
`;

const FullImage = styled.img`
  max-width: 95%;
  max-height: 95%;
  z-index: 2147483646;
  touch-action: auto;
`;

const CloseWrapper = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  margin-top: 34px;
  padding: 4px;
  z-index: 2147483647;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
`;

const RightArrayWrapper = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  margin-top: 34px;
  z-index: 2147483647;
  padding: 4px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
`;

const LeftArrayWrapper = styled.button`
  position: absolute;
  top: 50%;
  left: 20px;
  cursor: pointer;
  margin-top: 34px;
  z-index: 2147483647;
  padding: 4px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
`;

export default FullImageViewerDialog;
