import { useNavigate } from 'react-router-dom';

/**
 * HandleHistoryBack 훅
 * 버튼 클릭 시 호출할 수 있는 함수 반환
 */
export function useHandleHistoryBack() {
  const navigate = useNavigate();

  return () => {
    const queryParams = new URLSearchParams(window.location.search);
    const handleNewTabClose = () => {
      if (queryParams.get('newTabOpen')) {
        window.close();
        return true;
      }
      return false;
    };

    const handlePushNotification = () => {
      if (queryParams.get('pushNotification')) {
        navigate("/feed", { replace: true });
        return true;
      }
      return false;
    };

    const handleRedirectUrl = () => {
      const redirectUrl = queryParams.get('redirectUrl');
      if (redirectUrl) {
        navigate(redirectUrl, { replace: true });
        return true;
      }
      return false;
    };

    const handleRedirectFeedHome = () => {
      if (queryParams.get('redirectFeedHome')) {
        navigate("/feed", { replace: true });
        return true;
      }
      return false;
    };

    if (handleNewTabClose()) return;
    if (handlePushNotification()) return;
    if (handleRedirectUrl()) return;
    if (handleRedirectFeedHome()) return;

    // Default case: go back one step in history
    navigate(-1);
  };
}
