import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { isEmpty } from "lodash";
import { Virtuoso } from "react-virtuoso";

import { Divider } from "@chakra-ui/react";
import { t } from "i18next";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import Page from "../../components/Page";
import CommonDialog from "../../components/alerts/CommonDialog";
import updateTradingPhotoCards from "../../components/api/mutations/updateTradingPhotoCards";
import useChatRoomsQuery from "../../components/api/queries/useChatRoomsQuery";
import useTradingPhotoCardQuery from "../../components/api/queries/useTradingPhotoCardQuery";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import WarningButton from "../../components/buttons/WarningButton";
import ChatRoomV2 from "../../components/chats/ChatRoomV2";
import EmptyCase from "../../components/errors/EmptyCase";
import PrimaryInput from "../../components/input/PrimaryInput";
import TradingPhotoCardInfo from "../../components/tradings/TradingPhotoCardInfo";
import { extractPrice } from "../../utils/TextUtil";
import { isCompletedTrading, navigateReviewPath, reviewText } from "../../utils/TradingHelper";
import CommonVerticalButtonDialog from "../../components/alerts/CommonVerticalButtonDialog";

const TradingPhotoCardSectionWrapper = styled.div`
  padding-bottom: 20px;
`

const ListWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`

const ChatRoomV1Wrapper = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
`

const buttonStyle={
  padding: "16px 24px",
  fontWeight: 600,
}

export default function MyTradingPhotoCardChatRoomsPage() {
  const params = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [paginatedChatRooms, setPaginatedChatRooms] = useState();

  const [price, setPrice] = useState();
  const [isOpenExchangeDialog, setIsOpenExchangeDialog] = useState(false);
  const [isOpenSaleDialog, setIsOpenSaleDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenPriceDialog, setIsOpenPriceDialog] = useState(false);

  const tradingPhotoCard = useTradingPhotoCardQuery(params.trading_photo_card_id);
  const { data, isFetching, fetchNextPage, hasNextPage } = useChatRoomsQuery({tradingPhotoCardId: params.trading_photo_card_id});

  const handlePaginatedChatRooms = (paginatedData) => {
    const chatRooms = paginatedData.pages?.flatMap((page) =>
      page.chatRooms.map((chatRoom) => chatRoom)
    )
    setPaginatedChatRooms(chatRooms);
  }

  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedChatRooms(data);
    }
  }, [data]);

  const openDialog = (isExchange) => {
    isExchange ? setIsOpenSaleDialog(true) : setIsOpenExchangeDialog(true)
  }

  const onClose = () => {
    setIsOpenExchangeDialog(false);
    setIsOpenSaleDialog(false);
    setIsOpenDeleteDialog(false);
    setIsOpenPriceDialog(false)
  }

  const invalidateItems = () => {
    queryClient.invalidateQueries(["tradingPhotoCard", tradingPhotoCard.tradingPhotoCardId]);
    queryClient.invalidateQueries("SellingPhotoCards");
    queryClient.invalidateQueries("ExchangingPhotoCards");
  }

  const replaceNewTradingPhotoCard = (tradingPhotoCard) => {
    if (tradingPhotoCard?.tradingPhotoCardId) {
      navigate(`/trading_photo_cards/${tradingPhotoCard?.tradingPhotoCardId}/my_chat_rooms`, { replace: true })
    }
  }

  const handleUpdateAPIResponse = async (response) => {
    if (response.ok) {
      onClose();
      invalidateItems();

      const responseBody = await response.json();
      return responseBody.tradingPhotoCard;
    } else {
      return null;
    }
  }

  const onChangedToExchange = async () => {
    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
      price: extractPrice(price),
      category: "exchange"
    });
    const responseTradingPhotoCard = await handleUpdateAPIResponse(response);
    if (responseTradingPhotoCard) {
      replaceNewTradingPhotoCard(responseTradingPhotoCard);
    }
  }

  const onChangedToSale = async () => {
    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
      price: extractPrice(price),
      category: "sale",
    });
    const responseTradingPhotoCard = await handleUpdateAPIResponse(response);
    if (responseTradingPhotoCard) {
      replaceNewTradingPhotoCard(responseTradingPhotoCard);
    }
  }

  const onRequestToDeletePhotoCard = async () => {
    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
      price: tradingPhotoCard.price,
      status: "sold_out",
    });

    const responseTradingPhotoCard = await handleUpdateAPIResponse(response);
    if (responseTradingPhotoCard && responseTradingPhotoCard.chatRoomsCount > 0 && tradingPhotoCard.category !== "exchange") {
      navigate(`/trading_photo_cards/${responseTradingPhotoCard.tradingPhotoCardId}/select_chat_rooms`)
    }
  }

  const onClickCompleted = () => {
    if (isEmpty(paginatedChatRooms)) {
      setIsOpenDeleteDialog(true);
    } else if (tradingPhotoCard?.category === "exchange") {
      setIsOpenDeleteDialog(true);
    } else {
      navigate(`/trading_photo_cards/${tradingPhotoCard?.tradingPhotoCardId}/select_chat_rooms`)
    }
  }

  const isTradingDone = (tradingPhotoCard) => {
    return tradingPhotoCard.status === "canceled" || tradingPhotoCard.status === "sold_out"
  }

  const onChangedPrice = async () => {
    setIsOpenPriceDialog(false)
    if (!isEmpty(price)) {
      const response = await updateTradingPhotoCards(
        tradingPhotoCard.tradingPhotoCardId,
        extractPrice(price),
        tradingPhotoCard.category
      );

      if(response.ok) {
        onClose();
        invalidateItems();
        const responseBody = await response.json();
        replaceNewTradingPhotoCard(responseBody);
      }
      setPrice(null);
    }
  }

  const navigateReview = (tradingPhotoCard) => {
    if (tradingPhotoCard.category !== "exchange") {
      navigate(navigateReviewPath(tradingPhotoCard));
    }
  }

  return (
    <Page
      showBackButton={true}
      title={t("myPhotoCards.chatRoomPageName")}
      showTitle={true}
      titleTransparent={true}
    >
      <TradingPhotoCardSectionWrapper>
        <TradingPhotoCardInfo
          tradingPhotoCard={tradingPhotoCard}
          showPrice={true}
          onSelectPrice={() => !isTradingDone(tradingPhotoCard) && setIsOpenPriceDialog(true)}
        />
      </TradingPhotoCardSectionWrapper>
      {
        !tradingPhotoCard ? <></>
        : isTradingDone(tradingPhotoCard) ?
        (tradingPhotoCard.category !== "exchange" &&
          <SecondaryButton
            text={reviewText(tradingPhotoCard) }
            style={buttonStyle}
            onClick={() => navigateReview(tradingPhotoCard)}
          />
        ) :
        (
          <div className="flex">
            <div
              className="flex-1"
              style={{paddingRight: "8px"}}
            >
              <WarningButton
                text={t("myPhotoCards.chatRoomPageCompletedButton")}
                style={buttonStyle}
                onClick={() => onClickCompleted()}
              />
            </div>
            <div
              className="flex-1"
              onClick={() => openDialog(tradingPhotoCard.category === "exchange")}
            >
              <SecondaryButton
                text={tradingPhotoCard.category === "exchange" ? t("myPhotoCards.chatRoomPageConvertToSaleButton") : t("myPhotoCards.chatRoomPageConvertToExchangeButton")}
                style={buttonStyle}
              />
            </div>
          </div>
         )
      }
      <Divider
        marginTop={tradingPhotoCard?.category === "exchange" ? !isCompletedTrading(tradingPhotoCard) && "24px" : "24px"}
        width={"calc(100% + 48x)"}
        marginLeft={"-24px"}
        marginRight={"-24px"}
      />

      <ListWrapper>
        {
          paginatedChatRooms && isEmpty(paginatedChatRooms) ?
          <EmptyCase emoji={"😳"} description={t("myPhotoCards.chatRoomPageEmptyCase")}/> :

          <Virtuoso
            useWindowScroll
            style={{ height: '100%' }}
            endReached={loadMore}
            overscan={480}
            data={paginatedChatRooms}
            itemContent={(_, e) => {
              return (
                <ChatRoomV1Wrapper key={e.chatRoomId}>
                  <ChatRoomV2 chatRoom={e}/>
                </ChatRoomV1Wrapper>
              )
            }}
          />
        }
      </ListWrapper>

      {/* Toggle To ExchangeCategory Dialog */}
      <CommonDialog
        isOpen={isOpenExchangeDialog}
        onClose={onClose}
        title={t("myPhotoCards.chatRoomExchangeDialogTitle")}
        description={t("myPhotoCards.chatRoomExchangeDialogDescription")}
        onClickDialogButton={onChangedToExchange}
      />

      {/* Delete TradingPhotoCard Dialog */}
      <CommonVerticalButtonDialog
        isOpen={isOpenDeleteDialog}
        onClose={() => setIsOpenDeleteDialog(false)}
        upButtonText={t("myPhotoCards.doneAndSendReview")}
        downButtonText={t("no")}
        title={t("myPhotoCards.chatRoomCompleteDialogTitle")}
        onClickDialogUpButton={onRequestToDeletePhotoCard}
        onClickDialogDownButton={() => setIsOpenDeleteDialog(false)}
      />

      {/* Toggle To SaleCategory Dialog */}
      <CommonDialog
        isOpen={isOpenSaleDialog}
        onClose={onClose}
        title={t("myPhotoCards.chatRoomSaleDialogTitle")}
        description={t("myPhotoCards.chatRoomSaleDialogDescription")}
        children={
          <PrimaryInput
            defaultValue={null}
            placeholder={t("myPhotoCards.chatRoomSaleDialogInputPlaceholder")}
            onChangeText={(e) => setPrice(e.target.value)}
            style={{marginTop: "16px"}}
          />
        }
        onClickDialogButton={onChangedToSale}
      />

      {/* Change Price Dialog */}
      <CommonDialog
        isOpen={isOpenPriceDialog}
        onClose={onClose}
        title={t("myPhotoCards.changePriceDialogTitle")}
        description={t("myPhotoCards.changePriceDialogDescription")}
        children={
          <PrimaryInput
            defaultValue={extractPrice(tradingPhotoCard?.price)}
            placeholder={t("myPhotoCards.chatRoomSaleDialogInputPlaceholder")}
            onChangeText={(e) => setPrice(e.target.value)}
            style={{marginTop: "16px"}}
          />
        }
        onClickDialogButton={onChangedPrice}
      />
    </Page>
  )
}
