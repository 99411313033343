import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Page from "../components/Page";
import useUserProfilesListQuery from "../components/api/queries/useUserProfilesListQuery";
import MergeUserProfilesList from "../components/userProfile/MergeUserProfilesList";
import colors from "../constants/colors";
import { ReactComponent as XMarkIcon } from "../icons/xmark.svg";
import { t } from "i18next";

const Title = styled.p`
  color: ${colors.eventText};
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  white-space: pre-wrap;
`

const DescriptionWrapper = styled.div`
  margin-top: 12px;
`

const Description = styled.p`
  color: ${colors.photoCardText};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  white-space: pre-wrap;
  margin-bottom: 6px;
`

const SectionWrapper = styled.div`
  margin-top: 24px;
`

const TRADING_CATEGORY = "trading";

export default function MergeUserProfilesPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const currentQueryParams = new URLSearchParams(location.search);
  const backUrl = currentQueryParams.get("backUrl");

  const navigateBack = () => {
    if(backUrl) {
      navigate(backUrl, { replace: true });
    } else {
      navigate(`/feed`, { replace: true });
    }
  }

  const userProfiles = useUserProfilesListQuery(TRADING_CATEGORY);
  return (
    userProfiles && <div>
      <Page
        title={t("profiles.mergeProfilePageTitle")}
        leftElement={<XMarkIcon fill={"#000"}/>}
        onHistoryBackClick={() => navigateBack()}
      >
        <Title>
          { t("profiles.mergeProfileTitle") }
        </Title>
        <DescriptionWrapper>
          <Description>
            { t("profiles.mergeProfileDescription") }
          </Description>
        </DescriptionWrapper>
        <SectionWrapper>
          <MergeUserProfilesList userProfiles={userProfiles}/>
        </SectionWrapper>
        <Description>
          { t("profiles.mergeProfileFirstDescriptionRow") }
        </Description>
        <Description>
          { t("profiles.mergeProfileSecondDescriptionRow") }
        </Description>
        <Description>
          { t("profiles.mergeProfileThirdDescriptionRow")}
        </Description>
      </Page>
    </div>
  )
}
