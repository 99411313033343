import React, { useMemo } from "react";

import { Card, Center, Flex, Text } from '@chakra-ui/react';
import { t } from "i18next";
import { first, isEmpty } from "lodash";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";

import Page from "../components/Page";
import MatchedTradingPhotoCardInfo from "../components/tradings/MatchedTradingPhotoCardInfo";
import { UserProfileRow } from "../components/tradings/UserProfileRow";
import { hasBatchim } from "../utils/TextUtil";
import { queryGet } from "../utils/queryClient";

const headerStyle = {
  paddingTop: "6px",
  paddingBottom: "12px",
}

const emojiStyle = {
  fontSize: "38px",
  fontWeight: 700,
}

const dividerTextStyle = {
  color: "#666",
  fontSize: "14px",
  fontWeight: 400
}

const subTitleStyle = {
  fontSize: "15px",
  fontWeight: 400,
  marginInlineStart: "0px!important",
}

const titleStyle = {
  fontSize: "18px",
  fontWeight: 700,
  marginBottom: "8px"
}

const dividerWrapper = {
  paddingTop: "16px",
  paddingBottom: "16px"
}

const dividerStyle = {
  background: "rgba(231, 231, 231, 0.50)",
  border: 0,
  height: "1px"
}

const cardStyle = {
  borderRadius: "18px",
  background: "white",
  boxShadow: "0px 1px 33px 0px rgba(0, 0, 0, 0.08)",
  padding: "12px 20px 28px 20px",
  marginLeft: "-6px",
  marginRight: "-6px",
}



export default function TradingUserListPage() {
  const params = useParams();
  const artistId = params.artist_id;
  const location = useLocation();

  const currentQueryParams = new URLSearchParams(location.search);
  const tradingPhotoCardId = currentQueryParams.get("tradingPhotoCardId");
  const photoCardId = currentQueryParams.get("photoCardId");

  const { data: artist } = useQuery(
    ["artist", artistId],
    () => queryGet(`/artists/${artistId}`),{
      select: (data) => data?.artist,
    }
  );

  const { data: tradingPhotoCard } = useQuery(
    ["tradingPhotoCard", tradingPhotoCardId],
    () => queryGet(`/artists/${artistId}/trading_photo_cards/${tradingPhotoCardId}`),{
      select: (r) => r.tradingPhotoCard,
    }
  );

  const aggregatedEventQueryParams = new URLSearchParams();
  aggregatedEventQueryParams.append('tradingPhotoCardId', tradingPhotoCardId);
  aggregatedEventQueryParams.append('photoCardId', photoCardId);

  const { data: tradingPhotoCards } = useQuery(
    ["aggregatedPhotoCards", artistId, aggregatedEventQueryParams.toString()],
    () => queryGet(`/artists/${artistId}/aggregated_photo_cards_by_event?` + aggregatedEventQueryParams),
    {
      enabled: !!artistId,
      select: (r) => r.aggregatedPhotoCards.flatMap((p) => p.tradingPhotoCards),
    },
  );

  const saleTradingPhotoCards = useMemo(() => {
    return tradingPhotoCards?.filter((t) => t.category === "sale")
  }, [tradingPhotoCards])

  const exchangeTradingPhotoCards = useMemo(() => {
    return tradingPhotoCards?.filter((t) => t.category === "exchange")
  }, [tradingPhotoCards])

  return(
    <div className="homeBackground safeAreaPaddingExceptBottomBackground">
    <Page
      title={t("tradingUserListPage.pageName")}
      showTitle={true}
      titleTransparent={true}
    >
      <div style={{marginTop: "calc(-20px - env(safe-area-inset-top))"}}>
        <MatchedTradingPhotoCardInfo source={tradingPhotoCard} target={first(tradingPhotoCards)}/>
      </div>


      <Flex paddingY={"10px"}/>
      {
        !isEmpty(exchangeTradingPhotoCards) &&
        <Card style={cardStyle}>
          <div style={headerStyle} className="text-center">
            <Text style={emojiStyle}>😊</Text>
            <Text style={titleStyle}>
              {
                hasBatchim(artist?.fandomName) ?
                t("tradingUserListPage.batchimTitle", {fandom: artist?.fandomName}) :
                t("tradingUserListPage.title", {fandom: artist?.fandomName})
              }
            </Text>
          </div>
          {
            exchangeTradingPhotoCards.map((tradingPhotoCard, index) => {
              return (
                <div key={tradingPhotoCard.tradingPhotoCardId}>
                  {index > 0 && <div style={dividerWrapper}><hr style={dividerStyle}/></div>}
                  <UserProfileRow
                    key={tradingPhotoCard.tradingPhotoCardId}
                    tradingPhotoCard={tradingPhotoCard}
                    userProfile={tradingPhotoCard.userProfile}
                    maxTextLength={12}
                  />
                </div>
              )
            })
          }
        </Card>
      }

      <Center paddingY={"20px"}>
        <Text style={dividerTextStyle}>{t("tradingUserListPage.orRow")}</Text>
      </Center>
      {
        !isEmpty(saleTradingPhotoCards) &&
        <Card style={cardStyle}>
          <div style={headerStyle} className="text-center">
            <Text style={emojiStyle}>💸</Text>
            <div style={subTitleStyle}>{t("tradingUserListPage.saleSubTitleRow")}</div>
            <Text style={titleStyle}>
              <span style={{color: "#5C3DFA"}}>{t("tradingUserListPage.saleTitleFirstRow")}</span>
              {t("tradingUserListPage.saleTitleSecondRow", { fandom: artist?.fandomName })}
            </Text>
          </div>
          {
            saleTradingPhotoCards.map((tradingPhotoCard, index) => {
              return (
                <div key={tradingPhotoCard.tradingPhotoCardId}>
                  {index > 0 && <div style={dividerWrapper}><hr style={dividerStyle}/></div>}
                  <UserProfileRow
                    key={tradingPhotoCard.tradingPhotoCardId}
                    tradingPhotoCard={tradingPhotoCard}
                    userProfile={tradingPhotoCard.userProfile}
                    maxTextLength={12}
                  />
                </div>
              )
            })
          }
        </Card>
      }
    </Page>
    </div>
  )
}
