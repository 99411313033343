import React from "react";

import { Box, VStack, useToast } from "@chakra-ui/react";

import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import colors from "../../constants/colors";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ellipsis } from "../../utils/TextUtil";
import mergeUserProfileV1 from "../api/mutations/mergeUserProfileV1";
import PrimaryButton from "../buttons/PrimaryButton";
import ProfileAvatar from "../thumbnails/ProfileAvatar";
import ToastMessageBox from "../alerts/ToastMessageBox";
import { t } from "i18next";

const BoxWrapper = styled.div`
  border: 1px solid;
  border-radius: 12px;
  border-image-source: linear-gradient(134.41deg, rgba(255, 255, 255, 0.6) 5.69%, rgba(255, 255, 255, 0) 95.22%);
  padding: 16px 20px;
  margin-bottom: 8px;
  box-shadow: 0px 3px 20px 0px #0000000A, 0px 0px 8px 0px #00000005;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Nickname = styled.p`
  color: ${colors.eventText};
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-top: 4px;
`

const ArtistName = styled.p`
  color: ${colors.photoCardText};
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: 0em;
  text-align: left;
`

const EmptyWrapper = styled.div`
  padding-left: 8px;
`

export default function UserProfileBox({userProfile, ...props}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();

  const mergeUserProfile = async (userProfile) => {
    const response = await mergeUserProfileV1(userProfile.userProfileId);
    if(response) {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("profiles.mergedNicknameToastMessage")}/>
        ),
      });
      queryClient.invalidateQueries("V1:UserProfilesList");
      setTimeout(() => { navigate(-1) }, 500);
    }
  }

  return(
    (userProfile && typeof userProfile == 'object') ?
    <BoxWrapper {...props}>
      <Row>
        <ProfileAvatar
          widthAndHeight={"52px"}
          svgWidthAndHeight={"36px"}
          padding={"8px"}
          profileImageUrl={userProfile.thumbnailUrl || userProfile.pictureUrl}
        />
        <VStack spacing={0} flexGrow={1} align={"left"} paddingLeft={"8px"}>
          <ArtistName>{userProfile.artistName}</ArtistName>
          <Nickname>{ellipsis(userProfile.nickname, 15)}</Nickname>
        </VStack>
        <PrimaryButton text={t("select")} onClick={() => mergeUserProfile(userProfile)}/>
      </Row>
    </BoxWrapper> :
    <BoxWrapper {...props}>
      <Row>
        <Box style={{position: 'relative'}}>
          <div>
            <DefaultUserProfileBox/>
            <Box>
              <Box width={"52px"} height={"52px"} p={"14px"}>
                <PlusIcon fill="#B2A3FF"/>
              </Box>
            </Box>
          </div>
        </Box>
        <EmptyWrapper>
          <Nickname>{t("profiles.createNewNickname")}</Nickname>
        </EmptyWrapper>
      </Row>
    </BoxWrapper>
  )
}
const DefaultUserProfileBox = () => {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}>
      <svg width={"52px"} height={"52px"}>
        <circle cx="26" cy="26" r="24" fill="none" stroke="#B2A3FF" strokeWidth="2" strokeDasharray="5,5" />
      </svg>
    </div>
  )
}
