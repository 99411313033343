import { Text, VStack } from "@chakra-ui/react";
import FilledButton from "../FilledButton";
import LightColors from "../../constants/LightColors";

const emojiStyle = {
  fontSize: "50px",
}

const titleStyle = {
  fontWeight: 700,
  color: "rgba(68, 68, 68, 1)",
  fontSize: "17px",
  wordBreak: "keep-all",
  lineHeight: "22px"
}

const descriptionStyle = {
  fontWeight: 500,
  color: LightColors.textmainlv4,
  fontSize: "14px",
  lineHeight: "17px"
}

export default function EmptyCase({emoji, title, description, buttonText, onClickButton, props}) {
  return(
    <VStack marginTop={"128px"} alignContent={"center"} className="text-center" style={{...props}}>
      <Text style={emojiStyle}>{emoji}</Text>
      {
        <Text style={titleStyle}>
          {title}
        </Text>
      }
      {
        description && <Text style={descriptionStyle}>
          {description}
        </Text>
      }
      {
        buttonText && <div style={{paddingTop: "30px"}}>
          <FilledButton
            paddingX={"18px"}
            paddingY={"11px"}
            filled={false}
            color={"#444444"}
            fontWeight={600}
            fontSize={"14px"}
            text={buttonText}
            backgroundColor={"#FFF"}
            borderWidth={"1px"}
            borderColor={"#E7E7E7"}
            onClicked={onClickButton}
          />
        </div>
      }
    </VStack>
  )
}
