import React, { useEffect, useState } from "react";
import Page from "../components/Page";
import { t } from "i18next";
import { Box, Center, HStack, Image, Spacer, Text, VStack } from "@chakra-ui/react";
import { ReactComponent as PlusIcon } from "../icons/plus.svg";
import { useArtistQueryAll } from "../components/api/queries/useArtistQuery";
import _, { isEmpty } from "lodash"
import CommonVerticalButtonDialog from "../components/alerts/CommonVerticalButtonDialog";
import baseUrl from "../utils/baseUrl";
import getToken from "../utils/getToken";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import ArtistIconSelector from "../components/selectors/ArtistIconSelector";
import { useMembersQueryAll } from "../components/api/queries/useMemberQuery";
import { ReactComponent as LikeShapeIcon } from "../icons/like.shape.svg";
import FilledButton from "../components/FilledButton";

async function likeMembers(artistId, memberIds) {
  return fetch(baseUrl() + `/artists/${artistId}/like_members`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      memberIds: memberIds
    }),
  }).then((data) => data.json())
}

export default function ManageMemberPage() {
  const [selectedArtist, setSelectedArtist] = useState();
  const [selectedMembers, setSelectedMembers] = useState({});
  const [isRequesting, setIsRequesting] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const members = useMembersQueryAll(selectedArtist?.artistId);

  const getKey = (artist, member) => {
    return `${artist.artistId}_${member.memberId}`;
  };

  // #region update member list
  useEffect(() => {
    if (!members) return;
    if (!selectedArtist) return;

    const selected = { ... selectedMembers };
    for (var member of members) {
      if (member.isLiked) {
        selected[getKey(selectedArtist, member)] = {
          artist: selectedArtist.artistId,
          member: member.memberId
        };
      }
    }
    setSelectedMembers(selected);
  }, [members])

  const onSelectMember = (memberId) => {
    if (!selectedArtist) return;
    const artistId = selectedArtist.artistId;
    const key = `${artistId}_${memberId}`;

    const selected = { ... selectedMembers };
    if (key in selected) {
      delete selected[key];
    } else {
      selected[key] = {
        artist: selectedArtist.artistId,
        member: memberId
      };
    }
    setSelectedMembers(selected);
  }

  const confirmedLikeMembers = async () => {
    if (isRequesting) return;
    if(_.size(selectedMembers) <= 0){
      return alert(t("selectMemberPage.errorAlert"))
    }

    setIsRequesting(true);

    const grouped = _.groupBy(selectedMembers, 'artist');
    const groupedLikedMembers = _.mapValues(grouped, (items) => items.map(item => item.member));

    const requests = Object.entries(groupedLikedMembers)
      .map(([artist, ids]) => {
        return likeMembers(artist, ids);
      });

    Promise.all(requests)
      .then(responses => {
        if (_.every(responses, r => r.success)) {
          queryClient.clear();
          navigate(-1);
        } else {
          alert(t("requestFailed"))
          setIsRequesting(false);
        }
      })
      .catch(error => {
        alert(t("requestFailed"));
        setIsRequesting(false);
      });
  }

  const hasMember = (memberId) => {
    const artistId = selectedArtist.artistId;
    const key = `${artistId}_${memberId}`;
    return key in selectedMembers;
  }
  // #endregion

  return (
    <Page
      title={t('manageMemberPage.pageName')}
      showBackButton={true}
      showTitle={true}
      noGutter={true}>
        <VStack
          width={"100%"}
          maxWidth={"xl"}
          pt={"12px"}
          style={{margin: "0px -24px"}}
        >
          {
            // #region 아티스트 선택
          }
          <Box
            overflowX={"scroll"}
            overflowY={"hidden"}
            css={{
              '::-webkit-scrollbar': {
                display: 'none' // 스크롤바 숨김
              }
            }}
            width={"100%"}
            maxWidth={"xl"}
          >
            <ArtistIconSelector
              onSelected={(artist) => setSelectedArtist(artist)}
            />
          </Box>
          {
            // #endregion
          }

          {
            // #region 멤버 선택
          }
          <VStack
            spacing={"16px"}
            width={"100%"}
            maxWidth={"xl"}
            pt={"10px"}
            mb={"96px!important"}
            px={"24px"}
          >
          {
            members?.map((member, index) =>{
              return (
                <HStack
                  onClick={()=> onSelectMember(member.memberId)}
                  key={member.memberId}
                  width={"100%"}
                  maxWidth={"xl"}
                >
                  <Text fontSize={"40px"} lineHeight={"48px"} fontWeight={800} color={hasMember(member.memberId) ? "#5C3DFA" : "#E7E7E7"}>{member.name}</Text>
                  <LikeShapeIcon fill={hasMember(member.memberId) ? "#5C3DFA" : "#FFFFFF"} width={42} height={42} style={{marginLeft: "3px"}}/>
                </HStack>
              )
          })}
          </VStack>
          {
            // #endregion
          }
        </VStack>

        <Center className="mt-5">
        <FilledButton
          text={t("selectMemberPage.next")}
          onClicked={confirmedLikeMembers}
          minWidth={"335px"}
          minHeight={"52px"}
          paddingX={"24px"}
          paddingY={"16px"}
          className="bottomButton"
          isLoading={isRequesting}
        />
      </Center>
    </Page>
  )
}
