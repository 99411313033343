import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { enabledReactGA, init, setPageview } from "./ga";

const RouteChangeTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if(enabledReactGA()){
      init();
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      setPageview(location.pathname + location.search);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
}

export default RouteChangeTracker;
