import baseUrl from "../../../utils/baseUrl"
import getToken from "../../../utils/getToken"

export default async function mergeUserProfileV1(userProfileId) {
  async function mergeUserProfile(userProfileId) {
    return fetch(baseUrl() + `/api/v1/user_profiles/${userProfileId}/merge_trading_profiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    }).then((data) => data.json())
  }
  return mergeUserProfile(userProfileId);
}
