import React from "react";
import styled from "styled-components";

import { Image } from "@chakra-ui/react";
import LightColors from "../../constants/LightColors";
import { ReactComponent as ProfileFillIconSvg } from "../../icons/profile.fill.svg";
import { ReactComponent as RefreshFillIconSvg } from "../../icons/refresh.fill.svg";


const AvatarWrapper = styled.div`
  background-color: rgba(220, 214, 250, 1);
  border-radius: 50%;
  padding: 5px;
`

const ImageWrapper = styled.div`
  border-radius: 32px;
  background: "lightgray 50% / cover no-repeat, rgba(122, 89, 255, 0.26)";
  border: 1px solid ${LightColors.background};
`

export default function AuthorToggleAvatar({isAnonymous, profileImageUrl, widthAndHeight=24, svgWidthAndHeight=24, ...props}) {
  const RefreshIcon = () => {
    return(
      <div className="absolute" style={{zIndex: 1, right: "-1px", bottom: "-1px", width: "14px", height: "14px", borderWidth: "1px", borderColor: "white", borderRadius: "50%", background: "white"}}>
        <div style={{width: "12px", height: "12px", borderRadius: "50%", background: LightColors.primary}}>
          <RefreshFillIconSvg width={"12px"} height={"12px"} fill={"white"}/>
        </div>
      </div>
    )
  }

  return (
    isAnonymous ?
    <ImageWrapper style={{width: `${widthAndHeight}px`, height: `${widthAndHeight}px`, position: "relative"}}>
      <Image objectFit={"cover"} src={"/assets/icons/thumbnail.ghost.profile.png"} style={{width: `${widthAndHeight}px`}}/>
      {RefreshIcon()}
    </ImageWrapper> :
    (
      profileImageUrl ?
      <ImageWrapper style={{width: `${widthAndHeight}px`, height: `${widthAndHeight}px`, position: "relative"}}>
        <div style={{width: `${widthAndHeight-2}px`, height: `${widthAndHeight-2}px`, overflow: "hidden"}}>
          <Image borderRadius={"full"} objectFit={"cover"} src={profileImageUrl} style={{width: `${widthAndHeight-2}px`, height: `${widthAndHeight-2}px`}}/>
          {RefreshIcon()}
        </div>
      </ImageWrapper> :
      <AvatarWrapper style={{width: `${widthAndHeight}px`, height: `${widthAndHeight}px`, position: "relative", ...props}}>
        <ProfileFillIconSvg width={svgWidthAndHeight} height={svgWidthAndHeight} fill="white" />
        {RefreshIcon()}
      </AvatarWrapper>
    )
  );
}
