
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

import { isUndefined } from 'lodash';
import styled from 'styled-components';
import useCountryMenusQuery from '../api/queries/useCountryMenusQuery';
import GridSkeletons from '../skeletons/GridSkeletons';
import CircleMenu from "./CircleMenu";

const ScrollMenuWrapper = styled.div`
`

export default function HorizontalScollMenus({user}) {
  const menus = useCountryMenusQuery("KR")
  // TODO : Filter menus by user country and locale
  if(isUndefined(menus)) {
    return(
      <ScrollMenuWrapper>
        <GridSkeletons
          gridSize={1}
          skeletonSize={1}
          skeletonHeight={'100px'}
          style={{paddingLeft: "24px", paddingRight: "24px"}}
        />
      </ScrollMenuWrapper>
    )
  }
  return(menus &&
    <ScrollMenuWrapper>
      <ScrollMenu wrapperClassName='padding-left-scroll-container'>
        {
          menus.map((menu, index) => {
            return(
              <div key={index} style={{paddingLeft: index > 0 ? "8px" : ""}}>
                <CircleMenu
                  title={menu.title}
                  imageSvgBase64={menu.imageSvgBase64}
                  linkPath={menu.linkPath}
                  deeplinkPath={menu.deeplinkPath}
                />
              </div>
            )
          })
        }
      </ScrollMenu>
    </ScrollMenuWrapper>
  )
}
