import React from "react";

import { Box, Image, VStack } from '@chakra-ui/react';
import styled from "@emotion/styled";
import { t } from "i18next";
import { Virtuoso } from "react-virtuoso";

import LightColors from "../../constants/LightColors";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import FilledButton from "../FilledButton";
import { TagsList } from "../photocard/TagsList";

const EventName = styled.p`
  color: ${LightColors.textmainlv2};
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const PhotoCardName = styled.div`
  color: ${LightColors.textmainlv3};
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: left;
  margin-top: 8px!important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 4px;
  flex-wrap: wrap;
  margin-top: 8px !important;
`

export default function TradingPhotoCardWindowList({
  tradingPhotoCards,
  addTradingPhotoCard,
  removeTradingPhotoCard,
  windowHeight="63vh",
  editable=false
}) {
  return (
    <div style={{marginTop: "16px", marginBottom: "32px"}}>
      <Virtuoso
        data={tradingPhotoCards}
        style={{ height: windowHeight }}
        overscan={100}
        itemContent={(index, p) => (
          <Box key={p.photoCardId} borderWidth={1} padding={"10px"} width={"100%"} borderRadius={"12px"} marginTop={index !== 0 && "12px"}>
            <div className="flex">
              <div className="flex-none" style={{paddingLeft: "6px", marginTop: "6px", marginBottom: "6px", marginRight: "14px"}}>
                <Image
                  src={p.pictureUrl}
                  width={"48px"}
                  height={"75px"}
                  margin={"0px!important"}
                  borderRadius={"8px"}
                />
              </div>
              <div className="flex-grow">
                <VStack alignItems={"start"} spacing={0} paddingTop={"6px"}>
                  {/* <Text noOfLines={1} fontSize={"15px"} lineHeight={"18px"} fontWeight={600} color={"#222"}>{removedEmojiEventName(p.eventName)}</Text>
                  <Text noOfLines={1} paddingTop={"4px"} fontSize={"14px"} lineHeight={"17px"} fontWeight={500} color={"#666"} className="overflow-hidden">{p.event?.description}</Text>
                  <Badge marginTop={"12px!important"} paddingX={"8px"} paddingY={"4px"} borderRadius={"4px"} fontSize={"13px"} fontWeight={600} lineHeight={"16px"}>{p.memberName}</Badge> */}
                  <EventName>{t("photoCardDetailPage.title", {memberName: p.memberName, categoryName: p.categoryName, categoryEmoji: p.categoryEmoji})}</EventName>
                  <PhotoCardName>{p.eventName}</PhotoCardName>
                  <TagsWrapper>
                    <TagsList photoCard={p}/>
                  </TagsWrapper>
                </VStack>
              </div>
              <div className="flex-none content-center">
              {
                !!editable ?
                <>
                  <div className="flex justify-end" onClick={()=>removeTradingPhotoCard(p, index)}>
                    <CloseIcon width={"28px"} height={"28px"} fill="#5C3DFA" style={{ backgroundColor: "rgba(92, 61, 250, 0.06)", borderRadius: "32px", padding: "6px"}}/>
                  </div>
                </>
                :
                <FilledButton
                  paddingX={"12px"}
                  paddingY={"6px"}
                  fontSize={"13px"}
                  lineHeight={"16px"}
                  borderRadius={"32px"}
                  fontWeight={500}
                  text={t("addTradingPhotoCard.addButton")}
                  onClicked={()=>addTradingPhotoCard(p, p.event)}
                />
              }
              </div>
            </div>
          </Box>
        )}
      />
    </div>
  )
}
