import { Box } from "@chakra-ui/react";

export default function WhiteBadge({text, ...props}) {
  return (
    <Box
      bgColor={"white"}
      textColor={"#444"}
      paddingX={"10px"}
      paddingY={"6px"}
      className="text-center"
      borderRadius={"4px"}
      fontWeight={500}
      fontSize={"12px"}
      lineHeight={"14px"}
      border={"1px solid var(--gray-separator, #E7E7E7)"}
      {...props}
    >
      {text}
    </Box>
  )
}