import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";
import { isEmpty } from "lodash";

export default function useCollectedPhotoCardIdsQuery({artistId, memberId, eventIds, photoCardCategories}) {
  const queryParams = new URLSearchParams({
    "member_id": memberId
  });

  if (!isEmpty(eventIds)) {
    queryParams.append('event_ids', eventIds);
  }
  if (!isEmpty(photoCardCategories)) {
    queryParams.append('photo_card_categories', photoCardCategories);
  }

  const { data: collectedPhotoCardIds } = useQuery(
    ["collectedPhotoCardIds", artistId, memberId, eventIds, photoCardCategories],
    () => queryGet(`/api/v1/artists/${artistId}/photo_cards/collected_ids?${queryParams.toString()}`),{
      select: (data) => data.collectedPhotoCardIds,
      enabled: !!artistId && !!memberId,
    }
  );
  return collectedPhotoCardIds;
}
