import React from "react";
import { VStack, Text } from '@chakra-ui/react';
import FilledButton from "../FilledButton";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export default function EmptyPhotoCard({artistId, likedMembers, ...props}) {
  const navigate = useNavigate();

  const navigateEditPhotoCardPage = () => {
    navigate(`/artists/photo_cards_v2?editable=true`);
  }

  return (
    <VStack marginTop={"40px"}>
      <Text fontSize={"50px"}>😘</Text>
      <Text fontSize={"17px"} fontWeight={700} color={"#444444"}>
      {t("emptyPhotoCard.title")}
      </Text>
      <Text fontSize={"14px"} fontWeight={400} color={"#888888"} paddingBottom={"20px"}>
        {t("emptyPhotoCard.description")}
      </Text>
      <FilledButton
        paddingX={"18px"}
        paddingY={"11px"}
        filled={false}
        color={"#444444"}
        fontWeight={600}
        fontSize={"14px"}
        text={t("emptyPhotoCard.startCollectPhotoCard")}
        backgroundColor={"#FFF"}
        borderWidth={"1px"}
        borderColor={"#E7E7E7"}
        onClicked={()=>navigateEditPhotoCardPage(true)}
      />
    </VStack>
  )
}
