import { Image } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import TempPaymentAgreementBottomSheet from "../../components/bottomSheet/TempPaymentAgreementBottomSheet";
import ShopProductFrame from "./ShopProductFrame";


const shadowStyle = {
  borderRadius: "24px",
  boxShadow: "0px 0px 22px 10px rgba(0,0,0,0.02),0px 5px 11px 0px rgba(0,0,0,0.04)"
};

export default function ShopProductDermatoryPad() {
  const navigate = useNavigate();
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);

  const handleClick = () => {
    navigate(`/orders/${uuid()}`)
  }

  return <>
  <ShopProductFrame>
    <div style={{
      paddingBottom: "calc(30px + env(safe-area-inset-bottom))",
      paddingLeft: "24px",
      paddingRight: "24px",
    }}>
      <Image src="/assets/shop/p1/header.png" marginBottom={"22px"}/>
      <Image
        src="/assets/shop/p1/product_btn.png"
        borderRadius="38px!important"
        marginBottom={"22px"}
        style={shadowStyle}
        onClick={() => setIsOpenBottomSheet(true)}
      />
      <Image src="/assets/shop/p1/footer.png" />
    </div>
  </ShopProductFrame>

  <TempPaymentAgreementBottomSheet
    isOpen={isOpenBottomSheet}
    setIsOpen={setIsOpenBottomSheet}
    onAgreementClick={handleClick}
    title={"상품을 확인하고 결제해주세요"}
    description={"결제에 계속 진행하시겠습니까?"}
  />
  </>
}
