import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export function useMembersQueryLikedOnly(artistId) {
  const { data: members } = useQuery(
    ["likedMembers", artistId],
    () => queryGet(`/api/v1/artists/${artistId}/members?liked_only=true`),{
      select: (data) => data.members,
      enabled: !!artistId,
    }
  );
  return members;
}

export function useMembersQueryAll(artistId) {
  const { data: members } = useQuery(
    ["likedAllMembers", artistId],
    () => queryGet(`/api/v1/artists/${artistId}/members?liked_only=false`),{
      select: (data) => data.members,
      enabled: !!artistId,
    }
  );
  return members;
}
