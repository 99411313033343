import baseUrl from "./baseUrl"
import getToken from "./getToken"

export default async function ImageUpload(dataUrl, artistId, memberId, eventName, eventCategory, eventId) {
  return fetch(baseUrl() + `/photo_cards/upload`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      dataUrl: dataUrl,
      artistId: artistId,
      memberId: memberId,
      eventName: eventName,
      eventCategory: eventCategory,
      eventId: eventId,
    }),
  }).then((data) => data.json())
}