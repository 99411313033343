import React from "react";

import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogOverlay, Text, VStack } from '@chakra-ui/react';
import { t } from "i18next";

import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import LightColors from "../../constants/LightColors";

const titleStyle ={
  fontWeight: 700,
  fontSize: "17px",
  color: "#222"
}

const descriptionStyle ={
  fontWeight: 400,
  fontSize: "15px",
  color: "#888",
  whiteSpace: "pre-line"
}

export default function LeaveChatRoomDialog({isOpen, onClose, onClickDialogButton}) {
  return (
  <AlertDialog
    isOpen={isOpen}
    onClose={onClose}
    isCentered
    >
      <AlertDialogOverlay style={{ zIndex: 2147483647 }} >
        <AlertDialogContent borderRadius={"40px"} paddingTop={"32px"} paddingBottom={"16px"} marginX={"6%"} style={{boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)"}}>
          <AlertDialogBody paddingX={"32px"}>
            <VStack className="text-center">
              <Text style={titleStyle}>{t("tradingPhotoCardChatRoom.leaveChatRoomDialogTitle")}</Text>
              <Text style={descriptionStyle}>{t("tradingPhotoCardChatRoom.leaveChatRoomDialogDescription")}</Text>
            </VStack>
            <VStack className="w-full" marginTop={"20px"}>
              <PrimaryButton
                fontWeight={600}
                fontSize={"17px"}
                lineHeight={"20px"}
                padding={"16px 34px"}
                width={"100%"}
                onClick={()=>onClickDialogButton()}
                text={t("tradingPhotoCardChatRoom.leaveChatRoomDialogConfirm")}
              />
              <SecondaryButton
                fontWeight={600}
                fontSize={"17px"}
                lineHeight={"20px"}
                padding={"16px 34px"}
                width={"100%"}
                onClick={()=>onClose()}
                color={LightColors.primary}
                text={t("cancel")}
              />
            </VStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
