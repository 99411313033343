import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

const DEFAULT_PAGE = 30

export default function useExploreTradingPhotoCardsQuery(artistId, memberId, category, userProfileId, sortBy="scoring", perPage) {
  memberId = memberId || "all"
  async function fetchExploreTradingPhotoCards({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage || DEFAULT_PAGE,
      page: pageParam,
      member_id: memberId,
      sort_by: sortBy,
    });

    if(userProfileId) {
      queryParams.append("user_profile_id", userProfileId)
    }

    if(category) {
      queryParams.append("category", category)
    }

    const url = `/api/v1/artists/${artistId}/explore_trading_photo_cards?${queryParams.toString()}`;
    try {
      const data = await queryGet(url);
      return data;
    } catch (error) {
      throw new Error('Error fetching paginated data.');
    }
  }

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["ExploreTradingPhotoCards", artistId, memberId, category, userProfileId, sortBy],
    fetchExploreTradingPhotoCards,
    {
      enabled: !!artistId,
      getNextPageParam: (lastPage) => {
        if (!!lastPage?.meta?.pagination?.nextPage) {
          return lastPage?.meta?.pagination?.nextPage;
        } else {
          return undefined
        }
      }
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  };
}
