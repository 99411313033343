import React, { useEffect, useState } from "react";

import { Box, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { t } from "i18next";
import _ from "lodash";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useLocation } from "react-router-dom";

import Page from "../components/Page";
import { useArtistQueryLikedOnly } from "../components/api/queries/useArtistQuery";
import ArtistIconSelector from "../components/selectors/ArtistIconSelector";
import { getArtistId } from "../utils/etcUtils";
import WishPhotoCardsExchangeTab from "./tabs/WishPhotoCardsExchangeTab";
import WishPhotoCardsSaleTab from "./tabs/WishPhotoCardsSaleTab";
import WishPhotoCardsTab from "./tabs/WishPhotoCardsTab";
import ScrollRestoration from "../utils/ScrollRestoration";

const activeTabStyle = {
  fontWeight: 700,
  borderBottom: '3px solid',
  borderColor: '#5C3DFA',
  color: "#5C3DFA",
  borderBox: ""
}

export default function MyPhotoCardsWishPage() {
  // const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  const [tabIndex, setTabIndex] = useState(currentQueryParams.get("tabIndex") || 0);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const likedArtists = useArtistQueryLikedOnly();

  ScrollRestoration(window.location.pathname);

  useEffect(() => {
    if (selectedArtist) return;
    if (!likedArtists) return;

    const initialArtist = _.chain(likedArtists)
     .filter(a => a.artistId === getArtistId())
     .first();

    if (initialArtist) {
      setSelectedArtist(initialArtist);
    } else {
      setSelectedArtist(_.first(likedArtists));
    }
  }, [likedArtists]);

  // #region handlers
  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  useEffect(() => {
    if (!likedArtists) return;
    if (likedArtists.length === 1) {
      setSelectedArtist(likedArtists[0]);
    }
  }, [likedArtists])
  // #endregion

  return (
  <div>
    <Page
      title={t("myWishedPage.pageName")}
      showTitle={true}
      showBackButton={true}
      noGutter={true}
    >
      <Tabs isFitted index={parseInt(tabIndex)} paddingTop={"2px"} borderColor={"transparent"} colorScheme="primary.500" onChange={handleTabsChange}>
        <TabList marginX={"-24px"} paddingX={"16px"} borderBottom={"1px"} borderColor={"rgba(0, 0, 0, 0.04)"}>
          <Tab _active={""} _selected={activeTabStyle} lineHeight={"19px"} paddingY={"13px"}>{t("myWishedPage.wishedPhotoCardsTab")}</Tab>
          <Tab _active={""} _selected={activeTabStyle} lineHeight={"19px"} paddingY={"13px"}>{t("myWishedPage.wishedExchangesTab")}</Tab>
          <Tab _active={""} _selected={activeTabStyle} lineHeight={"19px"} paddingY={"13px"}>{t("myWishedPage.wishedSalesTab")}</Tab>
        </TabList>
        <VStack style={{margin: "0px -24px"}}>
          {
            // #region 아티스트를 한명 이상 등록되어있을 때만 보여주는 아티스트 실렉터
          }

          {
            (likedArtists?.length ?? 0) > 1
              ? (
                <Box
                  overflowX="scroll"
                  overflowY="hidden"
                  pt="16px"
                  // px="10px"
                  css={{
                    '::-webkit-scrollbar': {
                      display: 'none' // 스크롤바 숨김
                    }
                  }}
                  width={"100%"}
                  maxWidth= "calc(var(--chakra-sizes-xl))"
                >
                  <ArtistIconSelector onSelected={setSelectedArtist}/>
                </Box>
              ) : (
                <Box h={"10px"}/>
              )
          }

          {
            // #endregion
          }

          <TabPanels style={{ paddingBottom: "120px", paddingTop: "8px" }} px={"24px"} >
            <WishPhotoCardsTab artist={selectedArtist}/>
            <WishPhotoCardsExchangeTab artist={selectedArtist}/>
            <WishPhotoCardsSaleTab artist={selectedArtist}/>
          </TabPanels>
        </VStack>
      </Tabs>
    </Page>
  </div>
  )
}
