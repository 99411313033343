import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useCollectedPhotoCardsStat(userId) {
  const { data: collectedPhotoCardsStat } = useQuery(
    ["CollectedPhotoCardsStat", userId],
    () => queryGet(`/api/v1/users/${userId}/collected_photo_cards_stat`),{
      select: (data) => data.user,
      enabled: !!userId,
    }
  );
  return collectedPhotoCardsStat;
}
