import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function confirmTradingPhotoCardReview({
  reviewId,
}) {
  return fetch(baseUrl() + `/api/v1/reviews/${reviewId}/confirm`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
  });
}
