import React from "react";
import { Box, Flex, Text, VStack } from '@chakra-ui/react';

import Page from "../components/Page";

import SecondaryBadge from "../components/badges/SecondayBadge";
import PrimaryButton from "../components/buttons/PrimaryButton";
import { t } from "i18next";

export default function SharePocapanPage() {
  return (
    <>
      <Page title={t("sharePocapanPage.pageName")} showBackButton={true}>
        <Flex height={"calc(var(--vh, 1vh) * 100 - 90px)"} direction={"column"} paddingTop={"36px"} marginTop={"24px"}>
          <VStack paddingX={"10px"} gap={0}>
            <SecondaryBadge text={t("sharePocapanPage.waitingCountBadge", {count: 963})}/>
            <Text color={"black"} fontWeight={700} fontSize={"26px"} lineHeight={"31px"} marginTop={"15px!important"}>
              {t("sharePocapanPage.title")}
            </Text>
            <Text marginTop={"28px!important"} paddingX={"18px"} color={"gray"} fontWeight={400} fontSize={"13px"} lineHeight={"16px"} className="text-center whitespace-pre-line">
              {t("sharePocapanPage.description")}
            </Text>
            <Box bg={"secondary.600"} borderRadius={"12px"} paddingX={"18px"} paddingTop={"16px"} paddingBottom={"56px"} maxWidth={"320px"}>
              <Text color={"#444"} fontWeight={600} fontSize={"17px"} wordBreak={"keep-all"} >
                {"https://pocapan.com/?utm_source=pocapan&utm_medium=organic&utm_campaign=pocamatching&utm_content=invite"}
              </Text>
            </Box>
            <PrimaryButton
              marginTop={"32px!important"}
              text={t("sharePocapanPage.copyLinkButton")}
              paddingX={"24px"}
              paddingY={"16px"}
              fontWeight={600}
              fontSize={"17px"}
              lineHeight={"20px"}
              onClick={() => navigator.clipboard.writeText("https://pocapan.com/?utm_source=pocapan&utm_medium=organic&utm_campaign=pocamatching&utm_content=invite")}
            />
          </VStack>
        </Flex>
      </Page>
    </>
  )
}
