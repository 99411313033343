import React, { useCallback, useEffect, useRef, useState } from "react";

import { Box, Button, IconButton, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { first, isEmpty, isUndefined } from "lodash";
import { Trans } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

import useArtistQuery from "../../components/api/queries/useArtistQuery";
import useReviewStatsQuery from "../../components/api/queries/useReviewStatsQuery";
import useTradingPhotoCards from "../../components/api/queries/useTradingPhotoCards";
import useUserProfileQuery from "../../components/api/queries/useUserProfileQuery";
import EmptyCase from "../../components/errors/EmptyCase";
import VerifiedIcon from "../../components/icons/VerifiedIcon";
import MultiSelectLikedMembersList from "../../components/lists/MultiSelectLikedMembersList";
import Page from "../../components/Page";
import ArtistTextSelector from "../../components/selectors/ArtistTextSelector";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import ProfileAvatar from "../../components/thumbnails/ProfileAvatar";
import ExploreTradingPhotoCard from "../../components/tradings/ExploreTradingPhotoCard";
import LightColors from "../../constants/LightColors";
import { ReactComponent as ArrowRightIcon } from "../../icons/arrow.right.svg";
import { ReactComponent as ChevronDownSmallIcon } from "../../icons/chevron.down.small.svg";
import { ReactComponent as MoreIcon } from "../../icons/more.svg";
import { ReactComponent as SettingIcon } from "../../icons/setting.svg";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";
import BoxTabs from "../tabs/BoxTabs";

const ProfileWrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const NicknameWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  align-items: center;
`

const Nickname = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: ${LightColors.textmainlv1};
`

const VerifiedIconWrapper = styled.div`
  margin-left: 3px;
`

const DescriptionWrapper = styled.div`
  margin-top: 6px;
`

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: ${LightColors.textmainlv1};
`
const BoxTabsWrapper = styled.div`
  margin-top: 24px;
`

const ReviewScoreColor = (score) => {
  return score <= 30 ? "#FF4C41" : (score <= 60 ? "#FAD722" : "#2DDD28")
}

const ColoreText = (score) => {
  return score <= 30 ? t("ReviewColorText.red") : (score <= 60 ? t("ReviewColorText.yellow") : t("ReviewColorText.green"))
}

export default function TradingUserProfilePage() {
  const params = useParams();

  const [category, setCategory] = useState("sale");

  const [selectedArtistId, setSelectedArtistId] = useState();
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);

  const [paginatedTradingPhotoCards, setPaginatedTradingPhotoCards] = useState();

  const userProfile = useUserProfileQuery(params["user_profile_id"]);
  const reviewStats = useReviewStatsQuery({userProfileId: params["user_profile_id"]});
  const { data, isFetching, fetchNextPage, hasNextPage } = useTradingPhotoCards({
    userProfileId: params["user_profile_id"],
    artistId: selectedArtistId,
    memberIds: selectedMemberIds,
    category: category,
  });

  const handlePaginatedTradingPhotoCards = (paginatedData) => {
    const tradingPhotoCards = paginatedData.pages?.flatMap((page) =>
      page.tradingPhotoCards.map((tradingPhotoCard) => tradingPhotoCard)
    )
    setPaginatedTradingPhotoCards(tradingPhotoCards);
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedTradingPhotoCards(data);
    }
  }, [data]);

  const onTabSelected = (index) => {
    setCategory(index === 0 ? "sale" : "exchange")
  }

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isFetching, fetchNextPage, hasNextPage]
  );

  const onSelectedArtistId = (artistId) => {
    setSelectedArtistId(artistId)
  }

  const onSelectedMemberIds = (memberIds) => {
    setSelectedMemberIds(memberIds);
  }
  const handleHistoryBack = useHandleHistoryBack();


  return (
    <div style={{background: "linear-gradient(180deg, #F5F6F8 0%, #F5F6F8 23%, #FBFBFC 37%, #FFF 45%)"}}>
      <Page
        title={t("TradingUserProfilePage.pageTitle")}
        showTitle={true}
        noPadding={true}
        showBackButton={true}
        titleTransparent={true}
        navigationElement={<ProfileMenus userProfile={userProfile}/>}
        onHistoryBackClick={handleHistoryBack}
      >
        <ProfileWrapper>
          <ProfileSection userProfile={userProfile}/>
          <TradingStatCards reviewStats={reviewStats} userProfile={userProfile}/>
          <BoxTabsWrapper>
            <BoxTabs
              tabLabels={[
                t("TradingUserProfilePage.saleTab"),
                t("TradingUserProfilePage.exchangeTab"),
              ]}
              onTabSelected={onTabSelected}
            />
          </BoxTabsWrapper>
          <TradingPhotoCardsFilter
            userProfile={userProfile}
            onSelectedArtistId={onSelectedArtistId}
            onSelectedMemberIds={onSelectedMemberIds}
          />
          <TradingPhotoCardsList
            category={category}
            tradingPhotoCards={paginatedTradingPhotoCards}
            pagination={first(data?.pages)?.meta?.pagination}
            lastElementRef={lastElementRef}
            isMine={userProfile?.isMine}
          />
        </ProfileWrapper>
      </Page>
    </div>
  )
}

const ProfileMenus = ({userProfile}) => {
  const navigate = useNavigate();
  if (isEmpty(userProfile)) {
    return (<></>)
  }
  else if (userProfile.isMine) {
    return(
      <SettingIcon
        onClick={() => navigate("settings")}
        fill={LightColors.textmainlv1}
        width={"24px"}
        height={"24px"}
      />
    )
  } else {
    return (
      <Menu>
        <MenuButton
          height={"24px"}
          as={IconButton}
          variant=""
          aria-label='Options'
          minWidth={"0px"}
          paddingX={"0px!important"}
          icon={<MoreIcon fill={LightColors.textmainlv1} width={"24px"}height={"24px"}/>}
        />
        <MenuList>
          <MenuItem onClick={() => navigate("/trading_reports")}>
            {t("report")}
          </MenuItem>
        </MenuList>
      </Menu>
    )
  }
}

const ProfileSection = ({userProfile}) => {
  return (
    <>
      <Row style={{paddingTop: "12px"}}>
        <ProfileAvatar
          profileImageUrl={userProfile?.thumbnailUrl}
          widthAndHeight={"72px"}
          svgWidthAndHeight={"36px"}
          padding={"18px"}
        />
      </Row>
      <Row>
        <NicknameWrapper>
          <Nickname>{userProfile?.nickname}</Nickname>
          <VerifiedIconWrapper>
            {
              userProfile?.identified &&
              <VerifiedIcon width="18px" height="18px"/>
            }
          </VerifiedIconWrapper>
        </NicknameWrapper>
      </Row>
      {
        userProfile?.description &&
        <Row>
          <DescriptionWrapper>
            <Description>
              {userProfile?.description}
            </Description>
          </DescriptionWrapper>
        </Row>
      }
    </>
  )
}

const StatsBox = styled.div`
  border: 1px solid ${LightColors.separatorlv2};
  border-radius: 24px;
  width: 114px;
  height: 114px;
  align-content: center;
  padding-left: 16px;
  padding-right: 16px;
`

const StatEmoji = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
`

const StatTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  color: ${LightColors.textmainlv1};
  padding-top: 16px;
`

const StatArrowWrapper = styled.div`
  padding-top: 18px;
`

const StatDescription = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16x;
  text-align: left;
  color: ${LightColors.textmainlv4};
  padding-top: 4px;
`

const ProgressContainer = styled.div`
  width: 100%;
  height: 35px;
  position: relative;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 6px; /* Progress bar height */
  background: linear-gradient(270deg, #00D72F 0%, #FFF500 49.5%, #FF5E5E 100%);
  border-radius: 3px;
  position: absolute;
  top: 50%;
  opacity: 30%;
  transform: translateY(-50%); /* Centering the progress bar vertically */
`;

const ProgressDot = styled.div`
  position: absolute;
  top: 50%;
  left: ${(props) => props.score}%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: ${(props) => ReviewScoreColor(props.score)};
  border-radius: 50%;
  transition: left 0.5s ease;
`;


const LinearProgressWithDot = ({ score }) => {
  return (
    <ProgressContainer>
      <ProgressBar score={score} />
      <ProgressDot score={score} />
    </ProgressContainer>
  );
};

const StyledScoreTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #666666;
  padding-top: 16px;
`;

const ColorText = styled.span`
  color: ${(props) => props.reviewColor};
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
`;

const ScoreTitle = ({ score }) => {
  const reviewColor = ReviewScoreColor(score);
  const colorText = ColoreText(score);
  const onlyColorText = score <= 10 || (score >= 40 && score <= 60) || (score >= 80 && score <= 100);

  return (
    <StyledScoreTitle>
      {
        onlyColorText ?
        <ColorText reviewColor={reviewColor}>{colorText}</ColorText> :
        <Trans
          i18nKey="TradingUserProfilePage.reviewColorText"
          values={{ color: colorText }}
          components={{
            color: <ColorText reviewColor={reviewColor} />
          }}
        />
      }
    </StyledScoreTitle>
  );
};

const StatBox = ({score, emoji, title, description, navigateUrl}) => {
  const navigate = useNavigate();
  return (
    <StatsBox>
      {score && <LinearProgressWithDot score={score}/>}
      {emoji && <StatEmoji>{emoji}</StatEmoji>}
      {
        <Row>
          {
            title ?
            <StatTitle>
              {title}
            </StatTitle> :
            (score && <ScoreTitle score={score}/>)
          }
          {
            navigateUrl &&
            <StatArrowWrapper>
              <ArrowRightIcon
                fill={LightColors.pureblack}
                width={"15px"}
                height={"15px"}
                onClick={() => navigate(navigateUrl)}
              />
            </StatArrowWrapper>
          }
        </Row>
      }

      <StatDescription>
        {description}
      </StatDescription>
    </StatsBox>
  )
}

const TradingStatCards = ({reviewStats, userProfile}) => {
  if (isEmpty(userProfile) || isEmpty(reviewStats)) {
    return (
      <div style={{marginTop: "20px"}}>
        <GridSkeletons gridSize={3} skeletonSize={3} skeletonHeight={'114px'} gap={"10px"}/>
      </div>
    )
  } else {
    return (
      <ScrollMenu>
        <Row style={{marginTop: "20px", columnGap: "10px"}}>
          {
            reviewStats &&
            <StatBox
              score={reviewStats.ratingsScore}
              description={t("TradingUserProfilePage.mannerSignal")}
            />
          }
          {
            reviewStats.reviewsCount > 0 &&
            <StatBox
              emoji={"💬"}
              title={`${t("TradingUserProfilePage.reviewsCount", {count: reviewStats.reviewsCount})}`}
              description={t("TradingUserProfilePage.statTradingReviews")}
              navigateUrl={`/trading_photo_cards/reviews?userProfileId=${userProfile.userProfileId}`}
            />
          }
          {
            userProfile.lastLoginText &&
            <StatBox
              emoji={"🕐"}
              title={userProfile.lastLoginText}
              description={t("TradingUserProfilePage.statLastLogin")}
            />
          }
          {
            !isEmpty(userProfile.chatStats) &&
            <StatBox
              emoji={"⌛️"}
              title={parseInt(userProfile.chatStats.responseRate) + "%"}
              description={t("TradingUserProfilePage.statResponseRate")}
            />
          }
        </Row>
      </ScrollMenu>
    )
  }
}

const DropdownTextOnly = ({artist}) => {
  return (
    <Menu>
      <MenuButton
        height={"40px"}
        as={Button}
        colorScheme=""
        color={"black"}
        width={"fit-content"}
        padding={0}
        rightIcon={
          <div style={{marginLeft: "-0.5rem", paddingLeft: "4px"}}>
            <Box height="40px" display="flex" alignItems="center" justifyContent="center">
              <ChevronDownSmallIcon width={"20px"} height={"20px"} fill={"#666666"} opacity={0}/>
            </Box>
          </div>
        }>
        <Box height="40px" display="flex" alignItems="center" justifyContent="center">
          <Text fontSize={"17px"} fontWeight={700} lineHeight={"20px"} color={LightColors.textmainlv1}>{artist?.name}</Text>
        </Box>
      </MenuButton>
    </Menu>
  );
};


const TradingPhotoCardsFilter = ({userProfile, onSelectedArtistId, onSelectedMemberIds}) => {
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [members, setMembers] = useState([]);

  const artistData = useArtistQuery(selectedArtist?.artistId);

  useEffect(() => {
    if (artistData) {
      setMembers(artistData.members);
    }
  }, [artistData]);

  const onSelectedArtist = (artist) => {
    if (artist.artistId === "entire") {
      setSelectedArtist(null);
      setMembers([]);
      onSelectedArtistId(null);
    } else {
      setSelectedArtist(artist);
      onSelectedArtistId(artist.artistId);
    }
  };

  const onSelectedMembers = (members) => {
    onSelectedMemberIds(members.map((member) => member.memberId));
  }

  return(
    <div style={{marginTop: "10px"}}>
      <ArtistTextSelector
        artists={userProfile?.likedArtists}
        includeEntire={true}
        useInitArtistId={false}
        saveArtistId={false}
        onSelected={onSelectedArtist}
        alignDropdown="left"
        customTextAreaElementMultiple={
          (artist) =>
              <MenuButton
                height={"40px"}
                as={Button}
                colorScheme=""
                color={"black"}
                width={"fit-content"}
                padding={0}
                rightIcon={
                  <div style={{marginLeft: "-0.5rem", paddingLeft: "4px"}}>
                    <Box display="flex" alignItems="center" justifyContent="center" >
                      <ChevronDownSmallIcon width={"20px"} height={"20px"} fill={LightColors.textmainlv2}/>
                    </Box>
                  </div>
                }>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Text fontSize={"17px"} fontWeight={700} lineHeight={"20px"} color={LightColors.textmainlv1}>{artist?.name}</Text>
                </Box>
              </MenuButton>
        }
        customTextAreaElementSingle={
          (artist) => <DropdownTextOnly artist={artist}/>
        }
        />
        {
          !isEmpty(members) &&
          <MultiSelectLikedMembersList
            members={members}
            showAllMember={true}
            onChangeLikedMembers={onSelectedMembers}
          />
        }
      </div>
  )
}

const TradingPhotoCardsList = ({category, tradingPhotoCards, pagination, lastElementRef, isMine}) => {
  const navigate = useNavigate();
  return (
    <>
      {
        isUndefined(tradingPhotoCards) ?
          <GridSkeletons gridSize={3} skeletonSize={9} skeletonHeight={'109px'} gap={"6px"}/> :
        isEmpty(tradingPhotoCards) ?
        <EmptyCase
          emoji={"🙂"}
          title={t(`TradingUserProfilePage.${category}EmptyCards`)}
        /> :
        <SimpleGrid
          columns={3}
          spacingX={"7px"}
          spacingY={"19px"}
          marginTop={"2px"}
          marginBottom={"16px"}
        >
        {
          tradingPhotoCards?.map((tradingPhotoCard, index) => {
            if (tradingPhotoCards.length === index + 1) {
              return (
                <div key={tradingPhotoCard.tradingPhotoCardId} ref={lastElementRef}>
                  {
                    <ExploreTradingPhotoCard
                      tradingPhotoCard={tradingPhotoCard}
                      hiddenNickname={true}
                      hiddenChatStat={true}
                      showEventName={true}
                      onClickTradingPhotoCard={isMine ? () => navigate(`/trading_photo_cards/${tradingPhotoCard.tradingPhotoCardId}/my_chat_rooms`) : undefined}
                    />
                  }
                </div>
              );
            } else {
              return (
                <div key={tradingPhotoCard.tradingPhotoCardId}>
                  <ExploreTradingPhotoCard
                    tradingPhotoCard={tradingPhotoCard}
                    hiddenNickname={true}
                    hiddenChatStat={true}
                    showEventName={true}
                    onClickTradingPhotoCard={isMine ? () => navigate(`/trading_photo_cards/${tradingPhotoCard.tradingPhotoCardId}/my_chat_rooms`) : undefined}
                  />
                </div>
              );
            }
          })
        }
      </SimpleGrid>
      }
    </>
  )
}

