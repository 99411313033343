export default class UserProfileResponse {
  constructor({
    userProfileId,
    userId,
    artistId,
    artistName,
    locale,
    nickname,
    identified,
    category,
    chatStats,
    responseText,
    pictureUrl,
    thumbnailUrl,
  }) {
    this.userProfileId = userProfileId;
    this.userId = userId;
    this.artistId = artistId;
    this.artistName = artistName;
    this.locale = locale;
    this.nickname = nickname;
    this.identified = identified;
    this.category = category;
    this.chatStats = chatStats;
    this.responseText = responseText;
    this.pictureUrl = pictureUrl;
    this.thumbnailUrl = thumbnailUrl;
  }
}
