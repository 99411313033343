import React from "react";
import { Box, Center, Spinner } from '@chakra-ui/react';
import LightColors from "../constants/LightColors";

export default function FilledButton(
  {
    text,
    filled=true,
    onClicked,
    isLoading = false,
    ...props
  }
) {
  return (
    <Box
      className="text-center"
      textColor={filled ? LightColors.purewhite : LightColors.primary}
      backgroundColor={filled ? LightColors.primary : LightColors.secondary}
      onClick={onClicked}
      borderRadius={"52px"}
      lineHeight={"17px"}
      {...props}
    >
      <Center>
      {
        isLoading
          ? <Spinner size='sm' marginRight={"20px"}/>
          : <Center><p>{text}</p></Center>
      }
      </Center>
    </Box>
  )
}
