export function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function getArtistId() {
  return localStorage.getItem('artist');
}

export function getMemberId() {
  return localStorage.getItem('memberId');
}

export function getJWT() {
  return localStorage.getItem('token');
}

export function getArtistIdAnyway(obj) {
  return obj?.artist_id || obj?.artistId;
}

export function getMemberIdAnyway(obj) {
  return obj?.member_id || obj?.memberId;
}

export function getLanguage() {
  const savedLanguage = localStorage.getItem("i18nextLng");
  if (!savedLanguage) return null;

  // 정규식을 사용하여 ""로 감싸진 문자열에서 안의 내용만 추출합니다.
  const matches = savedLanguage.match(/^"(.*)"$/);
  if (matches) {
    return matches[1];
  }
  return savedLanguage;
}
