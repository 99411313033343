import React, { useEffect, useState } from "react";

import { Box, Divider, Flex, Image, Textarea, Menu, MenuButton, MenuItem, MenuList, Spinner, Stack, Text } from "@chakra-ui/react";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import baseUrl from "../../utils/baseUrl";
import getToken from "../../utils/getToken";
import { useParams } from "react-router";
import { useFilePicker } from "use-file-picker";
import { isEmpty } from "lodash";

import UserTradingPhotoCardsBottomDrawer from "../drawer/UserTradingPhotoCardsBottomDrawer";
import { t } from "i18next";

const attachmentBoxStyle = {
  borderRadius: "8px",
  background: "rgba(122, 89, 255, 0.15)",
  marginRight: "16px",
  padding: "6px",
  width: "32px",
  height: "32px"
}

// const bubbleButtonStyle = {
//   padding: "5px 10px",
//   borderRadius: "30px",
//   background: "white",
//   color: "#444",
//   fontSize: "15px",
//   fontWeight: 500,
//   border: "1px solid var(--gray-separator, #E7E7E7)"
// }

async function sendMessage(chatRoomId, content, messageType, timestamp) {
  return fetch(baseUrl() + `/chat_rooms/${chatRoomId}/chat_room_messages/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      chat_room_message: {
        content: content,
        message_type: messageType,
        sent_at: timestamp,
      },
    }),
  }).then((data) => data.json())
}

export default function MessageInputBox({tradingUserProfile, chatRoomStatus, onSentMessage, disabled}) {
  const params = useParams()
  const [message, setMessage] = useState();
  const [status, setStatus] = useState(chatRoomStatus);
  const [isSending, setIsSending] = useState(false);
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);

  useEffect(() => {
    if(chatRoomStatus){
      setStatus(chatRoomStatus)
    }
  }, [chatRoomStatus])

  const requestSendMessage = async() => {
    setIsSending(true);

    if(!isSending){
      if(filesContent.length !== 0){
        const response = await sendMessage(params.chat_room_id, filesContent[0].content, "image", parseInt(Date.now()/1000));
        clear();
        if(response) {
          onSentMessage(filesContent[0].content);
          setStatus(response.chatRoom.status);
        }
      }else if(!isEmpty(message)) {
        const response = await sendMessage(params.chat_room_id, message, "text", parseInt(Date.now()/1000));
        if(response){
          onSentMessage(message);
          setStatus(response.chatRoom.status);
        }
        setMessage('');
      }
      setIsSending(false);
    }
  }

  const { openFilePicker, filesContent, clear } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  const onClickBubbleButton = async (type) => {
    if (type === "booking") {
      const response = await sendMessage(params.chat_room_id, "booking", "booking", parseInt(Date.now()/1000));
      setMessage('')
      if(response){
        onSentMessage(message);
        setStatus(response.chatRoom.status);
      }
    }
  }

  const handleInputOnChanged = (e) => {
    setMessage(e.target.value)
  }

  const handleFoucusOnBlur = (e) => {
    if (e.relatedTarget === null) {
      e.target.focus();
    }
  }

  return(
    <Box
      className="bottomNavbar"
      paddingY={"20px"}
      paddingLeft={"20px"}
      maxW={"xl"}
      paddingTop={"0px"}
    >
      <Divider marginTop={"0px"} width={"calc(100% + 20px)"} marginLeft={"-20px"} />
      {
        // status === "ongoing" &&
        //   <HStack>
        //   {
        //     BUBBLE_BUTTONS.map((button) => {
        //       return (
        //         <Box style={bubbleButtonStyle} onClick={() => onClickBubbleButton(button.type)}>
        //           {button.text}
        //         </Box>
        //       )
        //     })
        //   }
        // </HStack>
      }
      <Flex align={"center"} marginTop={"6px"} className="safeAreaMarginBottom">
        <Menu autoSelect={false} strategy={"fixed"} _focus={{bgColor:"white"}} _active={{bgColor:"white"}} _pressed={{bgColor:"white"}} checked={{bgColor:"white"}} _selected={{bgColor:"white"}}>
          <MenuButton _focus={{bgColor:"white"}} _active={{bgColor:"white"}} _pressed={{bgColor:"white"}} checked={{bgColor:"white"}} _selected={{bgColor:"white"}}>
            <Box style={attachmentBoxStyle}>
              <PlusIcon width={"20px"} height={"20px"} fill={"#5C3DFA"}/>
            </Box>
          </MenuButton>
          <MenuList _focus={{bgColor:"white"}} _active={{bgColor:"white"}} _pressed={{bgColor:"white"}} checked={{bgColor:"white"}} _selected={{bgColor:"white"}}>
            <MenuItem key={"image"} onClick={()=> !disabled && openFilePicker()}>
              {t("chatRoom.imageMenu")}
            </MenuItem>
            <MenuItem key={"tradings"} onClick={()=> setIsOpenBottomSheet(true)}>
              {t("chatRoom.showTraderPhotoCards")}
            </MenuItem>
          </MenuList>
        </Menu>
        <div className="grow">
          {
            filesContent.length !== 0
            ? <Box borderWidth={"1px"} borderColor={"secondary.700"} width={"fit-content"}>
                <Image width="120px" src={filesContent[0].content}/>
              </Box>
            : <Textarea
              style={{
                height: "40px",
                minHeight: "auto",
                resize: "none",
                // width: "calc(var(--vw, 1vw) * 10)",
              }}
              disabled={disabled}
              variant='unstyled'
              value={message}
              placeholder={disabled ? t("chatRoom.disabledPlaceHolder") : t("chatRoom.placeHolder")}
              onChange={handleInputOnChanged}
              onBlur={handleFoucusOnBlur}/>
          }
        </div>
        {
          (!isEmpty(message) || filesContent.length !== 0) ?
          (isSending ?
            <Spinner size='sm' color="primary.500" marginRight={"20px"}/> :
            <Text
              minW={"28px"}
              fontSize={"15px"}
              fontWeight={600}
              marginRight={"20px"}
              textColor={"primary.500"}
              onClick={() => requestSendMessage()}>
                {t("send")}
            </Text>
          )
          : <></>
        }
      </Flex>
      {
        isOpenBottomSheet &&
        <UserTradingPhotoCardsBottomDrawer
          tradingUserProfile={tradingUserProfile}
          artistId={params.artist_id}
          isOpen={isOpenBottomSheet}
          onClose={() => setIsOpenBottomSheet(false)}
        />
      }
    </Box>
  )
}
