import styled from "styled-components"
import LightColors from "../../constants/LightColors"

const EventBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const EventCategoryBox = styled.div`
  width: 53px;
  min-width: 53px;
  max-width: 53px;
  display: inline-flex;
  padding: 6px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
  border-radius: 10px;
  background-image: url(/assets/backgrounds/categorybox.png);
  background-size: cover;
`

const EventCategoryEmoji = styled.img`
  width: 17px;
  height: 17px;
  margin-bottom: 1px;
`

const EventTextColumn = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-left: 8px;
  gap: 0px;
`

const EventCategoryText = styled.div`
  color: rgba(48, 25, 165, 0.93);
  text-align: center;
  font-size: 10px;
  line-height: 13px;
  font-weight: 700;
  mix-blend-mode: color-burn;
  letter-spacing: -0.1px;
`

const EventTitleText = styled.div`
  color: ${LightColors.textmainlv1};
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.15px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 18px;
  margin-bottom: 3px;
`

const EventDescriptionRow = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
`

const EventDescriptionText = styled.div`
  color: ${LightColors.textmainlv4};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-left: ${(props) => props.paddingX}px;
  padding-right: ${(props) => props.paddingX}px;
`

export const EventNameBox = ({event, isDisabled=false}) => {
  return(
    <EventBoxContainer alignItems={"center"}>
      <EventCategoryBox isDisabled={isDisabled}>
        <EventCategoryEmoji src={`/assets/icons/eventCategory/ic_event_${event.category}.png`}>
          {event.categoryEmoji}
        </EventCategoryEmoji>
        <EventCategoryText isDisabled={isDisabled}>
          {event.categoryName}
        </EventCategoryText>
      </EventCategoryBox>
      <EventTextColumn>
        <EventTitleText isDisabled={isDisabled}>{event.name}</EventTitleText>
        <EventDescriptionRow>
          {event.localizedName && <EventDescriptionText isDisabled={isDisabled}>{event.localizedName}</EventDescriptionText>}
          {event.localizedName && event.countryEmoji && event.countryName && (
            <EventDescriptionText paddingX={4} isDisabled={isDisabled} style={{color: LightColors.textsublv2}}>{`•`}</EventDescriptionText>
          )}
          {event.countryEmoji && event.countryName && (
            <EventDescriptionText isDisabled={isDisabled}>{`${event.countryEmoji} ${event.countryName}`}</EventDescriptionText>
          )}
        </EventDescriptionRow>
      </EventTextColumn>
    </EventBoxContainer>
  )
}
