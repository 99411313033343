import { Box, Center, Image, Stack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg";
import { useState } from "react";

export const ArtistCircleBox = ({artist, onArtistSelected}) => {
  const [selectedArtist, setSelectedArtist] = useState(null);

  return(
    <Stack>
      <Center>
      <Box>
      <Wrap>
        <WrapItem position={"relative"}>
          {
            // #region 아티스트 아이콘
          }
          <Image
            src={artist.pictureUrl}
            borderRadius='full'
            width={"154px"}
            height={"154px"}
            pl="4px"
            background={"#FFF"}
            alt={artist.name}
            onClick={() => {
              setSelectedArtist(artist);
              onArtistSelected(artist);
            }}
            loading={"lazy"}
          />
          {
            // #endregion
          }
          {
            // #region 선택시 오버레이
          }
          <WrapItem
            borderRadius='full'
            width={"154px"}
            height={"154px"}
            pl="4px"
            backgroundColor={"#5C3DFA9A"}
            zIndex={selectedArtist?.artistId === artist.artistId ? 999 : -11}
            style={{position: "absolute", top: 0, left: 0}}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <LikeShapeIcon width={60} height={60} fill="#FFF"/>
          </WrapItem>
          {
            // #endregion
          }
        </WrapItem>
      </Wrap>
      </Box>
      </Center>

      <Text
        className="text-center"
        paddingTop="16px"
        mt="0px!important"
        fontSize="17px"
        fontWeight={400}
        lineHeight="20.4px"
        color="#444444">
        { artist.name }
      </Text>
    </Stack>
  )
}
