import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";
import { isEmpty } from "lodash";

export default function useSearchablePhotoCardsQuery({
  artistId,
  memberId,
}) {
  const isEnabled = !isEmpty(artistId) && !isEmpty(memberId)
  const { data: phptoCards } = useQuery(
    ["SearchablePhotoCards", artistId, memberId],
    () => queryGet(`/api/v1/photo_cards/searchable?artist_id=${artistId}&member_id=${memberId}`),{
      enabled: isEnabled,
      select: (data) => data.photoCards,
    },
  );
  return phptoCards;
}
