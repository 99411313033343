import React from "react";

import { Box } from "@chakra-ui/react";

const defaultToastStyle = {
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  borderRadius: "32px",
  marginBottom: "75vh",
}

export default function ToastMessageBox({message}) {
  return (
    <Box className="text-center" p={2} style={defaultToastStyle}>
      {message}
    </Box>
  )
}