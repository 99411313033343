import PhotoCardResponse from "./PhotoCardResponse";

export default class EventResponse {
  constructor({
    eventId,
    sponsoredType,
    sponsoredId,
    name,
    localizedName,
    categoryName,
    description,
    category,
    country,
    countryName,
    countryEmoji,
    releasedAt,
    visibility,
    categories,
    photoCards,
  }) {
    this.eventId = eventId;
    this.sponsoredType = sponsoredType;
    this.sponsoredId = sponsoredId;
    this.name = name;
    this.localizedName = localizedName;
    this.categoryName = categoryName;
    this.description = description;
    this.country = country;
    this.countryName = countryName;
    this.countryEmoji = countryEmoji;
    this.category = category;
    this.releasedAt = releasedAt;
    this.visibility = visibility;
    this.categories = categories;
    this.photoCards = photoCards?.map((photoCard) => new PhotoCardResponse(photoCard));
  }
}
