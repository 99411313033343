import baseUrl from "../../../utils/baseUrl"
import getToken from "../../../utils/getToken"

export default async function deletePost(postId) {
  return fetch(baseUrl() + `/api/v1/posts/${postId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  });
}
