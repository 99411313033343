import React from "react";

import styled from "styled-components";

const ToastBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  background: white;
  border: 1px solid rgba(104, 70, 238, 1);
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
  top: 40px;
  padding: 16px 20px;
  border-radius: 13px;
  min-width: 95vw;
`

const StartWrapper = styled.div`
  flex-shirnk: 1;
  align-items: flex-start;
  margin-right: 6px;
`

const MessageWrapper = styled.div`
  flex-grow: 1;
`

const EndWrapper = styled.div`
  flex-shirnk: 1;
  align-items: flex-end;
  margin-left: 4px;
`

export default function WhiteToastMessageBox({message, startElement, endElement}) {
  return (
    <ToastBox>
      {
        startElement && <StartWrapper>{startElement}</StartWrapper>
      }
      <MessageWrapper>{message}</MessageWrapper>
      {
        endElement && <EndWrapper>{endElement}</EndWrapper>
      }
    </ToastBox>
  )
}
