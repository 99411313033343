import { t } from "i18next";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { openNewTab } from "../../utils/UIUtils";
import useUserQuery from "../api/queries/useUserQuery";
import AddUserProfileV1BottomSheet from "../bottomSheet/AddUserProfileV1BottomSheet";

const CircleMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  border-radius: 50%;
  box-sizing: border-box;
  background: linear-gradient(91.59deg, rgba(255, 255, 255, 0.5) 31.85%, rgba(255, 255, 255, 0.1) 102.92%);
  border-image-source: linear-gradient(134.41deg, rgba(255, 255, 255, 0.6) 5.69%, rgba(255, 255, 255, 0) 95.22%);
  box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.3) inset;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  padding: 17px;
`;

const Title = styled.div`
  padding-top: 7px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
`

export default function CircleMenu({title, imageSvgBase64, linkPath, deeplinkPath}) {
  const navigate = useNavigate();
  const [isShowBaseProfileBottomSheet, setIsShowBaseProfileBottomSheet] = useState(false);

  const user = useUserQuery();
  const isReportPhotoCard = linkPath.includes("report-photo-card");
  const isEmptyBaseUserProfile = user && isEmpty(user.baseUserProfile);

  const handleClick = (url) => {
    // URL이 외부 링크인지 확인 (http 또는 https로 시작하는지 확인)
    const isExternal = url.startsWith('http://') || url.startsWith('https://');
    if (isExternal) {
      // 외부 링크일 경우 새 탭에서 열기
      openNewTab(url);
    } else if (isReportPhotoCard && isEmptyBaseUserProfile) {
        setIsShowBaseProfileBottomSheet(true);
    } else {
      // 내부 링크일 경우 React Router의 useNavigate 사용
      navigate(url);
    }
  };

  return(
    <CircleMenuWrapper onClick={() => handleClick(linkPath)}>
      <ImageWrapper>
        {
          imageSvgBase64 && <div dangerouslySetInnerHTML={{__html: atob(imageSvgBase64)}}/>
        }
      </ImageWrapper>
      <Title>{title}</Title>
      {
        (isReportPhotoCard && isEmptyBaseUserProfile && isShowBaseProfileBottomSheet) &&
        <AddUserProfileV1BottomSheet
          title={t("createFeedUserProfilePage.title")}
          userProfile={user.baseUserProfile}
          isShowProfileBottomSheet={isShowBaseProfileBottomSheet}
          setIsShowProfileBottomSheet={setIsShowBaseProfileBottomSheet}
          onBackDropClick={() => setIsShowBaseProfileBottomSheet(false)}
          category={"base"}
          disableDrag={false}
        />
      }
    </CircleMenuWrapper>
  )
}
