import i18n from "i18next";
import { ko } from "./locales/ko/ko";
import { en } from "./locales/en/en";
import { zhTW } from "./locales/zhTW/zhTW";
import LanguageDetector from 'i18next-browser-languagedetector';
import { zhCN } from "./locales/zhCN/zhCN";

i18n
  .use(LanguageDetector) // 브라우저 언어 감지기 사용
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ko: ko,
      en: en,
      "zh-TW": zhTW,
      "zh-HK": zhTW,
      "zh-CN": zhCN,
    },
    detection: {
      order: ['customDetector', 'querystring', 'localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
