import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useReviewQuery(reviewId) {
  const { data: review } = useQuery(
    ["review", reviewId],
    () => queryGet(`/api/v1/reviews/${reviewId}`),{
      select: (data) => data.review,
      cacheTime: 0,
      staleTime: 0,
    },
  );
  return review;
}
