import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useUserProfileQuery(userProfileId) {
  const { data: userProfile } = useQuery(
    ["userProfile", userProfileId],
    () => queryGet(`/api/v1/user_profiles/${userProfileId}`),{
      select: (data) => data.userProfile,
      enabled: !!userProfileId,
    }
  );
  return userProfile;
}
