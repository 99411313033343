export const inputPriceFormat = (str) => {
  return comma(uncomma(str));
};

export const priceToNumberFormat = (str) => {
  return parseFloat(uncomma(str));
};

const comma = (str) => {
  const parts = str.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join('.');
};

const uncomma = (str) => {
  str = String(str);
  return str.replace(/[^\d.]+/g, "");
};
