import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { isEmpty } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import leaveChatRoom from "../../components/api/mutations/legacy/leaveChatRoom";
import useChatRoomsQuery from "../../components/api/queries/useChatRoomsQuery";
import ChatRoomV1 from "../../components/chats/ChatRoomV1";
import EmptyCase from "../../components/errors/EmptyCase";
import Page from "../../components/Page";
import LightColors from "../../constants/LightColors";
import { ReactComponent as ArrowRightSmall } from "../../icons/arrow.right.small.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as XMarkIcon } from "../../icons/xmark.svg";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const Title = styled.div`
  color: ${LightColors.textmainlv1};
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  white-space: pre-wrap;
  padding: 40px 24px 10px 24px;
`

const ChatRoomsWrapper = styled.div`
  margin-top: 21px;
`;

const ChatRoomV1Wrapper = styled.div`
  padding: 7px 24px;
  min-height: 72px;
  align-content: center;
`;

const DialogIconWrapper = styled.div`
  padding-top: 30px;
  text-align: center;
  text-align: -webkit-center;
`

const DialogTextWrapper = styled.div`
  padding-top: 24px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 30px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-align: -webkit-center;
`

const BottomSkipWrapper = styled.div`
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`

const SkipText = styled.div`
  color: ${LightColors.textmainlv3};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: pre-wrap;
`

export default function SelectReviewChatRoomPage() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const handleHistoryBack = useHandleHistoryBack();

  const currentQueryParams = new URLSearchParams(location.search);
  const isShowCompletedDialog = currentQueryParams.get("isShowCompletedDialog");

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [chatRooms, setChatRooms] = useState();

  const { data: paginatedChatRooms } = useChatRoomsQuery({
    tradingPhotoCardId: params.trading_photo_card_id,
    onlyJoined: false,
    perPage: 1000,
  });

  useEffect(() => {
    if (isShowCompletedDialog) {
      onOpen();
      setTimeout(() => { onClose() }, 1500);
    }
  }, [onOpen, onClose, isShowCompletedDialog]);

  useEffect(() => {
    if (!isEmpty(paginatedChatRooms)) {
      const tempChatRooms = paginatedChatRooms.pages?.flatMap((page) =>
        page.chatRooms.map((chatRoom) => chatRoom)
      )
      setChatRooms(tempChatRooms);
    }
  }, [paginatedChatRooms]);

  const onSelectedChatRoom = async (chatRoomId) => {
    const chatRoom = chatRooms.find((chatRoom) => chatRoom.chatRoomId === chatRoomId)
    const response = await leaveChatRoom(chatRoomId);
    if (response.success) {
      navigate(`/trading_photo_cards/${params.trading_photo_card_id}/add_review?chatRoomId=${chatRoomId}&traderUserProfileId=${chatRoom.traderProfile.userProfileId}`)
    }
  }

  return (
  <div>
    <Page
      title={t("SelectReviewChatRoomPage.pageTitle")}
      leftElement={<XMarkIcon fill={LightColors.pureblack}/>}
      onHistoryBackClick={handleHistoryBack}
      noPadding={true}
    >
      <Title>
        {t("SelectReviewChatRoomPage.title")}
      </Title>
      <ChatRoomsWrapper>
      {
        isEmpty(chatRooms) ?
        <EmptyCase
          emoji={"😳"}
          description={t("myPhotoCards.chatRoomPageEmptyCase")}
        />
        : chatRooms.map((chatRoom) => {
          return(
            <ChatRoomV1Wrapper key={chatRoom.chatRoomId}>
              <ChatRoomV1
                chatRoom={chatRoom}
                hideImage={true}
                showSelectButton={true}
                onClick={(chatRoomId) => onSelectedChatRoom(chatRoomId)}
              />
            </ChatRoomV1Wrapper>
          )
        })
      }
      </ChatRoomsWrapper>
      <BottomSkipWrapper onClick={handleHistoryBack}>
        <Row>
          <SkipText>
            {t("SelectReviewChatRoomPage.skip")}
          </SkipText>
          <ArrowRightSmall fill={LightColors.textmainlv3} width={"20px"} height={"20px"}/>
        </Row>
      </BottomSkipWrapper>
    </Page>
    {
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent width={"fit-content"} padding={0} borderRadius={"30px"}>
          <ModalBody alignContent={"center"}>
            <DialogIconWrapper>
              <CheckIcon width={"80px"} height={"80px"} fill={LightColors.primary} />
            </DialogIconWrapper>
            <DialogTextWrapper>
              {t("SelectReviewChatRoomPage.dialogText")}
            </DialogTextWrapper>
          </ModalBody>
        </ModalContent>
      </Modal>
    }
  </div>
  )
}
