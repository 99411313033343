import React, { useEffect, useState } from "react";

import { Spinner } from '@chakra-ui/react';
import { t } from "i18next";
import { filter, isEmpty } from "lodash";
import { Helmet } from "react-helmet-async";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import styled from "styled-components";

import addTradingPhotoCards from "../../components/api/mutations/addTradingPhotoCards";
import usePhotoCardsQuery from "../../components/api/queries/usePhotoCardsQuery";
import useUserQuery from "../../components/api/queries/useUserQuery";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import NewSaleTradingPhotoCard from "../../components/tradings/NewSaleTradingPhotoCard";
import LightColors from "../../constants/LightColors";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow.left.svg";
import { priceToNumberFormat } from "../../utils/NumberUtil";
import { fetchPaginatedData } from "../../utils/PaginationUtil";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";

const Header = styled.header`
  width: 100%;
  background: ${LightColors.purewhite};
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: calc(16px + env(safe-area-inset-top));
  padding-bottom: 15px;
  padding-left: calc(16px + env(safe-area-inset-left));
  padding-right: calc(16px + env(safe-area-inset-right));
  z-index: 1000;
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  padding: 0 24px;
`

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: ${LightColors.textmainlv1};
`

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  white-space: pre-wrap;
  color: ${LightColors.textsublv1};
`

const ListWrapper = styled.div`
  padding: 12px 24px 64px 24px;
`


export default function ConfirmNewSalesTradingPhotoCardsPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { state } = useLocation();
  const handleHistoryBack = useHandleHistoryBack();

  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const category = currentQueryParams.get("category");
  const artistId = currentQueryParams.get("artistId");
  const { data: photoCardsData } = usePhotoCardsQuery({artistId: artistId, photoCardIds: state.photoCardIds, perPage: 1000});

  useEffect(() => {
    if (!isEmpty(photoCardsData)) {
      setNewTradingPhotoCards(
        fetchPaginatedData(photoCardsData, "photoCards").map((photoCard => {
          return(
            {
              tradingPhotoCardId: uuid(),
              category: category,
              artistId: artistId,
              status: "wating",
              price: null,
              photoCard: photoCard,
            }
          )
        }))
      );
    }
  }, [artistId, photoCardsData, category]);

  const [newTradingPhotoCards, setNewTradingPhotoCards] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const user = useUserQuery();

  const onDone = async () => {
    if(!isUploading && user?.tradingUserProfile){
      setIsUploading(true);
      const response = await addTradingPhotoCards(user?.tradingUserProfile?.userProfileId, newTradingPhotoCards)
      if (response.ok) {
        queryClient.invalidateQueries(["tradingPhotoCards"]);
        queryClient.invalidateQueries(["TradingPhotoCards"]);
        queryClient.invalidateQueries(["tradingPhotoCardsAll"]);
        setIsUploading(false);
        navigate("/tradings/completed_add?isExchange=false", { replace: true });
      }
    }
  }

  const onChangedItem = (item) => {
    const items = newTradingPhotoCards.map((tradingPhotoCard) => {
      if (tradingPhotoCard.tradingPhotoCardId === item.tradingPhotoCardId) {
        return {...item, price: priceToNumberFormat(item.price)}
      } else {
        return tradingPhotoCard
      }
    });
    setNewTradingPhotoCards(items);
  }

  const onDeletedItem = (item) => {
    const items = filter(newTradingPhotoCards, (tradingPhotoCard) => {
      return tradingPhotoCard.tradingPhotoCardId !== item.tradingPhotoCardId;
    });
    setNewTradingPhotoCards(items);

    if (isEmpty(items)) {
      alert(t("ConfirmNewSalesTradingPhotoCardsPage.removeAllDialog"))
      handleHistoryBack();
    }
  }

  return (
    <>
      <Helmet>
        <title>{t("ConfirmNewSalesTradingPhotoCardsPage.title")}</title> {/* Helmet을 사용하여 title 설정 */}
      </Helmet>
      <Header>
        <LeftContainer>
          {<ArrowLeftIcon fill={LightColors.pureblack} onClick={handleHistoryBack}/>}
        </LeftContainer>
      </Header>
      <TitleWrapper>
        <Title>
          {t("ConfirmNewSalesTradingPhotoCardsPage.title")}
        </Title>
        <Description>
          {t("ConfirmNewSalesTradingPhotoCardsPage.description")}
        </Description>
      </TitleWrapper>
      <ListWrapper>
      <div>
        {
          newTradingPhotoCards.map((tradingPhotoCard) => {
            return (
              <div key={tradingPhotoCard.tradingPhotoCardId} style={{marginBottom: "16px"}}>
                <NewSaleTradingPhotoCard
                  tradingPhotoCard={tradingPhotoCard}
                  onChangedItem={(item) => onChangedItem(item)}
                  onDeletedItem={(item) => onDeletedItem(item)}
                />
              </div>
            )
          })
        }
      </div>
      </ListWrapper>
      <div
        className="bottomNavbar safeAreaPaddingBottom text-center"
        style={{padding: "0px 24px 16px 24px", zIndex: 9999, background: "white"}}
      >
        {
          isUploading
          ? <Spinner size='sm' color="primary.500"/>
          : <PrimaryButton paddingY={"16px"} text={t("done")} zIndex={9999} onClick={() => onDone()}/>
        }
      </div>
    </>
  )
}
