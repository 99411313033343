import { isEmpty } from "lodash";

const Countries = [
  {
    "name": "한국",
    "code": "KR"
  },
  {
      "name": "Andorra",
      "code": "AD"
  },
  {
      "name": "الإمارات العربية المتحدة",
      "code": "AE"
  },
  {
      "name": "افغانستان",
      "code": "AF"
  },
  {
      "name": "Antigua and Barbuda",
      "code": "AG"
  },
  {
      "name": "Anguilla",
      "code": "AI"
  },
  {
      "name": "Shqipëri",
      "code": "AL"
  },
  {
      "name": "Հայաստան",
      "code": "AM"
  },
  {
      "name": "Angola",
      "code": "AO"
  },
  {
      "name": "Argentina",
      "code": "AR"
  },
  {
      "name": "Amerika Sāmoa",
      "code": "AS"
  },
  {
      "name": "Österreich",
      "code": "AT"
  },
  {
      "name": "Australia",
      "code": "AU"
  },
  {
      "name": "Azərbaycan",
      "code": "AZ"
  },
  {
      "name": "Bosna i Hercegovina",
      "code": "BA"
  },
  {
      "name": "Barbados",
      "code": "BB"
  },
  {
      "name": "বাংলাদেশ",
      "code": "BD"
  },
  {
      "name": "België",
      "code": "BE"
  },
  {
      "name": "Burkina Faso",
      "code": "BF"
  },
  {
      "name": "България",
      "code": "BG"
  },
  {
      "name": "البحرين",
      "code": "BH"
  },
  {
      "name": "Burundi",
      "code": "BI"
  },
  {
      "name": "Bénin",
      "code": "BJ"
  },
  {
      "name": "Bermuda",
      "code": "BM"
  },
  {
      "name": "Brunei",
      "code": "BN"
  },
  {
      "name": "Bolivia",
      "code": "BO"
  },
  {
      "name": "Brasil",
      "code": "BR"
  },
  {
      "name": "Bahamas",
      "code": "BS"
  },
  {
      "name": "འབྲུག་ཡུལ་",
      "code": "BT"
  },
  {
      "name": "Botswana",
      "code": "BW"
  },
  {
      "name": "Беларусь",
      "code": "BY"
  },
  {
      "name": "Belize",
      "code": "BZ"
  },
  {
      "name": "Canada",
      "code": "CA"
  },
  {
      "name": "République centrafricaine",
      "code": "CF"
  },
  {
      "name": "République du Congo",
      "code": "CG"
  },
  {
      "name": "Schweiz",
      "code": "CH"
  },
  {
      "name": "Côte d'Ivoire",
      "code": "CI"
  },
  {
      "name": "Chile",
      "code": "CL"
  },
  {
      "name": "Cameroun",
      "code": "CM"
  },
  {
      "name": "中国",
      "code": "CN"
  },
  {
      "name": "Colombia",
      "code": "CO"
  },
  {
      "name": "Costa Rica",
      "code": "CR"
  },
  {
      "name": "Cuba",
      "code": "CU"
  },
  {
      "name": "Cabo Verde",
      "code": "CV"
  },
  {
      "name": "Κύπρος",
      "code": "CY"
  },
  {
      "name": "Česká republika",
      "code": "CZ"
  },
  {
      "name": "Deutschland",
      "code": "DE"
  },
  {
      "name": "جيبوتي",
      "code": "DJ"
  },
  {
      "name": "Danmark",
      "code": "DK"
  },
  {
      "name": "Dominica",
      "code": "DM"
  },
  {
      "name": "República Dominicana",
      "code": "DO"
  },
  {
      "name": "الجزائر",
      "code": "DZ"
  },
  {
      "name": "Ecuador",
      "code": "EC"
  },
  {
      "name": "Eesti",
      "code": "EE"
  },
  {
      "name": "مصر",
      "code": "EG"
  },
  {
      "name": "إرتريا",
      "code": "ER"
  },
  {
      "name": "España",
      "code": "ES"
  },
  {
      "name": "ኢትዮጵያ",
      "code": "ET"
  },
  {
      "name": "Suomi",
      "code": "FI"
  },
  {
      "name": "Fiji",
      "code": "FJ"
  },
  {
      "name": "Micronesia",
      "code": "FM"
  },
  {
      "name": "France",
      "code": "FR"
  },
  {
      "name": "Gabon",
      "code": "GA"
  },
  {
      "name": "United Kingdom",
      "code": "GB"
  },
  {
      "name": "Grenada",
      "code": "GD"
  },
  {
      "name": "საქართველო",
      "code": "GE"
  },
  {
      "name": "Ghana",
      "code": "GH"
  },
  {
      "name": "Gambia",
      "code": "GM"
  },
  {
      "name": "Guinée",
      "code": "GN"
  },
  {
      "name": "Guinea Ecuatorial",
      "code": "GQ"
  },
  {
      "name": "Ελλάδα",
      "code": "GR"
  },
  {
      "name": "Guatemala",
      "code": "GT"
  },
  {
      "name": "Guiné-Bissau",
      "code": "GW"
  },
  {
      "name": "Guyana",
      "code": "GY"
  },
  {
      "name": "Honduras",
      "code": "HN"
  },
  {
      "name": "Hrvatska",
      "code": "HR"
  },
  {
      "name": "Hongkong",
      "code": "HK"
  },
  {
      "name": "Haïti",
      "code": "HT"
  },
  {
      "name": "Magyarország",
      "code": "HU"
  },
  {
      "name": "Indonesia",
      "code": "ID"
  },
  {
      "name": "Éire",
      "code": "IE"
  },
  {
      "name": "ישראל",
      "code": "IL"
  },
  {
      "name": "भारत",
      "code": "IN"
  },
  {
      "name": "العراق",
      "code": "IQ"
  },
  {
      "name": "ایران",
      "code": "IR"
  },
  {
      "name": "Ísland",
      "code": "IS"
  },
  {
      "name": "Italia",
      "code": "IT"
  },
  {
      "name": "Jamaica",
      "code": "JM"
  },
  {
      "name": "الأردن",
      "code": "JO"
  },
  {
      "name": "日本",
      "code": "JP"
  },
  {
      "name": "Kenya",
      "code": "KE  E"
  },
  {
      "name": "Кыргызстан",
      "code": "KG"
  },
  {
      "name": "កម្ពុជា",
      "code": "KH"
  },
  {
      "name": "Kiribati",
      "code": "KI"
  },
  {
      "name": "جزر القمر",
      "code": "KM"
  },
  {
      "name": "Saint Kitts and Nevis",
      "code": "KN"
  },
  {
      "name": "조선민주주의인민공화국",
      "code": "KP"
  },
  {
      "name": "الكويت",
      "code": "KW"
  },
  {
      "name": "Cayman Islands",
      "code": "KY"
  },
  {
      "name": "Қазақстан",
      "code": "KZ"
  },
  {
      "name": "ປະເທດລາວ",
      "code": "LA"
  },
  {
      "name": "لبنان",
      "code": "LB"
  },
  {
      "name": "Saint Lucia",
      "code": "LC"
  },
  {
      "name": "Liechtenstein",
      "code": "LI"
  },
  {
      "name": "ශ්‍රී ලංකාව",
      "code": "LK"
  },
  {
      "name": "Liberia",
      "code": "LR"
  },
  {
      "name": "Lesotho",
      "code": "LS"
  },
  {
      "name": "Lietuva",
      "code": "LT"
  },
  {
      "name": "Luxembourg",
      "code": "LU"
  },
  {
      "name": "Latvija",
      "code": "LV"
  },
  {
      "name": "ليبيا",
      "code": "LY"
  },
  {
      "name": "المغرب",
      "code": "MA"
  },
  {
      "name": "Monaco",
      "code": "MC"
  },
  {
      "name": "Moldova",
      "code": "MD"
  },
  {
      "name": "Crna Gora",
      "code": "ME"
  },
  {
      "name": "Madagasikara",
      "code": "MG"
  },
  {
      "name": "Marshall Islands",
      "code": "MH"
  },
  {
      "name": "Mali",
      "code": "ML"
  },
  {
      "name": "မြန်မာ",
      "code": "MM"
  },
  {
      "name": "Монгол",
      "code": "MN"
  },
  {
      "name": "Macao",
      "code": "MO"
  },
  {
      "name": "موريتانيا",
      "code": "MR"
  },
  {
      "name": "Malta",
      "code": "MT"
  },
  {
      "name": "Mauritius",
      "code": "MU"
  },
  {
      "name": "Maldives",
      "code": "MV"
  },
  {
      "name": "Malawi",
      "code": "MW"
  },
  {
      "name": "México",
      "code": "MX"
  },
  {
      "name": "Malaysia",
      "code": "MY"
  },
  {
      "name": "Moçambique",
      "code": "MZ"
  },
  {
      "name": "Namibia",
      "code": "NA"
  },
  {
      "name": "Niger",
      "code": "NE"
  },
  {
      "name": "Nigeria",
      "code": "NG"
  },
  {
      "name": "Nicaragua",
      "code": "NI"
  },
  {
      "name": "Nederland",
      "code": "NL"
  },
  {
      "name": "Norway",
      "code": "NO"
  },
  {
      "name": "नेपाल",
      "code": "NP"
  },
  {
      "name": "Nauru",
      "code": "NR"
  },
  {
      "name": "New Zealand",
      "ccode": "NZ"
  },
  {
      "name": "عمان",
      "code": "OM"
  },
  {
      "name": "Panama",
      "code": "PA"
  },
  {
      "name": "Perú",
      "code": "PE"
  },
  {
      "name": "Papua New Guinea",
      "code": "PG"
  },
  {
      "name": "Pilipinas",
      "code": "PH"
  },
  {
      "name": "پاکستان",
      "code": "PK"
  },
  {
      "name": "Polska",
      "code": "PL"
  },
  {
      "name": "Portugal",
      "code": "PT"
  },
  {
      "name": "Palau",
      "code": "PW"
  },
  {
      "name": "Paraguay",
      "code": "PY"
  },
  {
      "name": "قطر",
      "code": "QA"
  },
  {
      "name": "România",
      "code": "RO"
  },
  {
      "name": "Србија",
      "code": "RS"
  },
  {
      "name": "Россия",
      "code": "RU"
  },
  {
      "name": "Rwanda",
      "code": "RW"
  },
  {
      "name": "المملكة العربية السعودية",
      "code": "SA"
  },
  {
      "name": "Solomon Islands",
      "code": "SB"
  },
  {
      "name": "Seychelles",
      "code": "SC"
  },
  {
      "name": "السودان",
      "code": "SD"
  },
  {
      "name": "Sverige",
      "code": "SE"
  },
  {
      "name": "Singapore",
      "code": "SG"
  },
  {
      "name": "Slovenija",
      "code": "SI"
  },
  {
      "name": "Slovensko",
      "code": "SK"
  },
  {
      "name": "Sierra Leone",
      "code": "SL"
  },
  {
      "name": "San Marino",
      "code": "SM"
  },
  {
      "name": "Sénégal",
      "code": "SN"
  },
  {
      "name": "Soomaaliya",
      "code": "SO"
  },
  {
      "name": "Suriname",
      "code": "SR"
  },
  {
      "name": "South Sudan",
      "code": "SS"
  },
  {
      "name": "São Tomé e Príncipe",
      "code": "ST"
  },
  {
      "name": "El Salvador",
      "code": "SV"
  },
  {
      "name": "سوريا",
      "code": "SY"
  },
  {
      "name": "Eswatini",
      "code": "SZ"
  },
  {
      "name": "تشاد",
      "code": "TD"
  },
  {
      "name": "Togo",
      "code": "TG"
  },
  {
      "name": "ประเทศไทย",
      "code": "TH"
  },
  {
      "name": "Тоҷикистон",
      "code": "TJ"
  },
  {
      "name": "Timor-Leste",
      "code": "TL"
  },
  {
      "name": "Türkmenistan",
      "code": "TM"
  },
  {
      "name": "تونس",
      "code": "TN"
  },
  {
      "name": "Tonga",
      "code": "TO"
  },
  {
      "name": "Türkiye",
      "code": "TR"
  },
  {
      "name": "Trinidad and Tobago",
      "code": "TT"
  },
  {
      "name": "台灣",
      "code": "TW"
  },
  {
      "name": "Tanzania",
      "code": "TZ"
  },
  {
      "name": "Україна",
      "code": "UA"
  },
  {
      "name": "Uganda",
      "code": "UG"
  },
  {
      "name": "United States",
      "code": "US"
  },
  {
      "name": "Uruguay",
      "code": "UY"
  },
  {
      "name": "Oʻzbekiston",
      "code": "UZ"
  },
  {
      "name": "Saint Vincent and the Grenadines",
      "code": "VC"
  },
  {
      "name": "Venezuela",
      "code": "VE"
  },
  {
      "name": "Việt Nam",
      "code": "VN"
  },
  {
      "name": "Vanuatu",
      "code": "VU"
  },
  {
      "name": "Samoa",
      "code": "WS"
  },
  {
      "name": "اليمن",
      "code": "YE"
  },
  {
      "name": "South Africa",
      "code": "ZA"
  },
  {
      "name": "Zambia",
      "code": "ZM"
  },
  {
      "name": "Zimbabwe",
      "code": "ZW"
  }
]

export default Countries;

export const GLOBAL_COUNTRY = {
  name: "Global",
  code: "GLOBAL",
}

export const SUPPROTED_COUNTRIES = ['KR', 'TW', 'HK', 'CN', 'JP'];

// export function SupportedCountries(countryCode) {
//   return useMemo(() => {
//     const currentCounty = Countries.find((country) => country.supported && countryCode === country.code)
//     if (currentCounty) {
//       return [GLOBAL_COUNTRY, currentCounty]
//     } else {
//       return [GLOBAL_COUNTRY]
//     }
//   }, [countryCode]);
// }

export function SupportedCountries() {
  return Countries.filter((country) => country.supported )
}

export function IsSupportedCountry(country) {
    return SUPPROTED_COUNTRIES.includes(country)
}

export function FindCountyByCountryCode(countryCode) {
  if (countryCode === "GLOBAL") {
    return "GLOBAL"
  } else {
    return Countries.find((country) => country.code === countryCode)
  }
}

export function EmojiBySupportedCountry(country) {
    if(isEmpty(country)) return "";

    switch (country) {
        case "KR":
          return "🇰🇷"
        case "TW":
          return "🇹🇼"
        case "JP":
          return "🇯🇵"
        case "HK":
          return "🇭🇰"
        case "CN":
          return "🇨🇳"
        default:
          return "🌎"
    }
}
