import { formatDistanceToNow } from "date-fns";
import { enUS, ko, zhCN, zhTW } from "date-fns/locale";
import { getLanguage } from "./etcUtils";

const locales = {
  en: enUS,
  ko: ko,
  "zh-TW": zhTW,
  "zh-CN": zhCN,
};

export function dateTimeDistanceToNow(dateString) {
  const localStorageLocale = getLanguage();
  const currentLocale = locales[localStorageLocale] || locales.en;

  return formatDistanceToNow(new Date(dateString), {
    addSuffix: true,
    locale: currentLocale,
  });
}
