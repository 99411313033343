import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function updateUserPreferences({showTradingProfile}) {
  return fetch(baseUrl() + `/api/v1/users/update_preferences`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      preferences: {
        show_trading_profile: showTradingProfile,
      },
    }),
  });
};
