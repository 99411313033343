import baseUrl from "../../../utils/baseUrl"
import getToken from "../../../utils/getToken"

export default async function updateUserLocaleOrCountry({userId, locale, country}) {
  async function updateUser(locale, country) {
    return fetch(baseUrl() + `/api/v1/users/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      body: JSON.stringify({
        user: {
          locale: locale,
          country: country,
        }
      }),
    }).then((data) => data.json())
  }
  return updateUser(locale, country);
}
