import React from "react";

import { AbsoluteCenter, Center, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import LightColors from "../../constants/LightColors";


const Image = styled.img`
  width: 200px;
  height: 200px;
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 24px;
  padding: 10px 24px;
  gap: 14px;
`
const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  color: ${LightColors.textmainlv1};
`

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  white-space: pre-wrap;
  color: ${LightColors.textmainlv4};
`

export default function CompletedAddTradingPhotoCardsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const isExchange = currentQueryParams.get("isExchange") === "true";
  return(
    <>
      <AbsoluteCenter width={"calc(100% - 48px)"}>
        <VStack>
        <Image src="/assets/icons/FoldedHands.png"/>
        <TextWrapper>
          <Title>
            {t("DoneTradingPhotoCardsPage.title")}
          </Title>
          <Description>
            {t("DoneTradingPhotoCardsPage.description")}
          </Description>
        </TextWrapper>
        </VStack>
      </AbsoluteCenter>

      <Center>
        <RegularSolidButton
          className="absolute bottom-0"
          text={t("confirm")}
          style={{marginBottom: "calc(24px + env(safe-area-inset-bottom))", padding: "16px", width: "calc(100% - 48px)"}}
          onClicked={() => navigate(`/tradings/photo_cards?tabIndex=${isExchange ? 1 : 0}`, { replace: true })}
        />
      </Center>
    </>
  )
}
