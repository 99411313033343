import React from 'react';
import ReactDOM from 'react-dom/client';

import { HelmetProvider } from 'react-helmet-async';

import * as Sentry from '@sentry/react';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import './safeArea.css';
import './i18n/i18n.js';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n.js';

if (process.env.REACT_APP_DEPLOY_ENV !== "local") {
  Sentry.init({
    dsn: "https://ebf0bb8a73b3c7d6a85d88457eeb3fb5@o4505141831270400.ingest.sentry.io/4505737498394624",
    environment: process.env.REACT_APP_DEPLOY_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "http://api.pocapan.com", "http://dev-api.pocapan.com/"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.05, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.01, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
