import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

const DEFAULT_PAGE = 20;

export default function useChatRoomMessages(chatRoomId, perPage) {
  async function fetchChatRoomMessages({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage || DEFAULT_PAGE,
      page: pageParam,
    });
    const url = `/api/v1/chat_rooms/${chatRoomId}/chat_room_messages?${queryParams.toString()}`;
    try {
      const chatRooms = await queryGet(url);
      return chatRooms;
    } catch (error) {
      throw new Error("Error fetching paginated data.");
    }
  };

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["ChatRoomMessages", chatRoomId],
    fetchChatRoomMessages,
    {
      enabled: !!chatRoomId,
      getNextPageParam: (lastPage, _) => lastPage?.meta?.pagination?.nextPage,
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  };
}
