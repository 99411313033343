import PhotoCardResponse from "./PhotoCardResponse";
import UserProfileResponse from "./UserProfileResponse";

export default class TradingPhotoCardResponse {
  constructor({
    tradingPhotoCardId,
    userId,
    userProfileId,
    artistId,
    memberId,
    photoCardId,
    eventId,
    price,
    quantity,
    country,
    status,
    matchedCount,
    category,
    createdAt,
    updatedAt,
    requestedAt,
    isWished,
    memberName,
    eventName,
    photoCard,
    userProfile,
  }) {
    this.tradingPhotoCardId = tradingPhotoCardId;
    this.userId = userId;
    this.userProfileId = userProfileId;
    this.artistId = artistId;
    this.memberId = memberId;
    this.photoCardId = photoCardId;
    this.eventId = eventId;
    this.price = price;
    this.quantity = quantity;
    this.country = country;
    this.status = status;
    this.matchedCount = matchedCount;
    this.category = category;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.requestedAt = requestedAt;
    this.isWished = isWished;
    this.memberName = memberName;
    this.eventName = eventName;
    this.photoCard = new PhotoCardResponse(photoCard);
    this.userProfile = new UserProfileResponse(userProfile);
  }
}
