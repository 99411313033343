import React from "react";

import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogOverlay, HStack, Text, VStack } from '@chakra-ui/react';

import { useRef } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import { t } from "i18next";
import LightColors from "../../constants/LightColors";

const titleStyle ={
  fontWeight: 700,
  fontSize: "17px",
  color: "#222"
}

const descriptionStyle ={
  fontWeight: 400,
  fontSize: "15px",
  color: "#888",
  whiteSpace: "pre-line"
}

export default function CommonDialog({
  isOpen,
  onClose,
  onClickDialogButton,
  title,
  description,
  children,
  yesButtonText=t("confirm"),
  noButtonText=t("cancel"),
  isOneButton=false,
}) {
  const cancelRef = useRef();
  return (
  <AlertDialog
    isOpen={isOpen}
    autoFocus={false}
    closeOnOverlayClick={false}
    onClose={onClose}
    leastDestructiveRef={cancelRef.current}
    isCentered
    >
      <AlertDialogOverlay style={{ zIndex: 2147483647 }}>
        <AlertDialogContent borderRadius={"40px"} paddingTop={"32px"} paddingBottom={"16px"} marginX={"6%"} style={{boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)"}}>
          <AlertDialogBody paddingX={"32px"}>
            <VStack className="text-center">
              {title && <Text style={titleStyle}>{title}</Text>}
              {description && <Text style={descriptionStyle}>{description}</Text>}
            </VStack>
            {
              children && <div>
                {children}
              </div>
            }
            <HStack className="w-full" marginTop={"20px"}>
              {
                !isOneButton && <SecondaryButton
                  ref={cancelRef.current}
                  fontWeight={600}
                  fontSize={"17px"}
                  lineHeight={"20px"}
                  padding={"16px 34px"}
                  width={"100%"}
                  onClick={onClose}
                  color={LightColors.primary}
                  text={noButtonText}
                />
              }
              <PrimaryButton
                fontWeight={600}
                fontSize={"17px"}
                lineHeight={"20px"}
                padding={"16px 34px"}
                width={"100%"}
                onClick={() => onClickDialogButton()}
                text={yesButtonText}
              />
            </HStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
