import { t } from "i18next";
import { first, isEmpty } from "lodash";

export function reviewText(tradingPhotoCard) {
  if (!isEmpty(tradingPhotoCard.receivedReviews)) {
    return t("myPhotoCards.receivedReviewButton")
  } else if (!isEmpty(tradingPhotoCard.sentReviews)) {
    return t("myPhotoCards.sentReviewButton")
  } else {
    return t("myPhotoCards.sendReviewButton")
  }
}

export function isCompletedTrading(tradingPhotoCard) {
  return tradingPhotoCard.status === "completed" || tradingPhotoCard.status === "canceled" || tradingPhotoCard.status === "sold_out"
}

export function navigateReviewPath(tradingPhotoCard) {
  if (!isEmpty(tradingPhotoCard.receivedReviews)) {
    if (tradingPhotoCard.receivedReviews.length > 1) {
      return alert("멀티 리뷰 수신 시 구현 필요");
    } else {
      const firstReceivedReview = first(tradingPhotoCard.receivedReviews);
      return `/reviews/${firstReceivedReview.reviewId}`;
    }
  } else if (!isEmpty(tradingPhotoCard.sentReviews)) {
    const firstSentReview = first(tradingPhotoCard.sentReviews);
    return `/reviews/${firstSentReview.reviewId}`;
  } else {
    return `/trading_photo_cards/${tradingPhotoCard.tradingPhotoCardId}/select_chat_rooms`;
  }

}
